import { useRef } from "react";
import { useRichTextEditorContext } from "mui-tiptap";
import useOpenOrCloseToolMenu from "../../hooks/useOpenOrCloseToolMenu";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";
import useHandleClickOutsideMenuControls from "../../hooks/useHandleClickOutsideMenuControls";
import AddChipPaper from "../../../../../../features/pages/workstation/components/chat/chatbox/SendThreadTextField/addChipButton/AddChipPaper";
import { handleAddChip } from "../../functions/handleAddChip";
import insertText from "../../functions/insertText";
import { AssetNodeType } from "@toolflow/shared";

function ToolMenu() {
  const { muiTipTapProps, isSlash, setShouldRenderBubble } =
    useMuiTipTapContext();
  const { chipsToUse, assetNodeType = AssetNodeType.Text } = muiTipTapProps;
  const editor = useRichTextEditorContext();

  useOpenOrCloseToolMenu();

  const ref = useRef<HTMLDivElement>(null);
  useHandleClickOutsideMenuControls(ref);

  const close = () => {
    setShouldRenderBubble(false);
  };

  const onClickChip = [AssetNodeType.Chip, AssetNodeType.Node].includes(
    assetNodeType
  )
    ? handleAddChip(editor, isSlash, close)
    : insertText(editor, chipsToUse, isSlash, close);

  return (
    <div ref={ref}>
      <AddChipPaper onClickChip={onClickChip} chipsToUse={chipsToUse} />
    </div>
  );
}

export default ToolMenu;
