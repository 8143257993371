// for tools:
// public - anyone can see it, its on their profile, and people can duplicate it
// restricted - anyone with the link can access it, and people can duplicate it
// private - only the creator can see it, and people can't duplicate it

// for workspaces
// public - anyone can see it and save it
// private - only the creator can see it and save it
// restricted - anyone with the link can see it and save it

export enum TCategory {
  Sales = "sales",
  Marketing = "marketing",
  Growth = "growth",
  Blocks = "blocks",
  Integrations = "integrations",
  Utilities = "utilities"
}

export const CATEGORIES_ARRAY = [
  TCategory.Sales,
  TCategory.Marketing,
  TCategory.Growth,
  TCategory.Utilities
];
