import { FormControl } from "@mui/material";
import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import {
  type BlockSettingsBase,
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type PipedreamFieldType
} from "@toolflow/shared";
import { CSSProperties, useEffect } from "react";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { Switch } from "../../../../builderDrawer/components/drawerContent/outputs/outputFields/Switch";
import useUpdateField from "../../../../useUpdateField";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../hooks/useFieldsByTypeHelper";

export function PipedreamBoolean(props: $TSAllowedAny) {
  const { onChange, prop, value } = useFormFieldContext();
  const { id, field, data } = useFieldsByTypeContext<
    PipedreamFieldType,
    BlockSettingsBase
  >();
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });
  const updateField = useUpdateField(id);
  const { getProps } = useCustomize();

  const baseStyles: CSSProperties = {
    width: "100%",
    gridArea: "label"
  };

  const fieldKey = prop?.name || field.config.fieldKey;
  const typeKey = `${fieldKey}_type`;
  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = value ?? prop?.default ?? false;

  useEffect(() => {
    if (value === undefined && prop?.default !== undefined) {
      onChange(prop.default);
      updateField(prop.default, fieldKey);
    }
  }, []);

  const handleChange = (newValue: string | boolean) => {
    updateField(newValue, fieldKey);
    onChange(newValue);
  };

  const handleTypeChange = (newType: DynamicFieldType) => {
    updateField(newType, typeKey);
    if (newType === DynamicFieldType.Dynamic) {
      handleChange(CREATE_USER_INPUT_LABEL);
    } else {
      handleChange(false);
    }
  };

  return (
    <div {...getProps("label", baseStyles, props)}>
      <FormControl margin="normal" fullWidth>
        <DynamicFieldWrapper
          id={id}
          type={dynamicFieldValue}
          label={prop?.label}
          configs={{
            fieldKey,
            typeKey,
            forceDynamic: false
          }}
          fieldValue={fieldValue}
          onTypeChangeCallback={handleTypeChange}
          addInputType={CustomToolInputFieldTypesEnum.CHECKBOX}
        >
          <div className="nowheel nodrag nopan m-t-16px">
            <Switch
              checked={!!fieldValue}
              label=""
              onChange={(checked) => handleChange(checked)}
            />
          </div>
        </DynamicFieldWrapper>
      </FormControl>
    </div>
  );
}
