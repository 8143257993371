import React, { FC } from "react";
import NavigateToToolBuilderButton from "../builder/toolBuilder/NavigateToToolBuilderButton";
import NavigateToWorkflowBuilderButton from "../builder/workflowBuilder/NavigateToWorkflowBuilderButton";
import {
  AGENT,
  type ButtonVariantWithIcon,
  EntityType,
  type TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import NavigateToAgentBuilderButton from "../builder/agentBuilder/NavigateToAgentBuilderButton";

interface INavigateToBuilderMenuItemProps {
  entityId: string;
  type: TMarketplaceUniversalEntityType;
  variant?: ButtonVariantWithIcon;
}

const NavigateToBuilderButton: FC<INavigateToBuilderMenuItemProps> = ({
  entityId,
  type,
  variant = "icon"
}) => {
  if (type === EntityType.WORKFLOW) {
    return (
      <NavigateToWorkflowBuilderButton
        workflowId={entityId}
        variant={variant}
      />
    );
  } else if (type === EntityType.TOOL) {
    return <NavigateToToolBuilderButton toolId={entityId} variant={variant} />;
  } else if (type === AGENT) {
    return (
      <NavigateToAgentBuilderButton agentId={entityId} variant={variant} />
    );
  }
};

export default NavigateToBuilderButton;
