import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { globalEventEmitterFE } from "../../../../../../../utilities/eventEmitter/globalEventEmitterFE";
import useGetCurrentChatThreadId from "./useGetCurrentChatThreadId";
import {
  completeSocketMessage,
  setThreadMessagesLoading,
  updateLastMessageSettings,
  updateMessagesWithNewSocketMessage,
  setThreadError
} from "../chatSlice";
import { EMessageRole, EMessageSocketType } from "@toolflow/shared";

const useChatSocket = () => {
  const dispatch = useDispatch();
  const currentThreadId = useGetCurrentChatThreadId();

  useEffect(() => {
    const handleWsMessage = (emittedString: string) => {
      const emittedMessage = JSON.parse(emittedString);
      if (emittedMessage.componentId === EMessageRole.ASSISTANT) {
        dispatch(setThreadMessagesLoading(true));
        if (emittedMessage.type === EMessageSocketType.COMPLETED_MESSAGE) {
          dispatch(
            completeSocketMessage({
              id: emittedMessage.messageId,
              settings: emittedMessage.settings
            })
          );
          dispatch(setThreadMessagesLoading(false));
        } else if (emittedMessage.type === EMessageSocketType.UPDATE_MESSAGE) {
          dispatch(
            updateMessagesWithNewSocketMessage({
              message: emittedMessage.message,
              id: emittedMessage.messageId
            })
          );
        } else if (
          emittedMessage.type === EMessageSocketType.CREATE_MESSAGE ||
          emittedMessage.type === EMessageSocketType.UPDATE_LOADING_STATUS
        ) {
          dispatch(
            updateLastMessageSettings({
              settings: emittedMessage.settings,
              id: emittedMessage.id
            })
          );
        } else if (emittedMessage.type === EMessageSocketType.THREAD_ERROR) {
          dispatch(setThreadMessagesLoading(false));
          dispatch(setThreadError(true));
        }
      }
    };

    globalEventEmitterFE.on("ws_message", handleWsMessage);

    return () => {
      globalEventEmitterFE.off("ws_message", handleWsMessage);
    };
  }, [currentThreadId]);
};
export default useChatSocket;
