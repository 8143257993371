import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import useValidateObject from "../../../utilities/hooks/useValidateObject";
import CircularLoading from "../../../utilities/components/loading/CircularLoading";
import useNavigateToWorkspaceWithDefaultObject from "../workstation/hooks/useNavigateToWorkspaceWithDefaultObject";
import ExternalPageWrapper from "./externalPageWrapper/ExternalPageWrapper";

function ReroutePage() {
  const { isError, isSuccess, type, externalId } = useValidateObject();
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject();

  useEffect(() => {
    if (isSuccess) {
      navigateToWorkspace({
        type,
        id: externalId
      })();
    }
  }, [isSuccess]);

  if (isError) {
    return <Navigate to="/" replace />;
  }

  return (
    <ExternalPageWrapper enableFullHeight>
      <CircularLoading />
    </ExternalPageWrapper>
  );
}

export default ReroutePage;
