import { useState } from "react";
import {
  ArrowDownSmallIcon,
  ArrowUpSmallIcon
} from "../../../globalTheme/icons/icons";
import { Chip, Collapse, Typography } from "@mui/material";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";

import styles from "./thinkingAccordion.module.css";

const cx = parsedClassNames.bind(styles);

function ThinkingAccordion({ value }: { value?: string }) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div onChange={handleChange} className="cursor-pointer m-h-10px">
      <Chip
        label="Thought process"
        size="small"
        icon={
          expanded ? (
            <ArrowUpSmallIcon size={12} color={toolflowTextDisabledColor} />
          ) : (
            <ArrowDownSmallIcon size={12} color={toolflowTextDisabledColor} />
          )
        }
        onClick={handleChange}
        className={cx(styles["thinking-accordion"])}
      />
      <Collapse
        in={expanded}
        className={cx("m-t-8px", { "m-b-16px": expanded })}
      >
        <Typography variant="caption" color="text.secondary">
          {value}
        </Typography>
      </Collapse>
    </div>
  );
}

export default ThinkingAccordion;
