import type {
  CustomToolOutputField,
  UserInputDictType
} from "@toolflow/shared";
import React from "react";
import EditorToolCard from "../../../../../tools/components/editorToolCard/EditorToolCard";
import { EditorToolCardContextComponent } from "../../../../../tools/components/editorToolCard/EditorToolCardContext";
import useCurrentAssetsRef from "../../../assets/useCurrentAssetsRef";
import useSetToolOutput from "../../../hooks/useSetToolOutput";
import useUpdateAssetsOnToolOutputChange from "../../../hooks/useUpdateInputsOnToolOutputChange";

function EditorToolCardContainerInnerWorkspace({
  componentId,
  userInput,
  handleClose,
  setUserInput,
  additionalActions,
  fieldsToHide = [],
  addInputsCallback,
  hideEntityHeader
}: {
  componentId: string;
  userInput: UserInputDictType;
  handleClose: () => void;
  setUserInput: (input: UserInputDictType) => void;
  additionalActions?: React.ReactNode;
  fieldsToHide?: string[];
  addInputsCallback?: (id: string, outputField: CustomToolOutputField) => void;
  hideEntityHeader?: boolean;
}) {
  const [highlightedText, setHighlightedText] = React.useState("");
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [tvrId, setTVRId] = React.useState("");
  const [percentCompleted, setPercentCompleted] = React.useState(0);
  const [toolOutput, setToolOutputBase] =
    React.useState<UserInputDictType | null>(null);
  const currentAssets = useCurrentAssetsRef();

  const setToolOutput = useSetToolOutput({
    currentAssets,
    userInput,
    componentId,
    tvrId,
    setToolOutputBase
  });

  useUpdateAssetsOnToolOutputChange({
    currentAssets,
    toolOutput,
    componentId,
    addInputsCallback
  });

  return (
    <EditorToolCardContextComponent
      userInput={userInput}
      highlightedText={highlightedText}
      componentId={componentId}
      tvrId={tvrId}
      percentCompleted={percentCompleted}
      toolOutput={toolOutput}
      hasLoaded={hasLoaded}
      handleClose={handleClose}
      setToolOutput={setToolOutput}
      setPercentCompleted={setPercentCompleted}
      setHighlightedText={setHighlightedText}
      setHasLoaded={setHasLoaded}
      setUserInput={setUserInput}
      setTVRId={setTVRId}
      additionalActions={additionalActions}
      fieldsToHide={fieldsToHide}
      hideEntityHeader={hideEntityHeader}
    >
      <EditorToolCard />
    </EditorToolCardContextComponent>
  );
}

export default EditorToolCardContainerInnerWorkspace;
