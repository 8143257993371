import type {
  CustomToolInputField,
  TSetFormState,
  UserInputDictType
} from "@toolflow/shared";
import FileUploadButton from "../../../../files/fileUpload/FileUploadButton";
import FieldWrapper from "./wrapper/FieldWrapper";

export const InputFileUpload = (props: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  useIds?: boolean;
}) => {
  const { toolInputField, setFormState, formState, disabled, useIds } = props;

  return (
    <FieldWrapper {...props}>
      <FileUploadButton
        hideLabel
        description={toolInputField.description}
        name={toolInputField.name}
        id={toolInputField.id}
        setFormState={setFormState}
        disabled={disabled}
        formState={formState}
        useIds={useIds}
      />
    </FieldWrapper>
  );
};
