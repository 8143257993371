import {
  BlockType,
  VERSION_1_VALUE,
  UtilBlockType,
  type AllBlockTypes,
  type BlockFieldsConfig,
  type DecimalString,
  type FieldsConfig
} from "../../../../types";
import { constantBlockField } from "./constant/constantBlockField";
import { dallEBlockFields } from "./dalle/dallEBlockFields";
import { deepgramBlockFields } from "./deepgram/deepgramBlockFields";
import { iterationExitBlockFields } from "./iterationExit/iterationExitBlockFields";
import { iterationStartBlockFields } from "./iterationStart/iterationStartBlockFields";
import { knowledgeBlockFields } from "./knowledge/knowledgeBlockFields";
import { logicBlockFields } from "./logic/logicBlockFields";
import { perplexityBlockFields } from "./perplexity/perplexityBlockFields";
import { promptBlockField } from "./prompt/promptBlockField";
import { savetoKnowledgeBlockFields } from "./savetoKnowledge/savetoKnowledgeBlockFields";
import { scraperBlockFields } from "./scraper/scraperBlockFields";
import { serpBlockFields } from "./serp/serpBlockFields";
import { userIntegrationBlockFields } from "./userIntegration/userIntegrationBlockFields";
import { structuredBlockFields } from "./structured/structuredBlockFields";
import { toolWithinToolBlockFields } from "./toolWithinTool/toolWithinToolBlockFields";
import { youtubeTranscriptBlockFields } from "./youtubeTranscript/youtubeTranscriptBlockFields";

const blockFieldsByBlockTypes: Record<AllBlockTypes, BlockFieldsConfig> = {
  [BlockType.DallE]: dallEBlockFields,
  [BlockType.Deepgram]: deepgramBlockFields,
  [BlockType.IterationExit]: iterationExitBlockFields,
  [BlockType.IterationStart]: iterationStartBlockFields,
  [BlockType.Knowledge]: knowledgeBlockFields,
  [BlockType.Perplexity]: perplexityBlockFields,
  [BlockType.ChatGPT]: promptBlockField,
  [BlockType.Anthropic]: promptBlockField,
  [BlockType.SavetoKnowledge]: savetoKnowledgeBlockFields,
  [BlockType.Scraper]: scraperBlockFields,
  [BlockType.SERP]: serpBlockFields,
  [BlockType.Structured]: structuredBlockFields,
  [BlockType.YoutubeTranscript]: youtubeTranscriptBlockFields,
  [BlockType.UserIntegration]: userIntegrationBlockFields,
  [UtilBlockType.Constant]: constantBlockField,
  [UtilBlockType.ToolWithinTool]: toolWithinToolBlockFields,
  [UtilBlockType.Logic]: logicBlockFields
};

export function getBlockFieldsByBlockType(
  blockType: AllBlockTypes,
  version: DecimalString = VERSION_1_VALUE
): FieldsConfig {
  const configs = blockFieldsByBlockTypes[blockType]?.[version] || [];
  const commonConfigs = blockFieldsByBlockTypes[blockType]?.common || [];

  return [...configs, ...commonConfigs];
}
