import type { EditorOptions } from "@tiptap/core";
import { useMemo } from "react";
import {
  ExtensionLocation,
  type TExtensionsConfig,
  type UseExtensionsOptions
} from "@toolflow/shared";
import { customExtensions } from "./customExtensions";
import { getExtensionsFromConfig } from "./getExtensionsFromConfig";
import { useLocation } from "react-router-dom";
import {
  homeMatcher,
  toolBuilderMatcher
} from "../../../../../../features/navigation/functions/matchers";

// We need to determine where the extensions are being called, to figure out
// what extensions to use. Otherwise context will break
export const useExtensionLocation = () => {
  const location = useLocation();

  const locationType = useMemo(() => {
    const isToolbuilder = toolBuilderMatcher(location.pathname);
    const isWorkspace = homeMatcher(location.pathname);
    if (isToolbuilder) {
      return ExtensionLocation.Toolbuilder;
    }
    if (isWorkspace) {
      return ExtensionLocation.Workspace;
    }
    return ExtensionLocation.Default;
  }, [location.pathname]);

  return locationType;
};

export default function useExtension({
  placeholder,
  disableCustomExtensions = false,
  enableAutolinkExtension = false,
  disableDefaultEnter = false,
  disableFormattingExtensions = false
}: TExtensionsConfig & UseExtensionsOptions = {}): EditorOptions["extensions"] {
  const locationType = useExtensionLocation();
  return useMemo(
    () =>
      getExtensionsFromConfig(
        {
          placeholder,
          disableCustomExtensions,
          enableAutolinkExtension,
          disableDefaultEnter,
          disableFormattingExtensions
        },
        customExtensions(locationType)
      ),
    [
      placeholder,
      disableCustomExtensions,
      enableAutolinkExtension,
      disableDefaultEnter,
      disableFormattingExtensions
    ]
  );
}
