import { ReactNode } from "react";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import styles from "./styles/pipedreamField.module.css";

const cx = parsedClassNames.bind(styles);

interface PipedreamAccordionProps {
  expanded: boolean;
  onToggle: () => void;
  children: ReactNode;
}

export function PipedreamAccordion({
  expanded,
  onToggle,
  children
}: PipedreamAccordionProps) {
  return (
    <AccordionWrapper
      title="Advanced settings"
      startsExpanded={expanded}
      className={cx("no-before-divider")}
      elevation={0}
      onChange={onToggle}
      noMaxWidth
    >
      {children}
    </AccordionWrapper>
  );
}
