import { CustomToolInputFieldTypesEnum } from "@toolflow/shared";

export const INPUT_TEXTFIELD_ARRAY: CustomToolInputFieldTypesEnum[] = [
  CustomToolInputFieldTypesEnum.TEXTFIELD,
  CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD
];

export const isSimilarInputType = (
  firstType: CustomToolInputFieldTypesEnum,
  secondType: CustomToolInputFieldTypesEnum
) => {
  return (
    firstType === secondType || // is it the same type
    (INPUT_TEXTFIELD_ARRAY.includes(secondType) && // or are they both of textfield types
      INPUT_TEXTFIELD_ARRAY.includes(firstType))
  );
};
