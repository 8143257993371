import {
  Condition,
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  FieldType,
  OpenAiImageModel,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  type BlockFieldsConfig,
  type DallE3BlockOptimization,
  type FieldTypeConfigs
} from "../../../../../types";
import { filterAvailableFieldsByFile } from "../../helpers";

const EvalDallE2HiddenV1: Condition = [
  "optimizations.model",
  "!=",
  OpenAiImageModel.DALLE2
];
const EvalDallE3HiddenV1: Condition = [
  "optimizations.model",
  "!=",
  OpenAiImageModel.DALLE3
];
const EvalDallE2HiddenV2: Condition = [
  "optimizations.model.value",
  "!=",
  OpenAiImageModel.DALLE2
];
const EvalDallE3HiddenV2: Condition = [
  "optimizations.model.value",
  "!=",
  OpenAiImageModel.DALLE3
];
const EvalDynamicModel: Condition = [
  "optimizations.model.type",
  "==",
  DynamicFieldType.Dynamic
];

const dallE2FieldsV1: FieldTypeConfigs[] = [
  {
    type: FieldType.SelectFieldV1,
    hidden: EvalDallE2HiddenV1,
    label: "Size",
    config: {
      fieldKey: "optimizations.size",
      options: ["256x256", "512x512", "1024x1024"]
    }
  },
  {
    type: FieldType.SimpleInput,
    label: "Count",
    hidden: EvalDallE2HiddenV1,
    config: {
      fieldKey: "optimizations.n",
      inputConfig: {
        type: "number",
        min: 1,
        max: 10
      }
    }
  }
];

const dallE3FieldsV1: FieldTypeConfigs[] = [
  {
    type: FieldType.SelectFieldV1,
    label: "Size",
    hidden: EvalDallE3HiddenV1,
    config: {
      fieldKey: "optimizations.size",
      options: ["1024x1024", "1792x1024", "1024x1792"]
    }
  },
  {
    type: FieldType.SelectFieldV1,
    label: "Quality",
    hidden: EvalDallE3HiddenV1,
    config: {
      fieldKey: "optimizations.quality",
      options: ["standard", "hd"]
    }
  },
  {
    type: FieldType.SelectFieldV1,
    label: "Style",
    hidden: EvalDallE3HiddenV1,
    config: {
      fieldKey: "optimizations.style",
      options: ["vivid", "natural"]
    }
  }
];

const dallE2FieldsV2: FieldTypeConfigs[] = [
  {
    type: FieldType.DynamicSelectField,
    label: "Size",
    hidden: EvalDallE2HiddenV2,
    config: {
      typeKey: "optimizations.size.type",
      fieldKey: "optimizations.size.value",
      options: ["256x256", "512x512", "1024x1024"],
      forceDynamic: EvalDynamicModel,
      filterAvailableFields: filterAvailableFieldsByFile,
      allowedFields: [CustomToolInputFieldTypesEnum.SELECT]
    }
  },
  {
    type: FieldType.DynamicInputField,
    label: "Count",
    hidden: EvalDallE2HiddenV2,
    config: {
      typeKey: "optimizations.n.type",
      fieldKey: "optimizations.n.value",
      htmlInputType: "number",
      forceDynamic: EvalDynamicModel,
      filterAvailableFields: filterAvailableFieldsByFile,
      allowedFields: [
        CustomToolInputFieldTypesEnum.SELECT,
        CustomToolInputFieldTypesEnum.NUMERIC_FIELD
      ]
    }
  }
];

const dallE3FieldsV2: FieldTypeConfigs[] = [
  {
    type: FieldType.DynamicSelectField,
    label: "Size",
    hidden: EvalDallE3HiddenV2,
    config: {
      typeKey: "optimizations.size.type",
      fieldKey: "optimizations.size.value",
      options: ["1024x1024", "1792x1024", "1024x1792"],
      forceDynamic: EvalDynamicModel,
      filterAvailableFields: filterAvailableFieldsByFile,
      allowedFields: [CustomToolInputFieldTypesEnum.SELECT]
    }
  },
  {
    type: FieldType.DynamicSelectField,
    label: "Quality",
    hidden: EvalDallE3HiddenV2,
    config: {
      typeKey: "optimizations.quality.type",
      fieldKey: "optimizations.quality.value",
      options: [
        { label: "Standard", value: "standard" },
        { label: "HD", value: "hd" }
      ],
      forceDynamic: EvalDynamicModel,
      filterAvailableFields: filterAvailableFieldsByFile,
      allowedFields: [CustomToolInputFieldTypesEnum.SELECT]
    }
  },
  {
    type: FieldType.DynamicSelectField,
    label: "Style",
    hidden: EvalDallE3HiddenV2,
    config: {
      typeKey: "optimizations.style.type",
      fieldKey: "optimizations.style.value",
      options: [
        { label: "Vivid", value: "vivid" },
        { label: "Natural", value: "natural" }
      ],
      forceDynamic: EvalDynamicModel,
      filterAvailableFields: filterAvailableFieldsByFile,
      allowedFields: [CustomToolInputFieldTypesEnum.SELECT]
    }
  }
];

export const dallEBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.Group,
      label: "Model",
      fields: [
        {
          type: FieldType.SelectFieldV1,
          label: "Model",
          onChange(value: OpenAiImageModel, _, updateField) {
            if (value === OpenAiImageModel.DALLE2) {
              updateField(undefined, "optimizations.style");
              updateField(undefined, "optimizations.quality");
              updateField(1, "optimizations.size");
            } else if (value === OpenAiImageModel.DALLE3) {
              updateField(1, "optimizations.n");
              updateField("1024x1024", "optimizations.size");
              updateField("vivid", "optimizations.style");
              updateField("standard", "optimizations.quality");
            }
          },
          config: {
            fieldKey: "optimizations.model",
            options: [OpenAiImageModel.DALLE2, OpenAiImageModel.DALLE3]
          }
        },
        ...dallE2FieldsV1,
        ...dallE3FieldsV1
      ]
    },
    {
      type: FieldType.Group,
      label: "Prompt",
      fields: [
        {
          type: FieldType.PromptInputV1,
          config: {
            fieldKey: "prompt"
          }
        }
      ]
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.PromptInputV2,
      label: "Prompt",
      subtitle: {
        condition: ["optimizations.model.value", "==", OpenAiImageModel.DALLE3],
        then: `Max 4000 characters. If you are piping in a response from another prompt, you may want to summarize the response to be fewer than 4000 characters.`,
        else: `Max 1000 characters. If you are piping in a response from another prompt, you may want to summarize the response to be fewer than 1000 characters.`
      },
      config: {
        fieldKey: "settings.prompt",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px"
      }
    },
    {
      type: FieldType.DynamicSelectField,
      label: "Model",
      subtitle: "Select image LLM",
      config: {
        typeKey: "optimizations.model.type",
        fieldKey: "optimizations.model.value",
        filterAvailableFields: filterAvailableFieldsByFile,
        allowedFields: [CustomToolInputFieldTypesEnum.SELECT],
        options: [
          { label: "Dall-E-2", value: OpenAiImageModel.DALLE2 },
          { label: "Dall-E-3", value: OpenAiImageModel.DALLE3 }
        ]
      },
      onChange(value, data, updateField) {
        if (value === OpenAiImageModel.DALLE3) {
          const optimizations = data.optimizations as DallE3BlockOptimization;
          if (optimizations.size.type !== DynamicFieldType.Dynamic) {
            updateField("1024x1024", "optimizations.size.value");
          }

          if (optimizations.n.type !== DynamicFieldType.Dynamic) {
            updateField(1, "optimizations.n.value");
          }

          if (optimizations.quality?.type !== DynamicFieldType.Dynamic) {
            updateField(DynamicFieldType.Preset, "optimizations.quality.type");
            updateField("standard", "optimizations.quality.value");
          }

          if (optimizations.style?.type !== DynamicFieldType.Dynamic) {
            updateField(DynamicFieldType.Preset, "optimizations.style.type");
            updateField("vivid", "optimizations.style.value");
          }
        } else {
          if (data.optimizations.size.type !== DynamicFieldType.Dynamic) {
            updateField("512x512", "optimizations.size.value");
          }

          if (data.optimizations.n.type !== DynamicFieldType.Dynamic) {
            updateField(1, "optimizations.n.value");
          }
        }
      }
    },
    {
      type: FieldType.Group,
      label: "Model settings",
      fields: [...dallE2FieldsV2, ...dallE3FieldsV2]
    }
  ]
};
