import { CopyableField, CustomToolInputFieldTypesEnum } from "@toolflow/shared";

const iconDict: Record<CustomToolInputFieldTypesEnum, string> = {
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: "📎",
  [CustomToolInputFieldTypesEnum.SELECT]: "🔽",
  [CustomToolInputFieldTypesEnum.CHECKBOX]: "✅",
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: "📝",
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: "📝",
  [CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD]: "📎",
  [CustomToolInputFieldTypesEnum.MULTI_SELECT]: "🔽",
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: "📝",
  [CustomToolInputFieldTypesEnum.TAGS_INPUT]: "️📋",
  [CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD]: "👫",
  [CustomToolInputFieldTypesEnum.NUMERIC_FIELD]: "🔢",
  [CustomToolInputFieldTypesEnum.SLIDER]: "🎚️",
  [CustomToolInputFieldTypesEnum.WORD_REPLACE]: "🔄",
  [CustomToolInputFieldTypesEnum.PIPEDREAM_ACTION]: "🔧",
  [CustomToolInputFieldTypesEnum.PIPEDREAM_FIELDS]: "🛠️",
  [CustomToolInputFieldTypesEnum.JSON_SCHEMA]: "📜" // Other options for this one "🗂️" "{}"
};

export const labelDict: Record<CustomToolInputFieldTypesEnum, string> = {
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: "File upload",
  [CustomToolInputFieldTypesEnum.SELECT]: "Dropdown selector",
  [CustomToolInputFieldTypesEnum.CHECKBOX]: "Checkbox",
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: "Text",
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: "Text",
  [CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD]: "CSV file Upload",
  [CustomToolInputFieldTypesEnum.MULTI_SELECT]: "Multiselect dropdown",
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: "Block output",
  [CustomToolInputFieldTypesEnum.TAGS_INPUT]: "List",
  [CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD]: "Text pairs",
  [CustomToolInputFieldTypesEnum.NUMERIC_FIELD]: "Number",
  [CustomToolInputFieldTypesEnum.SLIDER]: "Slider",
  [CustomToolInputFieldTypesEnum.WORD_REPLACE]: "Find and replace",
  [CustomToolInputFieldTypesEnum.PIPEDREAM_ACTION]: "Select an action",
  [CustomToolInputFieldTypesEnum.PIPEDREAM_FIELDS]: "Configurations",
  [CustomToolInputFieldTypesEnum.JSON_SCHEMA]: "Json schema"
};

export const outputIconDict: Record<CopyableField, string> = {
  [CopyableField.Image]: "🖼️",
  [CopyableField.LargeText]: "📝",
  [CopyableField.Structured]: "🗂️"
};

export default iconDict;
