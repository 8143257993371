import {
  BlockType,
  getInitialState,
  type JSONSchemaItem
} from "@toolflow/shared";

export default function useJsonSchemaConstructor({
  schema,
  onChange
}: {
  schema: JSONSchemaItem[];
  onChange(newSchema: JSONSchemaItem[]): void;
}) {
  function addSchemaItem() {
    const initialState = getInitialState(BlockType.Structured);
    const newSchema: JSONSchemaItem[] = [
      ...schema,
      ...initialState.settings.schema
    ];
    onChange(newSchema);
  }

  function removeIndex(index: number) {
    const newSchema = schema.filter((_, i) => i !== index);
    onChange(newSchema);
  }

  function updateSchemaItem(index: number, schemaItem: JSONSchemaItem) {
    const newSchema = [...schema];
    newSchema[index] = schemaItem;
    onChange(newSchema);
  }

  return {
    removeIndex,
    addSchemaItem,
    updateSchemaItem
  };
}
