import { Skeleton, type SxProps, Grid } from "@mui/material";
import React from "react";
import { useAuthStore } from "../../../features/auth/hooks/useAuthStore";
import type { TMarginTypes } from "@toolflow/shared";
import IntegrationEntityCardSkeleton from "../../../features/tools/components/searchToolsContainer/components/gridCards/IntegrationEntityCardSkeleton";

function ToolflowSkeletonWrapper({
  children,
  variant = "text",
  sx = {},
  width,
  height,
  loading = false,
  isTextField = false,
  margin = "none"
}: {
  children?: React.ReactNode;
  sx?: SxProps;
  variant?: "text" | "circular" | "rectangular" | "rounded";
  width?: string | number | undefined;
  height?: number;
  loading?: boolean;
  isTextField?: boolean;
  margin?: TMarginTypes;
}) {
  const { isLoading } = useAuthStore();
  let computedHeight = height;
  let computedVariant = variant;
  let computedSx = sx;

  if (isTextField) {
    computedHeight = 40;
    computedVariant = "rounded";
  }

  if (margin === "normal") {
    computedSx = { ...sx, marginTop: 2, marginBottom: 1 };
  } else if (margin === "dense") {
    computedSx = { ...sx, marginTop: 1, marginBottom: 0.5 };
  }

  if (isLoading || loading) {
    return (
      <Skeleton
        sx={computedSx}
        variant={computedVariant}
        width={width}
        height={computedHeight}
      />
    );
  }

  return <>{children}</>;
}

export function ToolsLoading() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <IntegrationEntityCardSkeleton />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <IntegrationEntityCardSkeleton />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <IntegrationEntityCardSkeleton />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <IntegrationEntityCardSkeleton />
        </Grid>
      </Grid>
    </>
  );
}

export default ToolflowSkeletonWrapper;
