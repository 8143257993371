import { Divider, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { FC } from "react";
import { SearchIcon } from "../../../../../../../../globalTheme/icons/icons";
import { ClickableTypes, type TAddChipConfig } from "@toolflow/shared";
import {
  classNames,
  parsedClassNames
} from "../../../../../../../../utilities/functions/parsedClassNames";
import { ALL } from "../runButton/helpers/constants";
import useAddChip from "../runButton/hooks/useAddChip";
import styles from "./addChipPaper.module.css";
import { ChipGroup } from "./ChipGroup";

const cx = parsedClassNames.bind(styles);

interface IAddChipPaperProps {
  className?: string;
  onClickChip: (type: ClickableTypes, id: string) => void;
  chipsToUse?: TAddChipConfig;
}

const AddChipPaper: FC<IAddChipPaperProps> = ({
  className,
  onClickChip,
  chipsToUse = {}
}) => {
  const {
    tabs,
    handleChangeTabIndex,
    selectedTabIndex,
    entitiesObject,
    setFilteredString,
    filteredString
  } = useAddChip({ chipsToUse });

  const getChipsExist = (type: string) => {
    return !!type;
  };

  return (
    <Box
      className={classNames(
        "p-t-10px p-b-20px h-100-percent flex flex-grow-1 flex-column",
        className
      )}
    >
      <Tabs
        value={selectedTabIndex}
        onChange={handleChangeTabIndex}
        className="p-h-20px"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 }
          }
        }}
      >
        {tabs.map((tab) => {
          if (tab.value !== ALL) {
            const { type } = entitiesObject[tab.value] || {};
            const chipsExist = getChipsExist(type);
            if (!chipsExist) return null;
          }
          return (
            <Tab
              key={tab.value}
              value={tab.indexValue}
              className={"min-w-unset"}
              label={
                <span className={`capitalize flex align-i-center`}>
                  {tab.icon ? <tab.icon size={16} className="m-r-4px" /> : null}
                  {tab.label}
                </span>
              }
            />
          );
        })}
      </Tabs>
      <Divider />
      <TextField
        size="small"
        margin="normal"
        fullWidth
        autoComplete="off"
        value={filteredString}
        className="p-h-20px"
        autoFocus
        InputProps={{
          startAdornment: <SearchIcon size={20} className="m-r-10px " />
        }}
        onChange={(e) => setFilteredString(e.target.value)}
      />
      <div
        className={cx(
          "scrollable-content h-100-percent w-100-percent flex-grow-1 p-h-20px",
          styles["entity-container"]
        )}
      >
        {tabs.map((tab, idx) => {
          if (tab.value === ALL) return null;
          const { entities, type, action, eventName } =
            entitiesObject[tab.value] || {};
          const chipsExist = getChipsExist(type);
          const isSelectedTab = selectedTabIndex === tab.indexValue;
          const hideOnTabsThatArentAll =
            selectedTabIndex !== 0 && !isSelectedTab;
          if (!chipsExist || hideOnTabsThatArentAll) return null;

          // Find if this is actually the first visible group by checking previous tabs
          const isFirstVisibleGroup = tabs.slice(1, idx).every((prevTab) => {
            if (prevTab.value === ALL) return true; // Skip the ALL tab
            const prevType = entitiesObject[prevTab.value]?.type;
            const prevChipsExist = prevType && getChipsExist(prevType);
            const isPrevTabSelected = selectedTabIndex === prevTab.indexValue;
            const hidePrevTab = selectedTabIndex !== 0 && !isPrevTabSelected;
            return !prevChipsExist || hidePrevTab;
          });

          return (
            <ChipGroup
              key={tab.value}
              name={tab.label}
              className={isFirstVisibleGroup ? "" : "m-t-24px"}
              type={type}
              entities={entities}
              onClickChip={onClickChip}
              action={action}
              eventName={eventName}
            />
          );
        })}
      </div>
    </Box>
  );
};

export default AddChipPaper;
