import React from "react";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import { WORKFLOWBUILDER_BASE_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import SettingsMenuItemWithClose from "../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { WorkflowIcon } from "../../../../globalTheme/icons/icons";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { EFeatureFlags } from "@toolflow/shared";

const NavigateToWorkflowBuilderMenuItem = () => {
  const navigate = useToolflowNavigate();
  const workflowBuilderEnabled = useFeatureFlagEnabled(
    EFeatureFlags.WORKFLOW_BUILDER
  );

  const navigateToWorkflowBuilder = () => {
    navigate(WORKFLOWBUILDER_BASE_ROUTE_PATH);
  };

  if (!workflowBuilderEnabled) {
    return null;
  }

  return (
    <SettingsMenuItemWithClose
      icon={<WorkflowIcon size={18} />}
      text="Workflow builder"
      action={navigateToWorkflowBuilder}
    />
  );
};

export default NavigateToWorkflowBuilderMenuItem;
