import { useDispatch } from "react-redux";
import { DrawerEnum, setDrawer } from "../../store/toolBuilderSlice";
import useUpdateField from "../../useUpdateField";
import type { PipedreamApp } from "@toolflow/shared";

export const useIntegrationSelection = (nodeId: string) => {
  const dispatch = useDispatch();
  const updateField = useUpdateField(nodeId);

  const handleActionSelect = (integration: PipedreamApp) => {
    updateField(integration.name_slug, "settings.selectedIntegrationType");
    updateField(integration.name, "label");
    updateField(integration.img_src, "settings.integrationLogo");
    updateField(integration.description, "description");
    updateField("", "settings.action");

    dispatch(
      setDrawer({
        type: DrawerEnum.node,
        nodeId
      })
    );
  };

  return { handleActionSelect };
};
