import React from "react";
import OpenAssetDialogButton from "../../../assetDialog/OpenAssetDialogButton";
import CopyInputText from "./CopyInputText";
import TextSettingsDropdown from "./TextSettingsDropdown";
import CloseAssetPanelButton from "../../../CloseAssetPanelButton";
import useHideIconsOnExpanded from "../../../hooks/useHideIconsOnExpanded";

const TextAssetIcons = () => {
  const hideIcons = useHideIconsOnExpanded();
  return (
    <div className="flex align-i-center show-on-hover">
      <CopyInputText />
      {hideIcons && <OpenAssetDialogButton />}
      <TextSettingsDropdown />
      {hideIcons && <CloseAssetPanelButton />}
    </div>
  );
};

export default TextAssetIcons;
