import { Box, Divider } from "@mui/material";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import MakeAssetListBigButton from "./MakeAssetListBigButton";
import MoveDownAnAssetButton from "./MoveDownAnAssetButton";
import MoveUpAnAssetButton from "./MoveUpAnAssetButton";
import styles from "./smallAssetTOC.module.css";

const cx = parsedClassNames.bind(styles);

function SmallAssetTOC() {
  return (
    <div className={"flex flex-column h-100-percent max-h-100-percent"}>
      <Box
        // className={cx(
        //   styles["small-asset-button-group"],
        //   "flex align-i-center flex-column asset-toc-box-shadow"

        // )}
        className={cx(
          styles["small-asset-button-group"],
          "asset-toc-box-shadow pos-absolute"
        )}
        style={{
          top: 64,
          right: 16
        }}
      >
        <MakeAssetListBigButton />
        <Divider orientation="horizontal" className="m-v-16px" flexItem />
        <MoveUpAnAssetButton />
        <MoveDownAnAssetButton />
      </Box>
    </div>
  );
}

export default SmallAssetTOC;
