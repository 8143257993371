import { Button } from "@mui/material";
import type {
  AgentToolProps,
  SelectToolsFieldType,
  TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import { useState } from "react";
import { SearchIcon } from "../../../../../../../../globalTheme/icons/icons";
import {
  SearchEntitiesDialog,
  SearchEntitiesDialogType
} from "../../../../../../../tools/components/searchEntitiesDialog/SearchEntitiesDialog";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import InputLabelWrapper from "../../components/InputLabelWrapper";
import useDynamicSetting from "../../hooks/useDynamicSetting";
import ToolListComponent from "./inputs/ToolListComponent";

const SelectToolsField = () => {
  const { field, getFieldValue, updateField } =
    useFieldsByTypeContext<SelectToolsFieldType>();
  const { fieldKey, className, lastUpdated } = field.config;

  const [editFormProps, setEditFormProps] = useState<{
    toolId: string;
    props?: Record<string, $TSAllowedAny>;
    isConfigureOnly?: boolean;
  }>();

  const currentValue = getFieldValue<AgentToolProps>(fieldKey || "", {});

  const updateToolId = (
    value: string,
    _: TMarketplaceUniversalEntityType,
    userInput: Record<string, $TSAllowedAny> = {}
  ) => {
    const updatedValue = { ...currentValue };
    updatedValue[value] = { userInput };
    updateField(updatedValue, fieldKey);
  };

  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const disabled = useDynamicSetting<boolean>(field.disabled);

  const onDeleteClick = (toolId: string) => {
    const updatedValue = { ...currentValue };
    delete updatedValue[toolId];
    updateField(updatedValue, fieldKey);
  };

  return (
    <InputLabelWrapper
      label={label}
      subtitle={subtitle}
      className={`${className} m-b-16px`}
    >
      <ToolListComponent
        toolIds={Object.keys(currentValue)}
        onEditClick={(toolId) => {
          setEditFormProps({
            toolId,
            props: currentValue[toolId].userInput,
            isConfigureOnly: true
          });
        }}
        onDeleteClick={onDeleteClick}
        lastUpdated={lastUpdated}
      />
      <div>
        <SearchEntitiesDialog
          allowConfiguration
          title="Add tool"
          editFormProps={editFormProps}
          onClickEntity={updateToolId}
          type={SearchEntitiesDialogType.TOOLS}
          disabledEntities={{ workflows: true, agents: true }}
          button={
            <Button
              onClick={() => {}}
              startIcon={<SearchIcon size={14} />}
              disabled={disabled}
            >
              Add tool
            </Button>
          }
        />
      </div>
    </InputLabelWrapper>
  );
};

export default SelectToolsField;
