import React from "react";
import { Box, Typography } from "@mui/material";
import { DarkUtilityTooltip } from "../../../../../../utilities/components/toolTips/DarkUtilityTooltip";

function AssetName({ text }: { text: string }) {
  return (
    <Box sx={{ width: "calc(100% - 140px)" }}>
      <DarkUtilityTooltip title={text}>
        <Typography variant="h7" noWrap>
          {text}
        </Typography>
      </DarkUtilityTooltip>
    </Box>
  );
}

export default AssetName;
