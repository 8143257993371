import Box from "@mui/material/Box";
import useEntityCategory from "./hooks/useCategoryTabs";
import { MarketplaceChip } from "../useCases/UseCaseChips";
import { type TMarketplaceTypes } from "@toolflow/shared";

const CategoryTabs = ({ entityType }: { entityType: TMarketplaceTypes }) => {
  const {
    selectMarketplaceTab,
    selectedTabIndex,
    marketplaceCategoryTabs: tabs
  } = useEntityCategory(entityType);

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "16px"
      }}
    >
      {tabs.map((tab, index) => {
        const isTabSelected = selectedTabIndex === index;
        if (tab.disabledType?.includes(entityType)) {
          return null;
        }
        return (
          <MarketplaceChip
            key={tab.value}
            selected={isTabSelected}
            value={tab.label}
            onClick={() => selectMarketplaceTab(tab.value)}
          />
        );
      })}
    </Box>
  );
};

export default CategoryTabs;
