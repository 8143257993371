import { Box } from "@mui/material";
import React from "react";
import AssetTableOfContents from "./AssetTableOfContents";

const AssetTOCPositioningWrapper = () => {
  return (
    <Box
      className="h-100-percent"
      sx={{
        position: { xs: "absolute", lg: "relative" },
        top: { xs: 0, lg: "auto" },
        right: { xs: 8, lg: "auto" },
        // width: { xs: "50px", lg: "auto" },
        // marginTop: { xs: 0, lg: "24px" },
        // marginRight: { xs: "0px", lg: "24px" },
        // marginBottom: { xs: "0px", lg: "24px" },
        maxHeight: { xs: "100%", lg: "calc(100% - 48px)" }
      }}
    >
      <AssetTableOfContents />
    </Box>
  );
};

export default AssetTOCPositioningWrapper;
