import { useMemo } from "react";
import OutputLoadingWrapper from "../../../../../utilities/components/loading/HideWhenLoadingWrapper";
import { GenericRichHtml } from "../../../../../utilities/components/textFields/workspace/GenericRichText";
import { useHandleCopyText } from "../../../../../utilities/components/copyContent/hooks/useHandleCopy";
import { CopyIcon } from "../../../../../globalTheme/icons/icons";
import { getHtmlFromText } from "@toolflow/shared";
import RemixButton from "../../../../../utilities/components/icons/RemixButton";

const CopyableLargeTextField = ({
  disabledCopy,
  showLoading,
  value,
  tvrId
}: {
  disabledCopy?: boolean;
  showLoading?: boolean;
  value: string;
  tvrId?: string;
}) => {
  const memoizedHtml = useMemo(() => getHtmlFromText(value), [value]);
  const handleCopy = useHandleCopyText();

  const handleCopyClick = () => {
    handleCopy(value, tvrId);
  };

  return (
    <OutputLoadingWrapper showLoading={showLoading}>
      <div className="pos-relative parent-hover">
        <RemixButton
          icon={CopyIcon}
          onClick={handleCopyClick}
          disabled={disabledCopy}
          className="pos-absolute cursor-pointer top-0 right-0 z-1000 pos-absolute show-on-hover"
        />
        <GenericRichHtml
          html={memoizedHtml}
          toolVersionResponseId={tvrId}
          rerender
        />
      </div>
    </OutputLoadingWrapper>
  );
};

export default CopyableLargeTextField;
