import { ClickableTypes } from "@toolflow/shared";
import SnippetChip from "./SnippetChip";

function ClickableChip({
  text,
  type,
  id,
  onClick,
  tooltipText
}: {
  text: string;
  type: ClickableTypes;
  id: string;
  onClick: (type: ClickableTypes, id: string) => void;
  tooltipText?: string;
}) {
  const handleClick = () => {
    onClick(type, id);
  };

  return (
    <SnippetChip
      text={text}
      type={type}
      className="m-t-8px m-r-8px cursor-pointer"
      onClick={handleClick}
      tooltipText={tooltipText}
    />
  );
}

export default ClickableChip;
