import { Box } from "@mui/material";
import type { AssetBaseIdsBySourceIdentifier } from "@toolflow/shared";
import React from "react";
import CondenseAssetListButton from "./CloseAssetListButton";
import AssetGroup from "./assetGroup/AssetGroup";
import styles from "./expandedAssetList.module.css";

import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import useTransformWorkspaceAsset from "../../../../assets/useTransformWorkspaceAsset";

const cx = parsedClassNames.bind(styles);

function AssetList({
  transformedAssets: transformedWorkflowInputs
}: {
  transformedAssets: AssetBaseIdsBySourceIdentifier;
}) {
  return (
    <div
      className={cx(
        "scrollable-content",
        styles["asset-padding"],
        styles["list-height"]
      )}
    >
      {Object.entries(transformedWorkflowInputs).map(
        ([sourceIdentifier, objectValue]) => (
          <AssetGroup key={sourceIdentifier} objectValue={objectValue} />
        )
      )}
    </div>
  );
}

function AssetListContainer() {
  const transformedAssets = useTransformWorkspaceAsset();
  return <AssetList transformedAssets={transformedAssets} />;
}

function ExpandedAssetList() {
  return (
    <div className="pos-relative flex h-100-percent">
      <Box
        className={cx(
          styles["expanded-list-outer"],
          "asset-toc-box-shadow pos-absolute"
        )}
        style={{
          top: 64,
          right: 16
        }}
      >
        <div
          className={cx(
            "align-i-center flex justify-flex-end",
            styles["asset-padding"],
            styles["asset-bottom-margin"]
          )}
        >
          <CondenseAssetListButton />
        </div>
        <AssetListContainer />
      </Box>
    </div>
  );
}

export default ExpandedAssetList;
