import type { Extension } from "@tiptap/core";
import {
  AssetExtensionConfig,
  ExtensionLocation,
  FlowAssetExtensionConfig,
  FlowThinkingExtensionConfig,
  NodeOutputViewerExtensionConfig,
  SearchToolResponseExtensionConfig,
  SnippetExtensionConfig,
  ThinkingExtensionConfig,
  ToolbuilderInputExtensionConfig,
  HiddenContextExtensionConfig
} from "@toolflow/shared";
import AssetViewerComponent from "../../tiptapExtensions/AssetViewerComponent";
import createNodeFromConfig from "../../tiptapExtensions/helpers/createNodeFromConfig";
import NodeOutputViewerComponent from "../../tiptapExtensions/NodeOutputViewerComponent";
import SearchToolResponseComponent from "../../tiptapExtensions/SearchToolResponseComponent/SearchToolResponseComponent";
import SnippetViewerComponent from "../../tiptapExtensions/SnippetViewerComponent";
import ToolbuilderInputViewerComponent from "../../tiptapExtensions/ToolbuilderInputViewerComponent";
import FlowThinkingViewerComponent from "../../tiptapExtensions/FlowThinkingViewerComponent";
import HiddenContextComponent from "../../tiptapExtensions/HiddenContextComponent";
import ThinkingViewerComponent from "../../tiptapExtensions/ThinkingViewerComponent";
import FlowAssetViewerComponent from "../../tiptapExtensions/FlowAssetViewerComponent";

const extensionsConfig = {
  InputViewerExtension: {
    config: AssetExtensionConfig,
    component: AssetViewerComponent,
    type: ExtensionLocation.Workspace
  },
  SnippetViewerExtension: {
    config: SnippetExtensionConfig,
    component: SnippetViewerComponent
  },
  ToolbuilderInputViewerExtension: {
    config: ToolbuilderInputExtensionConfig,
    component: ToolbuilderInputViewerComponent,
    type: ExtensionLocation.Toolbuilder
  },
  SearchToolResponseExtension: {
    config: SearchToolResponseExtensionConfig,
    component: SearchToolResponseComponent,
    type: ExtensionLocation.Workspace
  },
  NodeOutputViewerExtension: {
    config: NodeOutputViewerExtensionConfig,
    component: NodeOutputViewerComponent,
    type: ExtensionLocation.Toolbuilder
  },
  FlowThinkingExtension: {
    config: FlowThinkingExtensionConfig,
    component: FlowThinkingViewerComponent
  },
  FlowAssetExtension: {
    config: FlowAssetExtensionConfig,
    component: FlowAssetViewerComponent,
    type: ExtensionLocation.Workspace
  },
  ThinkingExtension: {
    config: ThinkingExtensionConfig,
    component: ThinkingViewerComponent
  },
  HiddenContextExtension: {
    config: HiddenContextExtensionConfig,
    component: HiddenContextComponent
  }
};

export const customExtensions: (typFilter: ExtensionLocation) => Extension[] = (
  typFilter: ExtensionLocation
) =>
  Object.values(extensionsConfig)
    .filter((value) => {
      return "type" in value ? value.type === typFilter : true;
    })
    .map(({ config, component }) => createNodeFromConfig(config, component));
