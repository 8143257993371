import type {
  ButtonVariantWithIcon,
  TMarketplaceUniversalEntity
} from "@toolflow/shared";
import { useGetCurrentUserProfileId } from "../../../user/hooks/useGetCurrentUserProfileId";
import HomePageMenuEntityDropdownMenu from "../../../entities/components/HomePageMenuEntityDropdownMenu";
import CopyEntityLink from "../../../tools/components/searchToolsContainer/CopyEntityLink";
import NavigateToBuilderButton from "../../../pages/home/NavigateToBuilderButton";
import DuplicateHomePageEntityMenuItem from "../../../pages/builder/toolBuilder/dropdowns/menuItems/DuplicateHomePageEntityMenuItem";

const SecondaryAction = ({
  entity,
  variant = "icon"
}: {
  entity: TMarketplaceUniversalEntity;
  variant?: ButtonVariantWithIcon;
}) => {
  const profileId = useGetCurrentUserProfileId();
  return (
    <>
      {entity.userId === profileId ? (
        <NavigateToBuilderButton
          entityId={entity.id}
          type={entity.type}
          variant={variant}
        />
      ) : (
        <DuplicateHomePageEntityMenuItem
          id={entity.id}
          type={entity.type}
          iconOnly={true}
          enableForking={entity.enableForking}
        />
      )}
    </>
  );
};

export const OwnerActions = ({
  entity,
  variant = "icon",
  children
}: {
  entity: TMarketplaceUniversalEntity;
  variant?: ButtonVariantWithIcon;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <HomePageMenuEntityDropdownMenu homPageEntity={entity} />
      <div className="m-h-8px flex align-i-center">
        <SecondaryAction entity={entity} variant={variant} />
      </div>
      {children}
    </>
  );
};

export const MarketplaceOwnerActions = ({
  entity
}: {
  entity: TMarketplaceUniversalEntity;
}) => {
  return (
    <>
      <CopyEntityLink
        entityId={entity.id}
        entityType={entity.type}
        variant="text"
      />
      <SecondaryAction entity={entity} />
      <HomePageMenuEntityDropdownMenu homPageEntity={entity} />
    </>
  );
};
