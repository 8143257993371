import { BlockNode } from "./blockTypes";

export const BlockTypDescriptionMap: Record<BlockNode, string> = {
  [BlockNode.PromptBlockNode]:
    "Send a query to a large language model and get a text response.",
  [BlockNode.ToolWithinToolBlockNode]:
    "Use an embedded Toolflow tool to return a response.",
  [BlockNode.LogicBlockNode]:
    "Determine whether the subsequent blocks should run.",
  [BlockNode.ConstantBlockNode]: "Compose text with variables.",
  [BlockNode.DeepgramBlockNode]:
    "Transcribe video or audio files to text using large language models.",
  [BlockNode.ScraperBlockNode]: "Scrape websites for text, markdown, or HTML.",
  [BlockNode.SerpBlockNode]:
    "Fetches real-time Google search results, including organic listings, ads, People Also Ask, and related queries for deeper insights.",
  [BlockNode.StructuredBlockNode]:
    "Extract structured outputs from text inputs.",
  [BlockNode.PerplexityBlockNode]:
    "Conduct AI-powered research with cited sources, retrieving summarized and contextual search results.",
  [BlockNode.IterationStartBlockNode]: "Start an iteration on a list.",
  [BlockNode.IterationExitBlockNode]: "Exit an iteration of a list.",
  [BlockNode.DallEBlockNode]:
    "Send a query to a large language model and get an image response.",
  [BlockNode.KnowledgeNode]:
    "Retrieves knowledge from your connected knowledge bases in Toolflow using RAG (Retrieval-Augmented Generation).",
  [BlockNode.SavetoKnowledgeNode]:
    "Add a new item to your Toolflow knowledge base for future retrieval and use.",
  [BlockNode.UserIntegrationBlockNode]: "",
  [BlockNode.YoutubeTranscriptNode]:
    "Retrieve title, description, duration, and transcript from any YouTube URL."
};
