import { CustomToolInputFieldTypesEnum } from "@toolflow/shared";
import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";
import useGetSelectedStepInputsToCollect from "./useGetSelectedStepInputToCollect";
import { updateInputType } from "../../../../../../tools/components/editorToolCard/inputs/helpers/constants";

const useChangeInputTypeWorkflow = () => {
  const { update } = useInputsToCollectFieldArrayContext();
  const inputsToCollect = useGetSelectedStepInputsToCollect();
  const changeInputType = (
    type: CustomToolInputFieldTypesEnum,
    index: number
  ) => {
    const newInput = updateInputType(type, inputsToCollect[index]);
    update(index, newInput);
  };

  return changeInputType;
};

export default useChangeInputTypeWorkflow;
