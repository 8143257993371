import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updatePaths } from "../navigationSlice";
import { setToolflowAlternativeUrl } from "./setToolflowAlternativeUrl";
import { TOOLBUILDER_BASE_ROUTE_PATH } from "../helpers/routePaths";
import { match } from "path-to-regexp";
import { setDrawer } from "../../pages/builder/toolBuilder/store/toolBuilderSlice";
import { setHasSetInitialObject } from "../../pages/workstation/state/workspaceSlice";
import { homeMatcher } from "../functions/matchers";

const useResetToolflowAlternativeUrl = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      // we keep the toolflow alternative url when we navigate to the workspace
      // because we navigate to the workspace from the useNavigateToWorkspaceWithDefaultObject
      // but in other cases, we want to reset the toolflow alternative url so that users don't login to random places
      setToolflowAlternativeUrl(undefined);
    }
  }, [location]);
};

// We set the initial object when we navigate to the workspace
// we use this flag to check whether someone sees it on the new workspace page
// if they navigate away from the workspace when logged out, we want them to see logged out screens
const useResetHasSetInitialObject = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (!homeMatcher(location.pathname)) {
      dispatch(setHasSetInitialObject(false));
    }
  }, [location]);
};

const useResetToolBuilderDrawer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (
      !match(TOOLBUILDER_BASE_ROUTE_PATH, { decode: decodeURIComponent })(
        location.pathname
      )
    ) {
      dispatch(setDrawer(null));
    }
  }, [location]);
};

const useRouterListener = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useResetToolflowAlternativeUrl();
  useResetToolBuilderDrawer();
  useResetHasSetInitialObject();

  useEffect(() => {
    dispatch(
      updatePaths({
        path: location.pathname,
        replaced: location.state?.replaced
      })
    );
  }, [location, dispatch]);

  return null;
};

export default useRouterListener;
