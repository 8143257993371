import { Stack } from "@mui/material";
import type { JSONContent } from "@tiptap/core";
import { FieldType, type UserInputDictType } from "@toolflow/shared";
import FileUploadButton from "../../../../../../../files/fileUpload/FileUploadButton";
import { TextInput } from "../../../../common/fieldsByType/fields/promptInput/V2/PromptInputV2";
import { AvailableTestField } from "../hooks/helpers/testFieldSet";
import useHandleTestFieldUpdate from "../hooks/useHandleTestFieldUpdate";

function TestFieldContainer({
  value,
  onChange,
  label,
  className
}: {
  value: string;
  onChange: (value: string | JSONContent) => void;
  label: string;
  className?: string;
}) {
  return (
    <div className={className}>
      <TextInput
        handleTipTapChange={onChange}
        initialValue={value}
        label={label}
      />
    </div>
  );
}

export default function TestFieldsInputTab({
  fields,
  uI,
  className,
  setUI
}: {
  uI: UserInputDictType;
  fields: AvailableTestField[];
  className?: string;
  setUI(uI: UserInputDictType): void;
}) {
  const handleUpdate = useHandleTestFieldUpdate(uI, setUI);

  return (
    <Stack className={className}>
      {fields.map(({ fieldLabel, type }, idx) => {
        return (
          <div key={idx} className={idx !== 0 ? "m-t-16px" : undefined}>
            {type === FieldType.FileField ? (
              <FileUploadButton
                name={fieldLabel}
                formState={uI}
                setFormState={setUI}
              />
            ) : (
              <TestFieldContainer
                label={fieldLabel}
                value={uI[fieldLabel] as string}
                onChange={(e) => handleUpdate(fieldLabel, e)}
              />
            )}
          </div>
        );
      })}
    </Stack>
  );
}
