import { CloseIcon } from "../../../../../globalTheme/icons/icons";
import RemixButton from "../../../../../utilities/components/icons/RemixButton";
import useCloseAssetSidepanel from "../../assets/useCloseAssetSidepanel";

const CloseAssetPanelButton = ({ className }: { className?: string }) => {
  const { closeAssetSidepanel } = useCloseAssetSidepanel();
  return (
    <RemixButton
      text="Close"
      icon={CloseIcon}
      onClick={closeAssetSidepanel}
      className={className}
    />
  );
};

export default CloseAssetPanelButton;
