import { AddSnippetFromButton } from "../../../../../features/snippets/SnippetForm/AddSnippetFromContentButton";
import useAutocompleteSnippets from "../../workspace/hooks/useAutocompleteSnippets";
import {
  ClickableActionTypes,
  EAddChipTab,
  type TAddChipConfig
} from "@toolflow/shared";

// we always want snippets to be used as a chip
const useChipsToUse = (chipsToUse: TAddChipConfig = {}) => {
  const snippets = useAutocompleteSnippets();
  return {
    [EAddChipTab.SNIPPETS]: {
      values: snippets,
      action: <AddSnippetFromButton />,
      eventName: ClickableActionTypes.CREATE_SNIPPET
    },
    ...chipsToUse
  };
};

export default useChipsToUse;
