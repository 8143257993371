import { Button } from "@mui/material";
import {
  AutocompleteGroups,
  ClickableActionTypes,
  CustomToolInputFieldTypesEnum,
  EAddChipTab,
  type ValidatedInput
} from "@toolflow/shared";
import { useState } from "react";
import AddInputDialog from "../../../../../features/pages/builder/toolBuilder/addInput/AddInputDialog";
import getFilteredFields from "../../../../../features/pages/builder/toolBuilder/promptEditor/functions/getFilteredFields";
import useToolInputFieldsAndAvailableFields from "../../../../../features/pages/builder/toolBuilder/useToolInputFieldsAndAvailableFields";
import { AddIcon } from "../../../../../globalTheme/icons/icons";
import formatToolbuilderBlockForAutocomplete from "../../../autocomplete/functions/formatToolbuilderBlockForAutocomplete";
import { dispatchAddToolBuilderInputEvent } from "../../../../../features/pages/workstation/components/chat/chatbox/SendThreadTextField/addChipButton/utils/addChipEvents";

const AddInputButton = () => {
  const [openInputModal, setOpenInputModal] = useState(false);

  return (
    <>
      <Button
        variant="text"
        className="bg-color-white grey"
        size="small"
        startIcon={<AddIcon size={14} />}
        onClick={() => setOpenInputModal(true)}
      >
        Create user input
      </Button>
      <AddInputDialog
        openModal={openInputModal}
        setOpenModal={setOpenInputModal}
        callback={(input) => dispatchAddToolBuilderInputEvent(input.name)}
        allowedFields={[
          CustomToolInputFieldTypesEnum.TEXTFIELD,
          CustomToolInputFieldTypesEnum.SELECT
        ]}
        initialType={CustomToolInputFieldTypesEnum.TEXTFIELD}
      />
    </>
  );
};

const getFormattedBlockOutputs = (filteredFields: ValidatedInput[]) => {
  return filteredFields
    .filter(
      (input) => input.type === CustomToolInputFieldTypesEnum.BLOCK_OUTPUT
    )
    .map((input) =>
      formatToolbuilderBlockForAutocomplete(
        input,
        AutocompleteGroups.BlockOutputs
      )
    );
};

const getFormattedInputs = (filteredFields: ValidatedInput[]) => {
  return filteredFields
    .filter(
      (input) => input.type !== CustomToolInputFieldTypesEnum.BLOCK_OUTPUT
    )
    .map((input) =>
      formatToolbuilderBlockForAutocomplete(
        input,
        AutocompleteGroups.BlockOutputs
      )
    );
};

const useToolbuilderAvailableFieldsChipsToUse = (id: string) => {
  const inputs = useToolInputFieldsAndAvailableFields(id);
  const filteredFields = getFilteredFields(inputs);
  const formattedBlockOutputs = getFormattedBlockOutputs(filteredFields);
  const formattedInputs = getFormattedInputs(filteredFields);

  return {
    [EAddChipTab.NODES]: {
      values: formattedInputs,
      action: <AddInputButton />,
      eventName: ClickableActionTypes.ADD_TOOLBUILDER_INPUT
    },
    [EAddChipTab.NODE_OUTPUTS]: {
      values: formattedBlockOutputs
    }
  };
};

export default useToolbuilderAvailableFieldsChipsToUse;
