export enum ChipNodeComponents {
  SnippetChipComponent = "snippetChipComponent",
  AssetComponent = "inputComponent",
  ToolbuilderInputComponent = "toolbuilderInputComponent",
  SearchToolResponseComponent = "searchToolResponseComponent",
  NodeOutputViewerComponent = "nodeOutputViewerComponent",
  FlowThinkingComponent = "flowThinking",
  FlowAssetComponent = "flowAsset",
  ThinkingComponent = "thinking",
  HiddenContextComponent = "hiddenContext"
}
