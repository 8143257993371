import { HydratedDocument } from "mongoose";
import { LeanDocument, TAvailableTiers } from ".";
import {
  AnthropicModel,
  OpenAiEmbeddingModel,
  PerplexityModel
} from "../LLMModels";
import { IntegrationPriceSchemaId } from "../schemaTypes";
import {
  DeprecatedOpenAITextModels,
  OpenAITextModels,
  TCrawlerActors,
  TDeepgramModels
} from "../types";

// what is the nice-name of the model called? e.g. "openai-gpt-3.5"
export type TIntegrationReferenceName =
  | OpenAITextModels
  | DeprecatedOpenAITextModels
  | TSpeechToTextReferenceName
  | TIntegrationReferenceNameDallE
  | TApifyScraperReferenceName
  | AnthropicModel
  | TApifySerpReferenceName
  | PerplexityModel
  | OpenAiEmbeddingModel;
// model - quality - size
export type TIntegrationReferenceNameDallE =
  | "dall-e-2"
  | "dall-e-3-standard-1024x1024"
  | "dall-e-3-standard-1792x1024"
  | "dall-e-3-standard-1024x1792"
  | "dall-e-3-hd-1024x1024"
  | "dall-e-3-hd-1792x1024"
  | "dall-e-3-hd-1024x1792";

export type TWhisperDeepgramReferenceName = TDeepgramModels;
export type TApifyScraperReferenceName = TCrawlerActors;
export type TApifySerpReferenceName = "serp";

export type TSpeechToTextReferenceName = TWhisperDeepgramReferenceName;

// mostly for organization purposes, e.g. "OpenAI"
export enum ServiceReferenceName {
  Deepgram = "deepgram",
  Scraper = "apify",
  OpenAI = "openAI",
  Anthropic = "anthropic",
  AzureOpenAI = "azureOpenAI",
  PerplexityAI = "perplexityAI"
}

export enum TMaxCreditCostUnit {
  Block = "block",
  Image = "image",
  Minute = "minute",
  Million = "millionTokens"
}

export type TMaxCreditCostUnitWithLabelAndValue = {
  label: TMaxCreditCostUnit;
  value: number;
};
export type IntegrationPrice = {
  _id: string;
  integrationReferenceName: TIntegrationReferenceName;
  serviceReferenceName: ServiceReferenceName;
  maxCreditCost: number;
  maxCreditCostUnit: TMaxCreditCostUnit;
  maxCreditCostUnitWithLabelAndValue?: TMaxCreditCostUnitWithLabelAndValue;
  inputTokenCost?: number;
  outputTokenCost?: number;
  maxTokenOutput?: number;
  availableToTiers: TAvailableTiers[];
  createdAt: number;
  updatedAt: number;
};

export type CreateIntegrationPrice = Partial<
  Omit<
    IntegrationPrice,
    "_id" | "createdAt" | "updatedAt" | "integrationReferenceName"
  > & {
    integrationReferenceName: string;
  }
>;

// IntegrationPriceDocuments are documents that maintain the price of a given integration
export type IntegrationPriceDocument = HydratedDocument<
  IntegrationPrice,
  {
    _id: IntegrationPriceSchemaId;
  }
>;

export type LeanIntegrationPriceDocument = LeanDocument<
  IntegrationPriceDocument,
  {
    _id: IntegrationPriceSchemaId;
  }
>;
