import { Typography } from "@mui/material";
import {
  AssetIcon,
  ReconnectIcon
} from "../../../../../../../globalTheme/icons/icons";
import { useWebsocketContext } from "../../../../../../../ToolflowAPI/websocket/contexts/WebsocketContext";
import useAddNewStringAsset from "../../../../assets/useAddNewStringAsset";
import useSetVisibleAsset from "../../../../assets/useSetVisibleAsset";
import useWorkspaceAssets from "../../../../assets/useWorkspaceAssets";
import AddFileButton from "./AddFileButton";
import FindToolButton from "./FindToolButton";
import TextButtonGray from "./textButtonGray/textButtonGray";
import SearchOrEditAgent from "../../../getStarted/SearchOrEditAgent";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";
import useGetCurrentAsset from "../../../../assets/useGetCurrentAsset";
import { AgentInfoDialog } from "../../../../../../agents/AgentInfo";

function ChatWebsocketReconnect() {
  const { reconnect } = useWebsocketContext();
  return (
    <div className="w-100-percent flex justify-space-between align-i-center">
      <Typography variant="chipLabel" color="error">
        Disconnected
      </Typography>
      <TextButtonGray
        onClick={reconnect}
        text="Reconnect"
        Icon={ReconnectIcon}
      />
    </div>
  );
}

const useHasAssets = () => {
  const assets = useWorkspaceAssets();
  return assets.length > 0;
};

const useOpenAssets = () => {
  const { setVisibleAsset } = useSetVisibleAsset();
  const assets = useWorkspaceAssets();
  const addNewStringAsset = useAddNewStringAsset();
  const currentAsset = useGetCurrentAsset();
  const hasAssets = useHasAssets();
  return () => {
    if (!currentAsset) {
      if (hasAssets) {
        setVisibleAsset(assets[0]?.id || "");
      } else {
        addNewStringAsset("");
      }
    } else {
      setVisibleAsset("");
    }
  };
};

function OpenAssetButton({ className }: { className?: string }) {
  const openAssets = useOpenAssets();
  const hasAssets = useHasAssets();

  return (
    <TextButtonGray
      onClick={openAssets}
      text="Assets"
      Icon={AssetIcon}
      className={className}
      disabled={!hasAssets}
    />
  );
}

function ChatBottomButton({ className = "" }: { className?: string }) {
  const { showReconnectButton } = useWebsocketContext();
  return (
    <div className={classNames("flex align-i-center", className)}>
      {showReconnectButton ? (
        <ChatWebsocketReconnect />
      ) : (
        <>
          <AddFileButton />
          <FindToolButton />
          <OpenAssetButton className="m-l-8px" />
          <Typography className="m-l-auto m-r-8px grey" variant="chipLabel">
            Chatting with
          </Typography>
          <SearchOrEditAgent
            variant="smallButton"
            cardClassName="p-v-2px p-h-8px"
          />
          <AgentInfoDialog />
        </>
      )}
    </div>
  );
}
export default ChatBottomButton;
