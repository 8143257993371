import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./blockIconWrapper.module.css";

const cx = parsedClassNames.bind(styles);

const BlockIconWrapper = ({
  BlockIcon,
  className
}: {
  BlockIcon: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cx(styles["icon-with-circle"], className)}>{BlockIcon}</div>
  );
};

export default BlockIconWrapper;
