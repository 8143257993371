import { Divider, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { a11yProps } from "../../../../../../../../utilities/components/tabs/functions/a11yProps";
import TabPanel from "../../../../../../../../utilities/components/tabs/TabPanel";

export type TestTabDict = {
  value: number;
  component: React.ReactNode;
  label: string;
};

export default function TestFieldsTabs({ tabs }: { tabs: TestTabDict[] }) {
  const [value, setValue] = useState(tabs.at(0)?.value || 0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((tab) => (
          <Tab
            label={tab.label}
            key={tab.value}
            value={tab.value}
            {...a11yProps(tab.value)}
            className="m-l-12px"
          />
        ))}
      </Tabs>
      <Divider />
      {tabs.map((tab) => (
        <TabPanel value={value} key={tab.value} index={tab.value}>
          <div className="m-t-24px m-b-16px">{tab.component}</div>
        </TabPanel>
      ))}
    </>
  );
}
