import { Divider } from "@mui/material";
import List from "@mui/material/List";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import {
  AgentsFillIcon,
  AgentsIcon,
  HomeFillIcon,
  HomeIcon,
  KnowledgeFillIcon,
  KnowledgeIcon,
  ToolFillIcon,
  ToolIcon
} from "../../../globalTheme/icons/icons";
import ToolflowLogoName from "../../../globalTheme/icons/logos/toolflowLogo/ToolflowLogoName";
import sidebarTheme from "../../../globalTheme/sidebarTheme";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import {
  allAgentsMatcher,
  homeMatcher,
  knowledgeMatcher,
  toolMatcher
} from "../../navigation/functions/matchers";
import {
  AGENTS_ROUTE_PATH,
  BASE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH
} from "../../navigation/helpers/routePaths";
import SidebarButtonContainer from "./SidebarButtonContainer";
import { SidebarCredits } from "./SidebarCredits";
import SidebarIcon from "./SidebarIcon";
import StyledSidebarDrawer from "./StyledSidebarDrawer";
import TermsOfServiceSidebar from "./TermsOfServiceSidebar";
import ToggleSidebarButton from "./ToggleSidebarButton";
import styles from "./sidebar.module.css";
import { selectSidebarOpen } from "./sidebarSlice";
import JoinSlackButton from "../../../utilities/components/buttons/JoinSlackButton";

const cx = parsedClassNames.bind(styles);

function SidebarBrandIcon() {
  const open = useSelector(selectSidebarOpen);
  return (
    <div className={cx("brand-logo-container")}>
      {open ? (
        <div className="flex align-i-center justify-space-around h-100-percent">
          <ToolflowLogoName variant="large" />
          <ToggleSidebarButton />
        </div>
      ) : (
        <div className="flex align-i-center justify-center h-100-percent">
          <ToolflowLogoName variant="small" />
        </div>
      )}
    </div>
  );
}

function SidebarCreditsDisplay() {
  const open = useSelector(selectSidebarOpen);
  if (!open) return null;

  return (
    <List>
      <SidebarCredits />
    </List>
  );
}

interface SidebarProps {
  isMediumScreen: boolean;
  open: boolean;
  isAuthenticated: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  isMediumScreen,
  open,
  isAuthenticated
}) => {
  const topButtons = [
    {
      route: BASE_ROUTE_PATH,
      icon: <SidebarIcon Icon={HomeIcon} />,
      selectedIcon: <SidebarIcon Icon={HomeFillIcon} />,
      text: "Home",
      authRequired: true,
      matcher: homeMatcher
    },
    {
      route: AGENTS_ROUTE_PATH,
      icon: <SidebarIcon Icon={AgentsIcon} />,
      selectedIcon: <SidebarIcon Icon={AgentsFillIcon} />,
      text: "Agents",
      authRequired: true,
      matcher: allAgentsMatcher
    },
    {
      route: TOOLS_PAGE_ROUTE_PATH,
      icon: <SidebarIcon Icon={ToolIcon} />,
      selectedIcon: <SidebarIcon Icon={ToolFillIcon} />,
      text: "Tools",
      matcher: toolMatcher
    },
    {
      route: KNOWLEDGE_ROUTE_PATH,
      icon: <SidebarIcon Icon={KnowledgeIcon} />,
      selectedIcon: <SidebarIcon Icon={KnowledgeFillIcon} />,
      text: "Knowledge",
      authRequired: true,
      matcher: knowledgeMatcher
    }
  ];

  return (
    <ThemeProvider theme={sidebarTheme}>
      <StyledSidebarDrawer
        variant="permanent"
        open={open}
        PaperProps={{ square: true, className: "scrollable-content" }}
        className={`sidebar flex-column justify-space-between flex ${
          isMediumScreen ? "h-100-percent" : "h-100vh"
        }`}
      >
        <SidebarBrandIcon />
        <Divider className={cx("divider")} />
        <div
          className={cx(
            "h-100-percent flex flex-column justify-space-between",
            { "p-h-16px": open }
          )}
        >
          <div>
            <List>
              {topButtons.map((button, index) => {
                if (button.authRequired && !isAuthenticated) {
                  return null;
                }
                if (
                  "featureFlagEnabled" in button &&
                  !button.featureFlagEnabled
                ) {
                  return null;
                }
                return (
                  <SidebarButtonContainer
                    key={index}
                    route={button.route}
                    icon={button.icon}
                    selectedIcon={button.selectedIcon}
                    text={button.text}
                    matcher={button.matcher}
                  />
                );
              })}
            </List>
          </div>
          <SidebarCreditsDisplay />
        </div>
        <JoinSlackButton className="m-v-8px" hide={!open} />
        <Divider className={cx("divider")} />
        <TermsOfServiceSidebar />
      </StyledSidebarDrawer>
    </ThemeProvider>
  );
};

export default Sidebar;
