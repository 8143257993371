import type { JSONContent } from "@tiptap/core";
import type {
  ExtensionLocation,
  PromptInputV2Type,
  TAddChipConfig,
  TExtensionsConfig
} from "@toolflow/shared";
import { useMemo } from "react";
import { TipTapTextFieldContainer } from "../../../../../../../../../utilities/components/textFields/tipTapTextField/TipTapTextField";
import { parsedClassNames } from "../../../../../../../../../utilities/functions/parsedClassNames";
import useGetDrawerNodeId from "../../../../../store/hooks/useGetDrawerNodeId";
import InputLabelWrapper from "../../../components/InputLabelWrapper";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useDynamicSetting from "../../../hooks/useDynamicSetting";
import styles from "./PromptInputV2.module.css";
import { useExtensionLocation } from "../../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/useExtension";

const cx = parsedClassNames.bind(styles);

export const textInputContainerExtensionsConfig = {
  disableFormattingExtensions: true,
  disableDefaultEnter: false
};

interface TextInputProps {
  subtitle?: string;
  autofocus?: boolean;
  chipsToUse?: TAddChipConfig;
  id?: string;
  handleTipTapChange: (value: string | JSONContent) => void;
  placeholder?: string;
  initialValue: string;
  label?: string;
  className?: string;
  textFieldClassName?: string;
  disableFormattingExtensions?: boolean;
  extensionsConfig?: {
    disableFormattingExtensions?: boolean;
    disableDefaultEnter?: boolean;
    enableAutolinkExtension?: boolean;
    extensionLocation?: ExtensionLocation;
  };
}
export function TextInput({
  subtitle,
  autofocus,
  chipsToUse,
  id,
  handleTipTapChange,
  initialValue,
  placeholder,
  label,
  className = "",
  textFieldClassName = "",
  extensionsConfig = {},
  disableFormattingExtensions
}: TextInputProps) {
  return (
    <InputLabelWrapper label={label} subtitle={subtitle} className={className}>
      <div className={"flex w-100-percent"}>
        <TipTapTextFieldContainer
          autoFocus={autofocus}
          chipsToUse={chipsToUse}
          id={id}
          extensionsConfig={{
            ...textInputContainerExtensionsConfig,
            ...extensionsConfig
          }}
          disableFormattingMenu={!!(disableFormattingExtensions ?? true)}
          saveWorkspaceType="json"
          initialValue={initialValue}
          onChange={handleTipTapChange}
          disableWhite
          label={placeholder}
          outerClassName="w-100-percent"
          className={cx("min-w-300px", textFieldClassName)}
          workspaceWrapperClassName={cx(styles["large-height"])}
        />
      </div>
    </InputLabelWrapper>
  );
}

// We need to make sure that the location of the extensions is correct
// so that the nodes that require context don't throw errors
const useGetExtensionConfigForTextInput = (
  extensionsConfig?: TExtensionsConfig,
  enableFormatting?: boolean
) => {
  const locationType = useExtensionLocation();

  const extensionsConfigWithLocation = {
    ...extensionsConfig,
    extensionLocation: locationType
  };

  return enableFormatting
    ? {
        ...extensionsConfigWithLocation,
        disableFormattingExtensions: false,
        enableAutolinkExtension: true
      }
    : extensionsConfigWithLocation;
};

export function PromptInputV2() {
  const { id, field, chipsToUse, getFieldValue, updateField } =
    useFieldsByTypeContext<PromptInputV2Type>();
  const drawerNodeId = useGetDrawerNodeId();

  const {
    autofocus,
    placeholder,
    fieldKey,
    className,
    textFieldClassName,
    enableFormatting,
    extensionsConfig
  } = field.config;

  const handleTipTapChange = (value: string | JSONContent) => {
    updateField(value, fieldKey);
  };

  const initialValue = useMemo(
    () => getFieldValue(fieldKey, ""),
    [fieldKey, drawerNodeId]
  );

  const extensionsConfigWithFormatting = useGetExtensionConfigForTextInput(
    extensionsConfig,
    enableFormatting
  );

  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");

  return (
    <>
      <TextInput
        label={label}
        subtitle={subtitle}
        autofocus={autofocus}
        chipsToUse={chipsToUse}
        handleTipTapChange={handleTipTapChange}
        className={className}
        textFieldClassName={textFieldClassName}
        placeholder={placeholder}
        initialValue={initialValue}
        extensionsConfig={extensionsConfigWithFormatting}
        disableFormattingExtensions={!enableFormatting}
        id={id}
      />
    </>
  );
}
