import { Dialog } from "@mui/material";
import { cloneElement, Fragment } from "react";
import { CloseIcon } from "../../../../../globalTheme/icons/icons";
import { toolflowAppBackground } from "../../../../../globalTheme/muiUtils/appTheme";
import useSearchToolsDialog from "../hooks/useSearchToolsDialog";
import EntitySelectStepper from "./EntitySelectStepper";
import RemixButton from "../../../../../utilities/components/icons/RemixButton";

export default function SearchEntitiesDialogInner() {
  const { open, button, disabled, handleOpen, handleClose } =
    useSearchToolsDialog();
  const { step } = useSearchToolsDialog();

  return (
    <Fragment>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        maxWidth={step === 1 ? "md" : "lg"}
        PaperProps={{
          sx: {
            backgroundColor: toolflowAppBackground,
            padding: "0 16px 0 16px"
          }
        }}
      >
        <RemixButton
          icon={CloseIcon}
          onClick={handleClose}
          className="top-24px right-24px pos-absolute"
        />

        <EntitySelectStepper />
      </Dialog>
      {cloneElement(button as React.ReactElement, {
        onClick: (e: React.MouseEvent) => {
          handleOpen();
          const originalOnClick = (button as React.ReactElement).props.onClick;
          if (originalOnClick) {
            originalOnClick(e);
          }
        },
        disabled
      })}
    </Fragment>
  );
}
