import type { TStructuredAsset } from "@toolflow/shared";
import { useAssetContext } from "../../../../../../../utilities/contexts/AssetContext";
import StructuredOutput from "../../../../../../tools/components/editorToolCard/outputs/structuredOutput/StructuredOutput";
import useGetVisibleAssetValue from "../../../../hooks/useGetVisibleAssetValue";
import AssetWrapper from "../assetWrapper/AssetWrapper";
import StructuredAssetHeader from "./StructuredAssetHeader";
import BottomGradient from "../../../BottomGradient";
import { useShowTopGradient } from "../textAsset/AssetTextFieldScrollWrapper";
import TopGradient from "../../../TopGradient";
import useScroller from "../textAsset/hooks/useScroller";
import ScrollToBottom from "react-scroll-to-bottom";

export default function StructuredAsset() {
  const { asset } = useAssetContext<TStructuredAsset>();
  const value = useGetVisibleAssetValue();
  const idClassName = "structured-asset-id";
  const isScrolled = useShowTopGradient(idClassName);
  const scroller = useScroller();

  return (
    <>
      <AssetWrapper header={<StructuredAssetHeader name={asset.name} />}>
        <TopGradient show={isScrolled} />
        <ScrollToBottom
          followButtonClassName="arrow-button"
          initialScrollBehavior="smooth"
          className={"h-100-percent scroll-to-bottom"}
          scrollViewClassName={`scrollable-content-important ${idClassName}`}
          scroller={scroller}
        >
          <StructuredOutput
            value={value}
            className="asset-max-width m-h-auto"
          />
          <BottomGradient />
        </ScrollToBottom>
      </AssetWrapper>
    </>
  );
}
