import {
  type ConnectIntegrationResponse,
  type GenerateTokenResponse,
  type GetComponentsResponse,
  type GetIntegrationsResponse,
  type PipedreamAppsResponse,
  type UserIntegrationMetadata
} from "@toolflow/shared";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { authenticatedApi } from "../authenticatedAPI";
import { USER_INTEGRATION_TAG_TYPE } from "../cacheTagConstants";

export const userIntegrationApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    generateToken: builder.mutation<GenerateTokenResponse, void>({
      query: () => ({
        url: `integration/token`,
        method: "POST"
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(setErrorMessage("Failed to generate token"));
        }
      }
    }),
    connectIntegration: builder.mutation<
      ConnectIntegrationResponse,
      {
        integrationType: string;
        accountId: string;
        metadata: UserIntegrationMetadata;
      }
    >({
      query: (body) => ({
        url: `integration/connect`,
        method: "POST",
        body
      }),
      invalidatesTags: [{ type: USER_INTEGRATION_TAG_TYPE }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Integration connected successfully"));
        } catch (err) {
          dispatch(setErrorMessage("Failed to connect integration"));
        }
      }
    }),
    getIntegrations: builder.query<GetIntegrationsResponse, void>({
      query: () => ({
        url: `integration`,
        method: "GET"
      }),
      providesTags: [{ type: USER_INTEGRATION_TAG_TYPE }]
    }),
    getIntegrationActions: builder.mutation<
      GetComponentsResponse,
      { userIntegrationType: string }
    >({
      query: (body) => ({
        url: `integration/components`,
        method: "POST",
        body
      })
    }),
    getApps: builder.query<
      PipedreamAppsResponse,
      { query?: string; cursor?: string } | void
    >({
      query: (params) => ({
        url: `integration/apps`,
        method: "GET",
        params: params
          ? {
              q: params.query,
              after: params.cursor
            }
          : undefined
      }),
      merge: (currentCache, newData, { arg }) => {
        if (!arg?.cursor) {
          return newData;
        }
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data]
        };
      },
      transformResponse: (response: PipedreamAppsResponse) => ({
        data: response.data || [],
        page_info: response.page_info || { end_cursor: "" },
        searchQuery: response.searchQuery || ""
      })
    }),
    deleteIntegrationAccount: builder.mutation<void, string>({
      query: (accountId) => ({
        url: `integration/account`,
        method: "DELETE",
        body: { accountId }
      }),
      invalidatesTags: [{ type: USER_INTEGRATION_TAG_TYPE }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            setSuccessMessage("Integration account deleted successfully")
          );
        } catch (err) {
          dispatch(setErrorMessage("Failed to delete integration account"));
        }
      }
    }),
    disconnectIntegration: builder.mutation<void, string>({
      query: (integrationType) => ({
        url: `integration/${integrationType}`,
        method: "DELETE"
      }),
      invalidatesTags: [{ type: USER_INTEGRATION_TAG_TYPE }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Integration disconnected successfully"));
        } catch (err) {
          dispatch(setErrorMessage("Failed to disconnect integration"));
        }
      }
    })
  }),
  overrideExisting: false
});

export const {
  useGenerateTokenMutation,
  useConnectIntegrationMutation,
  useGetIntegrationsQuery,
  useGetIntegrationActionsMutation,
  useDeleteIntegrationAccountMutation,
  useGetAppsQuery,
  useDisconnectIntegrationMutation
} = userIntegrationApi;
