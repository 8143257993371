import React from "react";
import { Typography } from "@mui/material";
import {
  HistoryIcon,
  SessionHistoryIcon
} from "../../../../../globalTheme/icons/icons";
import { SessionElementCard } from "../../../../workspace/components/SessionElementCard";
import type { StreamlinedWorkspace } from "@toolflow/shared";
import {
  toolflowPrimaryColor,
  toolflowTextSecondaryColor
} from "../../../../../globalTheme/muiUtils/appTheme";
import { SessionHistoryListContainer } from "../../../../workspace/components/SessionHistoryListContainer";
import { DialogButtonContainer } from "../../../../../utilities/components/dialogs/DialogButtonContainer";
import RemixButton from "../../../../../utilities/components/icons/RemixButton";

export const useSessionHistoryDialog = ({
  setOpen
}: {
  setOpen: (open: boolean) => void;
}) => {
  const onClickEntityWithClose = () => {
    setOpen(false);
  };

  const SessionElementWithOnClick = React.useCallback(
    ({
      workspace,
      className
    }: {
      workspace: StreamlinedWorkspace;
      className?: string;
    }) => (
      <SessionElementCard
        workspace={workspace}
        className={className}
        onClick={onClickEntityWithClose}
      />
    ),
    [onClickEntityWithClose]
  );

  return {
    setOpen,
    SessionElementWithOnClick,
    onClickEntityWithClose
  };
};

const SessionHistoryDialogContentInner = ({
  setOpen
}: {
  setOpen: (open: boolean) => void;
}) => {
  const { SessionElementWithOnClick } = useSessionHistoryDialog({ setOpen });
  return (
    <>
      <div className="flex align-i-center w-100-percent flex-wrap m-t-12px oflow-hidden">
        <HistoryIcon
          size={20}
          color={toolflowPrimaryColor}
          className="m-r-8px"
        />
        <Typography variant="h7">Recent sessions</Typography>
      </div>
      <SessionHistoryListContainer
        SessionElement={SessionElementWithOnClick}
        className="m-t-32px"
      />
    </>
  );
};

export function SessionHistoryButton({ className }: { className?: string }) {
  return (
    <DialogButtonContainer
      maxWidth="sm"
      PaperProps={{
        className: "oflow-hidden"
      }}
      button={
        <RemixButton
          text="Session history"
          icon={SessionHistoryIcon}
          onClick={() => {}}
          className={className}
          size={20}
          providedColor={toolflowTextSecondaryColor}
        />
      }
      content={({ setOpen }) => (
        <SessionHistoryDialogContentInner setOpen={setOpen} />
      )}
    />
  );
}
