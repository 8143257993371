import { Button, Chip, Paper, Typography } from "@mui/material";
import type { Content } from "@tiptap/core";
import { convertToolPropsFromLegacy } from "@toolflow/shared";
import { isEmpty } from "lodash";
import "swiper/css";
import { InfoIcon, SearchIcon } from "../../globalTheme/icons/icons";
import { DialogButtonContainer } from "../../utilities/components/dialogs/DialogButtonContainer";
import RemixButton from "../../utilities/components/icons/RemixButton";
import { GenericRichContent } from "../../utilities/components/textFields/workspace/GenericRichContent";
import { useToolContext } from "../tools/contexts/ToolContext";
import ToolContextComponentContainer from "../tools/contexts/ToolContextContainer";
import { useAgentContext } from "./contexts/AgentContext";
import FindAgentButton from "../pages/workstation/components/chat/chatbox/ChatInputPaper/FindAgentButton";
import useGreeting from "../pages/workstation/hooks/useGreeting";
import { useGetStarted } from "../pages/workstation/components/getStarted/hooks/useGetStarted";

const GetStartedToolChip = () => {
  const { tool } = useToolContext();

  return (
    <Chip
      label={tool.name}
      icon={
        tool.avatar ? (
          <img src={tool.avatar} alt="avatar" height={14} width={14} />
        ) : undefined
      }
      size="small"
      className="bg-color-white grey m-r-4px m-t-4px"
    />
  );
};

export const GuidelinesPaper = ({
  content,
  className
}: {
  content: Content;
  className?: string;
}) => {
  return (
    <Paper
      className={`w-100-percent p-16px border-radius-16px ${className}`}
      variant="outlined"
    >
      <GenericRichContent content={content} className="w-100-percent" />
    </Paper>
  );
};

export const AgentDescription = ({ description }: { description: string }) => {
  return (
    <div className="w-100-percent m-t-16px flex justify-center m-b-8px">
      <Typography
        variant="body1"
        align="center"
        className="grey max-w-540px oflow-two-lines"
      >
        {description}
      </Typography>
    </div>
  );
};

export const AgentTools = ({
  toolProps
}: {
  toolProps: Record<string, $TSAllowedAny>;
}) => {
  return (
    <div className="flex justify-center flex-wrap">
      {Object.keys(toolProps).map((toolId, index) => {
        return (
          <ToolContextComponentContainer toolId={toolId} key={index}>
            <GetStartedToolChip />
          </ToolContextComponentContainer>
        );
      })}
    </div>
  );
};

export const AgentTopInfo = ({
  headerNameOverride
}: {
  headerNameOverride?: React.ReactNode;
}) => {
  const { agent } = useAgentContext();
  const toolProps = convertToolPropsFromLegacy(agent.config);
  return (
    <>
      <Typography variant="h1" align="center" className="w-100-percent">
        {headerNameOverride || agent?.name}
      </Typography>
      {agent?.description && (
        <AgentDescription description={agent.description} />
      )}
      <AgentTools toolProps={toolProps} />
    </>
  );
};

export const AgentInstructionsContent = ({
  userInstructions
}: {
  userInstructions: Content;
}) => {
  return (
    <>
      <AgentTopInfo />
      <GuidelinesPaper content={userInstructions} className="m-t-24px" />
    </>
  );
};

export const AgentInfo = () => {
  const { agent } = useAgentContext();
  const greeting = useGreeting();
  const getStarted = useGetStarted();
  const headerNameOverride = getStarted ? greeting : agent?.name;
  return (
    <div className="max-w-100-percent">
      <AgentTopInfo headerNameOverride={headerNameOverride} />
      {agent?.userInstructions && (
        <div className="flex justify-center w-100-percent">
          <DialogButtonContainer
            button={
              <Button variant="outlined" className="m-t-16px bg-color-white">
                How to use
              </Button>
            }
            dialogContentClassName="w-100-percent"
            content={() => (
              <AgentInstructionsContent
                userInstructions={agent.userInstructions}
              />
            )}
          />
        </div>
      )}
      {getStarted && (
        <div className="flex justify-center w-100-percent m-t-32px">
          <FindAgentButton
            button={
              <Button
                variant="outlined"
                size="small"
                className="m-t-16px bg-color-white"
                endIcon={<SearchIcon size={16} />}
              >
                Change agent
              </Button>
            }
          />
        </div>
      )}
    </div>
  );
};

export const AgentInfoDialog = () => {
  const { agent } = useAgentContext();
  const toolProps = convertToolPropsFromLegacy(agent.config);
  if (
    !agent ||
    (!agent.userInstructions && !agent.description && isEmpty(toolProps))
  )
    return null;

  return (
    <DialogButtonContainer
      button={<RemixButton onClick={() => {}} icon={InfoIcon} />}
      content={() => <AgentInfo />}
    />
  );
};
