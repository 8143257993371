import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { LinkIcon } from "../../../../globalTheme/icons/icons";
import { useHandleCopy } from "../../../../utilities/components/copyContent/hooks/useHandleCopy";
import type {
  ButtonVariantWithIcon,
  ValidateTypeWithAgent
} from "@toolflow/shared";
import { Button } from "@mui/material";

const CopyEntityLink = ({
  entityId,
  entityType,
  variant = "icon"
}: {
  entityId: string;
  entityType: ValidateTypeWithAgent;
  variant?: ButtonVariantWithIcon;
}) => {
  const handleCopyToolLink = useHandleCopy(entityId, entityType);
  const copy = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleCopyToolLink();
  };

  if (variant !== "icon") {
    return (
      <Button onClick={copy} size="small" variant={variant}>
        Copy
      </Button>
    );
  }

  return (
    <RemixButton
      text="Copy tool link to clipboard"
      icon={LinkIcon}
      onClick={copy}
    />
  );
};

export default CopyEntityLink;
