import { FC } from "react";
import type { TTransformedMessage } from "@toolflow/shared";
import StepDetails from "./StepDetails";
import AgentMessage from "./AgentMessage";
interface IAgentMessageProps {
  message: TTransformedMessage;
}

const AgentMessageWrapper: FC<IAgentMessageProps> = () => {
  return (
    <div className="w-100-percent">
      <StepDetails />
      <AgentMessage />
    </div>
  );
};

export default AgentMessageWrapper;
