import React from "react";
import ToolflowHeader from "../../../utilities/components/headers/toolflowHeader/ToolflowHeader";
import { SearchToolsContextComponent } from "../../tools/components/searchToolsContainer/context/SearchToolsContext";
import AgentsTabs from "./AgentTabs";
import AgentEntityCardContainer from "./AgentEntityCardContainer";
import { useGetMarketplaceAgentsQuery } from "../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import { AGENT } from "@toolflow/shared";
import MarketplaceTabsSkeleton from "../../tools/components/searchToolsContainer/MarketplaceTabsSkeleton";

function AgentsLoadingSplitter({ action }: { action?: React.ReactNode }) {
  const { isLoading } = useGetMarketplaceAgentsQuery();

  return (
    <ToolflowHeader title="Agents" action={action}>
      {isLoading ? (
        <MarketplaceTabsSkeleton className="p-h-16px m-t-16px" />
      ) : (
        <SearchToolsContextComponent
          EntityElement={AgentEntityCardContainer}
          entityType={AGENT}
        >
          <AgentsTabs className="p-h-16px m-t-16px" />
        </SearchToolsContextComponent>
      )}
    </ToolflowHeader>
  );
}

export default AgentsLoadingSplitter;
