import React from "react";

import RemixButton from "../../../../../../utilities/components/icons/RemixButton";
import { useDispatch } from "react-redux";
import { setAssetExpanded } from "../../../state/workspaceSlice";
import { ExpandIcon } from "../../../../../../globalTheme/icons/icons";

const OpenAssetDialogButton = () => {
  const dispatch = useDispatch();

  const setOpen = () => {
    dispatch(setAssetExpanded(true));
  };

  return <RemixButton text="Expand" icon={ExpandIcon} onClick={setOpen} />;
};

export default OpenAssetDialogButton;
