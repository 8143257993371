import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { EditIcon } from "../../../../globalTheme/icons/icons";
import useNavigateToToolBuilder from "../../../tools/hooks/useNavigateToToolBuilder";
import { Button } from "@mui/material";
import type { ButtonVariantWithIcon } from "@toolflow/shared";
import { useGetToolQuery } from "../../../../ToolflowAPI/rtkRoutes/toolsApi";
import useIsCurrentUser from "../../../user/hooks/useIsCurrentUser";

function NavigateToToolBuilderButton({
  toolId,
  providedColor,
  className = "",
  variant = "icon"
}: {
  toolId: string;
  providedColor?: string;
  className?: string;
  variant?: ButtonVariantWithIcon;
}) {
  const { data } = useGetToolQuery(toolId);
  const isCurrentUser = useIsCurrentUser(data?.tool.userId);
  const navigateToToolBuilder = useNavigateToToolBuilder();

  if (!isCurrentUser) {
    return null;
  }

  if (variant !== "icon") {
    return (
      <Button
        onClick={navigateToToolBuilder(toolId)}
        size="small"
        variant={variant}
      >
        Edit
      </Button>
    );
  }

  return (
    <RemixButton
      text="Edit"
      icon={EditIcon}
      onClick={navigateToToolBuilder(toolId)}
      disabled={!toolId}
      providedColor={providedColor}
      className={className}
    />
  );
}

export default NavigateToToolBuilderButton;
