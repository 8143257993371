import { Grid } from "@mui/material";
import type {
  TMarketplaceTypes,
  TMarketplaceUniversalEntity
} from "@toolflow/shared";
import React, { FC } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import EntityIconCard from "../../../../../entities/components/entityCard/EntityIconCard";
import { useInfiniteScroll } from "../../useInfiniteScroll";

export type TEntityElement = React.FC<{
  entity: TMarketplaceUniversalEntity;
}>;

interface IElementGridProps {
  marketplaceEntities: TMarketplaceUniversalEntity[];
  EntityElement: TEntityElement;
  entityType: TMarketplaceTypes;
}

const EntitiesGrid: FC<IElementGridProps> = ({
  marketplaceEntities,
  EntityElement,
  entityType
}) => {
  // This is dumb - it seems that loadMoreEntities
  // doesn't do anything because all the entities are fetched at one time
  // need to refactor this to paginate entities
  const {
    visibleEntities,
    loadMoreEntities,
    hasMore,
    scrollHeight,
    scrollRef
  } = useInfiniteScroll<TMarketplaceUniversalEntity>(marketplaceEntities, 20);
  return (
    <div ref={scrollRef}>
      <InfiniteScroll
        dataLength={visibleEntities.length}
        next={loadMoreEntities}
        hasMore={hasMore}
        loader={<div className="m-t-16px"> </div>}
        height={scrollHeight}
        scrollableTarget="main-area"
      >
        <Grid container spacing={3} className="m-b-32px" alignItems="stretch">
          {/* We need this otherwise there is a flicker when the grid is empty */}
          {visibleEntities.length > 0 && (
            <Grid item className="flex">
              <EntityIconCard entityType={entityType} />
            </Grid>
          )}
          {visibleEntities.map((entity) => (
            <Grid item key={entity.id}>
              <EntityElement entity={entity} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default EntitiesGrid;
