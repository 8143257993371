import { isEnumValue } from "../../../../../functions";
import {
  AnthropicModel,
  AnthropicOverride,
  anthropicPriceDict,
  BlockType,
  FieldType,
  openAIPriceDict,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";
import {
  getBaseModelOptions,
  getTextGenerationTechOptions
} from "../../helpers";

export const promptBlockField: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.Group,
      label: "Model",
      fields: [
        {
          type: FieldType.SelectFieldV1,
          label: "Select model",
          config: {
            fieldKey: "type",
            options: [
              { label: "Open AI", value: "ChatGPT" },
              { label: "Anthropic", value: "Anthropic" }
            ]
          }
        },
        {
          type: FieldType.Slider,
          label: "Temperature",
          subtitle:
            "Adjust temperature of response. Higher is more random, and lower is more deterministic. Anthropic has a max temperature of 1 and OpenAI has a max temperature of 2.",
          config: {
            fieldKey: {
              condition: ["type", "==", BlockType.Anthropic],
              then: `anthropicOptimizations.temperature`,
              else: `optimizations.temperature`
            },
            marksMax: {
              condition: ["type", "==", BlockType.Anthropic],
              then: 50
            }
          }
        },
        {
          type: FieldType.SelectFieldV1,
          label: "Model",
          config: {
            fieldKey: {
              condition: ["type", "==", BlockType.Anthropic],
              then: `anthropicOptimizations.llmModel`,
              else: `optimizations.llmModel`
            },
            options: {
              condition: ["type", "==", BlockType.Anthropic],
              then: getBaseModelOptions(anthropicPriceDict),
              else: getBaseModelOptions(openAIPriceDict)
            },
            optionOverride: AnthropicOverride
          }
        }
      ]
    },
    {
      type: FieldType.Group,
      label: "Prompt",
      fields: [
        {
          type: FieldType.PromptInputV1,
          config: { fieldKey: "prompt" }
        }
      ]
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.SelectFieldV2,
      label: "Model",
      subtitle: "Select model",
      onChange(value, _, updateField) {
        if (isEnumValue(value, AnthropicModel)) {
          updateField(BlockType.Anthropic, "type");
          // Check if temperature is over 50 (Anthropic's max)
          updateField(50, 'settings.temperature"');
        } else {
          updateField(BlockType.ChatGPT, "type");
        }
      },
      config: {
        fieldKey: `settings.llmModel`,
        options: getTextGenerationTechOptions(),
        optionOverride: AnthropicOverride
      }
    },
    {
      type: FieldType.PromptInputV2,
      label: "Prompt",
      subtitle: "Write your prompt instructions here.",
      config: {
        fieldKey: "settings.prompt",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px"
      }
    },
    {
      type: FieldType.Slider,
      label: "Temperature",
      subtitle:
        "Adjust temperature of response. Higher is more random, and lower is more deterministic. Anthropic has a max temperature of 1 and OpenAI has a max temperature of 2.",
      config: {
        fieldKey: `settings.temperature`,
        marksMax: {
          conditions: Object.values(AnthropicModel).map((model) => [
            "settings.llmModel",
            "==",
            model
          ]),
          logic: "OR",
          then: 50
        }
      }
    }
  ]
};
