import { Dialog } from "@mui/material";
import { type AllBlockTypes } from "@toolflow/shared";
import { useModalState } from "../hooks/useModalState";
import { toolflowAppBackground } from "../../../../../../globalTheme/muiUtils/appTheme";

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

interface BlockModalProps extends ModalProps {
  nodeId: string;
}

interface BlockModalWrapperProps {
  nodeId: string;
  blockType: AllBlockTypes;
  ModalComponent: React.ComponentType<BlockModalProps>;
}

const BlockModalWrapper = ({
  nodeId,
  ModalComponent
}: BlockModalWrapperProps) => {
  const modalState = useModalState();

  const handleClose = () => {
    modalState.closeModal();
  };

  return (
    <Dialog
      open={modalState.isModalOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      fullScreen={false}
      PaperProps={{
        className: "border-radius-16px",
        sx: { backgroundColor: toolflowAppBackground }
      }}
    >
      <ModalComponent
        open={modalState.isModalOpen}
        onClose={handleClose}
        nodeId={nodeId}
      />
    </Dialog>
  );
};

export default BlockModalWrapper;
