import { Stack, Typography } from "@mui/material";
import { useToolContext } from "../../../../../../../../tools/contexts/ToolContext";

const ToolListItem = ({ children }: { children: React.ReactNode }) => {
  const { tool } = useToolContext();
  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        {tool.avatar && (
          <img src={tool.avatar} alt="avatar" height={24} width={24} />
        )}
        <Typography variant="body1">{tool.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} className="flex align-i-center">
        {children}
      </Stack>
    </>
  );
};

export default ToolListItem;
