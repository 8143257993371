import { deepCopy, type PipedreamApp } from "@toolflow/shared";
import { useCallback, useEffect, useState } from "react";
import { QueryParams } from "react-use-websocket/dist/lib/types";
import { useGetAppsQuery } from "../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { debounce } from "lodash";

export function useFetchIntegrations() {
  const [apps, setApps] = useState<PipedreamApp[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [queryParams, setQueryParams] = useState<QueryParams>({ query: "" });

  const {
    data: appsData,
    isLoading,
    isFetching
  } = useGetAppsQuery(queryParams);

  useEffect(() => {
    if (!!appsData?.data) {
      setApps((prev) => {
        if (!queryParams.cursor) {
          return appsData.data;
        }
        return appsData.data.reduce((acc, app) => {
          if (!acc.some((existing) => existing.id === app.id)) {
            acc.push(app);
          }
          return acc;
        }, deepCopy(prev));
      });
    }
  }, [appsData?.data, queryParams.cursor]);

  const debouncedSearch = useCallback(
    debounce((query: string) => setQueryParams({ query }), 300),
    []
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    debouncedSearch(event.target.value);
  };

  function fetchNextBatch() {
    if (appsData?.page_info.end_cursor) {
      setQueryParams((prev) => ({
        ...prev,
        cursor: appsData.page_info.end_cursor
      }));
    }
  }

  return {
    apps,
    pageInfo: appsData?.page_info || {
      end_cursor: "",
      count: 0,
      total_count: 0,
      start_cursor: ""
    },
    isLoading: isLoading && isFetching,
    isFetching,
    fetchNextBatch,

    searchProps: {
      value: searchQuery,
      onChange: handleSearch
    },
    searchQuery
  };
}
