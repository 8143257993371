import React from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import ThreadMessageContext from "../../../contexts/ThreadMessageContext";
import BottomGradient from "../../BottomGradient";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import LoadingThreadMessage from "./LoadingThreadMessage";
import ThreadMessage from "./ThreadMessage";
import styles from "./chatThread.module.css";
import useChatMessages from "./hooks/useChatMessages";
import { Box, Button } from "@mui/material";
import AgentMessageWrapper from "./AgentMessageWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { resetChatState } from "./chatSlice";
import useSetThreadId from "./hooks/useSetThreadId";

const cx = parsedClassNames.bind(styles);

function ResetThreadButton({ className }: { className?: string }) {
  const dispatch = useDispatch();
  const setThreadId = useSetThreadId();

  const handleReset = () => {
    dispatch(resetChatState());
    setThreadId("");
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleReset}
      className={className}
    >
      Reset Thread
    </Button>
  );
}

function ChatThread() {
  const { transformedSocketMessage, threadMessages, isMessageLoading } =
    useChatMessages();
  const hasErrored = useSelector((state: RootState) => state.chat.hasErrored);

  return (
    <ScrollToBottom
      className={cx("oflow-auto h-100-percent scroll-to-bottom")}
      followButtonClassName="arrow-button"
      initialScrollBehavior="smooth"
      scrollViewClassName="scrollable-content-important"
    >
      <Box className={styles["chat-thread"]}>
        {threadMessages.map((threadMessage) => (
          <ThreadMessageContext.Provider
            value={{ threadMessage }}
            key={threadMessage.id}
          >
            <ThreadMessage />
          </ThreadMessageContext.Provider>
        ))}
        {transformedSocketMessage && (
          <ThreadMessageContext.Provider
            value={{ threadMessage: transformedSocketMessage }}
          >
            <AgentMessageWrapper message={transformedSocketMessage} />
          </ThreadMessageContext.Provider>
        )}
        {isMessageLoading && <LoadingThreadMessage />}
        {hasErrored && <ResetThreadButton className="m-t-16px" />}
        <BottomGradient className={"border-radius-16px bottom-gradient-dark"} />
      </Box>
    </ScrollToBottom>
  );
}

export default ChatThread;
