import { MenuItem, Select, Tooltip } from "@mui/material";
import { FieldSelectorValue } from "@toolflow/shared";
import { ArrowDownSmallIcon } from "../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import styles from "./FieldWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export default function FieldSelector({
  value,
  onChange
}: {
  value: FieldSelectorValue;
  onChange(v: FieldSelectorValue): void;
}) {
  return (
    <Select
      value={value}
      size="small"
      fullWidth
      onChange={(e) => onChange(e.target.value as FieldSelectorValue)}
      variant="standard"
      IconComponent={() => (
        <ArrowDownSmallIcon className={cx("select-field-icon")} />
      )}
    >
      <MenuItem value={FieldSelectorValue.Agent} key={FieldSelectorValue.Agent}>
        <Tooltip
          title="Agent determines input in real-time based on the conversation context."
          placement="top"
        >
          <div>🤖 Agent controlled</div>
        </Tooltip>
      </MenuItem>
      <MenuItem
        value={FieldSelectorValue.Preset}
        key={FieldSelectorValue.Preset}
      >
        <Tooltip
          title="Agent is forced to use a pre-determined input each time the tool is used."
          placement="bottom"
        >
          <div>🔒 Pre-determined</div>
        </Tooltip>
      </MenuItem>
    </Select>
  );
}
