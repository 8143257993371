import { isString } from "lodash";
import { SelectOption } from "../../types";

export function getValueFromSelectOption(option: SelectOption) {
  return isString(option) ? option : option.value;
}

export function getLabelFromSelectOption(option: SelectOption) {
  return isString(option) ? option : option.label;
}

export function convertSelectOptions(options?: SelectOption[]) {
  return options?.map(getValueFromSelectOption);
}
