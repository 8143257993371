import React from "react";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { AddIcon } from "../../../../../../../globalTheme/icons/icons";
import { type Content } from "@tiptap/core";
import useConvertContentToHtml from "../../../../../../../utilities/formatters/strings/tiptap/hooks/useConvertContentToHtml";
import useAddNewStringAsset from "../../../../assets/useAddNewStringAsset";

function AddThreadAsAssetButton({ content }: { content: Content }) {
  const addAssetContent = useAddNewStringAsset();
  const convertContentToHtml = useConvertContentToHtml();
  const handleClick = () => {
    let stringContent = convertContentToHtml(content);
    // there are extra new lines in the content, so we remove them
    // otherwise tiptap will add a lot of extra paragraphs

    stringContent = stringContent.replaceAll("\n", "");

    // remove flowAsset and flowThinking tags so that it looks right on the asset
    stringContent = stringContent.replace(/<flowAsset[^>]*>/g, "");
    stringContent = stringContent.replace(/<\/flowAsset>/g, "");
    stringContent = stringContent.replace(/<flowThinking[^>]*>/g, "");
    stringContent = stringContent.replace(/<\/flowThinking>/g, "");

    addAssetContent(stringContent);
  };
  return (
    <RemixButton
      text="Add as asset"
      icon={AddIcon}
      onClick={handleClick}
      disabled={!content}
    />
  );
}

export default AddThreadAsAssetButton;
