import { match, type MatchFunction } from "path-to-regexp";
import {
  BASE_ROUTE_PATH,
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH,
  PROFILE_ROUTE_PATH,
  SETTINGS_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  TOOLBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  WORKSPACE_ROUTE_PATH,
  AGENT_BUILDER_ROUTE_PATH,
  AGENTS_ROUTE_PATH,
  EXTERNAL_AGENT_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH
} from "../helpers/routePaths";

function matchRoute(pathname: string, path: string) {
  const matcher = match(path, { decode: decodeURIComponent });
  return matcher(pathname);
}

function createMultiMatcher(routePaths: string[]) {
  const matchers: MatchFunction[] = routePaths.map((path) =>
    match(path, { decode: decodeURIComponent })
  );
  return (pathname: string) =>
    matchers.map((matcher) => matcher(pathname)).find((result) => result) ||
    null;
}

export const homeMatcher = (path: string) =>
  createMultiMatcher([
    BASE_ROUTE_PATH,
    WORKSPACE_ROUTE_PATH,
    EXTERNAL_TOOL_ROUTE_PATH,
    EXTERNAL_AGENT_ROUTE_PATH,
    EXTERNAL_WORKFLOW_ROUTE_PATH,
    EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
    EXTERNAL_WORKSPACE_ROUTE_PATH
  ])(path);

export const externalToolMatcher = (path: string) =>
  matchRoute(path, EXTERNAL_TOOL_ROUTE_PATH);
export const externalAgentMatcher = (path: string) =>
  matchRoute(path, EXTERNAL_AGENT_ROUTE_PATH);
export const externalWorkflowMatcher = (path: string) =>
  matchRoute(path, EXTERNAL_WORKFLOW_ROUTE_PATH);
export const loginMatcher = (path: string) =>
  matchRoute(path, LOGIN_ROUTE_PATH);
export const signUpMatcher = (path: string) =>
  matchRoute(path, SIGNUP_ROUTE_PATH);
export const toolMatcher = (path: string) =>
  createMultiMatcher([TOOLBUILDER_ROUTE_PATH, TOOLS_PAGE_ROUTE_PATH])(path);
export const toolBuilderMatcher = (path: string) =>
  matchRoute(path, TOOLBUILDER_ROUTE_PATH);
export const workflowBuilderMatcher = (path: string) =>
  matchRoute(path, WORKFLOWBUILDER_ROUTE_PATH);
export const tvrMatcher = (path: string) =>
  matchRoute(path, EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH);
export const findToolsPageMatcher = (path: string) =>
  matchRoute(path, TOOLS_PAGE_ROUTE_PATH);
export const workspaceMatcher = (path: string) =>
  matchRoute(path, WORKSPACE_ROUTE_PATH);
export const settingsMatcher = (path: string) =>
  matchRoute(path, SETTINGS_ROUTE_PATH);
export const profileMatcher = (path: string) =>
  matchRoute(path, PROFILE_ROUTE_PATH);
export const knowledgeMatcher = (path: string) =>
  matchRoute(path, KNOWLEDGE_ROUTE_PATH);
export const addAgentMatcher = (path: string) =>
  matchRoute(path, AGENT_BUILDER_ROUTE_PATH);
export const allAgentsMatcher = (path: string) =>
  createMultiMatcher([AGENT_BUILDER_ROUTE_PATH, AGENTS_ROUTE_PATH])(path);
export const agentsMatcher = (path: string) =>
  matchRoute(path, AGENTS_ROUTE_PATH);
