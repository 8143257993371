import { useContext } from "react";
import { ModalContext } from "../context/ModalContext";

export const useModalState = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalState must be used within ModalProvider");
  }
  return context;
};
