import { Chip } from "@mui/material";
import RemixWrapperIconWithTheme from "../icons/RemixWrapperIconWithTheme";
import { CloseIcon } from "../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import { ClickableTypes } from "@toolflow/shared";
import { snippetIconDict } from "../../../features/pages/workstation/components/chat/chatbox/SendThreadTextField/runButton/helpers/constants";
import { DarkUtilityTooltip } from "../toolTips/DarkUtilityTooltip";

function SnippetChip({
  text,
  onDelete,
  type,
  className,
  onClick,
  tabIndex,
  tooltipText = ""
}: {
  text: string;
  onDelete?: () => void;
  type: ClickableTypes;
  className?: string;
  onClick?: () => void;
  tabIndex?: number;
  tooltipText?: string;
}) {
  return (
    <DarkUtilityTooltip title={tooltipText}>
      <Chip
        icon={
          <RemixWrapperIconWithTheme
            size={14}
            Icon={snippetIconDict[type]}
            providedColor={toolflowTextDisabledColor}
          />
        }
        label={text}
        contentEditable={false}
        onDelete={onDelete}
        className={className}
        onClick={onClick}
        variant={type === ClickableTypes.SNIPPET ? "purple" : "white"}
        deleteIcon={
          <RemixWrapperIconWithTheme
            size={14}
            Icon={CloseIcon}
            providedColor={toolflowTextDisabledColor}
          />
        }
        size="small"
        tabIndex={tabIndex}
      />
    </DarkUtilityTooltip>
  );
}

export default SnippetChip;
