import {
  Card,
  Typography,
  type TypographyVariant,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton
} from "@mui/material";
import { useAgentContext } from "../../../../agents/contexts/AgentContext";
import FindAgentButton from "../chat/chatbox/ChatInputPaper/FindAgentButton";
import { NavigateToOwnAgentBuilderButton } from "../../../builder/agentBuilder/NavigateToAgentBuilderButton";
import { SearchIcon, CloseIcon } from "../../../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../../../globalTheme/muiUtils/appTheme";
import { classNames } from "../../../../../utilities/functions/parsedClassNames";
import useGetCurrentChatThreadId from "../chat/chatbox/hooks/useGetCurrentChatThreadId";
import { useState } from "react";

const useOnClick = () => {
  const threadId = useGetCurrentChatThreadId();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return {
    onClick: () => {
      if (threadId) {
        setIsDialogOpen(true);
      }
    },
    isDialogOpen,
    handleClose
  };
};

const AgentHistoryAlert = ({
  isDialogOpen,
  handleClose
}: {
  isDialogOpen: boolean;
  handleClose: () => void;
}) => {
  return (
    <Dialog open={isDialogOpen} onClose={handleClose}>
      <DialogTitle>
        Are you sure you want to change agents?
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <CloseIcon size={20} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          By changing the agent, you will lose your current chat history.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SearchOrEditAgent = ({
  variant = "h7",
  className,
  cardClassName = "p-8px"
}: {
  variant?: TypographyVariant | "h7" | "smallButton";
  className?: string;
  cardClassName?: string;
}) => {
  const { agent } = useAgentContext();
  const { onClick, isDialogOpen, handleClose } = useOnClick();

  return (
    <>
      <div className={classNames("flex align-i-center", className)}>
        <FindAgentButton
          button={
            <Card
              variant="outlined"
              onClick={onClick}
              className={classNames(
                "border-radius-16px m-r-8px flex align-i-center cursor-pointer primary-button-border",
                cardClassName
              )}
            >
              <Typography variant={variant}>{agent.name}</Typography>
              <SearchIcon
                className="m-l-8px"
                size={14}
                color={toolflowTextDisabledColor}
              />
            </Card>
          }
        />
        <NavigateToOwnAgentBuilderButton agent={agent} />
      </div>
      <AgentHistoryAlert
        isDialogOpen={isDialogOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default SearchOrEditAgent;
