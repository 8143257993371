import { Button } from "@mui/material";
import React, { FC } from "react";

interface IJoinSlackButtonProps {
  className?: string;
  hide?: boolean;
}
const JoinSlackButton: FC<IJoinSlackButtonProps> = ({
  className = "",
  hide = false
}) => {
  if (hide) {
    return null;
  }
  const redirectToSlackLink = () => {
    window.open(
      "https://join.slack.com/t/toolflowconnect/shared_invite/zt-28ksi2tvn-BACaJOxZWWr3CISVjbFedg",
      "_blank"
    );
  };
  return (
    <Button
      className={className}
      variant="text"
      size="small"
      onClick={redirectToSlackLink}
    >
      Join Slack community
    </Button>
  );
};

export default JoinSlackButton;
