import {
  CopyableField,
  CustomToolInputFieldTypesEnum,
  UtlBlockNode,
  VisibilityTypes,
  type BlockDataBase,
  type CustomEdge,
  type CustomToolInputField,
  type CustomToolOutputField,
  type TBlock,
  type ToolState,
  type UniqueNameOutput
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import {
  firstInputName,
  firstOutputLabel
} from "../../../../tools/components/editorToolCard/inputs/helpers/constants";

// Edge generation function
function generateGraph() {
  const ids = {
    inputBlockNode: uuidv4(),
    parentBlockNode: uuidv4(),
    outputBlockNode: uuidv4()
  };
  const nodes: TBlock[] = [
    {
      id: ids.inputBlockNode,
      deletable: false,
      type: UtlBlockNode.InputBlockNode,
      data: { label: "Get Inputs" } as BlockDataBase,
      position: { x: 534, y: 0 },
      hidden: true
    },
    {
      id: ids.parentBlockNode,
      data: { label: "Create Assets" } as BlockDataBase,
      position: { x: 0, y: 125 },
      hidden: true,
      style: {
        width: 1400,
        height: 700
      },
      type: UtlBlockNode.ParentBlockNode,
      draggable: false
    },
    {
      id: ids.outputBlockNode,
      type: UtlBlockNode.OutputBlockNode,
      hidden: true,
      data: { label: "Send Outputs" } as BlockDataBase,
      position: { x: 534, y: 875 }
    }
  ];
  const edges: CustomEdge[] = [
    {
      id: `e${nodes[0].id}-${nodes[1].id}`,
      source: nodes[0].id,
      target: nodes[1].id,
      data: { deletable: false }
    },
    {
      id: `e${nodes[1].id}-${nodes[2].id}`,
      source: nodes[1].id,
      target: nodes[2].id,
      data: { deletable: false }
    }
  ];
  return {
    blocks: nodes,
    edges
  };
}

export const createInitialToolInputFields = (): CustomToolInputField[] => [
  {
    name: firstInputName,
    type: CustomToolInputFieldTypesEnum.TEXTFIELD,
    id: uuidv4()
  }
];

export const createInitialToolOutputFields = (): CustomToolOutputField[] => [
  { name: firstOutputLabel, type: CopyableField.LargeText, id: uuidv4() }
];

export const defaultToolName = "New Tool";

export const createNewToolState = (): ToolState => {
  const { blocks, edges } = generateGraph();
  const originalState = {
    toolName: defaultToolName,
    toolAbout: "",
    toolInputFields: [],
    description: "",
    toolOutputFields: [],
    blocks,
    edges,
    availableFields: {},
    visibility: VisibilityTypes.PUBLIC,
    enableForking: false,
    tag: { categories: [], useCases: [] },
    estimatedCreditCost: 0,
    toolOutputOptions: {
      inputs: [],
      blocks: []
    } as UniqueNameOutput
  };

  return {
    toolLoading: false,
    creator: null,
    toolVersionResponse: null,
    toolVersions: {},
    main: null,
    toolId: "",
    toolVersionId: "",
    userInput: {},
    currentState: originalState,
    originalState: JSON.parse(JSON.stringify(originalState)) // need to deep copy so that the reference to the object changes in memory for originalState purposes
  };
};
