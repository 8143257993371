import { type PipedreamApp } from "@toolflow/shared";
import { useIntegrationFilter } from "../../pages/profile/pages/settings/UserIntegrationSettings/hooks/useIntegrationFilter";
import { useMemo } from "react";
import useGetIntegrations from "./useGetIntegrations";

export default function useFilteredIntegrations({
  apps,
  pageInfo,
  searchQuery
}: {
  apps: PipedreamApp[];
  pageInfo: {
    end_cursor: string;
    count: number;
    total_count: number;
    start_cursor: string;
  };
  searchQuery: string;
}) {
  const { data: userIntegrations } = useGetIntegrations();
  const { getFilteredApps } = useIntegrationFilter(
    userIntegrations ?? { integrations: [] },
    { data: apps, page_info: pageInfo },
    searchQuery
  );
  return useMemo(getFilteredApps, [apps, pageInfo, searchQuery]);
}
