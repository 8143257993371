import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  AGENT,
  EntityType,
  type ValidateTypeWithAgent
} from "@toolflow/shared";
import { DialogTitle } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloseIcon } from "../../../../../globalTheme/icons/icons";
import RemixButton from "../../../icons/RemixButton";

const DeleteConfirmationDialog = ({
  deleteObject,
  open,
  handleClose,
  type,
  name = ""
}: {
  deleteObject: (e: React.SyntheticEvent<Element, Event>) => void;
  open: boolean;
  handleClose: (
    e?: $TSFixMe,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => void;
  type: ValidateTypeWithAgent;
  name?: string;
}) => {
  const deleteNames = {
    [EntityType.TOOL]: "tool",
    workspace: "session",
    toolVersionResponse: "tool response",
    [EntityType.WORKFLOW]: "workflow",
    [AGENT]: "agent"
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: "pos-relative"
      }}
    >
      <RemixButton
        onClick={handleClose}
        className="pos-absolute top-24px right-24px"
        icon={CloseIcon}
      />

      <DialogTitle>Delete this {deleteNames[type]}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          aria-label="delete-tool"
          onClick={handleClose}
          size="large"
          variant="text"
          className="m-l-auto"
          fullWidth={false}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          aria-label="edit-name"
          onClick={deleteObject}
          size="large"
          className="m-l-8px"
          variant="contained"
          fullWidth={false}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
