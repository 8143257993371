import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";

const useHideIconsOnExpanded = () => {
  const open = useSelector((state: RootState) => state.workspace.assetExpanded);

  return !open;
};

export default useHideIconsOnExpanded;
