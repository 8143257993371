import {
  AnthropicModel,
  ChatLLMType,
  isEnumValue,
  OpenAiTextModel
} from "@toolflow/shared";
import { useAgentContext } from "../../../../../../../agents/contexts/AgentContext";

export default function useGetAgentModelType() {
  const { agent } = useAgentContext();

  if (isEnumValue(agent.config.model, AnthropicModel)) {
    return ChatLLMType.Anthropic;
  } else if (isEnumValue(agent.config.model, OpenAiTextModel)) {
    return ChatLLMType.OpenAi;
  }
}
