import { type SelectFieldV2Type, type SelectOption } from "@toolflow/shared";
import SelectInputInner from "../../../components/SelectInputInner";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useDynamicSetting from "../../../hooks/useDynamicSetting";

export function SelectFieldV2() {
  const { id, field, getFieldValue } =
    useFieldsByTypeContext<SelectFieldV2Type>();

  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const disabled = useDynamicSetting<boolean>(field.disabled);
  const fieldKey = useDynamicSetting<string>(field.config.fieldKey);
  const options = useDynamicSetting<SelectOption[]>(field.config.options);

  return (
    <SelectInputInner
      id={id}
      label={label}
      subtitle={subtitle}
      disabled={disabled}
      options={options}
      value={getFieldValue(fieldKey, "", field.config.optionOverride)}
      fieldKey={fieldKey}
      callback={field.onChange}
    />
  );
}
