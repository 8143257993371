import { type JSONContent, generateText } from "@tiptap/core";
import useExtension from "./useExtensions/useExtension";

const useGenerateTextFromJSON = () => {
  const extensions = useExtension();
  const getInputHtml = (jsonToConvert: JSONContent) => {
    try {
      return generateText(jsonToConvert, extensions);
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  return getInputHtml;
};

export default useGenerateTextFromJSON;
