import { Stack } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  deepCopy,
  evaluateDynamicSetting,
  type CustomToolInputField,
  type TAddChipConfig,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import React, { ReactNode, useEffect } from "react";
import useInputChipsToUse from "../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";
import { InputCheckbox } from "./editorToolCard/inputs/InputCheckbox";
import { InputDoubleTextField } from "./editorToolCard/inputs/InputDoubleTextField";
import { InputFileUpload } from "./editorToolCard/inputs/InputFileUpload";
import { InputMultiSelect } from "./editorToolCard/inputs/InputMultiSelect";
import { InputNumericField } from "./editorToolCard/inputs/InputNumericField";
import { InputSelect } from "./editorToolCard/inputs/InputSelect";
import { InputTags } from "./editorToolCard/inputs/InputsTags";
import { InputTextField } from "./editorToolCard/inputs/InputTextField";
import { JsonSchemaField } from "./editorToolCard/inputs/JsonSchemaField";
import { SliderField } from "./editorToolCard/inputs/SliderField";
import { WordReplaceField } from "./editorToolCard/inputs/WordReplaceField";
import { WsPipedreamActionField } from "./editorToolCard/inputs/wsPipedreamFields/WsPipedreamActionField";
import { WsPipedreamConfigurationsFields } from "./editorToolCard/inputs/wsPipedreamFields/wsPipedreamConfigurationsFields/WsPipedreamConfigurationsFields";

type TBaseProps = {
  allowAgentDeterminedValues?: boolean;
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  entityId?: string;
  useIds?: boolean;
};
type TExtraProps = {
  autofocus?: boolean;
  defaultFormState?: UserInputDictType;
  chipsToUse: TAddChipConfig;
  defaultExpanded?: boolean;
};

interface InputComponentConfig {
  component: React.ElementType;
  propFilter?: (
    baseProps: TBaseProps,
    extraProps: TExtraProps
  ) => TBaseProps & Partial<TExtraProps>; // Optional, for additional props
}

// Base props that are common across all components
export const baseProps = ({
  allowAgentDeterminedValues,
  toolInputField,
  setFormState,
  formState,
  entityId,
  useIds
}: TBaseProps) => ({
  allowAgentDeterminedValues,
  toolInputField,
  setFormState,
  formState,
  entityId,
  useIds
});

export const inputComponentMap: Record<
  CustomToolInputFieldTypesEnum,
  InputComponentConfig
> = {
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: {
    component: InputTextField,
    propFilter: (
      bProps,
      { defaultExpanded, defaultFormState, chipsToUse }
    ) => ({
      ...bProps,
      defaultExpanded,
      defaultFormState,
      chipsToUse
    })
  },
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: {
    component: InputTextField,
    propFilter: (
      bProps,
      { defaultExpanded, defaultFormState, chipsToUse }
    ) => ({
      ...bProps,
      defaultExpanded,
      defaultFormState,
      chipsToUse
    })
  },
  [CustomToolInputFieldTypesEnum.CHECKBOX]: {
    component: InputCheckbox,
    propFilter: (bProps) => ({ ...bProps })
  },
  [CustomToolInputFieldTypesEnum.SELECT]: {
    component: InputSelect,
    propFilter: (bProps, { autofocus }) => ({
      ...bProps,
      autofocus
    })
  },
  [CustomToolInputFieldTypesEnum.MULTI_SELECT]: {
    component: InputMultiSelect
  },
  [CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD]: {
    component: InputFileUpload,
    propFilter: (bProps) => ({ ...bProps })
  },
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: {
    component: InputFileUpload,
    propFilter: (bProps) => ({ ...bProps })
  },
  [CustomToolInputFieldTypesEnum.TAGS_INPUT]: {
    component: InputTags
  },
  [CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD]: {
    component: InputDoubleTextField
  },
  [CustomToolInputFieldTypesEnum.NUMERIC_FIELD]: {
    component: InputNumericField
  },
  [CustomToolInputFieldTypesEnum.SLIDER]: {
    component: SliderField
  },
  [CustomToolInputFieldTypesEnum.JSON_SCHEMA]: {
    component: JsonSchemaField
  },
  [CustomToolInputFieldTypesEnum.WORD_REPLACE]: {
    component: WordReplaceField
  },
  [CustomToolInputFieldTypesEnum.PIPEDREAM_ACTION]: {
    component: WsPipedreamActionField
  },
  [CustomToolInputFieldTypesEnum.PIPEDREAM_FIELDS]: {
    component: WsPipedreamConfigurationsFields
  },
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: {
    component: () => null
  }
};

function InputComponentWrapper({
  useIds,
  formState,
  toolInputField,
  children,
  setFormState
}: {
  useIds?: boolean;
  formState: UserInputDictType;
  toolInputField: CustomToolInputField;
  children: ReactNode;
  setFormState: TSetFormState;
}) {
  const key = useIds ? toolInputField.id : toolInputField.name;
  const hidden = evaluateDynamicSetting(toolInputField.hidden, formState);

  useEffect(() => {
    if (hidden && key in formState) {
      const newFormState = deepCopy(formState);
      delete newFormState[key];
      setFormState(newFormState);
    }
  }, [hidden]);

  if (hidden) return null;

  return children;
}

const WebsiteForm = ({
  entityId,
  formState,
  setFormState,
  toolInputFields,
  defaultFormState,
  autofocus,
  useIds,
  fieldsToHide = [],
  allowAgentDeterminedValues
}: {
  entityId?: string;
  formState: UserInputDictType;
  defaultFormState?: UserInputDictType;
  setFormState: TSetFormState;
  toolInputFields: CustomToolInputField[];
  autofocus?: boolean;
  useIds?: boolean;
  fieldsToHide?: string[];
  allowAgentDeterminedValues?: boolean;
}) => {
  const chipsToUse = useInputChipsToUse();
  if (toolInputFields.length === 0) return null;
  return (
    <Stack spacing={6} className="p-v-32px">
      {toolInputFields
        .filter((input) => !fieldsToHide.includes(input.id))
        .map((toolInputField, index) => {
          const config = inputComponentMap[toolInputField.type];
          if (!config) return null; // Skip if no matching component is found

          const InputComponent = config.component;
          const commonProps = baseProps({
            allowAgentDeterminedValues,
            toolInputField,
            setFormState,
            formState,
            entityId,
            useIds
          });
          const extraProps = {
            autofocus: index === 0 && autofocus,
            defaultFormState,
            defaultExpanded: true,
            chipsToUse
          };

          // Apply propFilter if it exists, otherwise pass common props directly
          const props = config.propFilter
            ? config.propFilter(commonProps, extraProps)
            : commonProps;

          return (
            <InputComponentWrapper
              key={index}
              useIds={useIds}
              formState={formState}
              setFormState={setFormState}
              toolInputField={toolInputField}
            >
              <InputComponent {...props} />
            </InputComponentWrapper>
          );
        })}
    </Stack>
  );
};

export default WebsiteForm;
