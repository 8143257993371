import AssetHeaderWrapper from "../AssetHeaderWrapper";
import AssetName from "../AssetName";
import StructuredAssetIcons from "./StructuredAssetIcons";

export default function StructuredAssetHeader({ name }: { name: string }) {
  return (
    <AssetHeaderWrapper>
      <AssetName text={name} />
      <StructuredAssetIcons />
    </AssetHeaderWrapper>
  );
}
