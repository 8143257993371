import { IconButton } from "@mui/material";
import React from "react";
import useAbortThreadButton from "./hooks/useAbortThreadButton";
import { AbortIcon } from "../../../../../../../../globalTheme/icons/icons";

function AbortChatMessageButton() {
  const { abortThread, currentThreadIdRef } = useAbortThreadButton();
  return (
    <IconButton
      color="primary"
      onClick={() => abortThread(currentThreadIdRef?.current ?? "")}
      className="m-l-8px asset-button border-radius-16px"
    >
      <AbortIcon size={16} />
    </IconButton>
  );
}

export default AbortChatMessageButton;
