import { CustomToolInputFieldTypesEnum } from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";

const useHandleAddToolInputFieldWorkflow = () => {
  const { append } = useInputsToCollectFieldArrayContext();

  const addToolInputFieldWorkflow = () => {
    append({
      name: "",
      type: CustomToolInputFieldTypesEnum.TEXTFIELD,
      id: uuidv4()
    });
  };

  return addToolInputFieldWorkflow;
};

export default useHandleAddToolInputFieldWorkflow;
