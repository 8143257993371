import AssetHeaderWrapper from "../AssetHeaderWrapper";
import AssetNameContainer from "./AssetNameContainer";
import TextAssetIcons from "./textAssetIcons/TextAssetIcons";

function AssetHeader() {
  return (
    <AssetHeaderWrapper>
      <AssetNameContainer />
      <TextAssetIcons />
    </AssetHeaderWrapper>
  );
}

export default AssetHeader;
