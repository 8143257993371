import {
  FieldType,
  VERSION_1_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";

export const userIntegrationBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.PipedreamActionField,
      label: "Action",
      subtitle: "Select the action to perform",
      config: {
        fieldKey: "settings.action"
      }
    },
    {
      type: FieldType.PipedreamField,
      label: "Configure Action",
      subtitle: "Configure the selected action",
      config: {
        fieldKey: "settings.configuredProps",
        typeKey: "settings.type"
      }
    }
  ]
};
