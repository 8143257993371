import { $TSAllowedAny, JSONValue } from "../../types";

export function validateAndParseJson<T = JSONValue>(str: unknown): T | null {
  if (!str || typeof str !== "string") {
    return null;
  }
  try {
    const o = JSON.parse(str);
    if (!!o && typeof o === "object") {
      return o;
    }
  } catch (error) {}
  return null;
}

export function deepParseJson<T = JSONValue>(input: string): T | string {
  // First, parse the top-level JSON string
  let parsed;
  try {
    if (!input) {
      return {} as T;
    }
    parsed = JSON.parse(input);
  } catch (error) {
    console.error("Invalid top-level JSON:", error, input);
    return input; // Return the original input if it's not valid JSON
  }

  // Recursively parse nested JSON strings
  function parseNested(obj: $TSAllowedAny): $TSAllowedAny {
    if (typeof obj === "string") {
      try {
        return JSON.parse(obj); // Parse if it's a JSON string
      } catch (error) {
        return obj; // Return the string as-is if it's not valid JSON
      }
    } else if (typeof obj === "object" && obj !== null) {
      // Handle objects and arrays
      if (Array.isArray(obj)) {
        return obj.map((item) => parseNested(item));
      } else {
        const result: Record<string, $TSAllowedAny> = {};
        for (const key in obj) {
          result[key] = parseNested(obj[key]);
        }
        return result;
      }
    }
    return obj; // Return non-string, non-object values unchanged
  }

  return parseNested(parsed);
}
