import {
  Button,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import {
  EntityType,
  type ExternalToolFE,
  type MapperObject
} from "@toolflow/shared";
import { DateTime } from "luxon";
import replaceInitialInputMap from "../helpers/replaceInitialInputMap";
import seperateOutputChanges from "../helpers/separateOutputChanges";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import useUpdateField from "../../../../../useUpdateField";
import useGetToolFromQuery from "../../../../../../../../tools/hooks/useGetToolFromQuery";

function ToolVersionCard({
  tool,
  blockId,
  inputMap
}: {
  tool?: ExternalToolFE | null;
  blockId: string;
  inputMap?: MapperObject | null;
}) {
  const currentTool = useGetToolFromQuery(tool?._id, {
    refetchOnMountOrArgChange: true,
    skip: !tool?._id
  });
  const { dispatch } = useToolbuilderContext();
  const updateField = useUpdateField(blockId);
  if (!tool || !inputMap) return null;

  const notCurrentVersion =
    currentTool && currentTool.updatedAt !== tool.updatedAt;

  const updateVersion = () => {
    if (!currentTool) return;
    const newInputMap = replaceInitialInputMap(currentTool, tool, inputMap);
    updateField(currentTool, EntityType.TOOL);
    updateField(newInputMap, "inputMap");
    const labelComparison = seperateOutputChanges(currentTool, tool);
    dispatch({
      type: "UPDATE_OUTPUTS_WITH_NEW_TOOL_VERSION",
      labelComparison
    });

    // separate outputs that are the same
  };

  return (
    <Card>
      <CardHeader
        title="Current version"
        subheader={DateTime.fromSeconds(tool.updatedAt).toLocaleString(
          DateTime.DATETIME_MED
        )}
      />
      {notCurrentVersion && (
        <List>
          <ListItem>
            <ListItemText
              primary={DateTime.fromSeconds(
                currentTool.updatedAt
              ).toLocaleString(DateTime.DATETIME_MED)}
              secondary="A newer version is available"
            />
            <ListItemSecondaryAction>
              <Button variant="outlined" onClick={updateVersion}>
                Update
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
    </Card>
  );
}

export default ToolVersionCard;
