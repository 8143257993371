import React from "react";

const DropdownMenuItem = ({ name, icon }: { name: string; icon?: string }) => {
  return (
    <>
      <span className="m-r-8px">{icon}</span>
      {name}
    </>
  );
};

export const DropdownIconMenuItem = ({
  name,
  Icon
}: {
  name: string;
  Icon?: React.ElementType;
}) => {
  return (
    <div className="flex align-i-center">
      {Icon && <Icon size={20} className="m-r-8px m-t-neg-4px" />}
      <span>{name}</span>
    </div>
  );
};

export default DropdownMenuItem;
