import {
  VERSION_1_VALUE,
  FieldType,
  VERSION_2_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";

export const constantBlockField: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      label: "",
      type: FieldType.PromptInputV1,
      config: {
        fieldKey: "constant"
      }
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.PromptInputV2,
      label: "Text composer",
      subtitle:
        "Type or combine text here. You can enter new text manually and/or pull in existing text dynamically from user inputs, node outputs, or snippets.",
      config: {
        fieldKey: "constant",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px"
      }
    }
  ]
};
