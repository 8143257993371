import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent
} from "@mui/material";
import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import {
  type BlockSettingsBase,
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type PipedreamFieldType
} from "@toolflow/shared";
import { isEmpty } from "lodash";
import { CSSProperties, useEffect, useState } from "react";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import PipedreamInputListComponent from "./PipedreamInputListComponent";

export function PipedreamSelectField(props: $TSAllowedAny) {
  const baseStyles: CSSProperties = {
    gridArea: "control"
  };

  const { onChange, prop, value } = useFormFieldContext();

  const { options, inputId, required } = props;
  const { id, field, updateField } = useFieldsByTypeContext<
    PipedreamFieldType,
    BlockSettingsBase
  >();

  const { getProps } = useCustomize();

  const fieldKey = field.config.fieldKey;
  const typeKey = field.config.typeKey;
  const [dynamicType, setDynamicType] = useState(DynamicFieldType.Preset);
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (!isEmpty(options)) {
      setFieldValue(value);
    } else {
      onChange(value);
    }
  }, [options]);

  const handleFieldValueChange = (newValue: SelectChangeEvent<string>) => {
    const updatedValue = newValue.target.value;
    setFieldValue(updatedValue);
    onChange(updatedValue);
  };
  const handleTypeChange = (newType: DynamicFieldType) => {
    setDynamicType(newType);
  };

  const handleListChange = (newValues: string[]) => {
    updateField(newValues, fieldKey);
    onChange(newValues);
  };

  const normalizeOption = (option: {
    label: string;
    value: string | { label: string; value: string };
  }): { label: string; value: string } => {
    if (option.value && typeof option.value === "object") {
      return {
        label: option.value.label || option.label,
        value: option.value.value || JSON.stringify(option.value)
      };
    }
    return {
      label: option.label,
      value: String(option.value)
    };
  };

  const renderSelectContent = () => {
    const selectProps = {
      fullWidth: true,
      size: "small" as const,
      value: fieldValue,
      onChange: handleFieldValueChange,
      className: "nowheel nodrag nopan",
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: "40vh"
          }
        }
      }
    };

    if (prop.type === "string[]" && !prop.options) {
      return (
        <PipedreamInputListComponent
          valuesList={value}
          handleChange={handleListChange}
          options={options?.map(normalizeOption)}
        />
      );
    } else if (dynamicType === DynamicFieldType.Dynamic) {
      return (
        <Select {...selectProps}>
          <MenuItem value={CREATE_USER_INPUT_LABEL}>
            {CREATE_USER_INPUT_LABEL}
          </MenuItem>
        </Select>
      );
    }

    return (
      <FormControl fullWidth>
        <Select {...selectProps} id={inputId} required={required}>
          {!options || options.length === 0 ? (
            <MenuItem value="" disabled>
              No Options
            </MenuItem>
          ) : (
            options.map(
              (option: {
                label: string;
                value: string | { label: string; value: string };
              }) => {
                const normalizedOption = normalizeOption(option);
                return (
                  <MenuItem
                    key={normalizedOption.value}
                    value={normalizedOption.value}
                  >
                    {normalizedOption.label}
                  </MenuItem>
                );
              }
            )
          )}
        </Select>
      </FormControl>
    );
  };

  return (
    <div {...getProps("label", baseStyles, props)}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicType}
        configs={{
          fieldKey,
          typeKey,
          forceDynamic: false
        }}
        label={prop?.label}
        fieldValue={fieldValue}
        addInputType={CustomToolInputFieldTypesEnum.SELECT}
        onChange={handleTypeChange}
        onDynamicInputChange={handleFieldValueChange}
      >
        <div className="m-t-8px">{renderSelectContent()}</div>
      </DynamicFieldWrapper>
    </div>
  );
}
