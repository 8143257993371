import { type NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { ChipNodeHtmlTags } from "@toolflow/shared";
import { Typography } from "@mui/material";

const ThinkingViewerComponent = (props: NodeViewProps) => {
  return (
    <NodeViewWrapper
      className={`${ChipNodeHtmlTags.FlowThinkingComponent} dontTriggerBubble`}
    >
      <Typography variant="caption" color="text.secondary">
        {props.node.textContent}
      </Typography>
    </NodeViewWrapper>
  );
};

export default ThinkingViewerComponent;
