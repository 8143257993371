export type TExtensionsConfig = {
  /** Placeholder hint to show in the text input area before a user types a message. */
  disableCustomExtensions?: boolean;
  disableDefaultEnter?: boolean;
  enableAutolinkExtension?: boolean;
  disableFormattingExtensions?: boolean;
  extensionLocation?: ExtensionLocation;
};

export enum ExtensionLocation {
  Workspace = "workspace",
  Toolbuilder = "toolbuilder",
  Default = "default"
}

export type UseExtensionsOptions = {
  placeholder?: string;
};
