import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent
} from "@mui/material";
import {
  useAccounts,
  useFormFieldContext,
  useFrontendClient
} from "@pipedream/connect-react";
import { useEffect } from "react";
import styles from "../PipedreamFields.module.css";
import { usePipedreamFieldsContext } from "../PipedreamFieldsProvider";
import PipedreamFieldLabel from "./PipedreamFieldLabel";

export function PipedreamAppSelect() {
  const client = useFrontendClient();
  const fieldsCtx = useFormFieldContext();
  const { onChange, prop, value, extra } = fieldsCtx;
  const { updateDynamicConfiguredProps } = usePipedreamFieldsContext();

  const app = extra?.app;

  const { accounts, refetch: refetchAccounts } = useAccounts(
    { app: app?.name_slug },
    { useQueryOpts: { enabled: !!app } }
  );

  useEffect(() => {
    refetchAccounts();
  }, []);

  const startConnectAccount = async () => {
    client.connectAccount({
      app: prop.app,
      onSuccess: async (res: { id: string }) => {
        await refetchAccounts();
        onChange({ authProvisionId: res.id });
      }
    });
  };

  function handleOnChange(e: SelectChangeEvent) {
    if (e.target.value === "_new") {
      startConnectAccount();
    } else {
      onChange({ authProvisionId: e.target.value });
      updateDynamicConfiguredProps(prop.name, {
        authProvisionId: e.target.value
      });
    }
  }

  return (
    <PipedreamFieldLabel
      overrideKey={"app"}
      alternateDescription={`When set to "Agent controlled", the agent will dynamically determine and set the appropriate values at runtime.`}
    >
      <FormControl fullWidth>
        <Select
          fullWidth
          size="small"
          value={value?.authProvisionId || ""}
          onChange={handleOnChange}
          className="nowheel nodrag nopan bg-color-white"
        >
          {accounts?.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              <div className={styles["account-container"]}>
                {account.img_src && (
                  <img
                    src={account.img_src}
                    alt={account.name}
                    className={styles["account-image"]}
                  />
                )}
                {account.name}
              </div>
            </MenuItem>
          ))}
          <MenuItem value="_new">Connect new {app?.name} account...</MenuItem>
        </Select>
      </FormControl>
    </PipedreamFieldLabel>
  );
}
