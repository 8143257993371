import { FormLabel, Stack } from "@mui/material";
import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import { Switch } from "../../../../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/outputs/outputFields/Switch";
import { usePipedreamFieldsContext } from "../PipedreamFieldsProvider";
import PipedreamFieldLabel from "./PipedreamFieldLabel";

export function PipedreamBoolean(props: $TSAllowedAny) {
  const formFieldProps = useFormFieldContext();
  const { prop, value, onChange } = formFieldProps;
  const { updateDynamicConfiguredProps } = usePipedreamFieldsContext();
  const { getProps } = useCustomize();
  const styleProps = getProps("label", { float: "inline-start" }, props);

  function handleOnChange(checked: boolean) {
    onChange(checked);
    updateDynamicConfiguredProps(prop.name, checked);
  }

  return (
    <PipedreamFieldLabel>
      <Stack {...styleProps} direction="row" spacing={1}>
        <Switch checked={value} label="" onChange={handleOnChange} />
        <FormLabel>Enable</FormLabel>
      </Stack>
    </PipedreamFieldLabel>
  );
}
