import React from "react";
import EntityNotFoundBase from "../../../../utilities/components/autocomplete/universalAutocomplete/EntityNotFoundBase";
import { Button } from "@mui/material";
import IconWithCircle from "../../../../globalTheme/icons/logos/IconWithCircle";
import RemixWrapperIconWithTheme from "../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../stores/store";
import {
  getDefaultContentValues,
  getTabsContentDict
} from "./utils/getTabContent";
import {
  setCategory,
  setSearchToolsTab,
  setUseCases
} from "./slice/searchToolsSlice";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import {
  AGENT_BUILDER_BASE_ROUTE_PATH,
  TOOLBUILDER_BASE_ROUTE_PATH
} from "../../../navigation/helpers/routePaths";
import {
  ALL_CATEGORY_TAB,
  MARKETPLACE_TAB,
  USER_TOOLS_TAB
} from "./utils/constants";
import { AGENT, type TMarketplaceUniversalEntityType } from "@toolflow/shared";

const useEntityNotFound = (entityType: TMarketplaceUniversalEntityType) => {
  const searchTab = useSelector((state: RootState) => state.searchTools.tab);
  const dispatch = useDispatch();
  const navigate = useToolflowNavigate();

  const content =
    getTabsContentDict(entityType)[searchTab] ||
    getDefaultContentValues(entityType);

  const handleClick = (e: React.MouseEvent) => {
    if (searchTab !== USER_TOOLS_TAB) {
      dispatch(setCategory(ALL_CATEGORY_TAB));
      dispatch(setUseCases([]));
      dispatch(setSearchToolsTab(MARKETPLACE_TAB));
    } else {
      e.stopPropagation();
      navigate(
        `${entityType === AGENT ? AGENT_BUILDER_BASE_ROUTE_PATH : TOOLBUILDER_BASE_ROUTE_PATH}`,
        e
      );
    }
  };

  return { content, handleClick };
};

const EntityNotFound = ({
  entityType
}: {
  entityType: TMarketplaceUniversalEntityType;
}) => {
  const { content, handleClick } = useEntityNotFound(entityType);

  return (
    <EntityNotFoundBase
      className="flex align-i-center justify-center flex-column h-450px"
      header={content.header}
      actionButton={
        content.actionButtonText && (
          <Button
            variant="contained"
            className="border-radius-16px text-transform-none"
            onClick={handleClick}
          >
            {content.actionButtonText}
          </Button>
        )
      }
      subHeader={content.subheader}
      icon={
        content.icon && (
          <IconWithCircle
            icon={
              <RemixWrapperIconWithTheme
                Icon={content.icon}
                className={content.className}
                size={40}
              />
            }
          />
        )
      }
    />
  );
};

export default EntityNotFound;
