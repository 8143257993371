import {
  type TDisabledEntities,
  type TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import React from "react";
import SearchEntitiesDialogInner from "./components/SearchEntitiesDialogInner";
import SearchEntitiesProvider from "./components/SearchEntitiesProvider";

export enum SearchEntitiesDialogType {
  TOOLS = "tool",
  AGENTS = "agent"
}

interface Props {
  type: SearchEntitiesDialogType;
  title: string;
  disabledEntities?: TDisabledEntities;
  allowConfiguration?: boolean;
  editFormProps?: { toolId: string; props?: Record<string, $TSAllowedAny> };
  button: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
  }>;
  onClickEntity: (
    id: string,
    type: TMarketplaceUniversalEntityType,
    toolProps?: Record<string, $TSAllowedAny>
  ) => void;
}

export const SearchEntitiesDialog = (props: Props) => {
  return (
    <SearchEntitiesProvider {...props}>
      <SearchEntitiesDialogInner />
    </SearchEntitiesProvider>
  );
};
