import { FormControl, MenuItem, Select } from "@mui/material";
import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import { FieldSelectorValue, PDFieldType } from "@toolflow/shared";
import { useEffect } from "react";
import { parsedClassNames } from "../../../../../../../../../utilities/functions/parsedClassNames";
import InputListComponent from "../../../../../../inputs/InputListComponent";
import { getAgentDeterminedObj } from "../helpers/configuredPropsHelpers";
import styles from "../PipedreamFields.module.css";
import { usePipedreamFieldsContext } from "../PipedreamFieldsProvider";
import PipedreamFieldLabel from "./PipedreamFieldLabel";

const cx = parsedClassNames.bind(styles);

export function PipedreamSelectField(props: $TSAllowedAny) {
  const { options, inputId, required } = props;
  const { prop, value, onChange } = useFormFieldContext();
  const { getProps } = useCustomize();
  const { getCurrentValue, updateDynamicConfiguredProps } =
    usePipedreamFieldsContext();
  const styleProps = getProps("label", {}, props);
  const currentValue = getCurrentValue(prop.name);

  const handleOnChange = (newValue: string | string[]) => {
    onChange(newValue);
    updateDynamicConfiguredProps(prop.name, newValue);
  };

  useEffect(() => {
    if (!currentValue) {
      updateDynamicConfiguredProps(prop.name, getAgentDeterminedObj(prop));
    }
  }, []);

  function onTypeChange(type: FieldSelectorValue) {
    if (type === FieldSelectorValue.Agent) {
      onChange(undefined);
    }
  }

  return (
    <PipedreamFieldLabel onTypeChange={onTypeChange}>
      <FormControl
        className={cx("grid-area-control w-100-percent", styleProps.className)}
      >
        {prop.type === PDFieldType.StringArray ? (
          <InputListComponent
            valuesList={value}
            handleChange={handleOnChange}
          />
        ) : (
          <Select
            id={inputId}
            value={value}
            size="small"
            className="nowheel nodrag nopan bg-color-white"
            onChange={(e) => handleOnChange(e.target.value)}
            required={required}
          >
            {options?.map((option: { label: string; value: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </PipedreamFieldLabel>
  );
}
