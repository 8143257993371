import { useFormFieldContext, useCustomize } from "@pipedream/connect-react";
import { Typography } from "@mui/material";
import { CSSProperties } from "react";
import Markdown from "markdown-to-jsx";
import styles from "./styles/PipedreamDescription.module.css";

const DESCRIPTION_STYLES: CSSProperties = {
  gridArea: "description",
  marginTop: "4px",
  marginBottom: "8px"
};

const MARKDOWN_OPTIONS = {
  overrides: {
    a: {
      props: {
        target: "_blank",
        rel: "noopener noreferrer"
      }
    }
  }
};

export function PipedreamDescription(props: $TSAllowedAny) {
  const { prop } = useFormFieldContext();
  const { getProps } = useCustomize();

  return (
    <div {...getProps("description", DESCRIPTION_STYLES, props)}>
      <Typography
        variant="body1"
        color="text.secondary"
        className={`font-size-12px ${styles.description}`}
      >
        <Markdown options={MARKDOWN_OPTIONS}>{prop.description}</Markdown>
      </Typography>
    </div>
  );
}
