import React from "react";
import NoWrapCardHeader from "../../../utilities/components/cards/NoWrapCardHeader";
import ToolflowSkeletonWrapper from "../../../utilities/components/loading/ToolflowSkeleton";
import MarketplaceTabsSkeleton from "../../tools/components/searchToolsContainer/MarketplaceTabsSkeleton";

const ToolsPageLoading = () => {
  return (
    <>
      <NoWrapCardHeader
        title={
          <ToolflowSkeletonWrapper sx={{ maxWidth: "80%" }}>
            Welcome to the Marketplace!
          </ToolflowSkeletonWrapper>
        }
        subheader={
          <ToolflowSkeletonWrapper sx={{ maxWidth: "80%" }}>
            The place to find and discover new Toolflow tools
          </ToolflowSkeletonWrapper>
        }
      />
      <MarketplaceTabsSkeleton />
    </>
  );
};

export default ToolsPageLoading;
