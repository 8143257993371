import { type BlockSettingsBase } from "@toolflow/shared";

export default function useFieldsByTypeHelper({
  settings
}: {
  settings: BlockSettingsBase;
}) {
  function getFieldValue(
    fieldKey: string,
    defaultValue: $TSAllowedAny,
    optionOverride?: Record<string, string>
  ) {
    if (fieldKey) {
      const value = fieldKey.split(".").reduce((obj, key) => {
        return obj && typeof obj === "object" && key in obj
          ? obj[key]
          : undefined;
      }, settings as $TSAllowedAny);
      if (optionOverride && optionOverride[value]) {
        return optionOverride[value];
      }
      return value ?? defaultValue;
    }
    throw Error("Field must have a fieldKey");
  }

  return { getFieldValue };
}
