import React, { useEffect } from "react";
import { FormProvider, useForm, type UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { AnthropicModel, OpenAiTextModel } from "@toolflow/shared";
import * as z from "zod";
import tagSchema from "../../../../../utilities/validation/zod/ts";
import { visibilitySchema } from "../../../../../utilities/validation/zod/visibilitySchema";
import { ABOUT_CHARACTER_LENGTH } from "../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { AGENT_NAME_REQUIRED_ERROR } from "../constants/errors";
import * as FL from "../constants/fields";
import useDefaultAgentFormValues, {
  TAgentFormValues
} from "../hooks/useDefaultAgentFormValues";

const schema = z.object({
  [FL.AGENT_NAME_FIELD_LABEL]: z
    .string()
    .min(1, { message: AGENT_NAME_REQUIRED_ERROR }),
  [FL.AGENT_DESCRIPTION_FIELD_LABEL]: z
    .string()
    .min(0)
    .max(ABOUT_CHARACTER_LENGTH, {
      message: `Description must be less than ${ABOUT_CHARACTER_LENGTH} characters`
    }),
  [FL.AGENT_VISIBILITY_FIELD_LABEL]: visibilitySchema,
  [FL.AGENT_USER_INSTRUCTIONS_FIELD_LABEL]: z.any(),
  [FL.AGENT_TAG_FIELD_LABEL]: tagSchema,
  config: z.object({
    [FL.AGENT_INSTRUCTIONS_FIELD_LABEL]: z.any(),
    [FL.AGENT_MODEL_FIELD_LABEL]: z.union([
      z.nativeEnum(OpenAiTextModel),
      z.nativeEnum(AnthropicModel) // Replace YourSecondEnum with your other enum
    ]),
    [FL.AGENT_TEMPERATURE_FIELD_LABEL]: z.number(),
    [FL.AGENT_TOOLS_PROPS_LABEL]: z.record(
      z.string(),
      z.object({ userInput: z.record(z.string(), z.any()) })
    ),
    [FL.AGENT_FILES_FIELD_LABEL]: z.array(z.any())
  })
});

const useResetDefaultValues = (
  defaultValues: TAgentFormValues,
  methods: UseFormReturn<TAgentFormValues, $TSAllowedAny, undefined>
) => {
  useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([name, value]) => {
        methods.register(name as keyof TAgentFormValues);
        methods.setValue(name as keyof TAgentFormValues, value, {
          shouldDirty: false,
          shouldTouch: false,
          shouldValidate: false
        });
      });
    }
  }, [JSON.stringify(defaultValues)]);
};

const AgentFormProvider = ({ children }: { children: React.ReactNode }) => {
  const defaultValues = useDefaultAgentFormValues();

  const methods = useForm<TAgentFormValues>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange"
  });

  useResetDefaultValues(defaultValues, methods);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default AgentFormProvider;
