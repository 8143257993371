import { useMemo } from "react";
import {
  ExtensionLocation,
  getPromptFillerBase,
  type TExtensionsConfig
} from "@toolflow/shared";
import { generateText, type JSONContent } from "@tiptap/core";
import { useSelector } from "react-redux";
import { getExtensionsFromConfig } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/getExtensionsFromConfig";
import { customExtensions } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/customExtensions";
import { selectAllSnippets } from "../../../../../../../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";

const usePromptFiller = (extensionsConfig: TExtensionsConfig) => {
  const extensions = getExtensionsFromConfig(
    extensionsConfig,
    customExtensions(ExtensionLocation.Toolbuilder)
  );
  const snippets = useSelector(selectAllSnippets);

  const insertUserInputIntoPrompt = useMemo(
    () =>
      getPromptFillerBase(
        (content: JSONContent) => generateText(content, extensions),
        snippets
      ),
    [extensions, snippets]
  );

  return insertUserInputIntoPrompt;
};

export default usePromptFiller;
