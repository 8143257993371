import { ThemeProvider } from "@emotion/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { useSetSecurityFunctions } from "../../ToolflowAPI/security";
import { WebsocketContextComponent } from "../../ToolflowAPI/websocket/contexts/WebsocketContext";
import AuthStoreWrapper from "../../features/auth/AuthStoreWrapper";
import { PipedreamProvider } from "../../features/integrations/context/PipedreamContext";
import router from "../../features/navigation/router";
import appTheme from "../../globalTheme/muiUtils/appTheme";
import InitializedPostHogProvider from "../../infrastructure/posthog/InitializedPostHogProvider";
import store from "../../stores/store";

export const Providers = () => {
  useSetSecurityFunctions();

  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <AuthStoreWrapper>
          <InitializedPostHogProvider>
            <WebsocketContextComponent>
              <PipedreamProvider>
                <ThemeProvider theme={appTheme}>
                  <RouterProvider router={router} />
                </ThemeProvider>
              </PipedreamProvider>
            </WebsocketContextComponent>
          </InitializedPostHogProvider>
        </AuthStoreWrapper>
      </Provider>
    </DndProvider>
  );
};
