import { generateText, type JSONContent } from "@tiptap/core";
import { getPromptFillerBase } from "../helpers";
import { getBaseExtensionsBackend, TContent } from "../tiptap";
import { customExtensions } from "../tiptap/customExtensions/customExtensionsBackend";
import { LeanSnippetDocument, Snippet, UserInputDictType } from "../../types";

export const jsonPromptFromUserInput = (
  p: TContent,
  userInput: UserInputDictType,
  snippets: Snippet[] | LeanSnippetDocument[]
) => {
  const extensions = [...getBaseExtensionsBackend(), ...customExtensions];
  return getPromptFillerBase(
    (content: JSONContent) => generateText(content, extensions),
    snippets
  )(p, userInput);
};
