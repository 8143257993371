import { generateText } from "@tiptap/core";
import {
  BlockType,
  ChipNodeComponents,
  ExtensionLocation,
  UtilBlockType,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  type AllBlockTypes,
  type ConstantBlockData,
  type DecimalString,
  type DeepgramBlockDataV1,
  type IValidateProps,
  type LogicBlockData,
  type LogicItem,
  type ScraperBlockDataV1,
  type ScraperBlockDataV2,
  type StructuredBlockDataV1,
  type StructuredBlockDataV2,
  type ToolWithinToolBlockData,
  type ValidatorFunction
} from "@toolflow/shared";
import { isEmpty, isString } from "lodash";
import { customExtensions } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/customExtensions";
import { getExtensionsFromConfig } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/getExtensionsFromConfig";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/promptInput";
import { convertToBlockMessageType } from "../../helpers/convertToBlockMessageType";

export const blockValidators: Record<
  string,
  Record<DecimalString, ValidatorFunction<$TSFixMe>>
> = {
  [BlockType.Deepgram]: {
    [VERSION_1_VALUE]: ({ data }: IValidateProps<DeepgramBlockDataV1>) => {
      if (data.settings.file === "") {
        return convertToBlockMessageType(
          `No mapped file to transcribe.`,
          "error"
        );
      }
    }
  },
  [BlockType.Scraper]: {
    [VERSION_1_VALUE]: ({ data }: IValidateProps<ScraperBlockDataV1>) => {
      if (data.settings.urlFieldInputKey === "") {
        return convertToBlockMessageType(`No mapped url to scrape`, "error");
      }
    },
    [VERSION_2_VALUE]: ({ data }: IValidateProps<ScraperBlockDataV2>) => {
      if (data.settings.url === "") {
        return convertToBlockMessageType(`No mapped url to scrape`, "error");
      }
    }
  },
  [BlockType.Structured]: {
    [VERSION_1_VALUE]: ({ data }: IValidateProps<StructuredBlockDataV1>) => {
      if (data.settings.input === "") {
        return convertToBlockMessageType(
          `No source text added. Please enter source text for data extractor`,
          "error"
        );
      }
    },
    [VERSION_2_VALUE]: ({ data }: IValidateProps<StructuredBlockDataV2>) => {
      let stringifiedValue = "";
      const inputString = JSON.stringify(data.settings.input);
      const hasCustomNodes = Object.values(ChipNodeComponents).some(
        (component) => inputString.includes(component)
      );

      if (isString(data.settings.input)) {
        stringifiedValue = data.settings.input;
      } else if (!isEmpty(data.settings.input)) {
        const extensions = getExtensionsFromConfig(
          textInputContainerExtensionsConfig,
          customExtensions(ExtensionLocation.Toolbuilder)
        );
        stringifiedValue = generateText(data.settings.input, extensions);
      }

      if (stringifiedValue.trim() === "" && !hasCustomNodes) {
        return convertToBlockMessageType(
          `No source text added. Please enter source text for data extractor`,
          "error"
        );
      }
    }
  },
  [UtilBlockType.ToolWithinTool]: {
    [VERSION_1_VALUE]: ({ data }: IValidateProps<ToolWithinToolBlockData>) => {
      if (
        data.inputMap &&
        Object.values(data.inputMap).some((value) => value === "")
      ) {
        return convertToBlockMessageType(
          `Not all inputs are mapped.`,
          "warning"
        );
      }
    }
  },
  [UtilBlockType.Constant]: {
    [VERSION_1_VALUE]: ({ data }: IValidateProps<ConstantBlockData>) => {
      if (!data.constant) {
        return convertToBlockMessageType(
          `A constant must have a value.`,
          "error"
        );
      }
    }
  },
  [UtilBlockType.Logic]: {
    [VERSION_1_VALUE]: ({
      data,
      edges,
      id
    }: IValidateProps<LogicBlockData>) => {
      if (
        data?.logicArray.filter((item: LogicItem) => item.input !== "")
          .length === 0
      ) {
        return convertToBlockMessageType(
          `Logic datas can't be empty. Add a filter.`,
          "error"
        );
      }
      if (!edges.some((e) => e.source === id)) {
        return convertToBlockMessageType(
          `Logic datas must connect their output to another data. Delete this data if it is unused.`,
          "error"
        );
      }
      if (
        data?.logicArray.some((logic: LogicItem) => logic.parameterValue === "")
      ) {
        return convertToBlockMessageType(
          `Logic filters need to be fully filled out.`,
          "error"
        );
      }
    }
  }
};

/**
 *
 * @param type
 * @param version @default 1.0
 * @returns ValidatorFunction
 */
export function getBlockValidatorByVersion(
  type: AllBlockTypes,
  version: DecimalString = VERSION_1_VALUE
) {
  if (blockValidators.hasOwnProperty(type)) {
    return (
      blockValidators[type][version] || blockValidators[type][VERSION_1_VALUE]
    );
  }
}
