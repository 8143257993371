import {
  AssetNodeType,
  AssetSourceType,
  AssetValueType,
  AutocompleteGroups,
  type Asset,
  type ToolAutocomplete
} from "@toolflow/shared";
import { VARIABLE_NAME } from "../../../../features/pages/workstation/helpers/workspaceConstants";
import { isArray, isString } from "lodash";

function formatAssetsForAutocomplete(
  assets: Asset[],
  assetType: AssetNodeType,
  id?: string
): ToolAutocomplete[] {
  return assets.reduce((acc, asset) => {
    const isChipTypeAsset = assetType === AssetNodeType.Chip;
    const isPlaceholderTypeSource =
      asset.source === AssetSourceType.ToolOutputPlaceholder;
    const isDifferentRef = asset.id !== id; // we don't want inputs to be able to reference themselves

    if (isDifferentRef && (isChipTypeAsset || !isPlaceholderTypeSource)) {
      if (asset.valueType === AssetValueType.String) {
        acc.push({
          primary: asset.name || VARIABLE_NAME,
          id: asset.id || "",
          secondary: (asset.value as string) || "",
          group: AutocompleteGroups.Asset
        });
      } else if (asset.valueType === AssetValueType.Structured) {
        const value = asset.value as Record<$TSAllowedAny, $TSAllowedAny>;

        if (isArray(value)) {
          value.forEach((val, idx) => {
            if (isString(val)) {
              acc.push({
                primary: `${asset.name}/result-${idx + 1}` || VARIABLE_NAME,
                id: asset.id || "",
                secondary: val || "",
                group: AutocompleteGroups.Asset
              });
            }
          });
        }
      }
    }

    return acc;
  }, [] as ToolAutocomplete[]);
}

export default formatAssetsForAutocomplete;
