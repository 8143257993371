import {
  DYNAMIC_FIELD_PREFIX,
  DynamicFieldType,
  evaluateDynamicSetting,
  FieldType,
  getFieldValues,
  isContent,
  type BlockDataBase,
  type DynamicFieldConfigBase,
  type FieldConfigBase,
  type FieldsConfig,
  type FieldTypeConfigs
} from "@toolflow/shared";
import {
  get as getValueFromObject,
  isArray,
  isPlainObject,
  isString
} from "lodash";
import { useMemo } from "react";
import useGetChips from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";
import TestFieldSet from "./helpers/testFieldSet";

export default function useAvailableTestFields(
  id: string,
  fields: FieldsConfig,
  data: BlockDataBase
) {
  const getChipsList = useGetChips();
  const availableFields = useToolInputFieldsAndAvailableFields(id);
  const fieldValuesAsDeps = getFieldValues(fields, data);

  return useMemo(() => {
    const testFields = new TestFieldSet(availableFields);

    function setTestField(value: $TSAllowedAny, field: FieldTypeConfigs) {
      if (isString(value)) {
        testFields.add({ field, fieldLabel: value, fieldValue: value });
      } else if (isContent(value)) {
        getChipsList(value).forEach((chip) => {
          testFields.add({ field, fieldLabel: chip, fieldValue: value });
        });
      } else if (isArray(value)) {
        value.forEach((v) => setTestField(v, field));
      } else if (isPlainObject(value)) {
        Object.values(value).forEach((v) => setTestField(v, field));
      }
    }

    function setFields(field: FieldTypeConfigs) {
      if (field.type.startsWith(DYNAMIC_FIELD_PREFIX)) {
        const fieldConfig = field.config as DynamicFieldConfigBase;
        const fieldType = getValueFromObject(data, fieldConfig.typeKey);
        if (fieldType === DynamicFieldType.Dynamic) {
          const fieldValue = getValueFromObject(data, fieldConfig.fieldKey);
          setTestField(fieldValue, field);
        }
      } else {
        const fieldConfig = field.config as FieldConfigBase;
        const fieldKey = evaluateDynamicSetting(fieldConfig.fieldKey, data);
        const fieldValue = getValueFromObject(data, fieldKey);
        setTestField(fieldValue, field);
      }
    }

    function setInputFieldsFromFieldsConfigs(blockFields: FieldsConfig) {
      blockFields.forEach((field) => {
        if (field.type === FieldType.Group) {
          const fc = evaluateDynamicSetting(field.fields, data, []);
          setInputFieldsFromFieldsConfigs(fc);
        } else {
          setFields(field);
        }
      });
    }

    setInputFieldsFromFieldsConfigs(fields);

    return testFields.toArray();
  }, fieldValuesAsDeps);
}
