import { HydratedDocument } from "mongoose";
import { TFreePlan } from "../api";
import { Auth0User, Auth0UserMetadata } from "../auth0Types";
import { OrganizationSchemaId, UserSchemaId } from "../schemaTypes";
import { TActiveTier, TBilling } from "./BillingEmbeddedDocument";
import { Organization, OrganizationDocument } from "./OrganizationDocument";
import { LeanDocument } from "./utilities";

export const DEFAULT_USER_NAME = "Anonymous";

export type SanitizedUserType = {
  _id: string;
  picture?: string;
  name?: string;
};

export const companySizes = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001+"
] as const;

export type CompanySize = (typeof companySizes)[number];

export type CompanyType = {
  name?: string;
  employee_count?: CompanySize;
};

// don't forget to update in user model
export const employeeResponsibilities = [
  "Individual Contributor",
  "Manager",
  "VP",
  "Exec/C-Suite"
] as const;

export type EmployeeResponsibility = (typeof employeeResponsibilities)[number];

// don't forget to update in user model
export const employeeFunctions = [
  "Sales",
  "Marketing",
  "Customer Success/Account Management",
  "Operations",
  "HR",
  "Finance",
  "Leadership / Exec",
  "Engineering",
  "Product Management"
];

export type EmployeeFunction = (typeof employeeFunctions)[number];

export type UpdateUserBody = Partial<
  Pick<
    TUser,
    | "firstName"
    | "lastName"
    | "email"
    | "emailVerified"
    | "company"
    | "employeeFunction"
    | "employeeResponsibility"
    | "requiresMeeting"
    | "allowFreeTier"
    | "auth0Metadata"
    | "phoneNumber"
  >
>;

export type CreateUserParams = Pick<
  Auth0User,
  "email" | "phoneNumber" | "picture"
> & {
  auth0UserId: Auth0User["user_id"];
  firstName: Auth0User["given_name"];
  lastName: Auth0User["family_name"];
  emailVerified: Auth0User["email_verified"];
  requiresMeeting: boolean;
  allowFreeTier: boolean;
};

export type UpdateUserParams = Partial<
  UpdateUserBody & { organizationId: string }
>;

export type FilterUserParams = Partial<
  TUser & { "billing.hyperlineCustomer.id"?: string }
>;

export type TEmployee = "employee";
export type TContractor = "contractor";
export type EmployeeType = TEmployee | TContractor;
export type TAvailableTiers = TActiveTier | TFreePlan;
export const availableTiersArray: TAvailableTiers[] = [
  "free",
  "pro",
  "organization"
];

export type TUser = SanitizedUserType & {
  auth0UserId: string;
  billing?: TBilling;
  organizationId?: string;
  email: string;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  createdAt: number;
  updatedAt: number;
  toolflowEmployeeType?: EmployeeType;
  company?: CompanyType; // eventually we will want to have companies as objects with properties like this, not necessary now though
  employeeResponsibility?: EmployeeResponsibility;
  employeeFunction?: EmployeeFunction;
  requiresMeeting?: boolean;
  allowFreeTier?: boolean;
  impersonatingId?: string;
  auth0Metadata?: Auth0UserMetadata;
};

type PropsToChange = {
  _id: UserSchemaId | string;
  organizationId?: OrganizationSchemaId | string;
  organization?: OrganizationDocument;
};

export type PopulatedUser = TUser & { organization?: Organization };

export type UserDocument = HydratedDocument<
  TUser,
  Omit<PropsToChange, "organization"> // unpopulated user shouldn't have organization prop
>;

export type PopulatedUserDocument = HydratedDocument<
  PopulatedUser,
  PropsToChange
>;

export type LeanPopulatedUserDocument = LeanDocument<PopulatedUserDocument>;
export type LeanUserDocument = LeanDocument<UserDocument>;
