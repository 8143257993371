import type {
  CustomToolInputField,
  TSetFormState,
  UserInputDictType
} from "@toolflow/shared";
import FieldWrapper from "./wrapper/FieldWrapper";
import { Switch } from "../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/outputs/outputFields/Switch";

export const InputCheckbox = (props: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  allowAgentDeterminedValues?: boolean;
  useIds?: boolean;
}) => {
  const { toolInputField, setFormState, formState, disabled, useIds } = props;
  const key = useIds ? toolInputField.id : toolInputField.name;

  const handleCheckboxChange = (checked: boolean) => {
    const params = { ...formState, [key]: checked };
    setFormState(params);
  };

  return (
    <FieldWrapper {...props}>
      <Switch
        checked={!!formState[key]}
        onChange={handleCheckboxChange}
        disabled={disabled}
        formControlLabelClassName="grey"
        label=""
      />
    </FieldWrapper>
  );
};
