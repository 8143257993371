import { FormControl } from "@mui/material";
import {
  BlockType,
  getInitialState,
  type CustomToolInputField,
  type JSONSchemaItem,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import JsonSchemaCreationField from "../../inputs/jsonSchemaCreationField/JsonSchemaCreationField";
import FieldWrapper from "./wrapper/FieldWrapper";

export function JsonSchemaField(props: {
  disableLabelAndInfo?: boolean;
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  autofocus?: boolean;
  formState: UserInputDictType;
  disabled?: boolean;
  useIds?: boolean;
}) {
  const { toolInputField, setFormState, formState, disabled, useIds } = props;
  const key = useIds ? toolInputField.id : toolInputField.name;
  const initialState = getInitialState(BlockType.Structured);
  const fieldValue =
    (formState[key] as JSONSchemaItem[]) || initialState.settings.schema;
  function onChange(
    newValue: $TSFixMe[] // Adding JsonSchemaItem type to UserInputDictType causes type to become too deep. Keeping the type as any for now.
  ) {
    setFormState({ ...formState, [key]: newValue });
  }

  return (
    <FormControl
      fullWidth
      disabled={disabled}
      className="w-100-percent"
      size="small"
    >
      <FieldWrapper {...props}>
        <JsonSchemaCreationField schema={fieldValue} onChange={onChange} />
      </FieldWrapper>
    </FormControl>
  );
}
