import {
  VERSION_1_VALUE,
  FieldType,
  VERSION_2_VALUE,
  type BlockFieldsConfig,
  CustomToolInputFieldTypesEnum
} from "../../../../../types";
import { googleSerpCountries, googleSerpLanguages } from "../../constants";
import { filterAvailableFieldsByFile } from "../../helpers";

const allowedOutputs = [
  { value: "peopleAlsoAsk", label: "People also ask" },
  { value: "relatedQueries", label: "Related queries" },
  { value: "organicResults", label: "Organic results" },
  { value: "paidResults", label: "Paid results" }
];

const resultOptions = [
  { value: "title", label: "Title" },
  { value: "url", label: "URL" },
  { value: "displayedUrl", label: "Display url" },
  { value: "description", label: "Description" },
  { value: "emphasizedKeywords", label: "Emphasize keywords" },
  { value: "siteLinks", label: "Site links" }
];

export const serpBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.SimpleInputSelect,
      label: "Select query input",
      config: {
        fieldKey: "settings.userQueriesFieldKey"
      }
    },
    {
      type: FieldType.SimpleListCreationField,
      label: "Keywords",
      config: {
        fieldKey: "settings.queries"
      }
    },
    {
      type: FieldType.Group,
      label: "Allowed outputs",
      fields: allowedOutputs.map((option) => ({
        type: FieldType.SimpleCheckBoxField,
        label: option.label,
        config: {
          fieldKey: `settings.allowedOutputs.${option.value}`
        }
      }))
    },
    {
      type: FieldType.Group,
      label: "Result options",
      fields: resultOptions.map((option) => ({
        type: FieldType.SimpleCheckBoxField,
        label: option.label,
        config: {
          fieldKey: `settings.allowedOutputs.resultOptions.${option.value}`
        }
      }))
    },
    {
      type: FieldType.Group,
      label: "Localization options",
      fields: [
        {
          type: FieldType.SelectFieldV1,
          label: "Select country",
          config: {
            fieldKey: "settings.countryCode",
            options: googleSerpCountries
          }
        },
        {
          type: FieldType.SelectFieldV1,
          label: "Select language",
          config: {
            fieldKey: "settings.languageCode",
            options: googleSerpLanguages
          }
        }
      ]
    },
    {
      type: FieldType.Group,
      label: "Page options",
      fields: [
        {
          type: FieldType.SimpleInput,
          label: "Results per page",
          config: {
            fieldKey: "settings.resultsPerPage",
            inputConfig: {
              type: "number",
              min: 1
            }
          }
        },
        {
          type: FieldType.SimpleInput,
          label: "Max pages per query",
          config: {
            fieldKey: "settings.maxPagesPerQuery",
            inputConfig: {
              type: "number",
              min: 1
            }
          }
        }
      ]
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.DynamicListField,
      label: "Search query(s)",
      subtitle:
        "Enter one or more search queries, separated by commas. Each query will be searched independently on Google to retrieve SERP results.",
      config: {
        typeKey: "settings.queries.type",
        fieldKey: "settings.queries.value"
      }
    },
    {
      type: FieldType.Group,
      label: "Output result options",
      subtitle: "Select which results to output from google search.",
      fields: [
        {
          type: FieldType.DynamicSwitchField,
          label: "Organic Results",
          subtitle: "Include organic results from google search.",
          config: {
            typeKey: "settings.allowedOutputs.organicResults.type",
            fieldKey: "settings.allowedOutputs.organicResults.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Paid Results",
          subtitle: "Include paid results from google search.",
          config: {
            typeKey: "settings.allowedOutputs.paidResults.type",
            fieldKey: "settings.allowedOutputs.paidResults.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "People also ask",
          subtitle:
            'Include results from "people also ask" section of google search.',
          config: {
            typeKey: "settings.allowedOutputs.peopleAlsoAsk.type",
            fieldKey: "settings.allowedOutputs.peopleAlsoAsk.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Related queries",
          subtitle: "Include related queries from google search.",
          config: {
            typeKey: "settings.allowedOutputs.relatedQueries.type",
            fieldKey: "settings.allowedOutputs.relatedQueries.value"
          }
        }
      ]
    },
    {
      type: FieldType.Group,
      label: "Additional options (Paid and organic)",
      subtitle: "Select additional options for result output.",
      fields: [
        {
          type: FieldType.DynamicSwitchField,
          label: "Title",
          subtitle: "Include Title of results.",
          config: {
            typeKey: "settings.allowedOutputs.resultOptions.title.type",
            fieldKey: "settings.allowedOutputs.resultOptions.title.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "URL",
          subtitle: "Include URL for results.",
          config: {
            typeKey: "settings.allowedOutputs.resultOptions.url.type",
            fieldKey: "settings.allowedOutputs.resultOptions.url.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Description",
          subtitle: "Include description for results.",
          config: {
            typeKey: "settings.allowedOutputs.resultOptions.description.type",
            fieldKey: "settings.allowedOutputs.resultOptions.description.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Displayed URL",
          subtitle: "Include displayed URL for results.",
          config: {
            typeKey: "settings.allowedOutputs.resultOptions.displayedUrl.type",
            fieldKey: "settings.allowedOutputs.resultOptions.displayedUrl.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Emphasized keywords",
          subtitle: "Include emphasized keywords for results.",
          config: {
            typeKey:
              "settings.allowedOutputs.resultOptions.emphasizedKeywords.type",
            fieldKey:
              "settings.allowedOutputs.resultOptions.emphasizedKeywords.value"
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Site links",
          subtitle: "Include site links for results.",
          config: {
            typeKey: "settings.allowedOutputs.resultOptions.siteLinks.type",
            fieldKey: "settings.allowedOutputs.resultOptions.siteLinks.value"
          }
        }
      ]
    },
    {
      type: FieldType.Group,
      label: "Localization options",
      subtitle: "Select localization options for result output.",
      fields: [
        {
          type: FieldType.DynamicSelectField,
          label: "Select Country",
          config: {
            typeKey: "settings.countryCode.type",
            fieldKey: "settings.countryCode.value",
            filterAvailableFields: filterAvailableFieldsByFile,
            allowedFields: [CustomToolInputFieldTypesEnum.SELECT],
            options: googleSerpCountries
          }
        },
        {
          type: FieldType.DynamicSelectField,
          label: "Select Language",
          config: {
            filterAvailableFields: filterAvailableFieldsByFile,
            allowedFields: [CustomToolInputFieldTypesEnum.SELECT],
            typeKey: "settings.languageCode.type",
            fieldKey: "settings.languageCode.value",
            options: googleSerpLanguages
          }
        }
      ]
    },
    {
      type: FieldType.Group,
      label: "Page options",
      subtitle: "Select page options for result output.",
      fields: [
        {
          type: FieldType.DynamicInputField,
          label: "Results per page",
          config: {
            typeKey: "settings.resultsPerPage.type",
            fieldKey: "settings.resultsPerPage.value",
            htmlInputType: "number"
          }
        },
        {
          type: FieldType.DynamicInputField,
          label: "Max pages per query",
          config: {
            typeKey: "settings.maxPagesPerQuery.type",
            fieldKey: "settings.maxPagesPerQuery.value",
            htmlInputType: "number"
          }
        }
      ]
    }
  ],
  common: []
};
