import { AgentInfo } from "../../../../agents/AgentInfo";

export const GetStartedChat = () => {
  return (
    <>
      <div
        className="flex justify-center w-100-percent align-i-center"
        style={{ height: "calc(100% - 36px)" }}
      >
        <AgentInfo />
      </div>
    </>
  );
};
