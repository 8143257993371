// workspaceSlice.ts
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../stores/store";
import { EntityType, type TAgentEntity } from "@toolflow/shared";

type TWorkspaceInitialObject = {
  type: EntityType | "toolVersionResponse" | TAgentEntity;
  id: string;
};
interface IWorkspaceInitialState {
  open: boolean;
  resetKey: number;
  assetExpanded: boolean;
  assetListBig: boolean;
  disableTabChange: boolean;
  workspaceId: string;
  initialObject?: TWorkspaceInitialObject;
  refreshInputs: boolean;
  allowReset: boolean;
  hasSetInitialObject: boolean;
}

const initialState: IWorkspaceInitialState = {
  workspaceId: "",
  assetExpanded: false,
  assetListBig: false,
  open: false,
  resetKey: 0, // this is to rerender workspaces. Hacky, might be a better solution,
  disableTabChange: false,
  refreshInputs: false,
  allowReset: false,
  hasSetInitialObject: false // this overrides authentication flag so that users can view objects when logged out
};

export type TWorkspaceReducerName = "workspace";
export const WORKSPACE_REDUCER_NAME: TWorkspaceReducerName = "workspace";

const workspaceSlice = createSlice({
  name: WORKSPACE_REDUCER_NAME,
  initialState,
  reducers: {
    resetWorkspaceState(state) {
      return { ...initialState, resetKey: state.resetKey + 1 };
    },
    setAssetExpanded: (state, action: PayloadAction<boolean>) => {
      state.assetExpanded = action.payload;
    },
    openWorkspaceSaveDialog(state) {
      state.open = true;
    },
    closeWorkspaceSaveDialog(state) {
      state.open = false;
    },
    setAssetListBig(state, action: PayloadAction<boolean>) {
      state.assetListBig = action.payload;
    },
    setInitialObject(state, action: PayloadAction<TWorkspaceInitialObject>) {
      state.initialObject = action.payload;
      state.hasSetInitialObject = true;
    },
    setHasSetInitialObject(state, action: PayloadAction<boolean>) {
      state.hasSetInitialObject = action.payload;
    },
    setAllowReset(state, action: PayloadAction<boolean>) {
      state.allowReset = action.payload;
    },
    setWorkspaceId(state, action: PayloadAction<string>) {
      state.workspaceId = action.payload;
    },
    clearInitialObject(state) {
      state.initialObject = undefined;
    },
    setResetKey(state, action: PayloadAction<number>) {
      state.resetKey = action.payload;
    },
    toggleRefreshInputs(state) {
      state.refreshInputs = !state.refreshInputs;
    },
    setDisableTabChange(state, action: PayloadAction<boolean>) {
      state.disableTabChange = action.payload;
    }
  }
});

export const selectWorkspaceSaveDialogOpen = (state: RootState) =>
  state[WORKSPACE_REDUCER_NAME].open;

export const {
  setAllowReset,
  toggleRefreshInputs,
  setInitialObject,
  clearInitialObject,
  openWorkspaceSaveDialog,
  closeWorkspaceSaveDialog,
  setAssetExpanded,
  setResetKey,
  setAssetListBig,
  setDisableTabChange,
  setWorkspaceId,
  resetWorkspaceState,
  setHasSetInitialObject
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
