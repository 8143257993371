import { Node } from "@tiptap/pm/model";
import { ChipNodeComponents } from "../ChipNodeComponents";
import { ChipNodeHtmlTags } from "../ChipNodeHtmlTags";
import { ExtensionContent, ExtensionGroup } from "./configTypes";
import { $TSAllowedAny } from "../../../../types";
import { mergeAttributes } from "@tiptap/core";

export const FlowAssetExtensionConfig = {
  config: {
    name: ChipNodeComponents.FlowAssetComponent,

    // group: "block",
    group: ExtensionGroup.Block,
    content: ExtensionContent.Block,
    inline: false,
    draggable: true,
    selectable: true, // needed so that we don't delete it when typing things if you click the tool

    atom: true
  },
  attributes: {
    rawHTML: {
      default: ""
    },
    identifier: {
      default: ""
    },
    sourceIdentifier: {
      default: ""
    },
    title: {
      default: ""
    },
    type: {
      default: ""
    },
    version: {
      default: 1
    },
    toolId: {
      default: ""
    }
  },

  // we need to render the html so that we can get the rawHTML
  // otherwise, its hard to get the rawHTML to pass to the asset
  renderHTML({
    HTMLAttributes,
    node
  }: {
    HTMLAttributes: Record<string, $TSAllowedAny>;
    node: Node;
  }) {
    return [
      ChipNodeHtmlTags.FlowAssetComponent,
      mergeAttributes(HTMLAttributes, {
        "data-type": "draggable-item",
        "data-raw-html": node.attrs.rawHTML
      }),
      0
    ];
  },

  // we need to render the html so that we can get the rawHTML
  // otherwise, its hard to get the rawHTML to pass to the asset
  parseHTML() {
    return [
      {
        tag: ChipNodeHtmlTags.FlowAssetComponent,
        getAttrs: (element: HTMLElement) => {
          // Capture the exact innerHTML of the element
          const rawHTML = element.innerHTML;
          return { rawHTML };
        }
      }
    ];
  },

  htmlTag: ChipNodeHtmlTags.FlowAssetComponent
};
