import { JSONValue } from "../types";

export enum FieldSelectorValue {
  Preset = "preset",
  Agent = "agent"
}

export type AgentDeterminedFieldValue = {
  key: string;
  type: FieldSelectorValue.Agent;
  guideline: string | null;
};

export type AgentFunctionArguments = JSONValue;
