import { AGENT } from "@toolflow/shared";
import useNavigateToWorkspaceWithDefaultObject from "./useNavigateToWorkspaceWithDefaultObject";

const useNavigateToAgentById = () => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject();
  const handleNavigate =
    (agentId = "") =>
    (e: React.MouseEvent) => {
      if (!agentId) return;
      navigateToWorkspace({
        type: AGENT,
        id: agentId
      })(e);
    };
  return handleNavigate;
};

export default useNavigateToAgentById;
