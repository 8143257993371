import { Card, CardActions, CardContent, Skeleton, Stack } from "@mui/material";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import NoWrapCardHeader from "../../../../../../utilities/components/cards/NoWrapCardHeader";
import styles from "./GridCard.module.css";

const cx = parsedClassNames.bind(styles);

export default function IntegrationEntityCardSkeleton() {
  return (
    <Card className={cx("integration-card", "pos-relative parent-hover")}>
      <NoWrapCardHeader
        className={cx("integration-card-header")}
        avatar={<Skeleton variant="circular" width={24} height={24} />}
        title={<Skeleton variant="text" width={120} height={32} />}
      />
      <CardContent className="p-b-0px p-t-8px pos-relative">
        <Stack>
          <Skeleton variant="text" width="90%" height={20} />
          <Skeleton variant="text" width="60%" height={20} />
        </Stack>
      </CardContent>
      <CardActions className="p-8px">
        <Skeleton
          variant="rounded"
          width={90}
          height={32}
          className="m-l-auto"
        />
      </CardActions>
    </Card>
  );
}
