import type {
  TEntityFilterByCategoriesFE,
  TMarketplaceTypes
} from "@toolflow/shared";
import { TEntityTab, marketplaceCategoryTabs } from "../constants";

export const getCategoryIndex = (
  tabs: TEntityTab[],
  category: TEntityFilterByCategoriesFE
) =>
  // If the category is not found, return 0
  // this can happen when the category is disabled before useSwitchCategory executes
  Math.max(
    0,
    tabs.findIndex((tab) => tab.value === category)
  );
const getCategoryTab = (category: TEntityFilterByCategoriesFE) => {
  return marketplaceCategoryTabs.filter((tab) => tab.value === category)[0];
};
export function isSelectedTabDisabled(
  reduxCategory: TEntityFilterByCategoriesFE,
  selectedTab: string,
  entityType: TMarketplaceTypes
) {
  const selectedCategoryTab = getCategoryTab(reduxCategory);
  const isTabDisabled =
    selectedCategoryTab.disabledTabs?.includes(selectedTab) ||
    selectedCategoryTab.disabledType?.includes(entityType);
  return isTabDisabled;
}
