import { Card, Grid } from "@mui/material";
import {
  type SelectToolForToolBuilderFieldType,
  type ToolWithinToolBlockData
} from "@toolflow/shared";
import { CloseIcon } from "../../../../../../../../globalTheme/icons/icons";
import { useLazyGetToolQuery } from "../../../../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import RemixButton from "../../../../../../../../utilities/components/icons/RemixButton";
import ToolInfo from "../../../../../../../tools/components/ToolInfo";
import ToolOutputsCard from "../../../../../../../tools/components/ToolOutputsCard";
import { ToolContextComponent } from "../../../../../../../tools/contexts/ToolContext";
import ToolVersionCard from "./components/ToolVersionCard";
import NavigateToToolInWorkspaceButton from "../../../../../NavigateToToolInWorkspaceButton";
import getInitialInputMap from "./helpers/getInitialInputMap";
import OutputMapper from "../../../../OutputMapper";
import useShouldWrap from "../../../../useShouldWrap";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import SearchToolsButton from "./components/SearchToolsButton";

export default function SelectToolForToolBuilder() {
  const { id, data, field, updateField } = useFieldsByTypeContext<
    SelectToolForToolBuilderFieldType,
    ToolWithinToolBlockData
  >();
  const {
    config: { fieldKey, inputMapKey }
  } = field;
  const { tool, inputMap } = data;
  const [shouldWrap, wrapperRef] = useShouldWrap();

  const [getLazyTool, { isLoading }] = useLazyGetToolQuery();

  const resetTool = () => {
    updateField(null, fieldKey);
    updateField(null, inputMapKey);
  };

  const updateTool = async (clickedId: string) => {
    if (!isLoading) {
      await getLazyTool(clickedId).then(({ data: toolResponseData }) => {
        if (toolResponseData?.tool) {
          const { tool: toolResponse } = toolResponseData;
          const initialInputMap = getInitialInputMap(toolResponse);
          updateField(toolResponse, fieldKey);
          updateField(initialInputMap, inputMapKey);
        }
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!tool) {
    return <SearchToolsButton updateTool={updateTool} />;
  }

  return (
    <div className="flex flex-column m-16px">
      <ToolContextComponent tool={tool}>
        <Grid container spacing={2} className="m-v-16px" ref={wrapperRef}>
          <Grid xs={12}>
            <Card variant="elevation">
              <ToolInfo
                action={
                  <>
                    <NavigateToToolInWorkspaceButton
                      toolId={tool._id}
                      size="small"
                      variant="outlined"
                    />
                    <RemixButton onClick={resetTool} icon={CloseIcon} />
                  </>
                }
              />
            </Card>
          </Grid>
          <Grid xs={shouldWrap ? 6 : 12}>
            <OutputMapper data={data} id={id} />
          </Grid>
          <Grid xs={shouldWrap ? 6 : 12}>
            <ToolOutputsCard toolOutputs={tool.main.toolOutputFields} />
          </Grid>
          <Grid xs={12}>
            <ToolVersionCard tool={tool} blockId={id} inputMap={inputMap} />
          </Grid>
        </Grid>
      </ToolContextComponent>
    </div>
  );
}
