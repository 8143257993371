import { HydratedDocument } from "mongoose";
import { ToolUnion } from "../blocks";
import {
  BlockResponseSchemaId,
  ToolSchemaId,
  ToolVersionSchemaId,
  UserSchemaId
} from "../schemaTypes";
import {
  MultiUserInputResponseWithHandledError,
  UserInputDictType
} from "../types";
import {
  LeanBlockResponseDocument,
  TBlockResponse
} from "./BlockResponseDocument";
import { ExternalToolFE } from "./ToolDocument";
import { ToolVersionUnion, TToolVersion } from "./ToolVersionDocument";
import { LeanDocument } from "./utilities";

export enum TVRModel {
  Base = "ToolVersionResponse",
  DB = "ToolVersionResponseDB",
  Synthetic = "SyntheticToolVersionResponse"
}

export type TResponseDict = {
  [key: string]: MultiUserInputResponseWithHandledError;
};

export type ToolVersionResponseBase = {
  _id: BlockResponseSchemaId;
  toolId: ToolSchemaId | string;
  toolVersionId: ToolVersionSchemaId | string;
  userId: UserSchemaId;
  creditCost?: number;
  blockResponseIds: string[];
  validatedUserInput: UserInputDictType;
  responseDict: TResponseDict;
  isToolWithinTool?: boolean;
  createdAt: number;
  updatedAt: number;
};

interface SyntheticToolVersionResponse extends ToolVersionResponseBase {
  toolId: string;
  toolVersionId: string;
}

interface DbToolVersionResponse extends ToolVersionResponseBase {
  toolId: ToolSchemaId;
  toolVersionId: ToolVersionSchemaId;
}

type ToolVersionResponse = DbToolVersionResponse | SyntheticToolVersionResponse;

export type DbToolVersionResponseDocument =
  HydratedDocument<DbToolVersionResponse>;
export type SyntheticToolVersionResponseDocument =
  HydratedDocument<SyntheticToolVersionResponse>;
export type ToolVersionResponseDocument = HydratedDocument<ToolVersionResponse>;

export type LeanToolVersionResponseDocument = LeanDocument<
  ToolVersionResponse,
  Pick<ToolVersionResponse, "_id" | "userId" | "toolId" | "toolVersionId">
>;

export type LeanTVRPopulatedBase = {
  toolId: ToolUnion;
  toolVersionId: ToolVersionUnion;
  blockResponseIds: LeanBlockResponseDocument[];
};

export type LeanPopulatedToolVersionResponse = LeanDocument<
  ToolVersionResponse,
  LeanTVRPopulatedBase
>;

export type TToolVersionResponse = Omit<
  ToolVersionResponse,
  "_id" | "userId" | "toolId" | "toolVersionId" | "blockResponseIds"
> & {
  _id: string;
  userId: string;
  toolId: string;
  toolVersionId: string;
  blockResponseIds: string[];
};

export type UpdateToolVersionResponse = {
  blockResponseIds?: string[];
  responseDict?: UserInputDictType;
  creditCost?: number;
};

export type CreateToolVersionResponse = {
  blockResponseIds: string[];
  responseDict: UserInputDictType;
};

export type PopulatedToolVersionResponseFE = Omit<
  TToolVersionResponse,
  "toolId" | "toolVersionId" | "blockResponseIds"
> & {
  toolId: ExternalToolFE;
  toolVersionId: TToolVersion;
  blockResponseIds: TBlockResponse[];
};
