import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setToolsOutdated } from "../../../../../../agentBuilder/state/agentBuilderSlice";

// if any tool is outdated, set the toolsOutdated status to true
const useUpdateToolStatus = () => {
  const [toolsOutdatedStatus, setToolsOutdatedStatus] = useState<boolean[]>([]);
  const dispatch = useDispatch();

  const updateToolStatus = (idx: number, isOutdated: boolean) => {
    setToolsOutdatedStatus((prev) => {
      const newStatus = [...prev];
      newStatus[idx] = isOutdated;
      return newStatus;
    });
  };

  useEffect(() => {
    const hasAnyOutdatedTool = toolsOutdatedStatus.some((status) => status);
    dispatch(setToolsOutdated(hasAnyOutdatedTool));
  }, [toolsOutdatedStatus, dispatch]);

  return { updateToolStatus };
};

export default useUpdateToolStatus;
