import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { EditIcon } from "../../../../globalTheme/icons/icons";
import useNavigateToAgentBuilderById from "./hooks/useNavigateToAgentBuilderById";
import useGetCurrentUser from "../../../user/hooks/useGetCurrentUser";
import type { ButtonVariantWithIcon, TUniversalAgent } from "@toolflow/shared";
import { Button, type SxProps } from "@mui/material";

function NavigateToAgentBuilderButton({
  agentId,
  providedColor,
  className = "",
  variant = "icon",
  sx
}: {
  agentId: string;
  providedColor?: string;
  className?: string;
  variant?: ButtonVariantWithIcon;
  sx?: SxProps;
}) {
  const navigateToAgentBuilder = useNavigateToAgentBuilderById(agentId);

  if (variant !== "icon") {
    return (
      <Button
        onClick={navigateToAgentBuilder}
        size="small"
        variant={variant}
        sx={sx}
      >
        Edit
      </Button>
    );
  }
  return (
    <RemixButton
      text="Edit"
      icon={EditIcon}
      onClick={navigateToAgentBuilder}
      disabled={!agentId}
      providedColor={providedColor}
      className={className}
      sx={sx}
    />
  );
}

export function NavigateToOwnAgentBuilderButton({
  agent,
  className,
  sx
}: {
  agent: TUniversalAgent;
  className?: string;
  sx?: SxProps;
}) {
  const user = useGetCurrentUser();
  return agent.userId === user?._id ? (
    <NavigateToAgentBuilderButton
      agentId={agent._id}
      className={className}
      sx={sx}
    />
  ) : null;
}

export default NavigateToAgentBuilderButton;
