import { Button } from "@mui/material";
import { EditIcon } from "../../../../globalTheme/icons/icons";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { WORKFLOWBUILDER_BASE_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import type { ButtonVariantWithIcon } from "@toolflow/shared";

function NavigateToWorkflowBuilderButton({
  workflowId,
  providedColor,
  className,
  variant = "icon"
}: {
  workflowId: string;
  providedColor?: string;
  className?: string;
  variant?: ButtonVariantWithIcon;
}) {
  const navigate = useToolflowNavigate();
  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${WORKFLOWBUILDER_BASE_ROUTE_PATH}${workflowId}`, e);
  };

  if (variant !== "icon") {
    return (
      <Button onClick={handleNavigate} size="small" variant={variant}>
        Edit
      </Button>
    );
  }

  return (
    <RemixButton
      text="Edit"
      icon={EditIcon}
      onClick={handleNavigate}
      disabled={!workflowId}
      providedColor={providedColor}
      className={className}
    />
  );
}

export default NavigateToWorkflowBuilderButton;
