import type { TDisabledEntities } from "@toolflow/shared";
import MarketplaceTabs from "../../MarketplaceTabs";
import { TToolTab } from "../../constants/toolTabsArray";
import ToolTabsProvider from "./ToolTabsProvider";

export default function ToolTabsContainer(props: {
  toolTabs: TToolTab[];
  className?: string;
  disabledEntities?: TDisabledEntities;
}) {
  return (
    <ToolTabsProvider {...props}>
      <MarketplaceTabs className={props.className} />
    </ToolTabsProvider>
  );
}
