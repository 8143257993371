import { FieldsConfig } from "../fields";
import { CustomEdge, TBlock } from "../models";
import { CustomToolOutputField } from "../outputs";
import { DecimalString } from "../primitives";
import {
  $TSAllowedAny,
  OriginalToolState,
  ToolOutputOption,
  ValidatedInput
} from "../types";
import { BlockDataTypes, BlockDataTypesWithDefault } from "./blocks";
import { BlockDataBase, BlockMessageType } from "./blocksBaseTypes";

export enum OutputConfigOptions {
  List = "list-output"
}

export type OutputConfigGetterFunction = (args: {
  id: string;
  data: BlockDataBase;
  toolOutputField: CustomToolOutputField;
  currentState: OriginalToolState;
}) => OutputConfigOptions[];

export interface IValidateProps<T extends BlockDataTypesWithDefault> {
  data: T;
  edges: CustomEdge[];
  id?: string;
}

export type ValidatorFunction<T extends BlockDataTypesWithDefault> = (
  props: IValidateProps<T>
) => BlockMessageType | undefined;

export type BlockInputUpdaterFunction = (
  block: TBlock,
  oldName: string,
  newName?: string,
  deleteBrackets?: boolean
) => TBlock;

export type BlockInputUpdater = Record<
  string,
  Record<DecimalString, BlockInputUpdaterFunction>
>;

export type GetNestedFieldsFn = (
  data: $TSAllowedAny,
  blocks: TBlock[]
) => ValidatedInput[];

export type GetOutputOptionsFn = (data: $TSAllowedAny) => ToolOutputOption[];

export type FnsRecord<T> = Record<string, Record<DecimalString, T>>;
export type AvailableFieldFnRecord = FnsRecord<GetNestedFieldsFn>;
export type OutputOptionFnsRecord = FnsRecord<GetOutputOptionsFn>;
export type BlockFunctionRecords = FnsRecord<BlockConfigFunctions>;
export type BlockFieldsConfig = Partial<
  Record<DecimalString | "common", FieldsConfig>
>;

export interface BlockConfigFunctions {
  validate?: ValidatorFunction<BlockDataTypes>;
  updateInput?: BlockInputUpdaterFunction;
  getOutputConfigOptions?: OutputConfigGetterFunction;
  getNestedFields?: GetNestedFieldsFn;
  getOutputOptions?: GetOutputOptionsFn;
}
