import { FormControl, Slider, type SliderOwnProps } from "@mui/material";
import {
  type CustomToolInputField,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import FieldWrapper from "./wrapper/FieldWrapper";

export function SliderField(props: {
  disableLabelAndInfo?: boolean;
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  autofocus?: boolean;
  formState: UserInputDictType;
  disabled?: boolean;
  useIds?: boolean;
}) {
  const { toolInputField, formState, disabled, useIds, setFormState } = props;
  const key = useIds ? toolInputField.id : toolInputField.name;
  const fieldValue = formState[key];

  const onChange: SliderOwnProps["onChange"] = (_, newValue) => {
    setFormState({ ...formState, [key]: newValue });
  };

  return (
    <FormControl fullWidth className="w-100-percent" size="small" margin="none">
      <FieldWrapper {...props}>
        <Slider
          disabled={disabled}
          className="nowheel nodrag nopan"
          value={fieldValue as number}
          onChange={onChange}
          valueLabelDisplay="auto"
          // step={marksMax ? null : undefined} // If max marks exist, we want steps to be limited to the marks max array
          scale={(value) => value / 50}
          // marks={Array.from({ length: marksMax + 1 }, (_, i) => ({ value: i }))}
        />
      </FieldWrapper>
    </FormControl>
  );
}
