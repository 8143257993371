import { isPlainObject } from "lodash";
import {
  $TSAllowedAny,
  AgentDeterminedFieldValue,
  AgentToolProps,
  FieldSelectorValue,
  TUniversalAgentConfig,
  TUniversalAgentConfigBase
} from "../../../types";

export function getToolIdsFromAgent(config: TUniversalAgentConfig): string[] {
  return Object.keys(config.toolProps) || config.toolIds || [];
}

export function convertToolPropsFromLegacy(
  config?: TUniversalAgentConfigBase | TUniversalAgentConfig
): AgentToolProps {
  if (!config) {
    return {};
  }

  if (config.toolProps) {
    return config.toolProps;
  } else if ("toolIds" in config && !!config.toolIds) {
    return config.toolIds.reduce((acc, cur) => {
      acc[cur] = { userInput: {} };
      return acc;
    }, {} as AgentToolProps);
  }

  return {};
}

export function isAgentDeterminedInputObj(
  obj: $TSAllowedAny
): obj is AgentDeterminedFieldValue {
  return (
    isPlainObject(obj) && "type" in obj && obj.type === FieldSelectorValue.Agent
  );
}
