import {
  BlockType,
  CopyableField,
  VERSION_1_VALUE,
  OutputType,
  UtilBlockType,
  type AllBlockTypes,
  type CustomToolOutputField,
  type DecimalString,
  type OutputOptionFnsRecord,
  type SerpBlockData,
  type StructuredBlockData,
  type ToolOutputOption,
  type ToolWithinToolBlockData,
  type YoutubeTranscriptBlockData
} from "@toolflow/shared";
import { isBoolean } from "lodash";
import { getOutputFieldValueFromPromptType } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";
import { getOutputFieldsFromJsonSchema } from "../../../../helpers/jsonSchemaHelpers";

const outputOptionFns: OutputOptionFnsRecord = {
  [BlockType.Structured]: {
    [VERSION_1_VALUE]: ({
      label,
      settings: { schema }
    }: StructuredBlockData) => {
      return [
        {
          value: label,
          type: CopyableField.Structured,
          outputs: getOutputFieldsFromJsonSchema(schema || [])
            .filter((item) => item.path)
            .map((item) => ({
              outputType: OutputType.JsonString,
              nodeName: label,
              fieldKey: item.path,
              value: `${label}.${item.path}`,
              label: `${label}.${item.path}`,
              type: CopyableField.LargeText
            }))
        }
      ];
    }
  },
  [BlockType.SERP]: {
    [VERSION_1_VALUE]: ({
      label,
      settings: { allowedOutputs }
    }: SerpBlockData) => {
      return [
        {
          value: label,
          type: CopyableField.LargeText,
          outputs: Object.entries(allowedOutputs || {}).reduce(
            (acc, [key, value]) => {
              if ((isBoolean(value) && value) || !!value?.value) {
                acc.push({
                  outputType: OutputType.JsonObject,
                  nodeName: label,
                  fieldKey: key,
                  value: `${label}.${key}`,
                  label: `${label}.${key}`,
                  type: CopyableField.LargeText
                });
              }
              return acc;
            },
            [] as ToolOutputOption[]
          )
        }
      ];
    }
  },
  [BlockType.YoutubeTranscript]: {
    [VERSION_1_VALUE]: ({ label }: YoutubeTranscriptBlockData) => {
      return [
        {
          value: label,
          type: CopyableField.LargeText,
          outputs: [
            "title",
            "channel",
            "description",
            "duration",
            "transcript"
          ].reduce((acc, key) => {
            acc.push({
              outputType: OutputType.JsonObject,
              nodeName: label,
              fieldKey: key,
              value: `${label}.${key}`,
              label: `${label}.${key}`,
              type: CopyableField.LargeText
            });
            return acc;
          }, [] as ToolOutputOption[])
        }
      ];
    }
  },
  [UtilBlockType.ToolWithinTool]: {
    [VERSION_1_VALUE]: ({ label, tool }: ToolWithinToolBlockData) => {
      const outputOptions: ToolOutputOption[] = [];
      tool?.main.toolOutputFields.forEach((field: CustomToolOutputField) => {
        outputOptions.push({
          value: `${label} - ${field.name}`,
          type: field.type
        });
      });
      return outputOptions;
    }
  },
  default: {
    [VERSION_1_VALUE]: ({ type, label }) => [
      {
        value: label,
        type: getOutputFieldValueFromPromptType(type)
      }
    ]
  }
};

export function getOutputOptionsFnByVersion(
  blockType: AllBlockTypes | "default" = "default",
  version: DecimalString = VERSION_1_VALUE
) {
  return (
    outputOptionFns[blockType]?.[version] ||
    outputOptionFns[blockType]?.[VERSION_1_VALUE] ||
    outputOptionFns.default?.[VERSION_1_VALUE]
  );
}
