import { useToolContext } from "../../../../../tools/contexts/ToolContext";
import { useTVRContext } from "../../../../../toolVersionResponse/contexts/ToolVersionResponseContext";
import ToolVersionResponseContextContainer from "../../../../../toolVersionResponse/contexts/ToolVersionResponseContextContainer";
import { ToolRunChip } from "../../../../../tools/components/ToolRunChip";
import useSetSelectedTool from "../../../hooks/useSetSelectedTool";
import { ToolRunLabel } from "../../../../../tools/components/ToolRunLabel";
import { Stack } from "@mui/material";

const ToolRunByUserInner = () => {
  const { tvr } = useTVRContext();
  const { tool } = useToolContext();
  const setSelectedTool = useSetSelectedTool();

  const handleClick = () => {
    setSelectedTool(tool._id.toString(), tvr.validatedUserInput);
  };

  return (
    <Stack direction="row" alignItems="center">
      <ToolRunLabel title="You ran:" className="m-t-8px m-l-16px m-r-8px" />
      <ToolRunChip
        title={tool.name}
        onClick={handleClick}
        className="grey m-t-8px"
      />
    </Stack>
  );
};

const ToolRunByUser = ({ tvrId }: { tvrId?: string }) => {
  return (
    <ToolVersionResponseContextContainer tvrId={tvrId}>
      <ToolRunByUserInner />
    </ToolVersionResponseContextContainer>
  );
};

export default ToolRunByUser;
