export type DefaultBlockType = "default";

export enum BlockType {
  DallE = "Dall-E2", // this is a misnomer, this is for all Dall-E types, we may want to migrate the names to "Dall-E" in the future
  ChatGPT = "ChatGPT",
  Anthropic = "Anthropic",
  Structured = "Structured",
  Perplexity = "Perplexity",
  SERP = "serp",
  Deepgram = "DeepgramTranscribe",
  Scraper = "WebsiteContentCrawler",
  IterationStart = "IterationStart",
  IterationExit = "IterationExit",
  Knowledge = "Knowledge",
  SavetoKnowledge = "SavetoKnowledge",
  UserIntegration = "UserIntegration",
  YoutubeTranscript = "YoutubeTranscript"
}

export enum UtilBlockType {
  Constant = "constant",
  Logic = "logic",
  ToolWithinTool = "toolWithinTool"
}

export type AllBlockTypes = BlockType | UtilBlockType;

export enum BlockNode {
  PromptBlockNode = "promptBlockNode",
  ToolWithinToolBlockNode = "toolWithinToolBlockNode",
  LogicBlockNode = "logicBlockNode",
  ConstantBlockNode = "constantBlockNode",
  DeepgramBlockNode = "deepgramBlockNode",
  ScraperBlockNode = "scraperBlockNode",
  SerpBlockNode = "serpBlockNode",
  StructuredBlockNode = "structuredBlockNode",
  PerplexityBlockNode = "perplexityBlockNode",
  IterationStartBlockNode = "iterationStartBlockNode",
  IterationExitBlockNode = "iterationExitBlockNode",
  DallEBlockNode = "dallEBlockNode",
  KnowledgeNode = "KnowledgeNode",
  SavetoKnowledgeNode = "SavetoKnowledgeNode",
  UserIntegrationBlockNode = "UserIntegrationBlockNode",
  YoutubeTranscriptNode = "YoutubeTranscriptNode"
}

export enum UtlBlockNode {
  ParentBlockNode = "parentBlockNode",
  InputBlockNode = "inputBlockNode",
  OutputBlockNode = "outputBlockNode"
}

export type AllBlockNodeTypes = BlockNode | UtlBlockNode;

export type TTextGenerationBlockType = BlockType.ChatGPT | BlockType.Anthropic;

export const BlockTypeNodeMap: Record<AllBlockTypes, BlockNode> = {
  [BlockType.Anthropic]: BlockNode.PromptBlockNode,
  [BlockType.ChatGPT]: BlockNode.PromptBlockNode,
  [BlockType.DallE]: BlockNode.DallEBlockNode,
  [BlockType.Deepgram]: BlockNode.DeepgramBlockNode,
  [BlockType.IterationExit]: BlockNode.IterationExitBlockNode,
  [BlockType.IterationStart]: BlockNode.IterationStartBlockNode,
  [BlockType.Knowledge]: BlockNode.KnowledgeNode,
  [BlockType.Perplexity]: BlockNode.PerplexityBlockNode,
  [BlockType.SERP]: BlockNode.SerpBlockNode,
  [BlockType.SavetoKnowledge]: BlockNode.SavetoKnowledgeNode,
  [BlockType.Scraper]: BlockNode.ScraperBlockNode,
  [BlockType.Structured]: BlockNode.StructuredBlockNode,
  [BlockType.YoutubeTranscript]: BlockNode.YoutubeTranscriptNode,
  [BlockType.UserIntegration]: BlockNode.UserIntegrationBlockNode,
  [UtilBlockType.Constant]: BlockNode.ConstantBlockNode,
  [UtilBlockType.Logic]: BlockNode.LogicBlockNode,
  [UtilBlockType.ToolWithinTool]: BlockNode.ToolWithinToolBlockNode
};

export type TBlockTypeNodeMap = {
  [BlockType.Anthropic]: BlockNode.PromptBlockNode;
  [BlockType.ChatGPT]: BlockNode.PromptBlockNode;
  [BlockType.DallE]: BlockNode.DallEBlockNode;
  [BlockType.Deepgram]: BlockNode.DeepgramBlockNode;
  [BlockType.IterationExit]: BlockNode.IterationExitBlockNode;
  [BlockType.IterationStart]: BlockNode.IterationStartBlockNode;
  [BlockType.Knowledge]: BlockNode.KnowledgeNode;
  [BlockType.Perplexity]: BlockNode.PerplexityBlockNode;
  [BlockType.SERP]: BlockNode.SerpBlockNode;
  [BlockType.SavetoKnowledge]: BlockNode.SavetoKnowledgeNode;
  [BlockType.Scraper]: BlockNode.ScraperBlockNode;
  [BlockType.Structured]: BlockNode.StructuredBlockNode;
  [BlockType.YoutubeTranscript]: BlockNode.YoutubeTranscriptNode;
  [BlockType.UserIntegration]: BlockNode.UserIntegrationBlockNode;
  [UtilBlockType.Constant]: BlockNode.ConstantBlockNode;
  [UtilBlockType.Logic]: BlockNode.LogicBlockNode;
  [UtilBlockType.ToolWithinTool]: BlockNode.ToolWithinToolBlockNode;
};

export type BlockTypeToNode<T extends AllBlockTypes> = TBlockTypeNodeMap[T];
