import { NodeViewWrapper } from "@tiptap/react";
import { ChipNodeHtmlTags } from "@toolflow/shared";

const HiddenContextComponent = () => {
  return (
    <NodeViewWrapper
      className={`hidden ${ChipNodeHtmlTags.HiddenContextComponent} dontTriggerBubble`}
    />
  );
};

export default HiddenContextComponent;
