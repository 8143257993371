/**
 * Utility functions for dispatching events related to chip actions
 */

import { ClickableActionTypes } from "@toolflow/shared";

/**
 * Dispatches a custom event for creating a snippet with the newly created snippet id
 * @param snippetId The id of the newly created snippet
 */
export const dispatchCreateSnippetEvent = (snippetId: string): void => {
  const event = new CustomEvent(ClickableActionTypes.CREATE_SNIPPET, {
    detail: { id: snippetId }
  });
  window.dispatchEvent(event);
};

/**
 * Dispatches a custom event for adding a tool builder input with the newly added input
 * @param inputName The name of the newly added tool builder input
 */
export const dispatchAddToolBuilderInputEvent = (inputName: string): void => {
  const event = new CustomEvent(ClickableActionTypes.ADD_TOOLBUILDER_INPUT, {
    detail: { id: inputName }
  });
  window.dispatchEvent(event);
};
