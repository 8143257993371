import { Typography } from "@mui/material";
export function ToolRunLabel({
  title,
  className
}: {
  title: string;
  className?: string;
}) {
  return (
    <Typography variant="caption" color="grey" className={className}>
      {title}
    </Typography>
  );
}
