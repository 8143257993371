import { AssetValueType, BlockType, CopyableField } from "@toolflow/shared";

export const PromptTypeToOutputFieldMap = {
  [BlockType.DallE]: CopyableField.Image,
  [BlockType.ChatGPT]: CopyableField.LargeText,
  [BlockType.Anthropic]: CopyableField.LargeText,
  [BlockType.Structured]: CopyableField.Structured,
  [BlockType.Perplexity]: CopyableField.LargeText,
  [BlockType.UserIntegration]: CopyableField.Structured
};

export const OutputFieldToValueTypeMap = {
  [CopyableField.Image]: AssetValueType.DallE,
  [CopyableField.LargeText]: AssetValueType.String,
  [CopyableField.Structured]: AssetValueType.Structured
};

/**
 * Returns AssetValueType based on CopyableField.
 * @default AssetValueType.String
 */
export function getValueTypeFromOutputField(copyableField: CopyableField) {
  return OutputFieldToValueTypeMap[copyableField] || AssetValueType.String;
}

/**
 * Returns CopyableField based on BlockType.
 * @default CopyableField.LargeText
 */
export function getOutputFieldValueFromPromptType(promptType: BlockType) {
  return (
    PromptTypeToOutputFieldMap[
      promptType as keyof typeof PromptTypeToOutputFieldMap
    ] || CopyableField.LargeText
  );
}
