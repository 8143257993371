import { useBlockContext } from "../../../BlockContext";
import useGetDrawerNodeId from "../../../store/hooks/useGetDrawerNodeId";
import getBlockHeaderIcon from "../../configs/getters/getBlockHeaderIcon";
import useSetBlockMessage from "../../hooks/useSetBlockMessage";

const useBlockPaperHeader = () => {
  const { blockData: data, id } = useBlockContext();
  const BlockIcon = getBlockHeaderIcon(data);

  const { label } = data;

  useSetBlockMessage();
  const openNodeId = useGetDrawerNodeId();

  return {
    label,
    BlockIcon,
    isOpenNode: openNodeId === id
  };
};

export default useBlockPaperHeader;
