import { isBoolean, isObject } from "lodash";
import {
  $TSFixMe,
  DynamicFieldType,
  type TSerpAllowedOptions
} from "../../../../../types";

export function getSerpAllowedOutputs(options: Record<string, $TSFixMe>) {
  return Object.entries(options).reduce((acc, [key, value]) => {
    if (isBoolean(value)) {
      acc[key] = {
        value,
        type: DynamicFieldType.Preset
      };
    } else if (isObject(value)) {
      acc[key] = getSerpAllowedOutputs(value as Record<string, $TSFixMe>);
    }
    return acc;
  }, {} as $TSFixMe);
}

export const serpBlockOutputAllowedValuesDict: TSerpAllowedOptions<boolean> = {
  peopleAlsoAsk: true,
  relatedQueries: true,
  organicResults: true,
  paidResults: true,
  resultOptions: {
    title: true,
    url: true,
    description: true,
    displayedUrl: false,
    emphasizedKeywords: false,
    siteLinks: false
  }
};
