import { type UserInputDictType } from "@toolflow/shared";
import { useMemo } from "react";
import GroupAccordion from "../../../../common/fieldsByType/components/fieldsGroupComponent/GroupAccordion";
import { AvailableTestField } from "../hooks/helpers/testFieldSet";
import TestFieldsInputTab from "./TestFieldsInputTab";
import TestFieldsPreviewTab from "./TestFieldsPreviewTab";
import TestFieldsTabs from "./TestFieldsTabs";

export default function TestFields({
  uI,
  fields = [],
  setUI
}: {
  uI: UserInputDictType;
  fields: AvailableTestField[];
  setUI(ui: UserInputDictType): void;
}) {
  const previewFields = useMemo(() => {
    const uniqMap = fields.reduce((acc, field) => {
      if (field.key) {
        acc.set(field.key, field);
      } else {
        acc.set(field.fieldLabel, field);
      }
      return acc;
    }, new Map<string, AvailableTestField>());

    return Array.from(uniqMap.values());
  }, [fields]);

  return (
    <GroupAccordion
      title="Test fields"
      subtitle="All the available fields are listed below. Enter your desired test values to substitute the placeholders."
      defaultOpen={true}
    >
      {!!previewFields.length ? (
        <TestFieldsTabs
          tabs={[
            {
              label: "Test inputs",
              value: 0,
              component: (
                <TestFieldsInputTab
                  uI={uI}
                  setUI={setUI}
                  fields={fields}
                  className="p-h-12px m-t-8px p-b-8px"
                />
              )
            },
            {
              label: "Preview",
              value: 1,
              component: <TestFieldsPreviewTab fields={previewFields} uI={uI} />
            }
          ]}
        />
      ) : (
        <TestFieldsInputTab
          uI={uI}
          setUI={setUI}
          fields={fields}
          className="p-h-12px m-t-8px p-b-8px"
        />
      )}
    </GroupAccordion>
  );
}
