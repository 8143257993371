import CategoryTabs from "./categories/CategoryTabs";
import ElementGrid from "./components/ElementGrid";
import { useSearchToolsContext } from "./context/SearchToolsContext";
import useGetTabEntities from "./hooks/useGetTabEntities";

export function SearchTools() {
  const entities = useGetTabEntities();
  const { EntityElement, entityType } = useSearchToolsContext();

  return (
    <div>
      <CategoryTabs entityType={entityType} />
      <ElementGrid
        entities={entities}
        entityType={entityType}
        EntityElement={EntityElement}
      />
    </div>
  );
}
