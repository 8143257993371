import { Asset } from "./models";

export type TAssetsReducer = {
  assets: Asset[];
};

export enum AssetNodeType {
  Text = "text",
  Chip = "chip",
  Node = "node"
}

export type SaveWorkspaceType = "text" | "json" | "html";
