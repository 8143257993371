import { Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  CustomToolInputFieldTypesEnum,
  type CustomToolInputField
} from "@toolflow/shared";
import DraggingWrapper from "../../../../../../utilities/components/draggables/DraggingWrapper";
import useIsDragging from "../../../../../../utilities/components/draggables/hooks/useIsDragging";
import { useCustomToolInputFieldContext } from "../../../../../../utilities/contexts/CustomToolInputFieldContext";
import ChangeInputFieldDescriptionTextField from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/ChangeInputFieldDescriptionTextField";
import ChangeInputFieldNameTextField from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/ChangeInputFieldNameTextField";
import DeleteInputButtonContainer from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/DeleteInputButton";
import DeprecatedDraggableToolInputFieldWrapper from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/DraggableToolInputFieldWrapper";
import GridXs6 from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/GridXs6";
import InputTypeSelectInDraggable from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/InputTypeSelectInDraggable";
import ToolInputFieldDragHandle from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/ToolInputFieldDragHandle";
import ToolInputOptions from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/ToolInputOptions";

const DeprecatedDraggableToolInputFieldInner = () => {
  const { input: toolInputField } = useCustomToolInputFieldContext();
  const needsOptions = (
    [
      CustomToolInputFieldTypesEnum.SELECT,
      CustomToolInputFieldTypesEnum.MULTI_SELECT,
      CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD
    ] as CustomToolInputFieldTypesEnum[]
  ).includes(toolInputField.type);
  const isDragging = useIsDragging();
  return (
    <DraggingWrapper
      gap="16px"
      preview={
        <Chip
          label={toolInputField.name || "Input"}
          variant="outlined"
          size="small"
        />
      }
    >
      <Grid className="m-v-16px" container spacing={3} alignItems="center">
        <Grid item xs={1}>
          <ToolInputFieldDragHandle />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <GridXs6>
              <ChangeInputFieldNameTextField disabled={isDragging} />
            </GridXs6>
            <GridXs6>
              <InputTypeSelectInDraggable disabled={isDragging} />
            </GridXs6>
            <Grid item xs={12}>
              <ChangeInputFieldDescriptionTextField disabled={isDragging} />
            </Grid>
            {needsOptions && (
              <Grid item xs={12} className="flex flex-column">
                <ToolInputOptions disabled={isDragging} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} className="flex justify-flex-end">
          {/* <div> formControls need to be wrapped in a div wihtin a grid it seems or the icon wont show */}
          <div>
            <DeleteInputButtonContainer disabled={isDragging} />
          </div>
        </Grid>
      </Grid>
    </DraggingWrapper>
  );
};

const DeprecatedDraggableToolInputField = ({
  toolInputField,
  index
}: {
  toolInputField: CustomToolInputField;
  index: number;
}) => {
  return (
    <DeprecatedDraggableToolInputFieldWrapper
      index={index}
      toolInputField={toolInputField}
    >
      <DeprecatedDraggableToolInputFieldInner />
    </DeprecatedDraggableToolInputFieldWrapper>
  );
};

export default DeprecatedDraggableToolInputField;
