import { useToolContext } from "../../contexts/ToolContext";
import WebsiteForm from "../WebsiteForm";
import { useEditorToolCardContext } from "./EditorToolCardContext";

function EditorToolCardContent() {
  const { tool } = useToolContext();
  const { main } = tool;
  const { toolInputFields } = main;
  const {
    useIds,
    userInput,
    hasLoaded,
    defaultUI,
    fieldsToHide,
    setUserInput
  } = useEditorToolCardContext();

  return (
    <WebsiteForm
      entityId={tool._id}
      autofocus={!hasLoaded}
      setFormState={setUserInput}
      formState={userInput}
      toolInputFields={toolInputFields}
      defaultFormState={defaultUI}
      fieldsToHide={fieldsToHide}
      useIds={useIds}
    />
  );
}

export default EditorToolCardContent;
