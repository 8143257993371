import { cloneDeep, merge } from "lodash";
import {
  AllBlockTypes,
  AllVersionedBlockConfigs,
  BlockConfig,
  BlockDataBase,
  BlockNode,
  BlockType,
  BlockTypeNodeMap,
  CopyableField,
  DecimalString,
  DraggableItem,
  UtilBlockType,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  VersionedBlockConfig
} from "../../../types";
import { getBlockFieldsByBlockType } from "./blockFields";
import { getLatestBlockVersion } from "./helpers";
import { getInitialState } from "./initialStates";
import { BlockTypeLabelMap } from "../../../types/blocks/blockLabels";
import { BlockTypDescriptionMap } from "../../../types/blocks/blockDescriptions";

type PartialConfig = Partial<
  Omit<BlockConfig, "draggableItem"> & {
    draggableItem: Partial<
      Omit<DraggableItem, "data"> & {
        data?: Partial<BlockDataBase>;
      }
    >;
  }
>;

function createBlockConfigByVersion(
  type: AllBlockTypes,
  version: DecimalString,
  config: PartialConfig = {}
) {
  const blockNodeType = BlockTypeNodeMap[type];
  const label = BlockTypeLabelMap[blockNodeType];
  const description = BlockTypDescriptionMap[blockNodeType];
  return {
    [version]: merge(
      {
        fields: getBlockFieldsByBlockType(type, version),
        blockPaperLabel: label,
        draggableItem: {
          type: blockNodeType,
          data: {
            type,
            version,
            label,
            description,
            ...(cloneDeep(getInitialState(type)) || {})
          }
        }
      },
      config
    )
  } as VersionedBlockConfig;
}

export const blockConfig: AllVersionedBlockConfigs = {
  [BlockType.ChatGPT]: {
    ...createBlockConfigByVersion(BlockType.ChatGPT, VERSION_1_VALUE),
    ...createBlockConfigByVersion(BlockType.ChatGPT, VERSION_2_VALUE, {
      individualTool: true
    })
  },
  [BlockType.Anthropic]: {
    ...createBlockConfigByVersion(BlockType.Anthropic, VERSION_1_VALUE),
    ...createBlockConfigByVersion(BlockType.Anthropic, VERSION_2_VALUE, {
      individualTool: true
    })
  },
  [BlockType.DallE]: {
    ...createBlockConfigByVersion(BlockType.DallE, VERSION_1_VALUE, {
      outputType: CopyableField.Image,
      draggableItem: {
        type: BlockNode.PromptBlockNode // V1 Dall-E uses PromptBlockNode, we should fix this
      }
    }),
    ...createBlockConfigByVersion(BlockType.DallE, VERSION_2_VALUE, {
      individualTool: true,
      outputType: CopyableField.Image
    })
  },
  [BlockType.Deepgram]: {
    ...createBlockConfigByVersion(BlockType.Deepgram, VERSION_1_VALUE),
    ...createBlockConfigByVersion(BlockType.Deepgram, VERSION_2_VALUE, {
      individualTool: true
    })
  },
  [BlockType.Scraper]: {
    ...createBlockConfigByVersion(BlockType.Scraper, VERSION_1_VALUE),
    ...createBlockConfigByVersion(BlockType.Scraper, VERSION_2_VALUE, {
      individualTool: true
    })
  },
  [BlockType.SERP]: {
    ...createBlockConfigByVersion(BlockType.SERP, VERSION_1_VALUE, {
      outputType: CopyableField.Structured
    }),
    ...createBlockConfigByVersion(BlockType.SERP, VERSION_2_VALUE, {
      individualTool: true,
      outputType: CopyableField.Structured
    })
  },
  [BlockType.Structured]: {
    ...createBlockConfigByVersion(BlockType.Structured, VERSION_1_VALUE, {
      outputType: CopyableField.Structured
    }),
    ...createBlockConfigByVersion(BlockType.Structured, VERSION_2_VALUE, {
      individualTool: true,
      outputType: CopyableField.Structured
    })
  },
  [BlockType.Perplexity]: {
    ...createBlockConfigByVersion(BlockType.Perplexity, VERSION_1_VALUE),
    ...createBlockConfigByVersion(BlockType.Perplexity, VERSION_2_VALUE, {
      individualTool: true
    })
  },
  [BlockType.IterationStart]: createBlockConfigByVersion(
    BlockType.IterationStart,
    VERSION_1_VALUE,
    {
      nonTestable: true
    }
  ),
  [BlockType.IterationExit]: createBlockConfigByVersion(
    BlockType.IterationExit,
    VERSION_1_VALUE,
    {
      nonTestable: true
    }
  ),
  [BlockType.Knowledge]: createBlockConfigByVersion(
    BlockType.Knowledge,
    VERSION_1_VALUE,
    {
      individualTool: true
    }
  ),
  [BlockType.SavetoKnowledge]: createBlockConfigByVersion(
    BlockType.SavetoKnowledge,
    VERSION_1_VALUE,
    {
      nonTestable: true,
      individualTool: true
    }
  ),
  [BlockType.YoutubeTranscript]: createBlockConfigByVersion(
    BlockType.YoutubeTranscript,
    VERSION_1_VALUE,
    {
      outputType: CopyableField.Structured,
      individualTool: true
    }
  ),
  [BlockType.UserIntegration]: createBlockConfigByVersion(
    BlockType.UserIntegration,
    VERSION_1_VALUE,
    {
      outputType: CopyableField.Structured
    }
  ),
  [UtilBlockType.ToolWithinTool]: createBlockConfigByVersion(
    UtilBlockType.ToolWithinTool,
    VERSION_1_VALUE,
    {
      nonTestable: true
    }
  ),
  [UtilBlockType.Logic]: createBlockConfigByVersion(
    UtilBlockType.Logic,
    VERSION_1_VALUE,
    {
      nonTestable: true
    }
  ),
  [UtilBlockType.Constant]: {
    ...createBlockConfigByVersion(UtilBlockType.Constant, VERSION_1_VALUE, {
      nonTestable: true
    }),
    ...createBlockConfigByVersion(UtilBlockType.Constant, VERSION_2_VALUE, {
      nonTestable: true
    })
  }
};

/**
 * Retrieves the block configuration for a given block type and version.
 *
 * @param {AllBlockTypes} blockType - The type of the block.
 * @param {DecimalString} [version] - The version of the block. If not provided, the latest version is used.
 * @returns {BlockConfig} The block configuration for the specified type and version.
 */
export function getRawBlockConfig<T extends AllBlockTypes = AllBlockTypes>(
  blockType: AllBlockTypes,
  version?: DecimalString
): BlockConfig<T> {
  if (blockType in blockConfig) {
    if (!version) {
      version = getLatestBlockVersion(blockType);
    }
    return blockConfig[blockType][version] as BlockConfig<T>;
  } else return {} as BlockConfig<T>;
}
