import {
  MenuItem,
  Select,
  type SelectChangeEvent,
  Tooltip,
  Typography
} from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type CustomToolInputField,
  type DynamicFieldConfigBase,
  type DynamicListFieldConfig,
  type SelectOption,
  type ValidatedInput
} from "@toolflow/shared";
import { isString } from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import AddInputDialog from "../../../../addInput/AddInputDialog";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useDynamicSetting from "../../hooks/useDynamicSetting";
import styles from "./dynamicFieldWrapper.module.css";
import iconDict from "../../../../../../../../utilities/constants/iconDict";
import DropdownMenuItem from "../../../../dropdowns/DropdownMenuItem";

const cx = parsedClassNames.bind(styles);

export default function DynamicFieldWrapper({
  id,
  label,
  subtitle,
  fieldValue,
  type,
  configs,
  children,
  addInputType,
  onChange,
  filterOptions,
  onDynamicInputChange,
  onTypeChangeCallback
}: {
  id: string;
  label?: string;
  subtitle?: string;
  fieldValue: string;
  type: DynamicFieldType;
  configs: DynamicFieldConfigBase;
  children: ReactNode;
  addInputType?: CustomToolInputFieldTypesEnum;
  onChange?(type: DynamicFieldType): void;
  onDynamicInputChange?(type: $TSAllowedAny): void;
  onTypeChangeCallback?(type: DynamicFieldType): void;
  filterOptions?(options: ValidatedInput[]): ValidatedInput[];
}) {
  const { updateField } = useFieldsByTypeContext();
  const { allowedFields, fieldKey, typeKey } = configs;

  const availableFields = useToolInputFieldsAndAvailableFields(id);
  const forceDynamic = useDynamicSetting<boolean>(configs.forceDynamic);

  const selectOptions: SelectOption[] = useMemo(() => {
    const fields = filterOptions?.(availableFields) || availableFields;
    return fields.map((field) => {
      return {
        label: field.config?.label || field.name,
        value: field.config?.label || field.name,
        type: field.type
      };
    });
  }, [filterOptions, availableFields]);

  useEffect(() => {
    if (forceDynamic && type !== DynamicFieldType.Dynamic) {
      updateField(DynamicFieldType.Dynamic, typeKey);
      updateField("", fieldKey);
    }
  }, [forceDynamic]);

  const [openInputModal, setOpenInputModal] = useState(false);
  const updateFromAdd = (value: CustomToolInputField) => {
    if (value.type === addInputType) {
      if (onDynamicInputChange) {
        onDynamicInputChange({
          target: { value: value.name }
        } as SelectChangeEvent<string>);
      } else {
        updateField(value.name, fieldKey);
      }
    }
  };

  return (
    <>
      <div className={cx("border-radius-16px ", "container")}>
        <div className={cx("flex justify-space-between align-i-center")}>
          <Typography
            variant="h8"
            fontWeight="500"
            className="capitalize-first-letter"
          >
            {label}
          </Typography>

          <Select
            value={type}
            size="small"
            fullWidth
            onChange={(event) => {
              if (!forceDynamic) {
                if (onChange) {
                  onChange(event.target.value as DynamicFieldType);
                } else {
                  updateField(event.target.value, typeKey);
                  onTypeChangeCallback?.(
                    event.target.value as DynamicFieldType
                  );
                }
              }
            }}
            className={cx("select-field")}
            inputProps={{
              className: "p-0px"
            }}
            variant="standard"
            IconComponent={() => (
              <ArrowDownSmallIcon className={cx("select-field-icon")} />
            )}
          >
            <MenuItem
              className={cx("select-field-item")}
              value={DynamicFieldType.Dynamic}
              key={DynamicFieldType.Dynamic}
            >
              <Tooltip
                title="Dynamic value is piped through other blocks or user inputs."
                placement="top"
              >
                <div>🎛️ Dynamic</div>
              </Tooltip>
            </MenuItem>
            <MenuItem
              className={cx("select-field-item")}
              value={DynamicFieldType.Preset}
              key={DynamicFieldType.Preset}
            >
              <Tooltip
                title="Set a preset value that will be used for this tool."
                placement="top"
              >
                <div>🔒 Fixed</div>
              </Tooltip>
            </MenuItem>
          </Select>
        </div>
        {subtitle && (
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        )}

        {type === DynamicFieldType.Dynamic ? (
          <Select
            value={fieldValue}
            size="small"
            fullWidth
            onChange={(event) =>
              onDynamicInputChange
                ? onDynamicInputChange(event)
                : updateField(event.target.value, fieldKey)
            }
            className="nowheel nodrag nopan m-t-16px bg-color-white"
          >
            {addInputType && (
              <MenuItem
                value={CREATE_USER_INPUT_LABEL}
                onClick={() => setOpenInputModal(true)}
              >
                {CREATE_USER_INPUT_LABEL}
              </MenuItem>
            )}
            {selectOptions.map((option) => {
              if (isString(option)) {
                return (
                  <MenuItem value={option} key={option} className="capitalize">
                    {option}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    value={option.value}
                    key={option.value}
                    className="capitalize"
                  >
                    <DropdownMenuItem
                      name={option.label}
                      icon={
                        option.type
                          ? iconDict[
                              option.type as CustomToolInputFieldTypesEnum
                            ]
                          : ""
                      }
                    />
                  </MenuItem>
                );
              }
            })}
          </Select>
        ) : (
          children
        )}
      </div>
      {addInputType && (
        <AddInputDialog
          allowedFields={allowedFields}
          openModal={openInputModal}
          setOpenModal={setOpenInputModal}
          callback={updateFromAdd}
          initialType={addInputType}
          initialOptions={
            addInputType === CustomToolInputFieldTypesEnum.SELECT
              ? (configs as DynamicListFieldConfig)?.options?.map?.((option) =>
                  isString(option) ? option : option.value
                )
              : undefined
          }
        />
      )}
    </>
  );
}
