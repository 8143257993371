import { Paper, Stack, Typography } from "@mui/material";
import { classNames } from "../../functions/parsedClassNames";

const OverviewCard = ({
  description,
  title,
  className,
  avatar,
  avatarSize = 24
}: {
  description?: string;
  title: string | React.ReactNode;
  className?: string;
  avatar?: string;
  avatarSize?: number;
}) => {
  const titleComponent =
    typeof title === "string" ? (
      title
    ) : (
      <Typography variant="h8">{title}</Typography>
    );

  return (
    <Paper
      variant="outlined"
      className={classNames("border-radius-16px p-16px", className)}
    >
      <>
        <Stack direction="row" spacing={1} alignItems="center">
          {avatar && (
            <img
              src={avatar}
              alt="avatar"
              height={avatarSize}
              width={avatarSize}
            />
          )}
          <div className="flex align-i-center">{titleComponent}</div>
        </Stack>

        {description && (
          <Typography
            variant="body2"
            color="text.secondary"
            className="m-t-8px"
          >
            {description}
          </Typography>
        )}
      </>
    </Paper>
  );
};

export default OverviewCard;
