import React from "react";
import { securityIsSet } from "../../ToolflowAPI/security";
import { useAuthStore } from "./hooks/useAuthStore";

const AuthenticationSplitter = ({
  loadingComponent = <></>,
  authenticatedComponent,
  externalComponent,
  loading,
  authenticationOverride
}: {
  loadingComponent?: React.ReactNode;
  authenticatedComponent?: React.ReactNode;
  externalComponent: React.ReactNode;
  loading?: boolean;
  authenticationOverride?: boolean;
}) => {
  const { isAuthenticated, isLoading } = useAuthStore();
  const loadingCondition =
    isLoading || (isAuthenticated && loading) || !securityIsSet();
  if (loadingCondition) {
    return <>{loadingComponent}</>;
  }

  if ((isAuthenticated || authenticationOverride) && authenticatedComponent) {
    return <>{authenticatedComponent}</>;
  }
  return <>{externalComponent}</>;
};
export default AuthenticationSplitter;
