import type {
  CustomToolInputFieldWithTags,
  TSetFormState,
  UserInputDictType
} from "@toolflow/shared";
import InputListComponent from "../../inputs/InputListComponent";
import FieldWrapper from "./wrapper/FieldWrapper";

export const InputTags = (props: {
  toolInputField: CustomToolInputFieldWithTags;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  const {
    toolInputField,
    setFormState,
    formState,
    disableLabelAndInfo,
    useIds
  } = props;
  const key = useIds ? toolInputField.id : toolInputField.name;
  const handleChangeTags = (tags: string[]) => {
    const uniqueSavedTags = [...new Set(tags)].filter(
      (tag) => !tag.includes("unsaved:")
    );
    setFormState({ ...formState, [key]: uniqueSavedTags });
  };
  return (
    <FieldWrapper {...props}>
      <InputListComponent
        valuesList={formState[key] as string[]}
        handleChange={handleChangeTags}
        disableHelperText={disableLabelAndInfo}
      />
    </FieldWrapper>
  );
};
