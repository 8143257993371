import { BlockType, type BlockDataTypesWithDefault } from "@toolflow/shared";
import { getBlockConfigFE } from "../blockConfig";
import { UserIntegrationsIcon } from "../../../../../../../globalTheme/icons/icons";

interface DynamicIntegrationIconProps {
  logo?: string;
  size?: number;
}

export const DynamicIntegrationIcon = ({
  logo,
  size = 24
}: DynamicIntegrationIconProps) => {
  if (!logo) {
    return <UserIntegrationsIcon size={size} />;
  }
  return (
    <img
      src={logo}
      width={size}
      height={size}
      className="border-radius-4px"
      alt="Integration Icon"
    />
  );
};

const getBlockHeaderIcon = (
  data?: BlockDataTypesWithDefault
): React.ElementType | null => {
  if (!data) return null;
  const { type, version } = data;
  if (type === "default") {
    return null;
  } else if (type === BlockType.UserIntegration) {
    // Create a wrapper component that passes the integrationLogo
    const IntegrationIconWithLogo: React.FC<
      Omit<DynamicIntegrationIconProps, "logo">
    > = (props) => (
      <DynamicIntegrationIcon
        {...props}
        logo={data.settings?.integrationLogo}
      />
    );
    return IntegrationIconWithLogo;
  }

  const config = getBlockConfigFE(type, version);
  return config.icon;
};

export default getBlockHeaderIcon;
