export enum ChipNodeHtmlTags {
  SnippetChipComponent = "tip-tap-snippet-chip-component",
  InputComponent = "tip-tap-input-component",
  ToolbuilderInputComponent = "tip-tap-toolbuilder-input-viewer",
  SearchToolResponseComponent = "tip-tap-search-tool-response-component",
  NodeOutputViewerComponent = "tip-tap-node-output-viewer-component",
  FlowThinkingComponent = "flowThinking",
  FlowAssetComponent = "flowAsset",
  ThinkingComponent = "think",
  HiddenContextComponent = "hiddenContext"
}
