import type { PipedreamApp } from "@toolflow/shared";
import useGetIntegrations from "./useGetIntegrations";

export default function useIsIntegrationConnected() {
  const { data: userIntegrations } = useGetIntegrations();
  return (app: PipedreamApp) =>
    !!userIntegrations?.integrations.some(
      (int) => int.integrationType === app.name_slug
    );
}
