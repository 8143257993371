import { Grid, Skeleton, Stack } from "@mui/material";

export default function FormSkeleton({
  show,
  length = 3
}: {
  show?: boolean;
  length?: number;
}) {
  if (!show) return null;
  return (
    <Stack spacing={6} className="p-v-32px">
      {Array(length)
        .fill(0)
        .map((_, key) => (
          <Grid container key={key}>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" width={100} height={18} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant="rectangular"
                width={385}
                height={25}
                className="float-right"
              />
            </Grid>

            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                width={250}
                height={18}
                className="m-t-16px"
              />
            </Grid>
          </Grid>
        ))}
    </Stack>
  );
}
