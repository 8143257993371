import { CircularProgress, Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import { useFetchIntegrations } from "../../../../../integrations/hooks/useFetchIntegrations";
import useFilteredIntegrations from "../../../../../integrations/hooks/useFilteredIntegrations";
import { UserIntegrationSearchField } from "../../../../../pages/profile/pages/settings/UserIntegrationSettings/components/userIntegrationSearchField";
import { IntegrationCardFC } from "../../context/SearchToolsContext";
import { useInfiniteScrollHeight } from "../../useInfiniteScrollHeight";
import IntegrationEntityCard from "../gridCards/IntegrationEntityCard";
import styles from "./IntegrationsGrid.module.css";
import IntegrationEntityCardSkeleton from "../gridCards/IntegrationEntityCardSkeleton";

const cx = parsedClassNames.bind(styles);

export default function IntegrationsGrid({
  EntityElement,
  offset
}: {
  EntityElement: IntegrationCardFC;
  offset?: number;
}) {
  const { apps, pageInfo, searchProps, fetchNextBatch, isFetching } =
    useFetchIntegrations();

  const filteredApps = useFilteredIntegrations({
    apps,
    pageInfo,
    searchQuery: searchProps.value
  });

  const { scrollHeight, scrollRef } = useInfiniteScrollHeight(offset);

  return (
    <div ref={scrollRef} id="main-area m-b-32px">
      <div className={cx("search-filter-container")}>
        <UserIntegrationSearchField {...searchProps} />
      </div>
      <InfiniteScroll
        dataLength={filteredApps.length}
        next={fetchNextBatch}
        hasMore={pageInfo?.count < pageInfo?.total_count}
        loader={
          <div className="w-100-percent flex justify-center">
            <CircularProgress size={24} />
          </div>
        }
        height={scrollHeight}
        scrollableTarget="main-area"
      >
        <Grid container spacing={1} className="m-b-32px">
          {filteredApps.map((app) => (
            <Grid item key={app.id}>
              <EntityElement app={app} />
            </Grid>
          ))}
          {isFetching && (
            <>
              <Grid item>
                <IntegrationEntityCardSkeleton />
              </Grid>
              <Grid item>
                <IntegrationEntityCardSkeleton />
              </Grid>
              <Grid item>
                <IntegrationEntityCardSkeleton />
              </Grid>
            </>
          )}
        </Grid>
      </InfiniteScroll>
    </div>
  );
}

export const IntegrationPanelConnectContainer = () => {
  return (
    <IntegrationsGrid
      EntityElement={({ app }) => (
        <IntegrationEntityCard app={app} connectedLabel="Connected" />
      )}
    />
  );
};
