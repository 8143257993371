import { isArray, isPlainObject } from "lodash";
import { $TSAllowedAny } from "../../types";
import { TContent } from "./customExtensions";

export function isContent(content: $TSAllowedAny): content is TContent {
  if (isArray(content)) {
    return content.some((c) => "type" in c && "content" in c);
  } else if (isPlainObject(content)) {
    return content.hasOwnProperty("type") && content.hasOwnProperty("content");
  }

  return false;
}
