import React from "react";
import { DeleteIcon } from "../../../../../../../globalTheme/icons/icons";
import useDeleteNodeButton from "./useDeleteNodeButton";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";

export function DeleteNodeButton({
  transparentBg,
  deleteNode = () => {}
}: {
  transparentBg?: boolean;
  deleteNode?: () => void;
}) {
  return (
    <div
      onClick={deleteNode}
      className={classNames(
        "asset-button border-radius-16px flex align-i-center justify-center",
        { "bg-color-transparent-important": transparentBg }
      )}
      style={{ width: 22, height: 22 }}
    >
      <DeleteIcon size={14} />
    </div>
  );
}

function DeleteNodeButtonContainer({
  transparentBg
}: {
  transparentBg?: boolean;
}) {
  const deleteNode = useDeleteNodeButton();
  return (
    <DeleteNodeButton transparentBg={transparentBg} deleteNode={deleteNode} />
  );
}

export default DeleteNodeButtonContainer;
