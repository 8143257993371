import { useDispatch } from "react-redux";
import { resetChatState } from "../components/chat/chatbox/chatSlice";
import { resetWorkspaceState } from "../state/workspaceSlice";
import { setSidebarClose } from "../../../layout/sidebar/sidebarSlice";

const useResetWorkstation = () => {
  const dispatch = useDispatch();

  const resetWorkstation = () => {
    dispatch(resetWorkspaceState());
    dispatch(resetChatState());
    dispatch(setSidebarClose());
  };

  return resetWorkstation;
};

export default useResetWorkstation;
