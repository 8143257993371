import { EntityType } from "@toolflow/shared";
import useNavigateToWorkspaceWithDefaultObject from "./useNavigateToWorkspaceWithDefaultObject";

const useNavigateToToolById = () => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject();
  const handleNavigate =
    (toolId = "") =>
    (e?: React.MouseEvent) => {
      if (!toolId) return;
      navigateToWorkspace({
        type: EntityType.TOOL,
        id: toolId
      })(e);
    };
  return handleNavigate;
};

export default useNavigateToToolById;
