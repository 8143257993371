import { createContext, useContext, useEffect, useState } from "react";
import { createFrontendClient, type BrowserClient } from "@pipedream/sdk";
import { useGenerateTokenMutation } from "../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { ENVIRONMENT } from "@toolflow/shared";
import useGetCurrentUser from "../../user/hooks/useGetCurrentUser";
import useGetIntegrations from "../hooks/useGetIntegrations";

type PipedreamContextType = {
  pdClient: BrowserClient | null;
};

const PipedreamContext = createContext<PipedreamContextType | undefined>(
  undefined
);

export function PipedreamProvider({ children }: { children: React.ReactNode }) {
  const [pdClient, setPdClient] = useState<BrowserClient | null>(null);
  const [generateToken] = useGenerateTokenMutation();
  const user = useGetCurrentUser();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: userIntegrations } = useGetIntegrations();

  useEffect(() => {
    const initializePipedreamClient = async () => {
      if (!user?._id) {
        setPdClient(null);
        return;
      }
      try {
        const tokenResponse = await generateToken().unwrap();
        const client = createFrontendClient({
          environment: ENVIRONMENT.DEVELOPMENT,
          externalUserId: user._id,
          tokenCallback: async () => ({
            token: tokenResponse.token,
            expires_at: tokenResponse.expires_at
          })
        });
        setPdClient(client);
      } catch (error) {
        console.error("Failed to initialize Pipedream client:", error);
      }
    };

    initializePipedreamClient();
  }, [generateToken, user]);

  return (
    <PipedreamContext.Provider value={{ pdClient }}>
      {children}
    </PipedreamContext.Provider>
  );
}

export const usePipedreamClient = () => {
  const context = useContext(PipedreamContext);
  if (context === undefined) {
    throw new Error(
      "usePipedreamClient must be used within a PipedreamProvider"
    );
  }
  return context;
};
