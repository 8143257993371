import { CustomToolInputFieldTypesEnum } from "@toolflow/shared";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";
import useGetCurrentToolInputFieldIndex from "./hooks/useGetCurrentToolInputFieldIndex";
import InputTypeSelect from "./InputTypeSelect";

function InputTypeSelectInDraggable({ disabled }: { disabled?: boolean }) {
  const index = useGetCurrentToolInputFieldIndex();
  const type = useGetCurrentToolInputField()
    ?.type as CustomToolInputFieldTypesEnum;
  const { changeInputType } = useToolInputFieldsContext();

  return (
    <InputTypeSelect
      disabled={disabled}
      type={type}
      onChange={(e) =>
        changeInputType(e.target.value as CustomToolInputFieldTypesEnum, index)
      }
    />
  );
}

export default InputTypeSelectInDraggable;
