import { parsedClassNames } from "../../../../../../../../../utilities/functions/parsedClassNames";
import ToolContextComponentContainer from "../../../../../../../../tools/contexts/ToolContextContainer";
import styles from "./toolListComponent.module.css";
import ToolNeedsToUpdate from "../components/ToolNeedsToUpdate";
import ToolListItem from "../components/ToolListItem";
import useUpdateToolStatus from "../hooks/useUpdateToolStatus";
import RemixButton from "../../../../../../../../../utilities/components/icons/RemixButton";
import {
  CloseIcon,
  SettingsIcon
} from "../../../../../../../../../globalTheme/icons/icons";

const cx = parsedClassNames.bind(styles);

interface InputListInnerProps {
  toolIds: string[];
  lastUpdated?: number;
  onEditClick: (toolId: string) => void;
  onDeleteClick: (toolId: string) => void;
}

export default function ToolListComponent({
  toolIds,
  lastUpdated,
  onEditClick,
  onDeleteClick
}: InputListInnerProps) {
  const { updateToolStatus } = useUpdateToolStatus();

  if (!toolIds.length) return null;

  return (
    <div className={cx("tags-list-container", "m-t-16px")}>
      {toolIds.map((toolId, idx) => {
        return (
          <div key={toolId} className={cx("tags-list-item")}>
            <ToolContextComponentContainer toolId={toolId}>
              <ToolListItem>
                <ToolNeedsToUpdate
                  lastUpdated={lastUpdated}
                  setToolsOutdatedStatus={updateToolStatus}
                  idx={idx}
                />
                <RemixButton
                  text="Configure"
                  icon={SettingsIcon}
                  onClick={() => onEditClick(toolId)}
                />
                <RemixButton
                  text="Delete"
                  icon={CloseIcon}
                  onClick={() => onDeleteClick(toolId)}
                  className={"tag-delete-button border-radius-16px"}
                />
              </ToolListItem>
            </ToolContextComponentContainer>
          </div>
        );
      })}
    </div>
  );
}
