import React, { FC } from "react";
import { NewSessionIcon } from "../../../globalTheme/icons/icons";
import { useAuthStore } from "../../auth/hooks/useAuthStore";
import useToolflowNavigate from "../../navigation/hooks/useToolflowNavigate";
import useResetWorkstation from "../../pages/workstation/hooks/useResetWorkstation";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import { toolflowTextSecondaryColor } from "../../../globalTheme/muiUtils/appTheme";

interface INewWorkspaceButtonProps {
  className?: string;
}
const NewWorkspaceButton: FC<INewWorkspaceButtonProps> = ({ className }) => {
  const { isAuthenticated } = useAuthStore();
  // get functionally from New Workstation button and navigate also
  // return null;
  const navigate = useToolflowNavigate();
  const resetWorkstation = useResetWorkstation();

  const newTool = (e: React.MouseEvent) => {
    resetWorkstation();
    navigate("/", e);
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <RemixButton
      onClick={newTool}
      icon={NewSessionIcon}
      text="New session"
      className={className}
      size={20}
      providedColor={toolflowTextSecondaryColor}
    />
  );
};

export default NewWorkspaceButton;
