import { useFilteredEntities } from "../../../../entities/hooks/useMarketplaceEntities/useFilteredMarketplaceEntities";
import { useToolTabsContext } from "../components/toolTabs/ToolTabsProvider";

export default function useGetTabEntities() {
  const { currentTab, disabledEntities } = useToolTabsContext();
  const entities = currentTab.getEntities();

  const filteredEntities = useFilteredEntities(entities, disabledEntities);
  return filteredEntities;
}
