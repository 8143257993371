import { BlockNode } from "./blockTypes";

export const BlockTypeLabelMap: Record<BlockNode, string> = {
  [BlockNode.PromptBlockNode]: "LLM query",
  [BlockNode.ToolWithinToolBlockNode]: "Embedded tool",
  [BlockNode.LogicBlockNode]: "Logic",
  [BlockNode.ConstantBlockNode]: "Text composer",
  [BlockNode.DeepgramBlockNode]: "Speech to text",
  [BlockNode.ScraperBlockNode]: "Web scraper",
  [BlockNode.SerpBlockNode]: "Google search",
  [BlockNode.StructuredBlockNode]: "Data extractor",
  [BlockNode.PerplexityBlockNode]: "Perplexity",
  [BlockNode.IterationStartBlockNode]: "Start iteration",
  [BlockNode.IterationExitBlockNode]: "Exit iteration",
  [BlockNode.DallEBlockNode]: "Image generator",
  [BlockNode.KnowledgeNode]: "Retrieve knowledge",
  [BlockNode.SavetoKnowledgeNode]: "Save to knowledge",
  [BlockNode.UserIntegrationBlockNode]: "Integrations",
  [BlockNode.YoutubeTranscriptNode]: "YouTube transcript"
};
