import { EntityType, type TMarketplaceUniversalEntity } from "@toolflow/shared";
import { FC } from "react";
import useNavigateToToolById from "../../../pages/workstation/hooks/useNavigateToToolById";
import useNavigateToWorkflowById from "../../../pages/workstation/hooks/useNavigateToWorkflowById";
import EntityCard from "./EntityCard";

interface IEntityCardProps {
  entity: TMarketplaceUniversalEntity;
}
const EntityCardContainer: FC<IEntityCardProps> = ({ entity }) => {
  const toolClick = useNavigateToToolById();
  const workflowClick = useNavigateToWorkflowById();

  return (
    <EntityCard
      entity={entity}
      onClick={
        entity.type === EntityType.TOOL
          ? toolClick(entity.id)
          : workflowClick(entity.id)
      }
    />
  );
};

export default EntityCardContainer;
