import { JSONSchemaItem } from "../jsonSchemaTypes";
import { CopyableField } from "./copyableFields";

export type CustomToolOutputFieldPartial = {
  name: string;
  type: CopyableField;
  options?: string[]; //? Not sure if this is used anywhere
  config?: ToolOutputFieldConfig;
  id?: string;
};

export type CustomToolOutputField = Omit<CustomToolOutputFieldPartial, "id"> & {
  id: string;
};

export enum OutputType {
  JsonString = "json-string",
  JsonObject = "json-object"
}

export type ToolOutputFieldConfig = {
  label?: string;
  splitOutputs?: boolean;
} & (JSONStringOutputFieldConfig | JsonObjectOutputFieldConfig);

export interface JSONStringOutputFieldConfig {
  type?: OutputType.JsonString;
  nestedOutputKey: string;
  valueType: JSONSchemaItem["type"];
}

export interface JsonObjectOutputFieldConfig {
  type?: OutputType.JsonObject;
  nestedOutputKey: string;
  valueType: JSONSchemaItem["type"];
}
