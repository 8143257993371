import AssetWrapper from "../assetWrapper/AssetWrapper";
import AssetHeader from "./AssetHeader";
import AssetTextFieldScrollWrapper from "./AssetTextFieldScrollWrapper";

export function TextAssetSizeSwitch({ className }: { className?: string }) {
  return (
    <AssetWrapper className={className} header={<AssetHeader />}>
      <AssetTextFieldScrollWrapper />
    </AssetWrapper>
  );
}
