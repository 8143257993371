import type {
  CustomToolInputField,
  TSetFormState,
  TWordPairsDict,
  UserInputDictType
} from "@toolflow/shared";
import ReplaceWordsForm from "../../../../pages/builder/toolBuilder/settings/deepgram/ReplaceWordsForm";
import FieldWrapper from "./wrapper/FieldWrapper";

export const InputDoubleTextField = (props: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  const {
    toolInputField,
    setFormState,
    formState,
    disableLabelAndInfo,
    useIds
  } = props;
  const key = useIds ? toolInputField.id : toolInputField.name;
  const handleChangeWordPairs = (wordPairsToReplace: TWordPairsDict) => {
    setFormState({ ...formState, [key]: wordPairsToReplace });
  };
  return (
    <FieldWrapper {...props}>
      <ReplaceWordsForm
        handleChange={handleChangeWordPairs}
        wordsToReplace={formState[key] as TWordPairsDict}
        disableHelperText={disableLabelAndInfo}
      />
    </FieldWrapper>
  );
};
