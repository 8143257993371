import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../stores/store";
import BlockModalWrapper from "../../../blocks/components/BlockModalWrapper";
import { getBlockConfigFE } from "../../../blocks/configs/blockConfig";
import ToolInfoContent from "../../../nodeSelector/ToolInfoContent";
import { DrawerEnum } from "../../../store/toolBuilderSlice";
import GenerateSchemaDrawer from "./generateSchema/GenerateSchemaDrawer";
import ToolInputDrawer from "./inputs/ToolInputDrawer";
import NodeInfoContent from "./nodeInfo/NodeInfoContent";
import ToolOutputDrawer from "./outputs/ToolOutputDrawer";
import useGetCurrentBlock from "../../../store/hooks/useGetCurrentBlock";

// Drawer component map
const drawerComponents: Record<DrawerEnum, ReactNode> = {
  [DrawerEnum.toolInfo]: <ToolInfoContent />,
  [DrawerEnum.inputs]: <ToolInputDrawer />,
  [DrawerEnum.outputs]: <ToolOutputDrawer />,
  [DrawerEnum.node]: <NodeInfoContent />,
  [DrawerEnum.generateSchema]: <GenerateSchemaDrawer />
};

const DrawerContent = () => {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  const currentBlock = useGetCurrentBlock();

  if (!drawer) return null;

  if (drawer.type === DrawerEnum.node && currentBlock) {
    const blockConfig = getBlockConfigFE(currentBlock.data.type);
    if (blockConfig.modalComponent) {
      return (
        <>
          {drawerComponents[drawer.type]}
          <BlockModalWrapper
            nodeId={drawer.nodeId}
            blockType={currentBlock.data.type}
            ModalComponent={blockConfig.modalComponent}
          />
        </>
      );
    }
  }

  return drawerComponents[drawer.type];
};

export default DrawerContent;
