import { ThemeProvider } from "@mui/material";
import SendMessageOnDirtyMonitor from "../monitors/SendMessageOnDirtyMonitor";
import WorkspaceFormProvider from "../../state/components/WorkspaceFormProvider";
import workspaceTheme from "../../theme/workspaceTheme";
import WorkspaceHeader from "../workspaceHeader/WorkspaceHeader";

import WorkspaceMainContent from "./WorkspaceMainContent";
import AuthenticationSplitter from "../../../../auth/AuthenticationSplitter";
import { LoggedOutGroup } from "../../../external/LoggedOut";
import SavedWorkstationDrawer from "../SavedWorkstationDrawer";
import WorkspaceAgentContextContainer from "../../../../agents/contexts/WorkspaceAgentContextContainer";
import { RootState } from "../../../../../stores/store";
import { useSelector } from "react-redux";

const Workspace = () => {
  const { hasSetInitialObject } = useSelector(
    (state: RootState) => state.workspace
  );
  return (
    <>
      <AuthenticationSplitter
        authenticationOverride={hasSetInitialObject}
        authenticatedComponent={
          <>
            <WorkspaceHeader />
            <WorkspaceMainContent>
              <SavedWorkstationDrawer />
            </WorkspaceMainContent>
          </>
        }
        externalComponent={
          <div className="w-100-percent justify-center align-i-center flex h-100vh flex-column">
            <LoggedOutGroup />
          </div>
        }
      />
    </>
  );
};

function NewWorkspacePage() {
  return (
    <ThemeProvider theme={workspaceTheme}>
      <WorkspaceFormProvider>
        <SendMessageOnDirtyMonitor>
          <WorkspaceAgentContextContainer>
            <Workspace />
          </WorkspaceAgentContextContainer>
        </SendMessageOnDirtyMonitor>
      </WorkspaceFormProvider>
    </ThemeProvider>
  );
}

export default NewWorkspacePage;
