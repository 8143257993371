import React, { FC } from "react";
import SettingsDropdownMenuButton from "../../../utilities/components/dropdowns/generic/SettingsDropdownMenuButton";
import UpdateWorkspaceVisibilityMenuItem from "../../pages/workstation/components/menuItems/UpdateWorkspaceVisibilityMenuItem";
import DeleteMenuItem from "../../../utilities/components/dropdowns/menuItems/deleteMenuItem/DeleteMenuItem";
import { Divider } from "@mui/material";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import RenameHomePageEntityMenuItem from "../../pages/home/menuItems/RenameHomePageEntityMenuItem";
import DuplicateHomePageEntityMenuItem from "../../pages/builder/toolBuilder/dropdowns/menuItems/DuplicateHomePageEntityMenuItem";
import TogglePopularObjectMenuItem from "../../pages/home/menuItems/TogglePopularObjectMenuItem";
import ToggleMarketplaceObjectMenuItem from "../../pages/home/menuItems/ToggleMarketplaceObjectMenuItem";
import { VerticalMoreIcon } from "../../../globalTheme/icons/icons";
import { useGetCurrentUserProfileId } from "../../user/hooks/useGetCurrentUserProfileId";
import { AGENT, type TMarketplaceUniversalEntity } from "@toolflow/shared";
import CopyLinkMenuItem from "../../../utilities/components/dropdowns/menuItems/CopyLinkMenuItem";

interface IHomePageMenuEntityDropdownMenuProps {
  homPageEntity: TMarketplaceUniversalEntity;
}
const HomePageMenuEntityDropdownMenu: FC<
  IHomePageMenuEntityDropdownMenuProps
> = ({ homPageEntity }) => {
  const ownMenuItems = [
    <CopyLinkMenuItem
      key={"copy-link"}
      objectId={homPageEntity.id}
      type={homPageEntity.type}
      text={"Copy Link"}
    />,
    <RenameHomePageEntityMenuItem
      key={"rename-entity"}
      entityId={homPageEntity.id}
      entityName={homPageEntity.name}
      entityType={homPageEntity.type}
    />,
    homPageEntity.type !== AGENT && (
      <DuplicateHomePageEntityMenuItem
        key={"duplicate-entity"}
        id={homPageEntity.id}
        type={homPageEntity.type}
      />
    ),
    // <UpdateWorkspaceVisibilityMenuItem
    //   visibility={homPageEntity.visibility}
    //   id={homPageEntity.id}
    //   name={homPageEntity.name}
    //   key="updateVisibility"
    // />,
    <TogglePopularObjectMenuItem
      key="makePopular"
      type={homPageEntity.type}
      objectId={homPageEntity.id}
    />,
    <ToggleMarketplaceObjectMenuItem
      key="feature"
      type={homPageEntity.type}
      objectId={homPageEntity.id}
    />,
    <Divider key={"divider"} />,
    <DeleteMenuItem
      id={homPageEntity.id}
      name={homPageEntity.name}
      setDisableCard={() => {}}
      type={homPageEntity.type}
      key="deleteMenuItem"
    />
  ].filter(Boolean);

  const externalMenuItems = [
    <UpdateWorkspaceVisibilityMenuItem
      visibility={homPageEntity.visibility}
      id={homPageEntity.id}
      name={homPageEntity.name}
      key="updateVisibility"
    />
  ];

  const profileId = useGetCurrentUserProfileId();

  return (
    <SettingsDropdownMenuButton
      menuItems={
        homPageEntity.userId === profileId ? ownMenuItems : externalMenuItems
      }
      icon={<VerticalMoreIcon size={16} color={toolflowTextDisabledColor} />}
    />
  );
};

export default HomePageMenuEntityDropdownMenu;
