import { Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ClickableChip from "../../../../../../../../utilities/components/chips/ClickableChip";
import type { ClickableActionTypes, ToolAutocomplete } from "@toolflow/shared";
import { ClickableTypes } from "@toolflow/shared";

import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import styles from "./chipGroup.module.css";
import { toolflowTextDisabledColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";

const cx = parsedClassNames.bind(styles);

export const ChipGroup = ({
  name,
  entities = [],
  type,
  onClickChip,
  action,
  className = "",
  eventName
}: {
  name: string;
  entities?: ToolAutocomplete[];
  type: ClickableTypes;
  onClickChip: (type: ClickableTypes, id: string) => void;
  action?: React.ReactNode;
  className?: string;
  eventName?: ClickableActionTypes;
}) => {
  useEffect(() => {
    const handleClickChip = (event: CustomEvent<{ id: string }>) => {
      if (event.detail && event.detail.id) {
        onClickChip(type, event.detail.id);
      }
    };

    if (eventName) {
      window.addEventListener(eventName, handleClickChip as EventListener);
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (eventName) {
        window.removeEventListener(eventName, handleClickChip as EventListener);
      }
    };
  }, []);

  return (
    <>
      <div
        className={cx(
          styles["height-35px"],
          "flex align-i-center justify-space-between",
          className
        )}
      >
        <Typography variant="subtitle2" className="capitalize">
          {name}
        </Typography>
        {action}
      </div>
      <Divider />
      {entities.length === 0 ? (
        <Typography
          variant="body2"
          className="p-t-8px p-b-8px"
          color={toolflowTextDisabledColor}
        >
          No {name.toLowerCase()} found
        </Typography>
      ) : (
        entities.map((entity) => (
          <ClickableChip
            key={entity.id}
            text={entity.primary}
            type={type}
            onClick={onClickChip}
            id={entity.id}
            tooltipText={entity.secondary}
          />
        ))
      )}
    </>
  );
};
