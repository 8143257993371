import { SelectOption } from "../fields";
import { CustomToolInputFieldBase } from "./base";

export enum CustomToolInputFieldTypesEnum {
  TEXTFIELD = "textField",
  NUMERIC_FIELD = "numericField",
  LARGE_TEXTFIELD = "largeTextField",
  SELECT = "select",
  CHECKBOX = "checkbox",
  FILE_UPLOAD = "fileUpload",
  MULTI_SELECT = "multiSelect",
  TAGS_INPUT = "tagsInput",
  DOUBLE_TEXTFIELD = "doubleTextfield",
  CSV_FILE_UPLOAD = "csvFileUpload",
  SLIDER = "slider",
  JSON_SCHEMA = "jsonSchema",
  WORD_REPLACE = "wordReplace",
  BLOCK_OUTPUT = "blockOutput",
  PIPEDREAM_ACTION = "pipedreamAction",
  PIPEDREAM_FIELDS = "pipedreamField"
}

type CustomToolInputFieldTypesEnumWithoutSpecialProps = Exclude<
  CustomToolInputFieldTypesEnum,
  CustomToolInputFieldWithTags["type"] | CustomToolInputFieldWithOptions["type"]
>;

// For any fields that have no special properties
export interface CustomToolInputFieldWithoutSpecialProps
  extends CustomToolInputFieldBase {
  type: CustomToolInputFieldTypesEnumWithoutSpecialProps;
}

// For any fields that have options
export interface CustomToolInputFieldWithOptions
  extends CustomToolInputFieldBase {
  type:
    | CustomToolInputFieldTypesEnum.MULTI_SELECT
    | CustomToolInputFieldTypesEnum.SELECT;
  options: SelectOption[];
}

export interface CustomToolInputFieldWithTags extends CustomToolInputFieldBase {
  type: CustomToolInputFieldTypesEnum.TAGS_INPUT;
  settings: {
    tags: string[];
  };
}

export type CustomToolInputField =
  | CustomToolInputFieldWithoutSpecialProps
  | CustomToolInputFieldWithOptions
  | CustomToolInputFieldWithTags;
