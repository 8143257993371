import { createTheme } from "@mui/material";
import utilityTheme from "../../../../globalTheme/muiUtils/utilityTheme";

const profilePageTheme = createTheme(utilityTheme, {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: "center",
          marginRight: 0
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "16px"
        }
      },
      variants: [
        {
          props: { size: "large" },
          style: {
            borderRadius: "16px",
            padding: "8px 22px"
          }
        },

        {
          props: { size: "small" },
          style: {
            padding: "4px 10px"
          }
        },
        {
          props: { variant: "text" },
          style: {
            backgroundColor: "rgba(138, 45, 255, 0.04)"
          }
        },
        {
          props: { variant: "text", disabled: true },
          style: {
            backgroundColor: "transparent",
            color: "#000",
            "&.Mui-disabled": {
              color: "#000"
            }
          }
        }
      ]
    }
  }
});

export default profilePageTheme;
