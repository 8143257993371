import { Typography } from "@mui/material";
import type { BlockConfigFE, TBlock } from "@toolflow/shared";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BlockContextProvider } from "../../../../BlockContext";
import TestDrawer from "../../../../blocks/components/testDrawer/TestDrawer";
import { getBlockConfigFE } from "../../../../blocks/configs/blockConfig";
import useGetCurrentBlock from "../../../../store/hooks/useGetCurrentBlock";
import { setDrawerExpandedOpen } from "../../../../store/toolBuilderSlice";
import isRunnableBlock from "../../../functions/isRunnableBlock";
import { DrawerHeader } from "../../drawerHeader/DrawerHeaderContainer";
import ExpandDrawerDialogButton from "../expandDrawerDialog/ExpandDrawerDialogButton";
import getBlockHeaderIcon from "../../../../blocks/configs/getters/getBlockHeaderIcon";

const useNodeExpandedContent = () => {
  const currentBlock = useGetCurrentBlock();
  const BlockIcon = getBlockHeaderIcon(currentBlock?.data);

  return {
    currentBlock: currentBlock,
    BlockIcon
  };
};

const useSetDrawerClosedIfNotExpandable = ({
  config,
  currentBlock
}: {
  config: BlockConfigFE;
  currentBlock: TBlock | null;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRunnableBlock(currentBlock) && config.nonTestable) {
      dispatch(setDrawerExpandedOpen(false));
    }
  }, [currentBlock]);
};

const NodeExpandedContent = () => {
  const { currentBlock } = useNodeExpandedContent();
  const config = getBlockConfigFE(
    currentBlock?.data.type,
    currentBlock?.data.version
  );

  useSetDrawerClosedIfNotExpandable({ currentBlock, config });

  if (!isRunnableBlock(currentBlock) || isEmpty(config) || config.nonTestable) {
    return <></>;
  }

  return (
    <BlockContextProvider
      id={currentBlock.id}
      data={currentBlock.data}
      selected={true}
    >
      <DrawerHeader
        title={<Typography variant="h8">Test panel</Typography>}
        rightSlot={<ExpandDrawerDialogButton />}
      />
      <TestDrawer
        id={currentBlock.id}
        data={currentBlock.data}
        config={config}
      />
    </BlockContextProvider>
  );
};

export default NodeExpandedContent;
