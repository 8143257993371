import {
  FieldType,
  VERSION_1_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";
import { filterAvailableFieldsByArray } from "../../helpers";

export const iterationStartBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.DynamicListField,
      label: "Choose list",
      subtitle:
        "The Start Iteration node begins a loop over a selected list. Choose the list to iterate through from the dropdown. Subsequent connected nodes will process each item in this list until encountering the Exit Iteration node.",
      config: {
        typeKey: "settings.fieldType",
        fieldKey: "settings.input",
        filterAvailableFields: filterAvailableFieldsByArray
      }
    }
  ]
};
