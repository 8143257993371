import { useEffect } from "react";
import { useToolContext } from "../../../../../../../../tools/contexts/ToolContext";
import { Typography } from "@mui/material";

const ToolNeedsToUpdate = ({
  lastUpdated,
  setToolsOutdatedStatus,
  idx
}: {
  lastUpdated?: number;
  setToolsOutdatedStatus: (idx: number, isOutdated: boolean) => void;
  idx: number;
}) => {
  const { tool } = useToolContext();
  const needsToUpdate = lastUpdated && tool.main.updatedAt > lastUpdated;

  useEffect(() => {
    setToolsOutdatedStatus(idx, !!needsToUpdate);
  }, [needsToUpdate, idx]);

  if (!needsToUpdate) return null;
  return (
    <Typography variant="caption" color="GrayText">
      Tool has been updated, please save
    </Typography>
  );
};

export default ToolNeedsToUpdate;
