import type { TTransformedMessage } from "@toolflow/shared";
import { isString } from "lodash";

export function getFileNamesFromThreadMessage(
  threadMessage: TTransformedMessage
) {
  if (!!threadMessage.files?.length) {
    return threadMessage.files?.map((file) => file.fileName);
  }

  const regex = /(?<=<FileName>)(.*?)(?=<\/FileName>)/g;
  return threadMessage.message?.match(regex) || [];
}

export function removeContextFromThreadMessage(
  threadMessage: TTransformedMessage
) {
  if (isString(threadMessage.content)) {
    return threadMessage.content.replace(
      /<hiddenContext>.*?<\/hiddenContext>/s,
      ""
    );
  }

  return threadMessage.message.replace(
    /<hiddenContext>.*?<\/hiddenContext>/s,
    ""
  );
}
