import { Button } from "@mui/material";
import useNavigateToWorkflowById from "../../workstation/hooks/useNavigateToWorkflowById";

const NavigateToWorkflowButton = ({
  workflowId,
  className = ""
}: {
  workflowId: string;
  className?: string;
}) => {
  const handleNavigate = useNavigateToWorkflowById();
  return (
    <Button
      variant="outlined"
      onClick={handleNavigate(workflowId)}
      className={className}
      disabled={!workflowId}
    >
      Open
    </Button>
  );
};

export default NavigateToWorkflowButton;
