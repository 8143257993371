import { FormControl } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicSwitchFieldType
} from "@toolflow/shared";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { Switch } from "../../../../builderDrawer/components/drawerContent/outputs/outputFields/Switch";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import useDynamicSetting from "../../hooks/useDynamicSetting";

export default function DynamicSwitchField() {
  const { field, id, getFieldValue, updateField } =
    useFieldsByTypeContext<DynamicSwitchFieldType>();

  const {
    fieldKey,
    typeKey,
    switchLabel,
    defaultValue = false,
    filterAvailableFields
  } = field.config;

  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = getFieldValue(fieldKey, defaultValue);
  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const disabled = useDynamicSetting<boolean>(field.disabled);

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={field.config}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        addInputType={CustomToolInputFieldTypesEnum.CHECKBOX}
        onTypeChangeCallback={(value) => {
          if (value === DynamicFieldType.Dynamic) {
            updateField(CREATE_USER_INPUT_LABEL, fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <div className="nowheel nodrag nopan">
          <Switch
            disabled={disabled}
            checked={fieldValue}
            label={switchLabel || ""}
            onChange={(value) => updateField(value, fieldKey)}
          />
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
