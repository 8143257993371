import type { PipedreamApp } from "@toolflow/shared";
import { useDisconnectIntegrationMutation } from "../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import useGetIntegrations from "./useGetIntegrations";

export default function useDisconnectIntegration() {
  const [disconnectIntegration] = useDisconnectIntegrationMutation();
  const { data: userIntegrations } = useGetIntegrations();

  return async (app: PipedreamApp) => {
    const integration = userIntegrations?.integrations.find(
      (int) => int.integrationType === app.name_slug
    );

    if (!integration) return false;

    try {
      await disconnectIntegration(app.name_slug).unwrap();
      return true;
    } catch (error) {
      console.error("Error disconnecting integration:", error);
      return false;
    }
  };
}
