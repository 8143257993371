import { useEffect } from "react";
import useGetCurrentUser from "../../features/user/hooks/useGetCurrentUser";
import { useSubscriptionDetails } from "../../features/billing/hooks/useSubscriptionDetails";

const useUpdateIntercomOnPageChange = () => {
  const user = useGetCurrentUser();
  const { activeTier } = useSubscriptionDetails();

  // we need to update Intercom every time the page changes
  // if no user data changes, then send an updated last request at
  useEffect(() => {
    window.Intercom("update", {
      api_base: process.env.REACT_APP_INTERCOM_API_BASE,
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      last_request_at: new Date().getTime() / 1000,
      email: user?.email ?? undefined,
      plan_type: activeTier ?? undefined
    });
  }, [location.pathname, user?._id, activeTier]);
};

export default useUpdateIntercomOnPageChange;
