import { Button } from "@mui/material";
import {
  SearchEntitiesDialog,
  SearchEntitiesDialogType
} from "../../../../../../../../tools/components/searchEntitiesDialog/SearchEntitiesDialog";
import { SearchIcon } from "../../../../../../../../../globalTheme/icons/icons";

export default function SearchToolsButton({
  updateTool
}: {
  updateTool(clickId: string): void;
}) {
  return (
    <div className="flex flex-column m-16px">
      <SearchEntitiesDialog
        onClickEntity={updateTool}
        type={SearchEntitiesDialogType.TOOLS}
        disabledEntities={{ workflows: true, agents: true }}
        title="Find tool"
        button={
          <Button
            variant="contained"
            size="medium"
            className="m-v-16px"
            startIcon={<SearchIcon size={20} />}
          >
            Select tool
          </Button>
        }
      />
    </div>
  );
}
