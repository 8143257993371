import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import {
  BlockType,
  getInitialState,
  type JSONArraySchema,
  type JSONEnumSchema,
  type JSONObjectSchema,
  type JSONSchemaItem
} from "@toolflow/shared";
import { Fragment, useMemo } from "react";
import { DeleteIcon } from "../../../../../../globalTheme/icons/icons";
import TextFieldWithLabel from "../components/TextFieldWithLabel";
import ArrayTypeSchemaFields from "./ArrayTypeSchemaFields";
import EnumSchemaFields from "./EnumSchemaFields";
import MainSchemaFields from "./MainSchemaFields";

export default function ObjectTypeSchemaFields({
  schemaItem,
  onChange,
  updateFieldNameInTOF
}: {
  schemaItem: JSONObjectSchema;
  onChange(items: JSONSchemaItem[]): void;
  updateFieldNameInTOF?(prevValue: string, newValue: string): void;
}) {
  const { fieldLabel, items } = schemaItem;
  const clonedItems: JSONSchemaItem[] = useMemo(() => {
    return items ? JSON.parse(JSON.stringify(items)) : [];
  }, [items]);
  const initialState = getInitialState(BlockType.Structured);

  return (
    <Fragment>
      {clonedItems.map((schema, index) => (
        <Grid key={index} item xs={12}>
          <Stack direction="row" spacing={1}>
            <div style={{ marginTop: 32 }}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 24,
                  height: 24,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "100%"
                })}
              >
                {index + 1}
              </Box>
            </div>

            <Stack spacing={2}>
              <Grid item xs={12}>
                <MainSchemaFields
                  schemaItem={schema}
                  skipTypes={["object"]}
                  updateFieldNameInTOF={(p, n) =>
                    updateFieldNameInTOF?.(
                      `${fieldLabel}.${p}`,
                      `${fieldLabel}.${n}`
                    )
                  }
                  onChange={(item) => {
                    clonedItems[index] = item;
                    onChange(clonedItems);
                  }}
                />
              </Grid>

              {schema.type !== "array" && (
                <Grid item xs={12}>
                  <TextFieldWithLabel
                    fullWidth
                    size="small"
                    label="Extraction guidelines for AI"
                    variant="outlined"
                    value={schema.guideline}
                    helperText="Give instructions to AI to extract the data"
                    onChange={(event) => {
                      clonedItems[index].guideline = event.target.value;
                      onChange(clonedItems);
                    }}
                  />
                </Grid>
              )}

              {schema.type === "array" && (
                <ArrayTypeSchemaFields
                  schemaItem={schema}
                  skipTypes={["object"]}
                  onChange={(item) => {
                    (clonedItems[index] as JSONArraySchema) = item;
                    onChange(clonedItems);
                  }}
                />
              )}

              {schema.type === "enum" && (
                <EnumSchemaFields
                  enums={schema.enums}
                  onChange={(enums) => {
                    (clonedItems[index] as JSONEnumSchema).enums = enums;
                    onChange(clonedItems);
                  }}
                />
              )}
            </Stack>

            <div style={{ marginTop: 32 }}>
              <IconButton
                aria-label="delete"
                size="small"
                style={{ borderRadius: 6 }}
                onClick={() => {
                  const filteredItems = clonedItems.filter(
                    (_, i) => i !== index
                  );
                  onChange(filteredItems);
                }}
              >
                <DeleteIcon color="#12172961" size={14} />
              </IconButton>
            </div>
          </Stack>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button
          variant="action"
          sx={(theme) => ({ color: theme.palette.primary.main })}
          onClick={() => {
            clonedItems.push({ ...(initialState.settings.schema[0] || {}) });
            onChange(clonedItems);
          }}
        >
          + Add nested field
        </Button>
      </Grid>
    </Fragment>
  );
}
