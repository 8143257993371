import { type SelectChangeEvent } from "@mui/material";
import {
  BlockType,
  CustomToolInputFieldTypesEnum,
  getInitialState,
  type JSONSchemaItem,
  type JSONSchemaItemType
} from "@toolflow/shared";
import { useMemo } from "react";
import SelectWithLabel from "../components/SelectWithLabel";
import iconDict from "../../../../../../utilities/constants/iconDict";

const spaceBetweenIconsAndText = "\u00A0\u00A0";

export default function SchemaTypeField({
  skip,
  schemaItem,
  onChange
}: {
  skip?: JSONSchemaItemType[];
  schemaItem: JSONSchemaItem;
  onChange(schemaItem: JSONSchemaItem): void;
}) {
  const typeOptions = useMemo(() => {
    const options: { label: string; value: JSONSchemaItemType }[] = [
      {
        label: `${iconDict[CustomToolInputFieldTypesEnum.TEXTFIELD]}${spaceBetweenIconsAndText}Text`,
        value: "string"
      },
      {
        label: `${iconDict[CustomToolInputFieldTypesEnum.NUMERIC_FIELD]}${spaceBetweenIconsAndText}Whole number`,
        value: "number"
      },
      {
        label: `${iconDict[CustomToolInputFieldTypesEnum.NUMERIC_FIELD]}${spaceBetweenIconsAndText}Number with decimals`,
        value: "integer"
      },
      {
        label: `${iconDict[CustomToolInputFieldTypesEnum.CHECKBOX]}${spaceBetweenIconsAndText}True/False`,
        value: "boolean"
      },
      {
        label: `${iconDict[CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]}${spaceBetweenIconsAndText}Group with fields`,
        value: "object"
      },
      {
        label: `${iconDict[CustomToolInputFieldTypesEnum.TAGS_INPUT]}${spaceBetweenIconsAndText}List`,
        value: "array"
      },
      {
        label: `${iconDict[CustomToolInputFieldTypesEnum.SELECT]}${spaceBetweenIconsAndText}Preset Options`,
        value: "enum"
      }
    ];

    if (skip) {
      return options.filter((option) => !skip.includes(option.value));
    }

    return options;
  }, [skip]);

  function handleOnChangeCB(event: SelectChangeEvent<unknown>) {
    const type = event.target.value as JSONSchemaItemType;
    const initialState = getInitialState(BlockType.Structured);
    const initialStateJsonSchema = initialState.settings.schema[0] || {};
    const item: Partial<JSONSchemaItem> = {
      fieldLabel: schemaItem.fieldLabel,
      guideline: schemaItem.guideline,
      type
    };

    if (item.type === "array") {
      item.itemType = { ...initialStateJsonSchema };
    }
    if (item.type === "object") {
      item.items = [{ ...initialStateJsonSchema }];
    }
    if (item.type === "enum") {
      item.enums = [""];
    }

    onChange(item as JSONSchemaItem);
  }

  return (
    <SelectWithLabel
      fullWidth
      size="small"
      label="Field Type"
      value={schemaItem.type}
      options={typeOptions}
      onChange={handleOnChangeCB}
    />
  );
}
