import { Box, Typography } from "@mui/material";
import { type StaticTextFieldType } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useDynamicSetting from "../../hooks/useDynamicSetting";

export default function StaticText() {
  const { field } = useFieldsByTypeContext<StaticTextFieldType>();
  const label = useDynamicSetting<string>(field.label);
  const subtitle = useDynamicSetting<string>(field.subtitle);

  return (
    <Box sx={{ textAlign: field.config.textAlign }}>
      {label && <Typography variant="h6">{label}</Typography>}
      {subtitle && <Typography variant="subtitleMedium">{subtitle}</Typography>}
    </Box>
  );
}
