import { get, isFunction } from "lodash";
import {
  $TSAllowedAny,
  AnthropicModel,
  BlockDataBase,
  BlockSettingsBase,
  BlockType,
  CustomToolInputFieldTypesEnum,
  DynamicSetting,
  FieldsConfig,
  FieldType,
  FieldTypeConfigs,
  TextPromptModels,
  ValidatedInput,
  type PromptBlockDataV1
} from "../../../../types";
import { isEnumValue } from "../../../helpers";
import {
  evaluateCondition,
  evaluateConditionRule,
  isConditionRule,
  isEvalCondition,
  isEvalConditionArray
} from "./conditionsHelpers";

export function getMarksMax(currentModel?: TextPromptModels) {
  return isEnumValue(currentModel, AnthropicModel) ? 50 : undefined;
}

export function isAnthropicBlock(data: PromptBlockDataV1) {
  return data.type == BlockType.Anthropic;
}

export function filterAvailableFieldsByArray(fields: ValidatedInput[]) {
  return fields.filter((field) => {
    return (
      (field.config?.valueType === "array" ||
        field.type === CustomToolInputFieldTypesEnum.TAGS_INPUT) &&
      !field.config?.nestedOutputKey?.includes("[]")
    );
  });
}

export function filterAvailableFieldsByFile(fields: ValidatedInput[]) {
  return fields.filter((field) => {
    return field.type !== CustomToolInputFieldTypesEnum.FILE_UPLOAD;
  });
}

export function isDynamicSetting<T = $TSAllowedAny>(
  setting: $TSAllowedAny
): setting is DynamicSetting<T> {
  return (
    isEvalCondition(setting) ||
    isEvalConditionArray(setting) ||
    isConditionRule(setting)
  );
}

export function evaluateDynamicSetting<T = $TSAllowedAny>(
  dynamicSetting: DynamicSetting,
  data: BlockSettingsBase | BlockDataBase,
  defaultValue?: T
): T {
  if (isEvalCondition(dynamicSetting)) {
    return evaluateCondition(dynamicSetting, data);
  }

  if (isEvalConditionArray(dynamicSetting)) {
    return dynamicSetting.every((condition) =>
      evaluateCondition(condition, data)
    ) as T;
  }

  if (isConditionRule(dynamicSetting)) {
    return evaluateConditionRule(dynamicSetting, data);
  }

  if (isFunction(dynamicSetting)) {
    return dynamicSetting(data);
  }

  return dynamicSetting || defaultValue;
}

export function getFieldValues(fields: FieldsConfig, data: BlockDataBase) {
  function getFieldKey(field: FieldTypeConfigs): string | null {
    return get(field, "config.fieldKey", null);
  }

  return fields.reduce((acc, field) => {
    if (field.type !== FieldType.Group) {
      const key = getFieldKey(field);
      if (key) {
        acc.push(get(data, key, null));
      }
    } else {
      field.fields.forEach((f) => {
        const key2 = getFieldKey(f);
        if (key2) {
          acc.push(get(data, key2, null));
        }
      });
    }

    return acc;
  }, [] as $TSAllowedAny[]);
}
