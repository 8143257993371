import {
  CustomToolInputFieldTypesEnum,
  type ValidatedInput
} from "@toolflow/shared";

const getFilteredFields = (toolInputFields: ValidatedInput[]) => {
  return toolInputFields.filter(
    (i) =>
      ![
        CustomToolInputFieldTypesEnum.FILE_UPLOAD,
        CustomToolInputFieldTypesEnum.CHECKBOX
      ].includes(i.type)
  );
};

export default getFilteredFields;
