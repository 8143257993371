import { useEffect } from "react";
import {
  DEFAULT_USER_NAME,
  type TAvailableTiers,
  type TUser
} from "@toolflow/shared";

const useIntercom = (
  user: TUser | null,
  isAuthenticated: boolean,
  activeTier: TAvailableTiers
) => {
  const doNotLoadIntercom =
    !window.Intercom ||
    // process.env.environment === "development" ||
    !user?.email;
  useEffect(() => {
    if (doNotLoadIntercom) return; // Ensure Intercom is loaded
    try {
      if (isAuthenticated) {
        window.Intercom("boot", {
          api_base: process.env.REACT_APP_INTERCOM_API_BASE,
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          name: user?.name === DEFAULT_USER_NAME ? "" : user?.name || "", // Full name
          email: user?.email || "", // Email address
          created_at: user?.createdAt, // Signup date as a Unix timestamp
          plan_type: activeTier,
          company_name: user?.company?.name,
          employee_count: user?.company?.employee_count,
          telephone: user?.phoneNumber,
          employee_function: user?.employeeFunction,
          employee_responsibility: user?.employeeResponsibility,
          linkedin_url: user?.auth0Metadata?.linkedin_url,
          use_case: user?.auth0Metadata?.use_case
        });
      } else {
        window.Intercom("boot", {
          api_base: process.env.REACT_APP_INTERCOM_API_BASE,
          app_id: process.env.REACT_APP_INTERCOM_APP_ID
        });
      }
    } catch {
      console.log("Intercom not loaded");
    }
  }, [user?._id, isAuthenticated, user?.name, user?.email]);
};

export default useIntercom;
