import type { TToolDetails } from "@toolflow/shared";
import { useGetThreadRunStepsByIdQuery } from "../../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import ToolContextComponentContainer from "../../../../../tools/contexts/ToolContextContainer";
import useGetCurrentChatThreadId from "./hooks/useGetCurrentChatThreadId";
import ToolDetails from "./ToolDetails";
import { useEffect, useState } from "react";
import LoadingToolDetails from "./LoadingToolDetails";
import useIsMessageLoading from "./SendThreadTextField/hooks/useIsMessageLoading";
import ToolRunByUser from "./ToolRunByUser";
import { ToolRunLabel } from "../../../../../tools/components/ToolRunLabel";
import { useThreadMessageContext } from "../../../contexts/ThreadMessageContext";
import { Stack } from "@mui/material";

const ToolRunDetails = ({ runId }: { runId?: string | null }) => {
  const currentThreadId = useGetCurrentChatThreadId();
  const isLoading = useIsMessageLoading();
  const { data } = useGetThreadRunStepsByIdQuery(
    {
      threadId: currentThreadId,
      runId: runId || ""
    },
    {
      skip: !runId || !currentThreadId || isLoading,
      refetchOnMountOrArgChange: true
    }
  );

  if (!data || !data.result || !data.success) return null;

  const runSteps = data.result.runSteps;

  if (runSteps.length === 0) return null;

  return (
    <Stack direction="row" alignItems="center" sx={{ flexWrap: "wrap" }}>
      {runSteps.length > 0 && (
        <ToolRunLabel title="Agent ran:" className="m-t-8px m-l-16px" />
      )}
      {runSteps.map((step, idx) => (
        <ToolContextComponentContainer
          toolId={step.toolId}
          key={`${step.toolId}-${idx}`}
        >
          <ToolDetails
            value={{ inputs: step.inputs, outputs: step.outputs }}
            className="m-t-8px m-l-8px"
          />
        </ToolContextComponentContainer>
      ))}
    </Stack>
  );
};

const ToolRunByAgentSplitter = ({
  runId,
  toolDetails
}: {
  runId?: string | null;
  toolDetails?: TToolDetails[];
}) => {
  if (runId) {
    return <ToolRunDetails runId={runId} />;
  }

  return (
    <>
      {toolDetails && toolDetails.length > 0 && (
        <ToolRunLabel title="Agent ran:" className="m-t-8px m-l-16px" />
      )}
      {toolDetails?.map((toolDetail) => (
        <ToolContextComponentContainer
          toolId={toolDetail.toolId}
          key={toolDetail.toolId}
        >
          <ToolDetails
            value={{ inputs: toolDetail.inputs, outputs: toolDetail.outputs }}
            className="m-t-8px m-l-8px"
          />
        </ToolContextComponentContainer>
      ))}
    </>
  );
};

const StepDetails = () => {
  const { threadMessage } = useThreadMessageContext();
  const { settings } = threadMessage;
  const [toolCallsStatus, setToolCallsStatus] = useState<string[] | null>(
    settings?.toolCallsStatus || null
  );

  useEffect(() => {
    if (settings?.toolCallsStatus) {
      setToolCallsStatus((prev) => [
        ...(prev || []),
        ...(settings?.toolCallsStatus || [])
      ]);
    } else {
      setToolCallsStatus(null);
    }
  }, [settings?.toolCallsStatus]);

  if (toolCallsStatus) {
    return (
      <>
        {Object.values(toolCallsStatus).map((toolId, idx) => (
          <ToolContextComponentContainer toolId={toolId} key={idx}>
            <LoadingToolDetails />
          </ToolContextComponentContainer>
        ))}
      </>
    );
  }

  return (
    <>
      <ToolRunByAgentSplitter
        runId={settings?.runId} // when run has been completed, we can use this route (like when coming back to a thread)
        toolDetails={settings?.toolDetails} // when run has not been completed, we can use this route (like when sending a message)
      />
      <ToolRunByUser tvrId={settings?.tvrId} />
    </>
  );
};

export default StepDetails;
