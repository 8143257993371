import { type GetComponentsResponse } from "@toolflow/shared";
import { useEffect } from "react";
import { useGetIntegrationActionsMutation } from "../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";

export default function useIntegrationActionsOptions(
  userIntegrationType?: string
) {
  const [getIntegrationActions, { data: componentsData, isLoading }] =
    useGetIntegrationActionsMutation();

  useEffect(() => {
    if (userIntegrationType) {
      getIntegrationActions({ userIntegrationType }).unwrap();
    }
  }, [getIntegrationActions, userIntegrationType]);

  return {
    options:
      componentsData?.data?.map(
        (component: GetComponentsResponse["data"][number]) => ({
          label: component.name,
          value: component.key
        })
      ) || [],
    isLoading
  };
}
