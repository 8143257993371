import type {
  DeepgramBlockDataV1,
  DeepgramSettingsFieldType
} from "@toolflow/shared";
import DeepgramSettings from "../../../settings/deepgram/DeepgramSettings";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";

export default function DeepgramSettingsFields() {
  const { id, data } = useFieldsByTypeContext<
    DeepgramSettingsFieldType,
    DeepgramBlockDataV1
  >();

  const { settings } = data;

  return <DeepgramSettings id={id} settings={settings} />;
}
