import { IconButton, Typography, Stack } from "@mui/material";

import { ReactNode } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../../../globalTheme/icons/icons";

export function PipedreamAccordion(props: {
  expanded: boolean;
  onToggle: () => void;
  children: ReactNode;
  title: string;
}) {
  const { expanded, onToggle, children, title } = props;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="m-b-8px p-b-8px"
        sx={{ borderBottom: "1px solid #E0E0E0" }}
      >
        <Typography variant="h8" className="m-r-8px capitalize">
          {title}
        </Typography>
        <IconButton
          onClick={onToggle}
          data-expanded={expanded}
          className="transition-rotate"
        >
          <ArrowDownSmallIcon />
        </IconButton>
      </Stack>
      <Stack spacing={3} className="transition-grid" data-expanded={expanded}>
        {children}
      </Stack>
    </>
  );
}
