import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../constants";
import {
  VERSION_1_VALUE,
  FieldType,
  VERSION_2_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";

export const structuredBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.PromptInputV1,
      label: "Source Text",
      config: {
        fieldKey: "settings.input",
        maxRows: MAX_ROWS_LARGE_TEXTFIELD,
        minRows: 1
      }
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.PromptInputV2,
      label: "Source text",
      subtitle: "Paste your source text here",
      config: {
        fieldKey: "settings.input",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true
      }
    }
  ],
  common: [
    {
      type: FieldType.JsonSchemaInput,
      label: "Configuration",
      subtitle: "Define what information to extract",
      config: {
        fieldKey: "settings.schema"
      }
    }
  ]
};
