import { useDispatch } from "react-redux";
import { setAllowReset, setWorkspaceId } from "../state/workspaceSlice";
import useNavigateToWorkspaceWithDefaultObject from "./useNavigateToWorkspaceWithDefaultObject";
import { homeMatcher } from "../../../navigation/functions/matchers";
import { useLocation } from "react-router-dom";

const useNavigateToWorkspaceById = () => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject();
  const dispatch = useDispatch();
  const location = useLocation();
  const handleNavigate =
    (workspaceId = "") =>
    (e: React.MouseEvent) => {
      if (homeMatcher(location.pathname)) {
        dispatch(setAllowReset(true));
        dispatch(setWorkspaceId(workspaceId));
      }

      if (!workspaceId) return;
      navigateToWorkspace({
        type: "workspace",
        id: workspaceId
      })(e);
    };
  return handleNavigate;
};

export default useNavigateToWorkspaceById;
