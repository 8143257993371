import {
  VERSION_1_VALUE,
  FieldType,
  type BlockFieldsConfig
} from "../../../../../types";

export const logicBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.LogicBuilderField,
      config: {
        fieldKey: "logicArray"
      }
    }
  ]
};
