import type {
  BlockDataTypesWithDefault,
  DecimalString
} from "@toolflow/shared";
import { getBlockConfigFE } from "../blockConfig";

const getBlockPaperLabel = (
  type: BlockDataTypesWithDefault["type"],
  version?: DecimalString
): { label: string } => {
  if (type === "default") {
    return { label: "" };
  }
  const config = getBlockConfigFE(type, version);
  return {
    label: config.draggableItem.data.label || ""
  };
};

export default getBlockPaperLabel;
