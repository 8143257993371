import {
  CustomToolInputFieldTypesEnum,
  ObjectProperty,
  TInputTypePrimitive,
  TInputTypePrimitiveMap
} from "../../types";

const baseSchemaProps: ObjectProperty["properties"] = {
  fieldLabel: { type: "string" },
  guideline: { type: "string" }
};

const nestedObjectType: ObjectProperty = {
  type: "object",
  properties: {
    ...baseSchemaProps,
    type: {
      type: "string",
      enum: ["string", "number", "integer", "boolean"]
    }
  },
  additionalProperties: false,
  required: ["fieldLabel", "guideline", "type"]
};

const JSONSchemaDefinition: TInputTypePrimitive = {
  type: "array",
  items: {
    type: "object",
    description: "This is the json schema object containing data for the tool",
    additionalProperties: false,
    required: ["fieldLabel", "guideline", "type", "items", "itemType", "enums"],
    properties: {
      ...baseSchemaProps,
      type: {
        type: "string",
        enum: [
          "string",
          "number",
          "integer",
          "boolean",
          "array",
          "object",
          "enum"
        ],
        additionalProperties: false
      },
      items: {
        type: "array",
        items: {
          ...nestedObjectType,
          description: "This is an array containing json schema objects"
        },
        additionalProperties: false,
        description:
          "Only applicable if type is 'object', otherwise set to null. Defines object properties."
      },
      itemType: {
        ...nestedObjectType,
        description:
          "Only applicable if type is 'array', otherwise set to null. Defines the type of array elements. It contains json schema object"
      },
      enums: {
        type: "array",
        items: { type: "string" },
        additionalProperties: false,
        description:
          "Only applicable if type is 'enum', otherwise set to null. Defines allowed values."
      }
    }
  },
  additionalProperties: false
};

const TextFieldDefinition: TInputTypePrimitive = {
  type: "object" as const,
  description: `This is the text field object containing data for the tool. USE flowAssetId, fileId,
  or toolOutputKey when referencing text from a flowAsset, file, or tool output. NEVER USE tvrId.`,
  properties: {
    flowAssetId: {
      type: "string" as const,
      description: `ALWAYS USE when referencing text from a flowAsset.`
    },
    fileId: {
      type: "string" as const,
      description: `ALWAYS USE when referencing text from a file`
    },
    toolOutputKey: {
      type: "string" as const,
      description: `ALWAYS USE when referencing text from a tool output. DO NOT USE tvrId. For instance if you want
      to use the blog text and the output is {'blog post': 'this is a blog post', tvrId: '6441cab9dc06a5e378195246' }
      then pass {'properties': {'toolOutputKey': 'blog post', 'textValue': ''}}. DO NOT pass the tvrId as the toolOutputKey.`
    },
    propertyValue: {
      type: "string" as const,
      description: `ALWAYS USE when referencing text that IS NOT from a flowAsset, file, or tool output.
        ALWAYS return just the text value. <example>'https://www.google.com' </example>
        <badExample><parameter name='propertyValue'>https://www.google.com</parameter>Reasoning - this uses xml syntax for this property, when it should be a string</badExample>
        <badExample><propertyValue>https://www.google.com</propertyValue>Reasoning - this uses xml syntax for this property, when it should be a string</badExample>`
    }
  },
  additionalProperties: false,
  required: ["flowAssetId", "fileId", "toolOutputKey", "propertyValue"] // Neither field is strictly required; requirements are conditional
};

// Old version
// const TextFieldDefinition: TInputTypePrimitive = {
//   type: "object" as const,
//   description:
// "This is the text field object containing data for the tool. Use the contextId field whenever possible. When using tool outputs as contextIds, pass the key of the output as the contextId. For instance if you want to use the blog text and the output is {'blog post': 'this is a blog post', tvrId: '6441cab9dc06a5e378195246' } then pass {'properties': {'contextId': 'blog post', 'textValue': ''}}. Do not pass the tvrId as the contextId.",
//   properties: {
//     contextId: {
//       type: "string" as const,
//       description:
//         "Do not pass the tvrId as the contextId. If the text is in a flowAsset or a file context use its id as the contextId field to reference it. If the text is in a tool output, use its key in the output object as its id. When using tool outputs as contextIds, pass the key of the output as the contextId. For instance if you want to use the blog text and the output is {'blog post': 'this is a blog post', tvrId: '6441cab9dc06a5e378195246' } then pass {'properties': {'contextId': 'blog post', 'textValue': ''}}. Do not pass the tvrId as the contextId. Do not use text value in contextId. Do not create arbitrary contextId."
//     },
//     textValue: {
//       type: "string" as const,
//       description:
//         "Use this field when text isn't in the context id. Leave empty if the context id is present. Do not use this field if the contextId exists.  Otherwise this is the text of the text field."
//     }
//   },
//   additionalProperties: false,
//   required: ["textValue", "contextId"]
// };

const FileUploadDefinition: TInputTypePrimitive = {
  type: "object",
  description: "This is the reference object containing data for the tool.",
  properties: {
    fileReferenceId: {
      type: "string",
      description: "Reference id of the file uploaded"
    },
    fileUrl: {
      type: "string",
      description: "Url of the uploaded file."
    }
  },
  additionalProperties: false,
  required: ["fileReferenceId", "fileUrl"]
};

export const inputTypePrimitiveMap: TInputTypePrimitiveMap = {
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: TextFieldDefinition,
  [CustomToolInputFieldTypesEnum.NUMERIC_FIELD]: { type: "number" },
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: TextFieldDefinition,
  [CustomToolInputFieldTypesEnum.SELECT]: { type: "string" },
  [CustomToolInputFieldTypesEnum.CHECKBOX]: { type: "boolean" },
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: FileUploadDefinition,
  [CustomToolInputFieldTypesEnum.MULTI_SELECT]: { type: "string" },
  [CustomToolInputFieldTypesEnum.TAGS_INPUT]: {
    type: "array",
    items: { type: "string" }
  },
  [CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD]: { type: "string" }, // TODO: this is not right
  [CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD]: { type: "string" }, // TODO: this is not right
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: { type: "string" },
  [CustomToolInputFieldTypesEnum.SLIDER]: { type: "number" },
  [CustomToolInputFieldTypesEnum.WORD_REPLACE]: { type: "string" },
  [CustomToolInputFieldTypesEnum.JSON_SCHEMA]: JSONSchemaDefinition,
  [CustomToolInputFieldTypesEnum.PIPEDREAM_ACTION]: { type: "string" },
  [CustomToolInputFieldTypesEnum.PIPEDREAM_FIELDS]: { type: "string" }
};
