import {
  AGENT,
  EntityType,
  MarketplaceGroup,
  type TDisabledEntities,
  type TMarketplaceUniversalEntityType,
  type TSelectAutocomplete
} from "@toolflow/shared";
import UniversalAutocomplete from "../../../../../utilities/components/autocomplete/universalAutocomplete/UniversalAutocomplete";

interface Props {
  placeholder?: string;
  disabledEntities?: TDisabledEntities;
  onClickEntity: (id: string, type: TMarketplaceUniversalEntityType) => void;
}

export default function SearchToolsDialogUniversalAutocomplete({
  placeholder,
  disabledEntities,
  onClickEntity
}: Props) {
  const handleSelectEntity: TSelectAutocomplete = (id, type) => {
    const entityMapper: Partial<
      Record<MarketplaceGroup, TMarketplaceUniversalEntityType>
    > = {
      [MarketplaceGroup.TOOL]: EntityType.TOOL,
      [MarketplaceGroup.WORKFLOW]: EntityType.WORKFLOW,
      [MarketplaceGroup.AGENT]: AGENT
    };
    const mappedType = entityMapper[type];
    if (mappedType) {
      onClickEntity(id, mappedType);
    }
  };

  return (
    <UniversalAutocomplete
      className="max-w-530px"
      autoFocus
      placeholder={placeholder}
      handleSelectEntity={handleSelectEntity}
      disabledFields={{
        useCases: true,
        workflows: disabledEntities?.workflows,
        tools: disabledEntities?.tools,
        agents: disabledEntities?.agents
      }}
    />
  );
}
