import { Box, Card, LinearProgress, useTheme } from "@mui/material";
import React from "react";
import type { TCredits } from "@toolflow/shared";
import SidebarCreditsUpgradeButton from "./SidebarCreditsUpgradeButton";
import { CreditsFillIcon } from "../../../globalTheme/icons/icons";
const SidebarCard = ({ credits }: { credits: TCredits }) => {
  const theme = useTheme();
  const { usedCredits, tierCredits } = credits;
  const progressPercentage = (usedCredits / tierCredits) * 100;
  return (
    <Card variant="outlined" className="m-h-8px">
      <Box className="p-16px">
        <div className="flex m-b-8px">
          <CreditsFillIcon
            className="m-r-8px"
            color={theme.palette.primary.main}
            size={20}
          />
          <span
            style={{
              color: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightMedium,
              fontFamily: "Konnect",
              fontSize: 14
            }}
          >
            {Math.round(usedCredits)}
          </span>
          <span
            style={{
              color: theme.palette.text.secondary,
              fontWeight: theme.typography.fontWeightMedium,
              fontFamily: "Konnect",
              fontSize: 14
            }}
          >
            &nbsp;
            {`/ ${tierCredits} credits`}
          </span>
        </div>

        <LinearProgress variant="determinate" value={progressPercentage} />
        <SidebarCreditsUpgradeButton />
      </Box>
    </Card>
  );
};

export default SidebarCard;
