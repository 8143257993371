import type { JSONContent } from "@tiptap/core";
import type {
  CustomToolInputField,
  TAddChipConfig,
  TSetFormState,
  UserInputDictType
} from "@toolflow/shared";
import { get } from "lodash";
import { TipTapTextFieldContainer } from "../../../../../utilities/components/textFields/tipTapTextField/TipTapTextField";
import FieldWrapper from "./wrapper/FieldWrapper";

export const InputTextField = (props: {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  autofocus?: boolean;
  defaultFormState?: UserInputDictType;
  disableLabelAndInfo?: boolean;
  defaultExpanded?: boolean;
  useIds?: boolean;
  chipsToUse?: TAddChipConfig;
}) => {
  const {
    useIds,
    formState,
    autofocus,
    chipsToUse,
    toolInputField,
    defaultExpanded,
    defaultFormState,
    disableLabelAndInfo,
    setFormState
  } = props;

  const key = useIds ? toolInputField.id : toolInputField.name;
  const initState = get(defaultFormState, key, get(formState, key, "")) as
    | string
    | JSONContent;

  const handleTipTapChange = (val: string | JSONContent) => {
    setFormState({ ...formState, [key]: val });
  };

  return (
    <FieldWrapper {...props}>
      <TipTapTextFieldContainer
        autoFocus={autofocus}
        chipsToUse={chipsToUse}
        id={toolInputField.id}
        saveWorkspaceType="json"
        defaultExpanded={defaultExpanded}
        initialValue={initState} // we just want the initialValue, not the current value because tiptap can't be controlled
        onChange={handleTipTapChange}
        disableWhite={disableLabelAndInfo}
        label={"Type or + to add elements"}
        disableFormattingMenu // we need this because otherwise html gets sent to the server
        className={disableLabelAndInfo ? "min-w-300px" : ""}
      />
    </FieldWrapper>
  );
};
