import type { PipedreamApp, UserIntegrationMetadata } from "@toolflow/shared";
import { useState } from "react";
import {
  useConnectIntegrationMutation,
  useGenerateTokenMutation
} from "../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { usePipedreamClient } from "../context/PipedreamContext";

export default function usePipedreamConnect() {
  const { pdClient } = usePipedreamClient();
  const [generateToken] = useGenerateTokenMutation();
  const [connectIntegration] = useConnectIntegrationMutation();
  const [isConnecting, setIsConnecting] = useState(false);

  const connect = async (
    app: PipedreamApp,
    authType: UserIntegrationMetadata["auth_type"] = "",
    categories: UserIntegrationMetadata["categories"] = []
  ): Promise<boolean> => {
    return new Promise(async (resolve) => {
      if (!pdClient) return resolve(false);

      try {
        setIsConnecting(true);
        const tokenResponse = await generateToken().unwrap();
        if (!tokenResponse.token) {
          return resolve(false);
        }

        await pdClient.connectAccount({
          app: app.name_slug,
          token: tokenResponse.token,
          onSuccess: async ({ id: accountId }: { id: string }) => {
            try {
              await connectIntegration({
                integrationType: app.name_slug,
                accountId,
                metadata: {
                  name: app.name,
                  description: app.description,
                  img_src: app.img_src,
                  auth_type: authType,
                  categories
                }
              }).unwrap();
              resolve(true);
            } catch (error) {
              console.error("Error saving integration:", error);
              resolve(false);
            }
          },
          onError(error) {
            console.error("Error connecting integration:", error);
            resolve(false);
          }
        });
      } catch (error) {
        console.error("Error connecting integration:", error);
        return resolve(false);
      } finally {
        setIsConnecting(false);
      }
    });
  };

  return { connect, isConnecting };
}
