import { Grid, Typography } from "@mui/material";
import {
  type DynamicInputFieldType,
  type JSONSchemaItem
} from "@toolflow/shared";
import JsonSchemaCreationField from "../../../../../../../tools/components/inputs/jsonSchemaCreationField/JsonSchemaCreationField";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useFindBlockById from "../../../../useFindBlockById";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useDynamicSetting from "../../hooks/useDynamicSetting";

function JsonSchemaInputField() {
  const { id, field, updateField, getFieldValue } =
    useFieldsByTypeContext<DynamicInputFieldType>();
  const block = useFindBlockById(id);
  const { dispatch } = useToolbuilderContext();
  const {
    config: { fieldKey }
  } = field;

  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const schema = getFieldValue<JSONSchemaItem[]>(fieldKey, []);

  function renameNestedSchemaItem(prevValue: string, newValue: string) {
    if (block?.data.label) {
      dispatch({
        type: "RENAME_NESTED_SCHEMA_ITEM_FIELD",
        name: block.data.label,
        newValue,
        prevValue
      });
    }
  }

  return (
    <Grid container spacing={2} className="m-t-8px">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h8">{label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitleLarge">{subtitle}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <JsonSchemaCreationField
          schema={schema}
          onChange={(value) => updateField(value, fieldKey)}
          updateFieldNameInTOF={renameNestedSchemaItem}
        />
      </Grid>
    </Grid>
  );
}

export default JsonSchemaInputField;
