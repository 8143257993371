import { Box } from "@mui/material";
import {
  evaluateDynamicSetting,
  type FieldsByTypeProps,
  type FieldsGroupConfig,
  type FieldTypeConfigs
} from "@toolflow/shared";
import { FieldsByTypeProvider } from "../../FieldsByTypeContext";
import { fieldTypeComponents } from "../../fieldTypeComponents";
import GroupAccordion from "./GroupAccordion";

function GroupedFields({
  field,
  configs
}: FieldsByTypeProps & {
  field: FieldTypeConfigs;
}) {
  const { data, id, chipsToUse, updateField } = configs;
  const Component = fieldTypeComponents[field.type];

  const hidden = evaluateDynamicSetting<boolean>(field.hidden, data);

  if (!Component || hidden) return null;
  return (
    <Box sx={{ marginInline: 1 }} className="m-v-24px">
      <FieldsByTypeProvider
        id={id}
        data={data}
        field={field}
        chipsToUse={chipsToUse}
        updateField={updateField}
      >
        <Component field={field} settings={data} id={id} />
      </FieldsByTypeProvider>
    </Box>
  );
}

export default function GroupedFieldWrapper({
  group,
  configs
}: FieldsByTypeProps & {
  group: FieldsGroupConfig;
}) {
  const label = evaluateDynamicSetting<string>(group.label, configs.data, "");
  const subtitle = evaluateDynamicSetting<string>(
    group.subtitle,
    configs.data,
    ""
  );

  return (
    <GroupAccordion title={label} subtitle={subtitle}>
      {group.fields?.map((groupedField, idx2) => (
        <GroupedFields key={idx2} field={groupedField} configs={configs} />
      ))}
    </GroupAccordion>
  );
}
