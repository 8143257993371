import {
  type BlockDataBase,
  type CustomToolInputField,
  type RunBlockToolData,
  type UserInputDictType,
  BlockNode
} from "@toolflow/shared";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../../../../../stores/actions";
import { getErrorMessage } from "../../../../../../../../ToolflowAPI/errors/getErrorMessage";
import { getIsOutOfCreditsError } from "../../../../../../../../ToolflowAPI/errors/getIsOutOfCreditsError";
import { useRunBlockMutation } from "../../../../../../../../ToolflowAPI/rtkRoutes/blockApi";
import useRunButtonBase from "../../../../../../../tools/components/editorToolCard/authenticatedActions/hooks/useRunButtonBase";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useHandleSocketMessage from "../../../../hooks/useHandleSocketMessage";
import useInnerBlockState from "../../../../hooks/useInnerBlockState";
import transformList from "../../../helpers/transformList";

const useTest = ({
  customFields,
  blockType,
  fields,
  data,
  id,
  userInput
}: {
  customFields?: CustomToolInputField[];
  blockType: BlockNode;
  fields: string[];
  data: BlockDataBase;
  id: string;
  userInput: UserInputDictType;
}) => {
  const { label } = data;
  const { dispatch, state } = useToolbuilderContext();
  const { loading, output, setOutput, setLoading } = useHandleSocketMessage();
  const { abort, componentId } = useInnerBlockState();

  const [runBlock] = useRunBlockMutation();
  const { toolId, toolVersionId } = state;
  const [showUpgrade, setShowUpgrade] = useState(false);

  const reduxDispatch = useDispatch();

  const handleRunBlock = async () => {
    setLoading(true);
    dispatch({
      type: "UPDATE_TOOL_OUTPUT",
      response: null,
      percentCompleted: 0
    });

    const toolInputFields = transformList(fields, customFields);
    const toolData: RunBlockToolData = {
      componentId,
      userInput,
      blockType,
      toolInputFields,
      blockData: data,
      trackingMetrics: {
        toolId,
        toolVersionId,
        blockId: id
      }
    };

    const handleError = (e: unknown) => {
      if (getIsOutOfCreditsError(e)) {
        setShowUpgrade(true);
      }
      reduxDispatch(setErrorMessage(getErrorMessage(e, "Error running block")));
      setLoading(false);
    };

    try {
      await runBlock(toolData).unwrap();
    } catch (err) {
      handleError(err);
    }
  };

  const { action, text, endIcon } = useRunButtonBase({
    send: handleRunBlock,
    abort,
    loading,
    runButtonText: "Test"
  });

  useEffect(() => {
    // Need to reset output if block changes.
    setOutput(null);
  }, [id]);

  return {
    action,
    loading,
    text,
    endIcon,
    showUpgrade,
    output,
    label,
    fields
  };
};

export default useTest;
