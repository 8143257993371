import { generateText, type JSONContent } from "@tiptap/core";
import { ExtensionLocation, type UserInputDictType } from "@toolflow/shared";
import { customExtensions } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/customExtensions";
import { getExtensionsFromConfig } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/getExtensionsFromConfig";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/promptInput/V2/PromptInputV2";

export default function useHandleTestFieldUpdate(
  uI: UserInputDictType,
  setUI: (ui: UserInputDictType) => void
) {
  const extensions = getExtensionsFromConfig(
    textInputContainerExtensionsConfig,
    customExtensions(ExtensionLocation.Toolbuilder)
  );

  const handleUpdate = (field: string, e: string | JSONContent) => {
    setUI({
      ...uI,
      [field]: typeof e === "string" ? e : generateText(e, extensions)
    });
  };

  return handleUpdate;
}
