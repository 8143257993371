import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { type SelectInputProps } from "@mui/material/Select/SelectInput";
import InputLabelWrapper from "../../components/InputLabelWrapper";
import styles from "./PipedreamActionSelect.module.css";

interface PipedreamActionSelectProps {
  id: string;
  value: string;
  fieldKey: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  options: Array<{
    label: string;
    value: string;
    icon?: string;
  }>;
  onChange: (value: string) => void;
}

export default function PipedreamActionSelect({
  value,
  label,
  options,
  placeholder,
  disabled,
  required,
  onChange
}: PipedreamActionSelectProps) {
  const handleChange: SelectInputProps["onChange"] = (event) => {
    onChange(event.target.value as string);
  };

  const renderOption = (option: (typeof options)[0]) => (
    <MenuItem
      value={option.value}
      key={option.value}
      className={styles["menu-item"]}
    >
      <div className={styles["option-content"]}>
        {option.icon && (
          <img src={option.icon} alt="" className={styles["option-icon"]} />
        )}
        <span>{option.label}</span>
      </div>
    </MenuItem>
  );

  return (
    <FormControl
      fullWidth
      margin="none"
      disabled={disabled}
      required={required}
    >
      <InputLabelWrapper label={label} required={required}>
        <Select
          disabled={disabled}
          size="small"
          value={value}
          displayEmpty
          required={required}
          className="nowheel nodrag nopan"
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "40vh"
              }
            }
          }}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography variant="subtitleMedium" color="text.secondary">
                  {placeholder}
                </Typography>
              );
            }
            const option = options.find((opt) => opt.value === selected);
            if (!option) return selected;
            return (
              <div className={styles["option-content"]}>
                {option.icon && (
                  <img
                    src={option.icon}
                    alt=""
                    className={styles["option-icon"]}
                  />
                )}
                <span>{option.label}</span>
              </div>
            );
          }}
        >
          {options.map(renderOption)}
        </Select>
      </InputLabelWrapper>
    </FormControl>
  );
}
