export enum OpenAiTextModel {
  GPT35 = "gpt-3.5-turbo",
  GPT4 = "gpt-4",
  GPT4Turbo = "gpt-4-turbo-preview",
  GPT4o = "gpt-4o",
  GPT4o_Aug = "gpt-4o-2024-08-06",
  GPT4o_Mini = "gpt-4o-mini",
  GPT45_Preview = "gpt-4.5-preview",
  o1 = "o1",
  o3_Mini = "o3-mini"
}

export enum OpenAiEmbeddingModel {
  SMALL_3 = "text-embedding-3-small",
  LARGE_3 = "text-embedding-3-large",
  ADA_002 = "text-embedding-ada-002"
}

export enum OpenAiImageModel {
  DALLE2 = "dall-e-2",
  DALLE3 = "dall-e-3"
}

export type OpenAiModel = OpenAiTextModel | OpenAiImageModel;

export enum AnthropicModel {
  OPUS = "claude-3-opus-20240229",
  SONNET = "claude-3-sonnet-20240229",
  SONNET_3_5 = "claude-3-5-sonnet-20240620",
  SONNET_3_5_LATEST = "claude-3-5-sonnet-latest",
  HAIKU = "claude-3-haiku-20240307",
  HAIKU_3_5 = "claude-3-5-haiku-latest",
  SONNET_3_7 = "claude-3-7-sonnet-latest"
}

export const ANTHROPIC_MAX_TOKENS_PER_MODEL = {
  [AnthropicModel.OPUS]: 4096,
  [AnthropicModel.SONNET]: 4096,
  [AnthropicModel.SONNET_3_5]: 8192,
  [AnthropicModel.SONNET_3_5_LATEST]: 8192,
  [AnthropicModel.HAIKU]: 4096,
  [AnthropicModel.HAIKU_3_5]: 8192,
  [AnthropicModel.SONNET_3_7]: 8192
};

// This object helps us override models that are deprecated
export const AnthropicOverride = {
  [AnthropicModel.SONNET_3_5]: AnthropicModel.SONNET_3_5_LATEST
};

export enum PerplexityModel {
  SonarDeepResearch = "sonar-deep-research",
  SonarReasoningPro = "sonar-reasoning-pro",
  SonarReasoning = "sonar-reasoning",
  SonarPro = "sonar-pro",
  Sonar = "sonar",
  R1_1776 = "r1-1776"
  // Deprecated
  // SonarSmOnline = "llama-3.1-sonar-small-128k-online",
  // SonarLgOnline = "llama-3.1-sonar-large-128k-online",
  // SonarHgOnline = "llama-3.1-sonar-huge-128k-online",
  // SonarSmChat = "llama-3.1-sonar-small-128k-chat",
  // SonarLgChat = "llama-3.1-sonar-large-128k-chat"}
}

export enum DeepgramModel {
  Nova3 = "nova-3",
  Nova2 = "nova-2",
  Nova = "nova",
  Enhanced = "enhanced",
  Base = "base",
  WhisperMedium = "whisper-medium"
}

export enum EmbeddingServiceType {
  OpenAi = "openAI"
}

export enum ChatLLMType {
  OpenAi = "openai",
  Anthropic = "anthropic"
}

export type TextPromptModels =
  | OpenAiTextModel
  | OpenAiImageModel
  | AnthropicModel
  | PerplexityModel;
