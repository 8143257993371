import { Grid, Typography } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  FieldSelectorValue,
  type CustomToolInputField,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import { cloneDeep, isUndefined } from "lodash";
import { ReactNode, useState } from "react";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import FieldSelector from "./FieldSelector";
import styles from "./FieldWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export default function FieldWrapper({
  useIds,
  children,
  formState,
  toolInputField,
  defaultToAgent,
  disableLabelAndInfo,
  alternateDescription,
  allowAgentDeterminedValues,
  setFormState
}: {
  useIds?: boolean;
  children?: ReactNode;
  formState: UserInputDictType;
  defaultToAgent?: boolean;
  toolInputField: CustomToolInputField;
  disableLabelAndInfo?: boolean;
  alternateDescription?: string;
  allowAgentDeterminedValues?: boolean;
  setFormState: TSetFormState;
}) {
  const key = useIds ? toolInputField.id : toolInputField.name;
  defaultToAgent = isUndefined(defaultToAgent)
    ? allowAgentDeterminedValues && !(key in formState)
    : defaultToAgent;

  const [value, setValue] = useState(
    defaultToAgent ? FieldSelectorValue.Agent : FieldSelectorValue.Preset
  );

  function handleChange(v: FieldSelectorValue) {
    const cloned = cloneDeep(formState);
    if (v === FieldSelectorValue.Agent) {
      delete cloned[key];
      setFormState(cloned);
    } else if (toolInputField.type === CustomToolInputFieldTypesEnum.CHECKBOX) {
      cloned[key] = false;
      setFormState(cloned);
    }

    setValue(v);
  }

  const description =
    value === FieldSelectorValue.Agent
      ? alternateDescription || toolInputField.description
      : toolInputField.description;

  return (
    <div>
      {!disableLabelAndInfo && (
        <Grid
          container
          spacing={[1, 0.5]}
          className={cx({ "m-b-16px": allowAgentDeterminedValues })}
        >
          <Grid item xs={8}>
            <Typography variant="h8" className="m-r-8px capitalize">
              {toolInputField.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {allowAgentDeterminedValues && (
              <FieldSelector value={value} onChange={handleChange} />
            )}
          </Grid>
          {!!description && (
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {value === FieldSelectorValue.Preset && children}
    </div>
  );
}
