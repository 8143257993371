import LoadingButton from "@mui/lab/LoadingButton";
import React, { FC } from "react";

interface ISaveProfileProps {
  isLoading: boolean;
  handleSubmit: () => void;
  isDisabled: boolean;
  className?: string;
}
const SaveProfile: FC<ISaveProfileProps> = ({
  isLoading,
  handleSubmit,
  isDisabled,
  className
}) => {
  return (
    <LoadingButton
      loading={isLoading}
      className={className}
      variant="contained"
      onClick={handleSubmit}
      disabled={isDisabled}
      size="large"
    >
      Save profile
    </LoadingButton>
  );
};

export default SaveProfile;
