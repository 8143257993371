export enum TaskQueue {
  Block = "blocks_queue",
  Tool = "tools_queue"
}

export type TaskQueueHandlerCallBack = (msg: string) => void;

export interface IHandleQueueToolExecutionRequestDtoProps {
  task: string;
}
