import {
  BlockType,
  CustomToolInputFieldTypesEnum,
  FieldType,
  PerplexityBlockDataV2,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../constants";
import {
  filterAvailableFieldsByFile,
  getPerplexityModelOptions
} from "../../helpers";
import { getInitialState } from "../../initialStates";

const initialState = getInitialState(
  BlockType.Perplexity
) as PerplexityBlockDataV2;

export const perplexityBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.SelectFieldV2,
      label: "Model",
      subtitle: "Select model",
      config: {
        fieldKey: "optimizations.llmModel",
        options: getPerplexityModelOptions()
      }
    },
    {
      type: FieldType.PromptInputV1,
      label: "Prompt",
      config: {
        fieldKey: "settings.input",
        maxRows: MAX_ROWS_LARGE_TEXTFIELD,
        minRows: MAX_ROWS_LARGE_TEXTFIELD
      }
    },
    {
      type: FieldType.Slider,
      label: "Temperature",
      subtitle:
        "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
      config: {
        fieldKey: "optimizations.temperature"
      }
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.PromptInputV2,
      label: "Prompt",
      subtitle: "Write your prompt instructions here.",
      config: {
        fieldKey: "settings.input",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px"
      }
    },
    {
      type: FieldType.Group,
      label: "LLM Settings",
      subtitle: "Select LLM settings for this block.",
      fields: [
        {
          type: FieldType.DynamicSelectField,
          label: "Model",
          subtitle: "Select model",
          config: {
            filterAvailableFields: filterAvailableFieldsByFile,
            allowedFields: [CustomToolInputFieldTypesEnum.SELECT],
            typeKey: "optimizations.llmModel.type",
            fieldKey: "optimizations.llmModel.value",
            options: getPerplexityModelOptions(),
            defaultValue: initialState.optimizations.llmModel.value
          }
        },
        {
          type: FieldType.DynamicSliderField,
          label: "Temperature",
          subtitle:
            "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
          config: {
            filterAvailableFields: filterAvailableFieldsByFile,
            allowedFields: [CustomToolInputFieldTypesEnum.SLIDER],
            typeKey: "optimizations.temperature.type",
            fieldKey: "optimizations.temperature.value",
            defaultValue: initialState.optimizations.temperature.value
          }
        }
      ]
    }
  ]
};
