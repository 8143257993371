import type { TDisabledEntities, TSearchToolsTabType } from "@toolflow/shared";
import { createContext, ReactNode, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { TToolTab } from "../../constants/toolTabsArray";
import { setSearchToolsTab } from "../../slice/searchToolsSlice";

interface IToolTabsContext {
  toolTabs: TToolTab[];
  selectedTabIndex: number;
  disabledEntities?: TDisabledEntities;
  currentTab: TToolTab;
  handleChangeTabIndex: (event: React.SyntheticEvent, newIndex: number) => void;
}

const ToolTabsContext = createContext<IToolTabsContext | null>(null);

const useMarketplaceEntityCategory = (toolTabs: TToolTab[]) => {
  const dispatch = useDispatch();
  const reduxTab = useSelector((state: RootState) => state.searchTools.tab);

  const getTabIndex = (category: TSearchToolsTabType) =>
    toolTabs.findIndex((tab) => tab.value === category);

  const selectedTabIndex = getTabIndex(reduxTab);

  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    const newCategory = toolTabs[newIndex]?.value as TSearchToolsTabType;
    if (newCategory) {
      dispatch(setSearchToolsTab(newCategory));
    }
  };

  return {
    selectedTabIndex,
    handleChangeTabIndex
  };
};

export default function ToolTabsProvider({
  toolTabs,
  disabledEntities,
  children
}: {
  toolTabs: TToolTab[];
  disabledEntities?: TDisabledEntities;
  children: ReactNode;
}) {
  const { selectedTabIndex, handleChangeTabIndex } =
    useMarketplaceEntityCategory(toolTabs);

  const currentTab = toolTabs[selectedTabIndex];

  return (
    <ToolTabsContext.Provider
      value={{
        toolTabs,
        selectedTabIndex,
        handleChangeTabIndex,
        disabledEntities,
        currentTab
      }}
    >
      {children}
    </ToolTabsContext.Provider>
  );
}

export function useToolTabsContext() {
  const context = useContext(ToolTabsContext);
  if (!context) {
    throw new Error(
      "useToolTabsContext must be used within a ToolTabsProvider"
    );
  }
  return context;
}
