import { isPlainObject, isString } from "lodash";
import {
  $TSAllowedAny,
  AGENT,
  AllBlockTypes,
  BlockType,
  EntityType,
  PipedreamApp,
  TMarketplaceUniversalEntity
} from "../../../../types";
import { isEnumValue } from "../../../helpers";

const INTEGRATION_ID_DELIMITER = "--";

export function createSyntheticToolId(type: AllBlockTypes) {
  return `${type}-block`;
}

export function getBlockTypeFromId(id: string) {
  const blockType = Object.values(BlockType).find((v) => id.includes(v));
  if (!blockType) {
    throw new Error(`Invalid block id: ${id}`);
  }
  return blockType;
}

export function isIntegrationBlockAppId(id: string) {
  return (
    id.includes(INTEGRATION_ID_DELIMITER) &&
    id.startsWith(createSyntheticToolId(BlockType.UserIntegration))
  );
}

export function isSyntheticToolId(id: string): boolean {
  if (!isString(id)) return false;

  // Check if it's an integration tool ID (format: "UserIntegration-block--{{appSlug}}")
  if (isIntegrationBlockAppId(id)) return true;

  // Check if it's a regular block tool ID (format: "{{BlockType}}-block")
  const isBlockId = Object.values(BlockType).some(
    (blockType) => id === createSyntheticToolId(blockType)
  );

  return isBlockId;
}

export function createIntegrationBlockId(appSlug: string) {
  return `${createSyntheticToolId(BlockType.UserIntegration)}${INTEGRATION_ID_DELIMITER}${appSlug}`;
}

export function getIntegrationFromId(id: string) {
  return id.split(INTEGRATION_ID_DELIMITER).at(-1);
}

export function isPipedreamApp(entity: $TSAllowedAny): entity is PipedreamApp {
  return (
    isPlainObject(entity) &&
    "id" in entity &&
    "name" in entity &&
    "name_slug" in entity
  );
}

export function isMarketplaceEntity(
  entity: $TSAllowedAny
): entity is TMarketplaceUniversalEntity {
  return (
    isPlainObject(entity) &&
    "id" in entity &&
    "type" in entity &&
    (isEnumValue(entity.type, EntityType) || entity.type === AGENT)
  );
}
