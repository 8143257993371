import {
  VERSION_1_VALUE,
  FieldType,
  type BlockFieldsConfig
} from "../../../../../types";

export const savetoKnowledgeBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.DynamicInputField,
      label: "Name",
      subtitle:
        "Choose a unique, descriptive name that captures the essence of the source.",
      config: {
        fieldKey: "settings.name.value",
        typeKey: "settings.name.type"
      }
    },
    {
      type: FieldType.PromptInputV2,
      label: "Content",
      subtitle: "Add content to save into the knowledge base.",
      config: {
        fieldKey: "settings.content",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px"
      }
    }
  ]
};
