import {
  ALL_VERSIONS,
  BlockType,
  VERSION_1_VALUE,
  UtilBlockType,
  type AllBlockTypes,
  type BlockFunctionRecords,
  type DecimalString
} from "@toolflow/shared";
import * as fns from "./fns";

const blockConfigFunctions = Object.values({
  ...BlockType,
  ...UtilBlockType
}).reduce((acc, blockType) => {
  acc[blockType] = ALL_VERSIONS.reduce(
    (acc2, ver) => {
      acc2[ver] = {
        validate: fns.getBlockValidatorByVersion(blockType, ver),
        updateInput: fns.getBlockInputUpdaterFunction(blockType, ver),
        getNestedFields: fns.getNestedFieldFnsByBlockType(blockType, ver),
        getOutputOptions: fns.getOutputOptionsFnsByBlockType(blockType, ver),
        getOutputConfigOptions: fns.getOutputConfigOptionsFn(blockType, ver),
        ...fns.getBlockComponentsByVersion(blockType, ver)
      };
      return acc2;
    },
    {} as BlockFunctionRecords[string]
  );
  return acc;
}, {} as BlockFunctionRecords);

export default function getBlockConfigFunctions(
  blockType: AllBlockTypes,
  version: DecimalString = VERSION_1_VALUE
) {
  return blockConfigFunctions[blockType]?.[version] || {};
}
