import { Chip } from "@mui/material";
import { classNames } from "../../../utilities/functions/parsedClassNames";
import { DarkUtilityTooltip } from "../../../utilities/components/toolTips/DarkUtilityTooltip";

export function ToolRunChip({
  title,
  onClick,
  Icon,
  className
}: {
  title: string;
  onClick?: () => void;
  Icon?: React.ElementType;
  className?: string;
}) {
  return (
    <DarkUtilityTooltip title="Click to open">
      <Chip
        size="small"
        icon={Icon ? <Icon size={14} /> : undefined}
        onClick={onClick}
        clickable
        label={title}
        className={classNames(className, "chat-chip-color light-chat-border")}
      />
    </DarkUtilityTooltip>
  );
}
