import { type PromptInputV1Type } from "@toolflow/shared";
import PromptEditor from "../../../../../promptEditor/PromptEditor";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import InputLabelWrapper from "../../../components/InputLabelWrapper";
import useDynamicSetting from "../../../hooks/useDynamicSetting";

export function PromptInputV1() {
  const { field, id, getFieldValue, updateField } =
    useFieldsByTypeContext<PromptInputV1Type>();

  const { prependKey, fieldKey, minRows, maxRows } = field.config;

  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");

  return (
    <InputLabelWrapper label={label} subtitle={subtitle}>
      <PromptEditor
        id={id}
        prompt={getFieldValue(fieldKey, "")}
        maxRows={maxRows}
        minRows={minRows}
        handleUpdatePrompt={(newValue) => {
          const key = prependKey ? [prependKey, fieldKey].join(".") : fieldKey;
          updateField(newValue, key);
        }}
      />
    </InputLabelWrapper>
  );
}
