import type { JSONValue } from "@toolflow/shared";
import StructuredOutput from "../../../../../tools/components/editorToolCard/outputs/structuredOutput/StructuredOutput";
import { useToolContext } from "../../../../../tools/contexts/ToolContext";
import { DialogButtonContainer } from "../../../../../../utilities/components/dialogs/DialogButtonContainer";
import OverviewCard from "../../../../../../utilities/components/cards/OverviewCard";
import { ToolRunChip } from "../../../../../tools/components/ToolRunChip";
import { white } from "../../../../../../globalTheme/muiUtils/appTheme";
import { classNames } from "../../../../../../utilities/functions/parsedClassNames";
const ToolDetails = ({
  value,
  className
}: {
  value: JSONValue;
  className?: string;
}) => {
  const { tool } = useToolContext();
  return (
    <DialogButtonContainer
      button={
        <ToolRunChip
          title={tool.name}
          className={classNames("grey", className)}
          Icon={(props) =>
            tool.avatar && (
              <img
                src={tool.avatar}
                alt="avatar"
                height={14}
                width={14}
                {...props}
              />
            )
          }
        />
      }
      PaperProps={{ sx: { color: white } }}
      offset={{ top: 36, right: 36 }}
      content={() => (
        <>
          <OverviewCard
            description={tool.about}
            title={tool.name}
            className="m-b-24px background-color-overview-card"
          />
          <StructuredOutput value={value} />
        </>
      )}
    />
  );
};

export default ToolDetails;
