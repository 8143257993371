import { FormControl, MenuItem, Select } from "@mui/material";
import {
  getIntegrationFromId,
  getLabelFromSelectOption,
  getValueFromSelectOption,
  type CustomToolInputFieldWithOptions,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import useIntegrationActionsOptions from "../../../../../integrations/hooks/useIntegrationActionsOptions";
import FieldWrapper from "../wrapper/FieldWrapper";

export function WsPipedreamActionField(props: {
  entityId?: string;
  toolInputField: CustomToolInputFieldWithOptions;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  autofocus?: boolean;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) {
  const {
    entityId = "",
    toolInputField,
    setFormState,
    formState,
    disabled,
    autofocus,
    useIds
  } = props;
  const { options = [] } = useIntegrationActionsOptions(
    getIntegrationFromId(entityId)
  );

  const key = useIds ? toolInputField.id : toolInputField.name;
  const handleSelectChange = (e: $TSFixMe) => {
    const { value } = e.target;
    setFormState({ ...formState, [key]: value });
  };

  return (
    <FieldWrapper
      {...props}
      defaultToAgent={false}
      alternateDescription={`When set to "Agent controlled", the agent will dynamically determine and set the appropriate values at runtime.`}
    >
      <FormControl fullWidth className="w-100-percent m-t-8px" size="small">
        <Select
          name={toolInputField.name}
          autoFocus={autofocus}
          id={toolInputField.id}
          value={(formState[key] as string) || ""}
          onChange={handleSelectChange}
          disabled={disabled}
          className="bg-color-white"
        >
          {options.map((option, idx) => (
            <MenuItem key={idx} value={getValueFromSelectOption(option)}>
              {getLabelFromSelectOption(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FieldWrapper>
  );
}
