import { Box, IconButton, type SxProps } from "@mui/material";
import { type RemixiconComponentType } from "@remixicon/react";
import React from "react";
import RemixWrapperIconWithTheme from "./RemixWrapperIconWithTheme";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import { DarkUtilityTooltip } from "../toolTips/DarkUtilityTooltip";

const RemixButton = ({
  text,
  icon,
  onClick,
  disabled,
  className = "",
  providedColor = toolflowTextDisabledColor,
  size = 16,
  sx
}: {
  text?: string;
  icon: RemixiconComponentType;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  className?: string;
  providedColor?: string;
  size?: number;
  sx?: SxProps;
}) => {
  return (
    <Box className={className} sx={sx}>
      <DarkUtilityTooltip title={text}>
        <IconButton onClick={onClick} disabled={disabled}>
          <RemixWrapperIconWithTheme
            Icon={icon}
            size={size}
            providedColor={providedColor}
          />
        </IconButton>
      </DarkUtilityTooltip>
    </Box>
  );
};

export default RemixButton;
