import {
  MarketplaceFillIcon,
  MarketplaceIcon,
  UserFillIcon,
  UserIcon
} from "../../../globalTheme/icons/icons";
import { useOnlyUserAgents } from "../../agents/useOnlyUserAgents";
import { useAllMarketplaceAgentEntities } from "../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";
import ToolTabsContainer from "../../tools/components/searchToolsContainer/components/toolTabs/ToolTabsContainer";
import {
  iconSize,
  type TToolTab
} from "../../tools/components/searchToolsContainer/constants/toolTabsArray";
import {
  MARKETPLACE_TAB,
  USER_TOOLS_TAB
} from "../../tools/components/searchToolsContainer/utils/constants";

let tabId = 0;

export const agentTabs: TToolTab[] = [
  {
    value: USER_TOOLS_TAB,
    indexValue: tabId++,
    label: "My agents",
    getEntities: useOnlyUserAgents,
    icon: <UserIcon size={iconSize} />,
    selectedIcon: <UserFillIcon size={iconSize} />,
    RemixIconRaw: UserIcon
  },
  {
    value: MARKETPLACE_TAB,
    indexValue: tabId++,
    label: "Marketplace",
    getEntities: useAllMarketplaceAgentEntities,
    icon: <MarketplaceIcon size={iconSize} />,
    selectedIcon: <MarketplaceFillIcon size={iconSize} />,
    RemixIconRaw: MarketplaceIcon
  }
  // {
  //   indexValue: tabId++,
  //   value: STARRED_TAB,
  //   label: "Starred",
  //   getEntities: useOnlyStarredEntities,
  //   icon: <StarIcon size={iconSize} />,
  //   selectedIcon: <StarFillIcon size={iconSize} />,
  //   RemixIconRaw: StarIcon
  // }
];

function AgentsTabs({ className }: { className?: string }) {
  return <ToolTabsContainer toolTabs={agentTabs} className={className} />;
}

export default AgentsTabs;
