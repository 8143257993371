import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { AGENT, EntityType, isSyntheticToolId } from "@toolflow/shared";
import { ArrowBackIcon } from "../../../../../globalTheme/icons/icons";
import { useGetToolQuery } from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import UniversalAutocompletePositionWrapper from "../../../../../utilities/components/autocomplete/universalAutocomplete/UniversalAutocompletePositionWrapper";
import OverviewCard from "../../../../../utilities/components/cards/OverviewCard";
import RemixButton from "../../../../../utilities/components/icons/RemixButton";
import AgentsTabs from "../../../../pages/agents/AgentTabs";
import NavigateToToolBuilderButton from "../../../../pages/builder/toolBuilder/NavigateToToolBuilderButton";
import { useToolContext } from "../../../contexts/ToolContext";
import ToolContextComponentContainer from "../../../contexts/ToolContextContainer";
import ToolTabsContainer from "../../searchToolsContainer/components/toolTabs/ToolTabsContainer";
import { toolTabs } from "../../searchToolsContainer/constants/toolTabsArray";
import { SearchToolsContextComponent } from "../../searchToolsContainer/context/SearchToolsContext";
import WebsiteForm from "../../WebsiteForm";
import useSearchToolsDialog from "../hooks/useSearchToolsDialog";
import { SearchEntitiesDialogType } from "../SearchEntitiesDialog";
import FormSkeleton from "./FormSkeleton";
import SearchToolsDialogUniversalAutocomplete from "./SearchToolsDialogUniversalAutocomplete";

function ConfigureStepHeader() {
  const { step, isConfigureOnly, handleBack } = useSearchToolsDialog();
  return (
    <div className="p-t-8px p-b-16px flex flex-column p-h-32px">
      <Stack direction="column" spacing={1} className="m-t-8px">
        <Stack direction="row" spacing={1} alignItems="center">
          {step > 0 && !isConfigureOnly && (
            <RemixButton icon={ArrowBackIcon} onClick={handleBack} />
          )}
          <Typography variant="h6">Configure</Typography>
        </Stack>

        <Typography variant="caption" color="text.secondary">
          Set which parameters the agent can determine from conversation context
          and which ones you want to define upfront.
        </Typography>
      </Stack>

      <Divider className="m-t-8px" />
    </div>
  );
}

function EntitySelectStep() {
  const {
    type,
    title,
    disabledEntities,
    handleEntityClick,
    EntityElementWithOnClick
  } = useSearchToolsDialog();

  return (
    <>
      <div className="flex align-i-center w-100-percent flex-wrap p-t-8px p-h-32px">
        <Typography variant="h5">{title}</Typography>
        <UniversalAutocompletePositionWrapper>
          <SearchToolsDialogUniversalAutocomplete
            onClickEntity={handleEntityClick}
            disabledEntities={disabledEntities}
            placeholder={"Search"}
          />
        </UniversalAutocompletePositionWrapper>
      </div>
      <DialogContent className="oflow-hidden p-t-0px">
        <SearchToolsContextComponent
          EntityElement={EntityElementWithOnClick}
          entityType={
            type === SearchEntitiesDialogType.TOOLS ? EntityType.TOOL : AGENT
          }
        >
          {type === SearchEntitiesDialogType.TOOLS ? (
            <ToolTabsContainer
              toolTabs={toolTabs}
              className="m-t-16px"
              disabledEntities={disabledEntities}
            />
          ) : (
            <AgentsTabs className="m-t-16px" />
          )}
        </SearchToolsContextComponent>
      </DialogContent>
    </>
  );
}

function OverviewCardWrapper() {
  const { tool } = useToolContext();
  return (
    <OverviewCard
      description={tool.about}
      avatar={tool.avatar}
      title={
        <div className="flex align-i-center">
          {tool.name}
          <NavigateToToolBuilderButton
            toolId={tool._id}
            variant="icon"
            className="m-l-8px"
          />
        </div>
      }
      className="background-color-overview-card"
    />
  );
}

function EntityConfigureStep() {
  const { entityId, formState, setFormState, onClickDoneWithSelectedEntity } =
    useSearchToolsDialog();

  const { data, isLoading } = useGetToolQuery(entityId || "", {
    skip: !entityId
  });

  if (isLoading) {
    return <FormSkeleton show={true} />;
  }

  return (
    <>
      <ToolContextComponentContainer toolId={entityId}>
        <ConfigureStepHeader />

        <DialogContent>
          <OverviewCardWrapper />
          <FormSkeleton show={isLoading} />

          {!isLoading && data?.tool?.main?.toolInputFields && (
            <WebsiteForm
              entityId={entityId}
              formState={formState}
              setFormState={setFormState}
              toolInputFields={data.tool.main.toolInputFields}
              allowAgentDeterminedValues
              useIds={isSyntheticToolId(data.tool._id.toString())}
            />
          )}
        </DialogContent>
        <DialogActions>
          {!isLoading && (
            <Button
              size="large"
              className="align-self-end m-t-8px"
              onClick={onClickDoneWithSelectedEntity}
              disabled={!data?.tool?.main?.toolInputFields}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </ToolContextComponentContainer>
    </>
  );
}

export default function EntitySelectStepper() {
  const { step } = useSearchToolsDialog();

  return (
    <>
      {step === 0 ? (
        <EntitySelectStep />
      ) : step === 1 ? (
        <EntityConfigureStep />
      ) : (
        <></>
      )}
    </>
  );
}
