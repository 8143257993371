import {
  CustomToolInputFieldTypesEnum,
  type CustomToolInputField
} from "@toolflow/shared";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";

function transformList(
  input: string[],
  optionalFields?: CustomToolInputField[]
): CustomToolInputField[] {
  let o: CustomToolInputField[] = input.map((name) => ({
    name,
    id: uuidv4(),
    type: CustomToolInputFieldTypesEnum.TEXTFIELD
  }));
  if (optionalFields && !isEmpty(optionalFields)) {
    o = o.concat(optionalFields);
  }
  return o;
}

export default transformList;
