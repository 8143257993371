import {
  $TSAllowedAny,
  AllBlockTypes,
  DecimalString,
  VERSION_1_VALUE
} from "../../../../types";
import { sortSemanticVersions } from "../../../formatters";
import { blockConfig } from "../blockConfig";

export const getLatestBlockVersion = (
  block: AllBlockTypes,
  record: Record<string, Record<DecimalString, $TSAllowedAny>> = blockConfig
): DecimalString => {
  try {
    const blockVersions = record[block];
    const versions = Object.keys(blockVersions);
    const sortedVersions = sortSemanticVersions(versions);
    return sortedVersions[0];
  } catch (error) {
    return VERSION_1_VALUE;
  }
};
