import { type DynamicSetting, evaluateDynamicSetting } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";

export default function useDynamicSetting<T = $TSAllowedAny>(
  field: DynamicSetting,
  defaultValue?: T
) {
  const { data } = useFieldsByTypeContext();
  return evaluateDynamicSetting<T>(field, data, defaultValue);
}
