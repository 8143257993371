import {
  type AllBlockTypes,
  BlockType,
  EFeatureFlags,
  UtilBlockType
} from "@toolflow/shared";
import styles from "./nodeSelector.module.css";
import DraggableBlockContainer from "../blocks/components/blockPaperHeader/draggableBlock/DraggableBlockContainer";
import { useFeatureFlagEnabled } from "posthog-js/react";

const DIVIDER = "divider";

export default function NodeSelector() {
  const userIntegrationsEnabled = useFeatureFlagEnabled(
    EFeatureFlags.USER_INTEGRATIONS
  );

  const blocks: (AllBlockTypes | typeof DIVIDER | null)[] = [
    BlockType.ChatGPT,
    BlockType.DallE,
    BlockType.Deepgram,
    BlockType.Scraper,
    BlockType.Structured,
    BlockType.Perplexity,
    DIVIDER,
    BlockType.IterationStart,
    BlockType.IterationExit,
    BlockType.SERP,
    BlockType.YoutubeTranscript,
    BlockType.Knowledge,
    BlockType.SavetoKnowledge,
    UtilBlockType.Logic,
    userIntegrationsEnabled ? BlockType.UserIntegration : null,
    DIVIDER,
    UtilBlockType.Constant,
    UtilBlockType.ToolWithinTool
  ];
  return (
    <div className={`${styles.paper} scrollable-content`}>
      {blocks.map((block, idx) =>
        block === DIVIDER ? (
          <div className={styles.divider} key={idx} />
        ) : (
          block && <DraggableBlockContainer key={block} type={block} />
        )
      )}
    </div>
  );
}
