import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import { TextInput } from "../../fields/promptInput";
import { CSSProperties } from "react";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";

export function PipedreamControlInput(props: $TSAllowedAny) {
  const { onChange, prop, value } = useFormFieldContext();
  const { getProps } = useCustomize();
  const { id } = useFieldsByTypeContext();
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  const baseStyles: CSSProperties = {
    gridArea: "control"
  };

  return (
    <div {...getProps("controlBoolean", baseStyles, props)}>
      <TextInput
        id={prop?.id}
        label={prop?.label}
        placeholder={prop?.label}
        initialValue={value || ""}
        handleTipTapChange={onChange}
        className="w-100-percent"
        chipsToUse={chipsToUse}
      />
    </div>
  );
}
