import { FormControl } from "@mui/material";
import {
  type CustomToolInputField,
  type TSetFormState,
  type TWordPairsDict,
  type UserInputDictType
} from "@toolflow/shared";
import ReplaceWordsForm from "../../../../pages/builder/toolBuilder/settings/deepgram/ReplaceWordsForm";
import FieldWrapper from "./wrapper/FieldWrapper";

export function WordReplaceField(props: {
  disableLabelAndInfo?: boolean;
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  autofocus?: boolean;
  formState: UserInputDictType;
  disabled?: boolean;
  useIds?: boolean;
}) {
  const { toolInputField, setFormState, formState, disabled, useIds } = props;
  const key = useIds ? toolInputField.id : toolInputField.name;
  const fieldValue = formState[key] as TWordPairsDict;

  function onChange(newValue: TWordPairsDict) {
    setFormState({ ...formState, [key]: newValue });
  }

  return (
    <FormControl
      fullWidth
      disabled={disabled}
      className="w-100-percent"
      size="small"
    >
      <FieldWrapper {...props}>
        <ReplaceWordsForm handleChange={onChange} wordsToReplace={fieldValue} />
      </FieldWrapper>
    </FormControl>
  );
}
