import { useContext } from "react";
import { SearchEntitiesContext } from "../components/SearchEntitiesProvider";

export default function useSearchToolsDialog() {
  const values = useContext(SearchEntitiesContext);
  if (!values) {
    throw new Error("Cannot use SearchToolsDialog outside of context.");
  }
  return values;
}
