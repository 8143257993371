import { Box } from "@mui/material";
import { ReactNode } from "react";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";
import AssetTOCPositioningWrapper from "../../assetTableOfContents/AssetTOCPositioningWrapper";
import useGetElementHeight from "../textAsset/hooks/useGetElementHeight";

function AssetWrapper({
  header,
  className,
  children
}: {
  header: ReactNode;
  className?: string;
  children: ReactNode;
}) {
  const { ref, height } = useGetElementHeight();
  return (
    <div className={classNames("w-100-percent flex", className)}>
      <div className={"w-100-percent m-h-auto parent-hover"}>
        <div ref={ref}>{header}</div>
        <Box
          className="pos-relative p-b-16px h-100-percent"
          sx={{ maxHeight: `calc(100% - ${height}px)` }}
        >
          {children}
        </Box>
      </div>
      <AssetTOCPositioningWrapper />
    </div>
  );
}

export default AssetWrapper;
