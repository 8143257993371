import { OpenAiTextModel, AnthropicModel, PerplexityModel } from "./LLMModels";

export type TOpenAIPriceDictType = {
  [key in OpenAiTextModel]: {
    tokenCount: number;
    label: string;
    skip?: boolean;
  };
};
export type TAnthropicPriceDictType = {
  [key in AnthropicModel]: {
    tokenCount: number;
    label: string;
  };
};
export type TPerplexityPriceDictType = {
  [key in PerplexityModel]: {
    tokenCount: number;
    label: string;
    skip?: boolean;
  };
};
// amount is input token max count , also referred in backend in TokenLengthValidation

export const openAIPriceDict: TOpenAIPriceDictType = {
  [OpenAiTextModel.GPT35]: {
    tokenCount: 16000,
    label: "GPT-3.5"
  },
  [OpenAiTextModel.GPT4]: {
    tokenCount: 8000,
    label: "GPT-4"
  },
  [OpenAiTextModel.GPT4Turbo]: {
    tokenCount: 128000,
    label: "GPT-4 Turbo"
  },
  [OpenAiTextModel.GPT4o]: {
    tokenCount: 128000,
    label: "GPT-4o"
  },
  [OpenAiTextModel.GPT4o_Aug]: {
    tokenCount: 128000,
    label: "GPT-4o Aug",
    skip: true
  },
  [OpenAiTextModel.GPT4o_Mini]: {
    tokenCount: 128000,
    label: "GPT-4o Mini"
  },
  [OpenAiTextModel.GPT45_Preview]: {
    tokenCount: 128000,
    label: "GPT-4.5 Preview"
  },
  [OpenAiTextModel.o1]: {
    tokenCount: 200000,
    label: "o1"
  },
  [OpenAiTextModel.o3_Mini]: {
    tokenCount: 200000,
    label: "o3-mini"
  }
};

export const anthropicPriceDict: TAnthropicPriceDictType = {
  [AnthropicModel.OPUS]: {
    tokenCount: 200000,
    label: "Claude 3 Opus"
  },
  [AnthropicModel.SONNET]: {
    tokenCount: 200000,
    label: "Claude 3 Sonnet"
  },
  [AnthropicModel.SONNET_3_5]: {
    tokenCount: 200000,
    label: "Claude 3.5 Sonnet"
  },
  [AnthropicModel.SONNET_3_5_LATEST]: {
    tokenCount: 200000,
    label: "Claude 3.5 Sonnet"
  },
  [AnthropicModel.HAIKU]: {
    tokenCount: 200000,
    label: "Claude 3 Haiku"
  },
  [AnthropicModel.HAIKU_3_5]: {
    tokenCount: 200000,
    label: "Claude 3.5 Haiku"
  },
  [AnthropicModel.SONNET_3_7]: {
    tokenCount: 200000,
    label: "Claude 3.7 Sonnet"
  }
};

export const perplexityPriceDict: TPerplexityPriceDictType = {
  [PerplexityModel.SonarDeepResearch]: {
    tokenCount: 128000,
    label: "Sonar Deep Research"
  },
  [PerplexityModel.SonarReasoningPro]: {
    tokenCount: 128000,
    label: "Sonar Reasoning Pro"
  },
  [PerplexityModel.SonarReasoning]: {
    tokenCount: 128000,
    label: "Sonar Reasoning"
  },
  [PerplexityModel.SonarPro]: {
    tokenCount: 200000,
    label: "Sonar Pro"
  },
  [PerplexityModel.Sonar]: {
    tokenCount: 128000,
    label: "Sonar"
  },
  [PerplexityModel.R1_1776]: {
    tokenCount: 128000,
    label: "DeepSeek R1 - 1776"
  }
};

export function isOpenAITextModel(model: string): model is OpenAiTextModel {
  return Object.keys(openAIPriceDict).includes(model);
}

export function isAnthropicModel(model: string): model is AnthropicModel {
  return Object.keys(anthropicPriceDict).includes(model);
}

export function isPerplexityModel(model: string): model is PerplexityModel {
  return Object.keys(perplexityPriceDict).includes(model);
}
