// generated from Figma to Code plugin
function EmptyOutputIcon() {
  return (
    <div
      style={{
        width: 180,
        height: 180,
        paddingTop: 31,
        paddingBottom: 32,
        paddingLeft: 22,
        paddingRight: 22,
        background: "white",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex"
      }}
    >
      <div style={{ width: 136, height: 117, position: "relative" }}>
        <div
          style={{
            width: 95.59,
            height: 53,
            left: 40.41,
            top: 64,
            position: "absolute",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 7,
            display: "inline-flex"
          }}
        >
          <div
            style={{
              width: 96,
              padding: 10,
              background: "white",
              boxShadow:
                "0px 10px 30px 0px rgba(138, 45, 255, 0.20), 0px 0px 5px 0px rgba(142, 45, 255, 0.15)",
              borderRadius: 16,
              border: "1px #8A2DFF solid",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "inline-flex"
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "inline-flex"
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  height: 9,
                  background: "#E1D0F8",
                  borderRadius: 4
                }}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: 14,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "flex"
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: 5,
                  background: "#E1D0F8",
                  borderRadius: 4
                }}
              />
              <div
                style={{
                  alignSelf: "stretch",
                  height: 5,
                  background: "#E1D0F8",
                  borderRadius: 4
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: 95.59,
            height: 53,
            left: 0,
            top: 0,
            position: "absolute",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 7,
            display: "inline-flex"
          }}
        >
          <div
            style={{
              width: 96,
              padding: 10,
              background: "white",
              boxShadow:
                "0px 10px 30px 0px rgba(138, 45, 255, 0.20), 0px 0px 5px 0px rgba(142, 45, 255, 0.15)",
              borderRadius: 16,
              border: "1px #8A2DFF solid",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              display: "inline-flex"
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 4,
                display: "inline-flex"
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  height: 9,
                  background: "#E1D0F8",
                  borderRadius: 4
                }}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: 14,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "flex"
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: 5,
                  background: "#E1D0F8",
                  borderRadius: 4
                }}
              />
              <div
                style={{
                  alignSelf: "stretch",
                  height: 5,
                  background: "#E1D0F8",
                  borderRadius: 4
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyOutputIcon;
