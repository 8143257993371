import {
  type CustomToolInputFieldWithOptions,
  type DispatchFormState,
  type UserInputDictType
} from "@toolflow/shared";
import { get, isString } from "lodash";
import PipedreamFields from "./PipedreamConfigurationFields/PipedreamFields";
import { PipedreamFieldsProvider } from "./PipedreamConfigurationFields/PipedreamFieldsProvider";

export function WsPipedreamConfigurationsFields(props: {
  toolInputField: CustomToolInputFieldWithOptions;
  formState: UserInputDictType;
  disabled?: boolean;
  autofocus?: boolean;
  useIds?: boolean;
  disableLabelAndInfo?: boolean;
  allowAgentDeterminedValues?: boolean;
  setFormState: DispatchFormState;
}) {
  const action = get(props.formState, "settings_action", null);

  if (!isString(action)) return <></>;

  return (
    <PipedreamFieldsProvider {...props} action={action}>
      <PipedreamFields />
    </PipedreamFieldsProvider>
  );
}
