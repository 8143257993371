import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import {
  type CustomToolInputField,
  CustomToolInputFieldTypesEnum
} from "@toolflow/shared";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { updateInputType } from "../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import useIsUniqueField from "../useIsUniqueField";
import AddInput from "./AddInput";

const AddInputDialogContainer = ({
  newField,
  setNewField,
  allowedFields,
  hideTypeDropdown
}: {
  newField: CustomToolInputField;
  setNewField: (field: CustomToolInputField) => void;
  allowedFields?: CustomToolInputFieldTypesEnum[];
  hideTypeDropdown?: boolean;
}) => {
  const setFieldWrapper = (field: CustomToolInputField) => {
    setNewField(field);
  };

  const changeInputName = (name: string) => {
    setNewField({ ...newField, name });
  };

  const changeInputDescription = (description: string) => {
    setNewField({ ...newField, description });
  };

  const handleTypeChange = (v: CustomToolInputFieldTypesEnum) => {
    const newF = updateInputType(v, newField);
    setNewField(newF);
  };

  return (
    <AddInput
      newField={newField}
      setNewField={setFieldWrapper}
      allowedFields={allowedFields}
      changeInputType={handleTypeChange}
      changeInputName={changeInputName}
      changeInputDescription={changeInputDescription}
      hideTypeDropdown={hideTypeDropdown || allowedFields?.length === 1}
    />
  );
};

const AddInputDialog = ({
  openModal,
  setOpenModal,
  callback,
  closeCallback,
  initialName = "",
  initialType = CustomToolInputFieldTypesEnum.TEXTFIELD,
  initialOptions,
  hideTypeDropdown,
  allowedFields,
  addNewFieldCondition = false
}: {
  setOpenModal: (a: boolean) => void;
  openModal: boolean;
  callback?: (s: CustomToolInputField) => void;
  closeCallback?: () => void;
  initialName?: string;
  initialType?: CustomToolInputFieldTypesEnum;
  hideTypeDropdown?: boolean;
  allowedFields?: CustomToolInputFieldTypesEnum[];
  initialOptions?: string[];
  addNewFieldCondition?: boolean;
}) => {
  const { state, dispatch } = useToolbuilderContext();
  const isUniqueField = useIsUniqueField();

  const getReset = () => {
    return {
      name: initialName,
      type: initialType,
      id: uuidv4(),
      options: initialOptions
    } as CustomToolInputField;
  };

  const [newField, setNewField] = useState<CustomToolInputField>(getReset());

  useEffect(() => {
    setNewField(getReset());
  }, [initialName, initialType, initialOptions]);
  //   const [options, setOptions] = useState<string[] | undefined>(undefined);

  const onAddField = (updatedToolInputFields: CustomToolInputField[]) => {
    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
  };

  const handleCloseModal = () => {
    if (closeCallback) {
      closeCallback();
    }
    setOpenModal(false);
    setNewField(getReset());
  };

  const handleSaveNewField = () => {
    if (isUniqueField(newField.name, state.currentState.toolOutputOptions)) {
      if (!addNewFieldCondition) {
        onAddField([...state.currentState.toolInputFields, { ...newField }]);
        if (callback) {
          callback(newField);
        }
      }
      setOpenModal(false);
      setNewField(getReset());
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth
      disableRestoreFocus
      id="dontFocusOnClickId"
      PaperProps={{ id: "dontCloseOnClickId" }}
    >
      <DialogTitle>Add user input</DialogTitle>
      <DialogContent>
        <AddInputDialogContainer
          newField={newField}
          setNewField={setNewField}
          allowedFields={allowedFields}
          hideTypeDropdown={hideTypeDropdown}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button onClick={handleSaveNewField} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInputDialog;
