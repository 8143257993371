export enum PDFieldType {
  App = "app",
  Boolean = "boolean",
  Integer = "integer",
  String = "string",
  StringArray = "string[]"
}

export type PipedreamRemoteOption = {
  value: string;
  label: string;
  description?: string;
};
