import {
  type GetIntegrationsResponse,
  type PipedreamAppsResponse,
  type UserIntegration
} from "@toolflow/shared";
import { useCallback } from "react";

export const useIntegrationFilter = (
  userIntegrations: GetIntegrationsResponse,
  appsData: PipedreamAppsResponse,
  searchQuery?: string
) => {
  const getFilteredApps = useCallback(() => {
    if (searchQuery?.trim()) {
      return appsData?.data || [];
    }

    const connectedApps =
      userIntegrations?.integrations?.map((integration: UserIntegration) => ({
        id: integration.integrationType,
        name_slug: integration.integrationType,
        name: integration.metadata?.name || integration.integrationType,
        description: integration.metadata?.description,
        img_src: integration.metadata?.img_src
      })) || [];

    const allApps = (appsData?.data || []).filter(
      (app: { name_slug: string }) =>
        !connectedApps.some(
          (connectedApp) => connectedApp.name_slug === app.name_slug
        )
    );

    return [...connectedApps, ...allApps];
  }, [userIntegrations?.integrations, appsData?.data, searchQuery]);

  return { getFilteredApps };
};
