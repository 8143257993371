import { EntityType } from "@toolflow/shared";
import useNavigateToWorkspaceWithDefaultObject from "./useNavigateToWorkspaceWithDefaultObject";

const useNavigateToWorkflowById = () => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject();
  const handleNavigate =
    (workflowId = "") =>
    (e: React.MouseEvent) => {
      if (!workflowId) return;
      navigateToWorkspace({
        type: EntityType.WORKFLOW,
        id: workflowId
      })(e);
    };
  return handleNavigate;
};

export default useNavigateToWorkflowById;
