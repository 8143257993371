import { alpha, createTheme, useMediaQuery } from "@mui/material";
import appTheme, { toolflowAppBackground } from "./muiUtils/appTheme";

const sidebarTheme = createTheme(appTheme, {
  palette: {
    mode: "light",
    primary: {
      main: appTheme.palette.primary.main
    },
    background: {
      paper: toolflowAppBackground
    }
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: "52px",
          fontWeight: 600,
          borderRadius: 16,
          "&.Mui-selected": {
            // backgroundColor: "#fff",
            color: appTheme.palette.primary.main,
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff"
            },
            "& .MuiListItemText-root": {
              "& .MuiTypography-root": {
                color: appTheme.palette.text.primary
              }
            }
          },
          "& .MuiTypography-root": {
            color: appTheme.palette.text.primary,
            fontFamily: "Konnect",
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: "0.1px"
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: "large"
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          borderRadius: 16
        }
      },
      variants: [
        {
          props: { variant: "action" },
          style: {
            backgroundColor: appTheme.palette.primary.main,
            color: appTheme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: appTheme.palette.primary.main,
              color: appTheme.palette.primary.contrastText,
              opacity: 0.9
            }
          }
        },
        {
          props: { variant: "secondaryContained" },
          style: {
            backgroundColor: alpha(appTheme.palette.primary.main, 0.04),
            color: appTheme.palette.primary.main
          }
        }
      ]
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderRadius: 16
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#EDE7F6",
          color: appTheme.palette.primary.main,
          borderRadius: 100,
          height: 6
        }
      }
    }
  }
});

export const closedSmallSidebarWidthInPx = 56;
export const closedLargeSidebarWidthInPx = 64;

export const useSidebarWidth = () => {
  const isSmallAndBigger = useMediaQuery(sidebarTheme.breakpoints.up("sm"));
  const sidebarBase = isSmallAndBigger
    ? closedLargeSidebarWidthInPx
    : closedSmallSidebarWidthInPx;
  return sidebarBase + 1;
};

export default sidebarTheme;
