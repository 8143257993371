import { ListItemIcon, ListItemText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { type SelectInputProps } from "@mui/material/Select/SelectInput";
import {
  type AllBlockTypes,
  type SelectOption,
  type UpdateField
} from "@toolflow/shared";
import { isString } from "lodash";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";
import InputLabelWrapper from "../components/InputLabelWrapper";
import { blockIcons } from "../../../blocks/configs/blockIcons";

interface Props {
  id: string;
  value: string;
  fieldKey: string;
  label?: string;
  subtitle?: string;
  disabled?: boolean;
  options: SelectOption[];
  callback?: (
    value: string,
    data: $TSAllowedAny,
    updateField: UpdateField
  ) => void;
}

export default function SelectInputInner({
  value,
  fieldKey,
  label,
  options,
  subtitle,
  disabled,
  callback
}: Props) {
  const { data, updateField } = useFieldsByTypeContext();

  const onChange: SelectInputProps["onChange"] = (event) => {
    updateField(event.target.value as string, fieldKey);
    if (callback) {
      callback(event.target.value as string, data, updateField);
    }
  };

  return (
    <FormControl fullWidth margin="none" disabled={disabled}>
      <InputLabelWrapper label={label} subtitle={subtitle}>
        <Select
          disabled={disabled}
          size="small"
          value={value}
          className="nowheel nodrag nopan"
          onChange={onChange}
          renderValue={(val) => {
            const option = options.find((opt) =>
              isString(opt) ? opt === val : opt.value === val
            );
            const OptionIcon = isString(option)
              ? null
              : option?.type && blockIcons[option.type as AllBlockTypes];
            return (
              <MenuItem
                disableGutters
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent"
                  }
                }}
              >
                {OptionIcon && (
                  <ListItemIcon>{<OptionIcon size={20} />}</ListItemIcon>
                )}
                <ListItemText
                  primary={isString(option) ? option : option?.label}
                />
              </MenuItem>
            );
          }}
        >
          {options.map((option, i) => {
            if (isString(option)) {
              return (
                <MenuItem value={option} key={i}>
                  <ListItemText primary={option} />
                </MenuItem>
              );
            }
            const OptionIcon =
              option.type && blockIcons[option.type as AllBlockTypes];

            return (
              <MenuItem value={option.value} key={option.value}>
                {OptionIcon && (
                  <ListItemIcon>{<OptionIcon size={20} />}</ListItemIcon>
                )}
                <ListItemText
                  primary={option.label}
                  secondary={option.subText}
                />
              </MenuItem>
            );
          })}
        </Select>
      </InputLabelWrapper>
    </FormControl>
  );
}
