import { Box, Stack } from "@mui/material";
import {
  evaluateDynamicSetting,
  FieldType,
  type FieldsByTypeProps
} from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import GroupedFieldWrapper from "./components/fieldsGroupComponent/GroupedFieldWrapper";
import styles from "./fieldsByType.module.css";
import { FieldsByTypeProvider } from "./FieldsByTypeContext";
import { fieldTypeComponents } from "./fieldTypeComponents";

const cx = parsedClassNames.bind(styles);

export default function FieldsByType({ configs }: FieldsByTypeProps) {
  return (
    <Stack spacing={2}>
      {configs.fields.map((field, index) => {
        const { data, id, chipsToUse, updateField } = configs;

        if (field.type === FieldType.Group) {
          return (
            <GroupedFieldWrapper key={index} configs={configs} group={field} />
          );
        }
        const hidden = evaluateDynamicSetting<boolean>(
          field.hidden,
          data,
          false
        );

        const Component = fieldTypeComponents[field.type];
        if (!Component || hidden) return null;
        return (
          <Box key={index} className={cx("container")}>
            <FieldsByTypeProvider
              id={id}
              data={data}
              field={field}
              chipsToUse={chipsToUse}
              updateField={updateField}
            >
              <Component settings={data} id={id} field={field} />
            </FieldsByTypeProvider>
          </Box>
        );
      })}
    </Stack>
  );
}
