import {
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH,
  PROFILE_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  SNIPPET_ROUTE_PATH,
  TOOLBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  WORKSPACE_ROUTE_PATH,
  AGENT_BUILDER_ROUTE_PATH,
  AGENTS_ROUTE_PATH,
  EXTERNAL_AGENT_ROUTE_PATH,
  BILLING_CTA_ROUTE_PATH
} from "./routePaths";
import routeTitleDict from "./routeTitleDict";
import routeElementDict from "./routeElementDict";

type TSnippetRoutePath = typeof SNIPPET_ROUTE_PATH;
type TKnowledgeRoutePath = typeof KNOWLEDGE_ROUTE_PATH;
type TAddAgentRoutePath = typeof AGENT_BUILDER_ROUTE_PATH;
type TAgentsRoutePath = typeof AGENTS_ROUTE_PATH;
type TToolBuilderRoutePath = typeof TOOLBUILDER_ROUTE_PATH;
type TWorkflowBuilderRoutePath = typeof WORKFLOWBUILDER_ROUTE_PATH;
type TLoginRoutePath = typeof LOGIN_ROUTE_PATH;
type TSignupRoutePath = typeof SIGNUP_ROUTE_PATH;
type TProfileRoutePath = typeof PROFILE_ROUTE_PATH;
type TMarketplaceRoutePath = typeof TOOLS_PAGE_ROUTE_PATH;
type TWorkspaceRoutePath = typeof WORKSPACE_ROUTE_PATH;
type TExternalToolRoutePath = typeof EXTERNAL_TOOL_ROUTE_PATH;
type TExternalAgentRoutePath = typeof EXTERNAL_AGENT_ROUTE_PATH;
type TExternalWorkflowRoutePath = typeof EXTERNAL_WORKFLOW_ROUTE_PATH;
type TExternalWorkspaceRoutePath = typeof EXTERNAL_WORKSPACE_ROUTE_PATH;
type TBillingRoutePath = typeof BILLING_CTA_ROUTE_PATH;
type TExternalToolVersionResponseRoutePath =
  typeof EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH;

type TRoutePath =
  | TAddAgentRoutePath
  | TAgentsRoutePath
  | TSnippetRoutePath
  | TExternalWorkflowRoutePath
  | TExternalAgentRoutePath
  | TToolBuilderRoutePath
  | TWorkflowBuilderRoutePath
  | TLoginRoutePath
  | TSignupRoutePath
  | TMarketplaceRoutePath
  | TWorkspaceRoutePath
  | TExternalToolRoutePath
  | TExternalWorkspaceRoutePath
  | TKnowledgeRoutePath
  | TExternalToolVersionResponseRoutePath
  | TProfileRoutePath
  | TBillingRoutePath;

const orderedRoutesArray: TRoutePath[] = [
  TOOLBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  AGENT_BUILDER_ROUTE_PATH,
  AGENTS_ROUTE_PATH,
  SNIPPET_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH,
  WORKSPACE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_AGENT_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  PROFILE_ROUTE_PATH,
  BILLING_CTA_ROUTE_PATH
];

const populatedRouteArray = orderedRoutesArray.map((path) => {
  return { path, element: routeElementDict[path], title: routeTitleDict[path] };
});

export default populatedRouteArray;
