import { FormControl, TextField } from "@mui/material";
import {
  type CustomToolInputField,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import FieldWrapper from "./wrapper/FieldWrapper";

export function InputNumericField(props: {
  disableLabelAndInfo?: boolean;
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  autofocus?: boolean;
  formState: UserInputDictType;
  disabled?: boolean;
  useIds?: boolean;
}) {
  const { toolInputField, setFormState, formState, disabled, useIds } = props;
  const key = useIds ? toolInputField.id : toolInputField.name;
  const fieldValue = formState[key];

  return (
    <FormControl fullWidth className="w-100-percent" size="small">
      <FieldWrapper {...props}>
        <TextField
          fullWidth
          size="small"
          value={fieldValue}
          type={"number"}
          disabled={disabled}
          className="bg-color-white"
          onChange={(e) => {
            setFormState({ ...formState, [key]: Number(e.target.value) });
          }}
        />
      </FieldWrapper>
    </FormControl>
  );
}
