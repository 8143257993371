import { useGetIntegrationsQuery } from "../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { useAuthStore } from "../../auth/hooks/useAuthStore";

// We want to always make sure that someone is authorized before getting integrations
const useGetIntegrations = () => {
  const { isAuthenticated } = useAuthStore();
  return useGetIntegrationsQuery(undefined, { skip: !isAuthenticated });
};

export default useGetIntegrations;
