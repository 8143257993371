import {
  get,
  isArray,
  isBoolean,
  isNull,
  isPlainObject,
  isUndefined
} from "lodash";
import {
  $TSAllowedAny,
  BooleanCondition,
  ComparisonCondition,
  Condition,
  ConditionRule,
  LogicalConditionRule,
  SingleConditionRule
} from "../../../../types";

export function isEvalCondition(
  condition: $TSAllowedAny
): condition is Condition {
  return (
    isArray(condition) &&
    ["true", "false", "==", "!=", ">", "<", "includes"].includes(condition[1])
  );
}

export function isEvalConditionArray(
  conditions: $TSAllowedAny
): conditions is Condition[] {
  return (
    isArray(conditions) &&
    conditions.every((condition) => isEvalCondition(condition))
  );
}

export function isBoolCondition(
  condition: Condition
): condition is BooleanCondition {
  return isArray(condition) && ["true", "false"].includes(condition[1]);
}

export function isComparisonCondition(
  condition: Condition
): condition is ComparisonCondition {
  return (
    isArray(condition) &&
    ["==", "!=", ">", "<", "includes"].includes(condition[1])
  );
}

export function isSingleConditionRule(
  rule: $TSAllowedAny
): rule is SingleConditionRule {
  return isPlainObject(rule) && "condition" in rule;
}

export function isLogicalConditionRule(
  rule: $TSAllowedAny
): rule is LogicalConditionRule {
  return isPlainObject(rule) && "conditions" in rule;
}

export function isConditionRule(rule: $TSAllowedAny): rule is ConditionRule {
  return isPlainObject(rule) && ("condition" in rule || "conditions" in rule);
}

export function evaluateCondition(condition: Condition, data: object) {
  const [path, operator, thenOrValue, maybeValue] = condition;
  const valueAtPath = get(data, path);
  const isBooleanOperator = ["true", "false"].includes(operator);
  const thenValue = isBooleanOperator ? thenOrValue : maybeValue;
  const hasThenValue = !isUndefined(thenValue) && !isNull(thenValue);

  const evalResult = (() => {
    switch (operator) {
      case "true":
        return isBoolean(valueAtPath) && valueAtPath;
      case "false":
        return isBoolean(valueAtPath) && !valueAtPath;
      case "==":
        return valueAtPath === thenOrValue;
      case "!=":
        return valueAtPath !== thenOrValue;
      case ">":
        return valueAtPath > thenOrValue;
      case "<":
        return valueAtPath < thenOrValue;
      case "includes":
        return isArray(valueAtPath) && valueAtPath.includes(thenOrValue);
      default:
        return false;
    }
  })();

  return hasThenValue ? (evalResult ? thenValue : undefined) : evalResult;
}

export function evaluateConditionRule(rule: ConditionRule, data: object) {
  if (isSingleConditionRule(rule)) {
    const result = evaluateCondition(rule.condition, data);
    return !isUndefined(result) ? (rule.then ?? true) : (rule.else ?? false);
  }

  const { conditions, logic, then, else: elseValue } = rule;

  // Handle first-passing condition with a "then" value
  if (
    !logic &&
    conditions.some((c) => (isBoolCondition(c) ? !!c[2] : !!c[3]))
  ) {
    for (const condition of conditions) {
      const result = evaluateCondition(condition, data);
      if (!isUndefined(result)) return result;
    }
  }

  // Evaluate all conditions based on logical operator
  const evaluations = conditions.map((condition) =>
    evaluateCondition(condition, data)
  );
  const passes =
    logic === "OR" ? evaluations.some(Boolean) : evaluations.every(Boolean);

  return passes ? (then ?? true) : (elseValue ?? false);
}
