import { FormControl, Stack, Typography } from "@mui/material";
import {
  type WordReplaceDict,
  type WordReplaceFieldProps
} from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import ReplaceWordsForm from "../../../../settings/deepgram/ReplaceWordsForm";
import styles from "../../components/dynamicFieldWrapper/dynamicFieldWrapper.module.css";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useDynamicSetting from "../../hooks/useDynamicSetting";

const cx = parsedClassNames.bind(styles);

export function WordReplaceField({ field }: WordReplaceFieldProps) {
  const { getFieldValue, updateField } = useFieldsByTypeContext();
  const { fieldKey, defaultValue = {} } = field.config;

  const fieldValue = getFieldValue<WordReplaceDict>(fieldKey, defaultValue);
  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const hidden = useDynamicSetting<boolean>(field.hidden);

  if (hidden) {
    return null;
  }

  return (
    <FormControl margin="none" fullWidth>
      <div className={cx("border-radius-16px", "container")}>
        <Stack spacing={1}>
          <div>
            <Typography variant="h8" fontWeight="500">
              {label}
            </Typography>
            {subtitle && (
              <Typography variant="body2" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </div>
          <ReplaceWordsForm
            handleChange={(value) => {
              updateField(value, fieldKey);
            }}
            wordsToReplace={fieldValue}
          />
        </Stack>
      </div>
    </FormControl>
  );
}
