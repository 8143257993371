import { Box, Paper, Typography } from "@mui/material";
import { toolflowTextSecondaryColor } from "../../../../../../../globalTheme/muiUtils/appTheme";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import BlockAlert from "../BlockAlert";
import BlockIconWrapper from "../blockIcons/BlockIconWrapper";
import DeleteNodeButtonContainer from "../deleteNodeButton/DeleteNodeButtonContainer";
import styles from "./blockPaperHeader.module.css";
import useBlockPaperHeader from "./useBlockPaperHeader";
import useIterationHelpers from "../../../hooks/useIterationHelpers";

const cx = parsedClassNames.bind(styles);

function BlockPaperHeader() {
  const { isOpenNode, label, BlockIcon } = useBlockPaperHeader();

  const { isIterationBlock, isIterationChild } = useIterationHelpers();

  return (
    <Paper
      variant="outlined"
      className={cx(
        {
          "border-on-hover": !isOpenNode,
          "border-on-select": isOpenNode
        },
        "cursor-pointer border-radius-16px bg-color-right-side-content",
        styles["paper-header-container"]
      )}
    >
      {isIterationChild && !isIterationBlock && (
        <>
          <div className={styles["iteration-child-shadow"]} />
          {!isOpenNode && (
            <>
              <div className={styles["iteration-child-border"]} />
              <div className={styles["iteration-child-border-arrow"]} />
            </>
          )}
        </>
      )}
      <Box
        className={cx(
          "flex align-i-center justify-space-between p-8px border-radius-16px background-white",
          styles["paper-header"],
          { [`${styles["iteration-block-header"]}`]: isIterationBlock }
        )}
      >
        <div
          className="align-i-center flex w-100-percent"
          style={{ maxWidth: "88%" }}
        >
          {BlockIcon && (
            <BlockIconWrapper
              BlockIcon={
                <BlockIcon color={toolflowTextSecondaryColor} size={20} />
              }
            />
          )}
          <div className="m-l-8px flex flex-column">
            <Typography variant="nodeHeader">{label}</Typography>
          </div>
        </div>
        <div className="flex align-i-center">
          <DeleteNodeButtonContainer transparentBg={isIterationBlock} />
        </div>
      </Box>
      <BlockAlert />
    </Paper>
  );
}

export default BlockPaperHeader;
