import { FormControl, MenuItem, Select } from "@mui/material";
import {
  useAccounts,
  useCustomize,
  useFormFieldContext
} from "@pipedream/connect-react";
import { useDispatch } from "react-redux";
import {
  useConnectIntegrationMutation,
  useDeleteIntegrationAccountMutation,
  useGenerateTokenMutation
} from "../../../../../../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { usePipedreamClient } from "../../../../../../../integrations/context/PipedreamContext";
import { setErrorMessage } from "../../../../../../../layout/alertMessageSnackbar/alertMessageSlice";
import styles from "./styles/pipedreamField.module.css";

export function PipedreamAppSelect(props: $TSAllowedAny) {
  const { onChange, prop, value, extra } = useFormFieldContext();
  const { pdClient } = usePipedreamClient();
  const { getProps } = useCustomize();
  const app = extra?.app;
  const dispatch = useDispatch();

  const [connectIntegration] = useConnectIntegrationMutation();
  const [generateToken] = useGenerateTokenMutation();
  const [deleteIntegrationAccount] = useDeleteIntegrationAccountMutation();

  const baseStyles = {
    gridArea: "control"
  };

  const { accounts, refetch: refetchAccounts } = useAccounts(
    {
      app: app?.name_slug,
      // Using Pipedream's standard OAuth flow instead of custom OAuth app
      oauth_app_id: undefined
    },
    {
      useQueryOpts: {
        enabled: !!app
      }
    }
  );

  const startConnectAccount = async () => {
    if (!pdClient) return;

    try {
      const tokenResponse = await generateToken().unwrap();
      if (!tokenResponse.token) return;

      pdClient.connectAccount({
        app: prop.app,
        token: tokenResponse.token,
        oauthAppId: undefined,
        onSuccess: async (res: { id: string }) => {
          try {
            const existingAccount = accounts?.find(
              (account) =>
                account.app.name_slug === app?.name_slug &&
                account.healthy === true
            );

            if (existingAccount) {
              await deleteIntegrationAccount(res.id).unwrap();

              dispatch(
                setErrorMessage(
                  `An account (${existingAccount.name}) is already connected for ${app?.name}. Please use the existing account or disconnect it first.`
                )
              );
              return;
            }

            await connectIntegration({
              integrationType: prop.app,
              accountId: res.id,
              metadata: {
                name: app?.name,
                description: app?.description,
                img_src: app?.img_src,
                auth_type: app?.auth_type,
                categories: app?.categories || []
              }
            }).unwrap();

            await refetchAccounts();
            onChange({
              authProvisionId: res.id
            });
          } catch (error) {
            console.error("Error saving integration:", error);
          }
        }
      });
    } catch (error) {
      console.error("Error generating token:", error);
    }
  };

  return (
    <div {...getProps("label", baseStyles, props)}>
      <FormControl fullWidth>
        <Select
          fullWidth
          size="small"
          value={value?.authProvisionId || ""}
          onChange={(e) => {
            if (e.target.value === "_new") {
              startConnectAccount();
            } else {
              onChange({
                authProvisionId: e.target.value
              });
            }
          }}
          className="nowheel nodrag nopan"
        >
          {accounts?.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              <div className={styles["account-container"]}>
                {account.img_src && (
                  <img
                    src={account.img_src}
                    alt={account.name}
                    className={styles["account-image"]}
                  />
                )}
                {account.name}
              </div>
            </MenuItem>
          ))}
          <MenuItem value="_new">Connect new {app?.name} account...</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
