import {
  BlockType,
  createIntegrationBlockId,
  createSyntheticToolId,
  EntityType,
  isIntegrationBlockAppId,
  isMarketplaceEntity,
  isPipedreamApp,
  type PipedreamApp,
  type TDisabledEntities,
  type TMarketplaceUniversalEntity,
  type TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react";
import { useAuthStore } from "../../../../auth/hooks/useAuthStore";
import EntityCard from "../../../../entities/components/entityCard/EntityCard";
import useGetAllEntities from "../../../hooks/useGetAllEntities";
import IntegrationEntityCard from "../../searchToolsContainer/components/gridCards/IntegrationEntityCard";
import { SearchEntitiesDialogType } from "../SearchEntitiesDialog";

interface ISearchEntitiesDialogue {
  step: number;
  type: SearchEntitiesDialogType;
  open: boolean;
  title: string;
  entityId?: string;
  disabled: boolean;
  formState: Record<string, $TSAllowedAny>;
  selectedEntity: TMarketplaceUniversalEntity | PipedreamApp | null;
  disabledEntities?: TDisabledEntities;
  allowConfiguration?: boolean;
  button: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
  }>;
  setFormState: Dispatch<SetStateAction<Record<string, $TSAllowedAny>>>;
  isConfigureOnly: boolean;
  handleBack(): void;
  handleOpen(): void;
  handleClose(): void;
  setIsConfigureOnly: Dispatch<SetStateAction<boolean>>;
  handleEntityClick(
    id: string,
    entityType: TMarketplaceUniversalEntityType
  ): void;
  onClickEntityWithClose(
    id: string,
    type: TMarketplaceUniversalEntityType
  ): void;
  EntityElementWithOnClick(
    args: { entity: TMarketplaceUniversalEntity } | { app: PipedreamApp }
  ): JSX.Element;
  onClickDoneWithSelectedEntity(): void;
}

export const SearchEntitiesContext =
  createContext<ISearchEntitiesDialogue | null>(null);

export default function SearchEntitiesProvider({
  type,
  title,
  button,
  children,
  editFormProps,
  disabledEntities,
  allowConfiguration,
  onClickEntity
}: {
  title: string;
  type: SearchEntitiesDialogType;
  children: ReactNode;
  disabledEntities?: TDisabledEntities;
  allowConfiguration?: boolean;
  editFormProps?: {
    toolId: string;
    props?: Record<string, $TSAllowedAny>;
    isConfigureOnly?: boolean;
  };
  button: React.ReactElement<{
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
  }>;
  onClickEntity: (
    id: string,
    type: TMarketplaceUniversalEntityType,
    toolProps?: Record<string, $TSAllowedAny>
  ) => void;
}) {
  const { isLoading: disabled } = useAuthStore();
  const entities = useGetAllEntities();

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [formState, setFormState] = useState({});
  const [isConfigureOnly, setIsConfigureOnly] = useState(false);
  const [entityId, setEntityId] = useState<string>();
  const [selectedEntity, setSelectedEntity] = useState<
    TMarketplaceUniversalEntity | PipedreamApp | null
  >(null);

  function setEntity(
    entity: TMarketplaceUniversalEntity | PipedreamApp | null
  ) {
    const id = isPipedreamApp(entity)
      ? createIntegrationBlockId(entity.name_slug)
      : entity?.id;

    setEntityId(id);
    setSelectedEntity(entity);
  }

  function handleBack() {
    setStep((prev) => {
      const prevStep = --prev;
      if (prevStep === 0) {
        setFormState({});
        setEntity(null);
      }
      return prevStep;
    });
  }

  function handleOpen() {
    setOpen(true);
    setStep(0);
    setEntity(null);
  }

  function handleClose() {
    setStep(0);
    setFormState({});
    setIsConfigureOnly(false);
    setEntityId(undefined);
    setSelectedEntity(null);
    setOpen(false);
  }

  function onClickEntityWithClose(
    id: string,
    entityType: TMarketplaceUniversalEntityType
  ) {
    onClickEntity(id, entityType);
    handleClose();
  }

  function handleEntityClick(
    id: string,
    entityType: TMarketplaceUniversalEntityType
  ) {
    const entity = entities.find((e) => e.id === id);
    if (entity && entityType === EntityType.TOOL) {
      if (allowConfiguration) {
        setStep(1);
        setEntity(entity);
      } else {
        onClickEntityWithClose(entity.id, entity.type);
      }
    }
  }

  function onClickDoneWithSelectedEntity() {
    if (!!entityId) {
      const entityType = isMarketplaceEntity(selectedEntity)
        ? selectedEntity.type
        : EntityType.TOOL;
      onClickEntity(entityId, entityType, formState);
      handleClose();
    } else {
      throw new Error("No entity found");
    }
  }

  const EntityElementWithOnClick = useCallback(
    (props: { entity: TMarketplaceUniversalEntity } | { app: PipedreamApp }) =>
      "app" in props ? (
        <IntegrationEntityCard
          app={props.app}
          onClick={() => {
            if (allowConfiguration) {
              setStep(1);
              setEntity(props.app);
              setIsConfigureOnly(false);
            } else {
              onClickEntityWithClose(
                createSyntheticToolId(BlockType.UserIntegration),
                EntityType.TOOL
              );
            }
          }}
        />
      ) : (
        <EntityCard
          entity={props.entity}
          onClick={() => {
            if (allowConfiguration) {
              setStep(1);
              setEntity(props.entity);
              setIsConfigureOnly(false);
            } else {
              onClickEntityWithClose(props.entity.id, props.entity.type);
            }
          }}
          disableActions
        />
      ),
    [onClickEntityWithClose]
  );

  useEffect(() => {
    if (editFormProps) {
      setOpen(true);
      setStep(1);
      if (editFormProps.props) setFormState(editFormProps.props);
      if (editFormProps.isConfigureOnly) setIsConfigureOnly(true);

      const isIntegrationBlock = isIntegrationBlockAppId(editFormProps.toolId);

      if (isIntegrationBlock) {
        setEntityId(editFormProps.toolId);
      } else {
        const entity = entities.find((e) => e.id === editFormProps.toolId);
        if (entity) setEntity(entity);
      }
    }
  }, [editFormProps]);

  return (
    <SearchEntitiesContext.Provider
      value={{
        step,
        open,
        type,
        title,
        button,
        entityId,
        disabled,
        formState,
        selectedEntity,
        isConfigureOnly,
        disabledEntities,
        allowConfiguration,
        handleBack,
        handleOpen,
        handleClose,
        setFormState,
        handleEntityClick,
        setIsConfigureOnly,
        onClickEntityWithClose,
        EntityElementWithOnClick,
        onClickDoneWithSelectedEntity
      }}
    >
      {children}
    </SearchEntitiesContext.Provider>
  );
}
