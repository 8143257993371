import type { Content } from "@tiptap/core";
import { FC } from "react";
import { GenericRichContent } from "../../../../../../utilities/components/textFields/workspace/GenericRichContent";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import MessageFileChips from "./ChatInputPaper/MessageFileChips";
import ThreadActions from "./threadActions/ThreadActions";
import threadStyles from "./threadMessage.module.css";
import styles from "./userMessage.module.css";

const cx = parsedClassNames.bind(threadStyles);
interface IUserMessageProps {
  messageContent: Content;
  files?: string[];
}

const UserMessage: FC<IUserMessageProps> = (props) => {
  const { messageContent } = props;
  return (
    <div
      className={cx(
        "w-fit-content pos-relative parent-hover m-l-auto",
        styles["user-message-container"]
      )}
    >
      <MessageFileChips files={props.files || []} />
      <GenericRichContent
        content={messageContent}
        className={cx(styles["user-message"], "light-chat-border")}
        key="user-message"
      />
      <ThreadActions
        content={messageContent}
        className={cx(
          "pos-absolute show-on-hover",
          threadStyles["thread-actions"]
        )}
      />
    </div>
  );
};
export default UserMessage;
