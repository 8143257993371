import { TextField, Typography } from "@mui/material";
import { MAX_ROWS_LARGE_TEXTFIELD } from "@toolflow/shared";
import { useSelector } from "react-redux";
import { DataExtractorIcon } from "../../../../../../../../globalTheme/icons/icons";
import { RootState } from "../../../../../../../../stores/store";
import { DrawerEnum } from "../../../../store/toolBuilderSlice";
import DrawerBottom from "../../drawerBottom/DrawerBottom";
import DrawerHeaderContainer from "../../drawerHeader/DrawerHeaderContainer";

const GenerateSchemaDrawer = () => {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  if (drawer?.type === DrawerEnum.generateSchema && drawer.schema) {
    return (
      <>
        <DrawerHeaderContainer
          BlockIcon={<DataExtractorIcon />}
          title={<Typography variant="h8">Generate Schema</Typography>}
        />
        <DrawerBottom
          mainContent={
            <TextField
              disabled
              multiline
              fullWidth
              rows={MAX_ROWS_LARGE_TEXTFIELD}
              value={JSON.stringify(drawer.schema)}
              margin="normal"
            />
          }
        />
      </>
    );
  }
  return null;
};

export default GenerateSchemaDrawer;
