import { generateText, type JSONContent } from "@tiptap/core";
import {
  CopyableField,
  CustomToolInputFieldTypesEnum,
  type CustomToolInputField,
  type CustomToolOutputField,
  type UserInputDictType
} from "@toolflow/shared";
import useExtension from "./useExtensions/useExtension";

type InputsAndOutputs = CustomToolInputField | CustomToolOutputField;

function useConvertTipTapToText() {
  const extensions = useExtension();

  return function convertTipTapToText(
    jsonContent: JSONContent,
    name: string
  ): string {
    if (jsonContent === null || jsonContent === undefined) return "";
    let text = "";
    try {
      text = generateText(jsonContent, extensions, { blockSeparator: " " });
    } catch (e) {
      console.log("error generating text", jsonContent, name, e);
    }
    return text;
  };
}

function useValidateUserInput(useIds?: boolean) {
  const convertTipTapToText = useConvertTipTapToText();

  function validateTextField(
    inputOrOutput: InputsAndOutputs,
    newUserInput: UserInputDictType
  ): string {
    const key = useIds ? inputOrOutput.id : inputOrOutput.name;
    const val = newUserInput[key];
    // if the value is empty, it will throw an error in convertTipTapToText
    // instead just return an empty string
    if (!val) return "";
    const text = convertTipTapToText(val as JSONContent, key);
    if (
      text.trim() === "" &&
      JSON.stringify(val || {}).includes("inputComponent")
    ) {
      throw new Error(`Input chip in field:${key} is empty`);
    }
    return text;
  }

  // New: Generic function for handling text field validation to avoid code duplication
  function handleTextFieldValidation(
    inputOrOutput: InputsAndOutputs,
    newUserInput: UserInputDictType
  ) {
    const key = useIds ? inputOrOutput.id : inputOrOutput.name;
    const text = validateTextField(inputOrOutput, newUserInput);
    newUserInput[key] = text;
  }

  // Validation functions dictionary
  const validationFunctions: Partial<
    Record<
      CustomToolInputFieldTypesEnum | CopyableField,
      (inputOrOutput: InputsAndOutputs, newUserInput: UserInputDictType) => void
    >
  > = {
    [CustomToolInputFieldTypesEnum.CHECKBOX]: (inputOrOutput, newUserInput) => {
      const key = useIds ? inputOrOutput.id : inputOrOutput.name;
      if (!(key in newUserInput)) {
        newUserInput[key] = false;
      }
    },
    [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: handleTextFieldValidation,
    [CustomToolInputFieldTypesEnum.TEXTFIELD]: handleTextFieldValidation,
    [CopyableField.LargeText]: handleTextFieldValidation
  };

  return function validateUserInput(
    userInput: UserInputDictType,
    inputAndOutputs: Array<InputsAndOutputs>
  ): { error: string; newUserInput: UserInputDictType } {
    const newUserInput = { ...userInput };
    let error = "";
    try {
      inputAndOutputs.forEach((inputOrOutput) => {
        const validate = validationFunctions[inputOrOutput.type];
        if (validate) {
          validate(inputOrOutput, newUserInput);
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        error = e.message;
      }
    }
    return { error, newUserInput };
  };
}

export default useValidateUserInput;
