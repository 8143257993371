import { EntityType } from "@toolflow/shared";
import React from "react";
import ToolflowHeader from "../../../../utilities/components/headers/toolflowHeader/ToolflowHeader";
import { useAllMarketplaceEntitiesWithLoading } from "../../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";
import GridCardSelector from "./components/gridCards/GridCardSelector";
import ToolTabsContainer from "./components/toolTabs/ToolTabsContainer";
import { toolTabs } from "./constants/toolTabsArray";
import { SearchToolsContextComponent } from "./context/SearchToolsContext";
import MarketplaceTabsSkeleton from "./MarketplaceTabsSkeleton";

function SearchToolsLoadingSplitter({ action }: { action?: React.ReactNode }) {
  const { isLoading } = useAllMarketplaceEntitiesWithLoading();

  return (
    <ToolflowHeader title="Tools" action={action}>
      {isLoading ? (
        <MarketplaceTabsSkeleton />
      ) : (
        <SearchToolsContextComponent
          EntityElement={GridCardSelector}
          entityType={EntityType.TOOL}
        >
          <ToolTabsContainer
            toolTabs={toolTabs}
            className="p-h-16px m-t-16px"
          />
        </SearchToolsContextComponent>
      )}
    </ToolflowHeader>
  );
}

export default SearchToolsLoadingSplitter;
