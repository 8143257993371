import { Paper } from "@mui/material";
import {
  AnthropicOverride,
  FieldType,
  getAgentModelOptions,
  TEXT_FILE_TYPES,
  type FieldConfig,
  type TAgentBuilderForm,
  type UpdateSType
} from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import CenterContentContainer from "../../../../utilities/components/containers/CenterContentContainer";
import { useModelTemperatureLogic } from "../../../../utilities/hooks/useModelTemperatureLogic";
import FieldsByType from "../toolBuilder/common/fieldsByType/FieldsByType";
import * as FL from "./constants/fields";
import useGetAgentByParams from "./hooks/useGetAgentByParams";
import { useSendJSONContent } from "./hooks/useSendJSONContent";

const useGetFormAgent = () => {
  const { watch } = useFormContext<TAgentBuilderForm>();
  return watch();
};

const AgentBuilder = () => {
  const agent = useGetFormAgent();
  const { data: agentData } = useGetAgentByParams();
  const { setValue } = useFormContext<TAgentBuilderForm>();

  const promptInstructions = `config.${FL.AGENT_INSTRUCTIONS_FIELD_LABEL}`;
  const sendJSONContent = useSendJSONContent();

  const updateField = (updateValue: UpdateSType, dataProperty: string) => {
    // only update the JSON if its a non empty tiptap node, otherwise don't change
    const updateJSON = sendJSONContent(
      dataProperty === promptInstructions
        ? promptInstructions
        : FL.AGENT_USER_INSTRUCTIONS_FIELD_LABEL,
      updateValue,
      dataProperty
    );
    if (updateValue && updateJSON) {
      setValue(
        dataProperty as keyof TAgentBuilderForm,
        updateValue as $TSAllowedAny,
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        }
      );
    }
  };

  const { handleModelChange, getMarksMax } = useModelTemperatureLogic({
    temperatureFieldKey: `config.${FL.AGENT_TEMPERATURE_FIELD_LABEL}`,
    currentModel: agent.config[FL.AGENT_MODEL_FIELD_LABEL]
  });

  const configs: FieldConfig = {
    id: "agentBuilder",
    data: agent,
    updateField,
    fields: [
      {
        type: FieldType.SelectFieldV2,
        label: "Model",
        subtitle: "Select model",
        onChange: handleModelChange,
        config: {
          fieldKey: `config.${FL.AGENT_MODEL_FIELD_LABEL}`,
          options: getAgentModelOptions(),
          optionOverride: AnthropicOverride
        }
      },
      {
        type: FieldType.PromptInputV2,
        label: "System instructions",
        subtitle: "Write your prompt instructions here.",
        config: {
          fieldKey: promptInstructions,
          placeholder: `Type "/" or click "+" for dynamic inputs`,
          textFieldClassName: "min-h-246px",
          className: "m-t-8px",
          autofocus: true
        }
      },
      {
        type: FieldType.SelectTools,
        label: "Tools",
        subtitle: "Select tools to use in your agent.",
        config: {
          className: "m-t-16px",
          fieldKey: `config.${FL.AGENT_TOOLS_PROPS_LABEL}`,
          lastUpdated: agentData?.result.agent?.updatedAt
        }
      },
      {
        type: FieldType.SelectKnowledgeField,
        label: "Files",
        subtitle: "Give your agent context with files.",
        config: {
          fieldKey: `config.${FL.AGENT_FILES_FIELD_LABEL}`,
          acceptedExtensions: TEXT_FILE_TYPES
        }
      },
      {
        type: FieldType.Slider,
        label: "Temperature",
        subtitle:
          "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
        config: {
          fieldKey: `config.${FL.AGENT_TEMPERATURE_FIELD_LABEL}`,
          className: "m-v-16px",
          marksMax: getMarksMax()
        }
      },
      {
        type: FieldType.PromptInputV2,
        label: "How to use",
        subtitle:
          "Write instructions explaining what this agent does and how to use it. These instructions will be shown when users click the 'How to Use' button. Markdown and formatting are enabled.",
        config: {
          fieldKey: `${FL.AGENT_USER_INSTRUCTIONS_FIELD_LABEL}`,
          placeholder: `Type "/" or click "+" for dynamic inputs`,
          textFieldClassName: "min-h-246px",
          className: "m-t-8px",
          autofocus: false,
          enableFormatting: true
        }
      }
    ]
  };

  return (
    <CenterContentContainer className="w-100-percent">
      <Paper
        variant="outlined"
        className="border-radius-16px m-16px p-h-12px p-t-12px p-b-24px"
      >
        <FieldsByType configs={configs} />
      </Paper>
    </CenterContentContainer>
  );
};

export default AgentBuilder;
