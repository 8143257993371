import { FormControl } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicListFieldProps
} from "@toolflow/shared";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import InputListComponent from "../../../../../../../tools/components/inputs/InputListComponent";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useDynamicSetting from "../../hooks/useDynamicSetting";

export function DynamicListField({ id, field }: DynamicListFieldProps) {
  const { getFieldValue, updateField } = useFieldsByTypeContext();
  const {
    fieldKey,
    typeKey,
    defaultValue = [],
    filterAvailableFields
  } = field.config;

  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = getFieldValue(fieldKey, defaultValue);
  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const disabled = useDynamicSetting<boolean>(field.disabled);
  const hidden = useDynamicSetting<boolean>(field.hidden);

  if (hidden) {
    return null;
  }

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={field.config}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        addInputType={CustomToolInputFieldTypesEnum.TAGS_INPUT}
        onTypeChangeCallback={(value) => {
          if (value === DynamicFieldType.Dynamic) {
            updateField(CREATE_USER_INPUT_LABEL, fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <InputListComponent
            disabled={disabled}
            valuesList={fieldValue}
            handleChange={(value) => updateField(value, fieldKey)}
          />
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
