import {
  VERSION_1_VALUE,
  FieldType,
  type BlockFieldsConfig
} from "../../../../../types";

export const iterationExitBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.StaticText,
      subtitle:
        "Exit the iteration. All nodes after this one will not be iterated.",
      config: {
        textAlign: "center"
      }
    }
  ]
};
