import {
  Button,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Typography
} from "@mui/material";
import { createIntegrationBlockId, type PipedreamApp } from "@toolflow/shared";
import { useCallback, useMemo } from "react";
import NoWrapCardHeader from "../../../../../../utilities/components/cards/NoWrapCardHeader";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import useIsIntegrationConnected from "../../../../../integrations/hooks/useIsIntegrationConnected";
import usePipedreamConnect from "../../../../../integrations/hooks/usePipedreamConnect";
import useNavigateToToolById from "../../../../../pages/workstation/hooks/useNavigateToToolById";
import styles from "./GridCard.module.css";
import useDisconnectIntegration from "../../../../../integrations/hooks/useDisconnectIntegration";

const cx = parsedClassNames.bind(styles);

function IntegrationEntityCardBase({
  imgSrc,
  name,
  description,
  children
}: {
  imgSrc: string;
  name: string;
  description: string;
  children?: React.ReactNode;
}) {
  return (
    <Card className={cx("integration-card", "pos-relative parent-hover")}>
      <NoWrapCardHeader
        className={cx("integration-card-header")}
        avatar={<img src={imgSrc} width={24} height={24} />}
        title={
          <Typography variant="h6" noWrap>
            {name}
          </Typography>
        }
      />
      <CardContent className="p-b-0px p-t-8px pos-relative">
        <Tooltip title={description} placement="right">
          <Typography
            variant="body2"
            color="text.secondary"
            paragraph
            className="m-0px oflow-two-lines h-40px"
          >
            {description}
          </Typography>
        </Tooltip>
      </CardContent>
      <CardActions className="p-8px">{children}</CardActions>
    </Card>
  );
}

export default function IntegrationEntityCard({
  app,
  disabled,
  connectedLabel = "Select",
  onClick
}: {
  app: PipedreamApp;
  connectedLabel?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}) {
  const isConnectedFunction = useIsIntegrationConnected();
  const disconnectIntegration = useDisconnectIntegration();

  const { connect, isConnecting } = usePipedreamConnect();

  const isConnected = useMemo(
    () => isConnectedFunction(app),
    [app.id, isConnectedFunction]
  );

  // Http webhook doesn't require a user to be connected to it to be used
  const isHttpWebhook = app.name_slug === "http";

  const handleDisconnect = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await disconnectIntegration(app);
  };

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (isConnected || isHttpWebhook) {
        onClick?.(e);
      } else {
        connect(app);
      }
    },
    [isConnected, onClick, connect, app, isHttpWebhook]
  );

  return (
    <IntegrationEntityCardBase
      imgSrc={app.img_src}
      name={app.name}
      description={app.description}
    >
      {isConnected && !isHttpWebhook && (
        <Button
          variant="text"
          size="small"
          className="m-l-auto"
          disableRipple
          onClick={handleDisconnect}
        >
          Disconnect
        </Button>
      )}

      <Button
        variant="contained"
        size="small"
        className="m-l-auto"
        disableRipple
        disabled={disabled || (isConnecting && !isHttpWebhook)}
        onClick={handleClick}
      >
        {isConnected || isHttpWebhook
          ? connectedLabel
          : isConnecting
            ? "Connecting..."
            : "Connect"}
      </Button>
    </IntegrationEntityCardBase>
  );
}

export function IntegrationCardContainer({ app }: { app: PipedreamApp }) {
  const toolClick = useNavigateToToolById();

  return (
    <IntegrationEntityCard
      app={app}
      connectedLabel="Open"
      disabled={false}
      onClick={toolClick(createIntegrationBlockId(app.name_slug))}
    />
  );
}
