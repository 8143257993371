import { Divider, Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../../../utilities/components/tabs/functions/a11yProps";
import TabPanel from "../../../../utilities/components/tabs/TabPanel";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
import { useToolTabsContext } from "./components/toolTabs/ToolTabsProvider";
import styles from "./marketplaceTabs.module.css";
import { SearchTools } from "./SearchTools";

const MarketplaceTabs = ({ className }: { className?: string }) => {
  const { toolTabs, selectedTabIndex, handleChangeTabIndex } =
    useToolTabsContext();

  return (
    <div className={classNames("oflow-x-hidden", className)}>
      <Tabs value={selectedTabIndex} onChange={handleChangeTabIndex}>
        {toolTabs.map((tabDetails) => {
          const isTabSelected = selectedTabIndex === tabDetails.indexValue;
          return (
            <Tab
              key={tabDetails.value}
              icon={isTabSelected ? tabDetails.selectedIcon : tabDetails.icon}
              iconPosition="start"
              classes={{
                root: styles["tab-height"]
              }}
              {...a11yProps(tabDetails.indexValue)}
              label={
                <span className={isTabSelected ? styles.selected : ""}>
                  {tabDetails.label}
                </span>
              }
            />
          );
        })}
      </Tabs>
      <Divider />
      {toolTabs.map((tabDetails) => {
        return (
          <TabPanel
            value={selectedTabIndex}
            key={tabDetails.value}
            index={tabDetails.indexValue}
            className="m-t-12px"
          >
            <SearchTools />
          </TabPanel>
        );
      })}
    </div>
  );
};

export default MarketplaceTabs;
