import {
  ComponentFormContainer,
  CustomizeProvider,
  FrontendClientProvider
} from "@pipedream/connect-react";
import {
  isAgentDeterminedInputObj,
  PIPEDREAM_OVERRIDE_PROPS
} from "@toolflow/shared";
import { useMemo } from "react";
import { PipedreamAlert } from "../../../../../../../pages/builder/toolBuilder/common/fieldsByType/pipedreamFields/pipedreamField/PipedreamAlert";
import { PipedreamAccordion } from "./components/PipedreamAccordion";
import { PipedreamAppSelect } from "./components/PipedreamAppSelect";
import { PipedreamBoolean } from "./components/PipedreamBoolean";
import { PipedreamControlInput } from "./components/PipedreamControlInput";
import { PipedreamAppLabel } from "./components/PipedreamFieldLabel";
import { PipedreamSelectField } from "./components/PipedreamSelectField";
import { usePipedreamFieldsContext } from "./PipedreamFieldsProvider";

export default function PipedreamFields() {
  const { action, userId, pdClient, configuredProps } =
    usePipedreamFieldsContext();

  const filteredConfiguredProps = useMemo(
    () =>
      Object.keys(configuredProps).reduce(
        (acc, key) => {
          if (!isAgentDeterminedInputObj(configuredProps[key])) {
            acc[key] = configuredProps[key];
          }
          return acc;
        },
        {} as typeof configuredProps
      ),
    [configuredProps]
  );

  return (
    <FrontendClientProvider client={pdClient}>
      <CustomizeProvider
        components={{
          ControlApp: PipedreamAppSelect,
          ControlInput: PipedreamControlInput,
          ControlSelect: PipedreamSelectField,
          ControlBoolean: PipedreamBoolean,
          Label: PipedreamAppLabel,
          OptionalFieldsContainer: PipedreamAccordion,
          Alert: PipedreamAlert,
          Description: () => <></>
        }}
      >
        <ComponentFormContainer
          userId={userId}
          componentKey={action}
          configuredProps={filteredConfiguredProps}
          overrideProps={PIPEDREAM_OVERRIDE_PROPS}
        />
      </CustomizeProvider>
    </FrontendClientProvider>
  );
}
