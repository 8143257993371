import { useDispatch } from "react-redux";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { setAssetListBig } from "../../../../state/workspaceSlice";
import React from "react";
import { CloseIcon } from "../../../../../../../globalTheme/icons/icons";

function CloseAssetListButton() {
  const dispatch = useDispatch();
  const makeSmall = () => {
    dispatch(setAssetListBig(false));
  };
  return <RemixButton icon={CloseIcon} text="Close" onClick={makeSmall} />;
}

export default CloseAssetListButton;
