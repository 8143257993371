import { FieldType } from "@toolflow/shared";
import React from "react";
import DynamicInputField from "./dynamicFields/dynamicInputField/DynamicInputField";
import { DynamicListField } from "./dynamicFields/dynamicListField/DynamicListField";
import DynamicSelectField from "./dynamicFields/dynamicSelectField/DynamicSelectField";
import DynamicSliderField from "./dynamicFields/dynamicSliderField/DynamicSliderField";
import DynamicSwitchField from "./dynamicFields/dynamicSwitchField/DynamicSwitchField";
import JsonSchemaInputField from "./fields/jsonSchemaInputField/JsonSchemaInputField";
import LogicBuilderField from "./fields/logicBuilderField/LogicBuilderField";
import { PromptInputV1, PromptInputV2 } from "./fields/promptInput";
import { SelectFieldV1, SelectFieldV2 } from "./fields/selectField";
import SelectKnowledgeField from "./fields/selectKnowledgeField/SelectKnowledgeField";
import SelectToolForToolBuilder from "./fields/selectToolForToolBuilder/SelectToolForToolBuilder";
import SelectToolsField from "./fields/selectTools/SelectToolsField";
import SliderField from "./fields/SliderField";
import StaticText from "./fields/staticText/StaticText";
import { WordReplaceField } from "./fields/wordReplaceField/WordReplaceField";
import DeepgramSettingsFields from "./legacyFields/DeepgramSettingsFields";
import { FileField } from "./legacyFields/FileField";
import SimpleCheckBoxField from "./simpleFields/SimpleCheckBoxField";
import SimpleInputField from "./simpleFields/SimpleInputField";
import SimpleInputSelectField from "./simpleFields/SimpleInputSelectField";
import SimpleListCreationField from "./simpleFields/SimpleListCreationField";
import PipedreamField from "./pipedreamFields/pipedreamField/PipedreamField";
import { PipedreamActionField } from "./pipedreamFields/pipedreamActionField/PipedreamActionField";

// Combined object for components and prop getters
export const fieldTypeComponents: Record<FieldType, React.ElementType> = {
  [FieldType.Slider]: SliderField,
  [FieldType.SelectTools]: SelectToolsField,
  [FieldType.PromptInputV1]: PromptInputV1,
  [FieldType.PromptInputV2]: PromptInputV2,
  [FieldType.SelectFieldV1]: SelectFieldV1,
  [FieldType.SelectFieldV2]: SelectFieldV2,
  [FieldType.JsonSchemaInput]: JsonSchemaInputField,
  [FieldType.WordReplaceField]: WordReplaceField,
  [FieldType.SelectToolForToolBuilder]: SelectToolForToolBuilder,
  [FieldType.LogicBuilderField]: LogicBuilderField,
  [FieldType.SelectKnowledgeField]: SelectKnowledgeField,

  [FieldType.SimpleInput]: SimpleInputField,
  [FieldType.SimpleInputSelect]: SimpleInputSelectField,
  [FieldType.SimpleCheckBoxField]: SimpleCheckBoxField,
  [FieldType.SimpleListCreationField]: SimpleListCreationField,

  [FieldType.DynamicListField]: DynamicListField,
  [FieldType.DynamicSwitchField]: DynamicSwitchField,
  [FieldType.DynamicSelectField]: DynamicSelectField,
  [FieldType.DynamicInputField]: DynamicInputField,
  [FieldType.DynamicSliderField]: DynamicSliderField,

  [FieldType.PipedreamField]: PipedreamField,
  [FieldType.PipedreamActionField]: PipedreamActionField,

  [FieldType.StaticText]: StaticText,

  [FieldType.Group]: () => <></>,

  [FieldType.DeepgramSettings]: DeepgramSettingsFields,
  [FieldType.FileField]: FileField
};
