import React from "react";
import DeleteCurrentAssetButton from "../../deleteAssetButtons/DeleteCurrentAssetButton";
import GenericSettingsDropdown from "../GenericSettingsDropdown";
import useHideIconsOnExpanded from "../../hooks/useHideIconsOnExpanded";
import CloseAssetPanelButton from "../../CloseAssetPanelButton";

const DallEAssetIcons = () => {
  const hideIcons = useHideIconsOnExpanded();
  return (
    <div className="flex align-i-center show-on-hover">
      <DeleteCurrentAssetButton />
      <GenericSettingsDropdown />
      {hideIcons && <CloseAssetPanelButton />}
    </div>
  );
};

export default DallEAssetIcons;
