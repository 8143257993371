import type {
  TEntityFilterByCategoriesFE,
  TMarketplaceTypes
} from "@toolflow/shared";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { setCategory } from "../../slice/searchToolsSlice";
import { ALL_CATEGORY_TAB } from "../../utils/constants";
import { TEntityTab, marketplaceCategoryTabs } from "../constants";
import { getCategoryIndex, isSelectedTabDisabled } from "../functions/utils";

const useSwitchCategoryWhenDisabled = (entityType: TMarketplaceTypes) => {
  const selectedTab = useSelector((state: RootState) => state.searchTools.tab);
  const reduxCategory = useSelector(
    (state: RootState) => state.searchTools.category
  );

  const dispatch = useDispatch();

  const handleChangeTabIndex = () => {
    dispatch(setCategory(ALL_CATEGORY_TAB));
  };

  const isTabDisabled = useMemo(() => {
    return isSelectedTabDisabled(reduxCategory, selectedTab, entityType);
  }, [selectedTab, reduxCategory, entityType]);

  useEffect(() => {
    if (isTabDisabled) {
      handleChangeTabIndex();
    }
  }, [isTabDisabled]);
};

const useFilteredTabs = (entityType: TMarketplaceTypes) => {
  const selectedTab = useSelector((state: RootState) => state.searchTools.tab);
  return useMemo(
    () =>
      marketplaceCategoryTabs.filter(
        (tab) =>
          !tab.disabledTabs?.includes(selectedTab) &&
          !tab.disabledType?.includes(entityType)
      ),
    [selectedTab, entityType]
  );
};

const useSelectedTabIndex = (filteredTabs: TEntityTab[]) => {
  const reduxCategory = useSelector(
    (state: RootState) => state.searchTools.category
  );
  return getCategoryIndex(filteredTabs, reduxCategory);
};

const useSelectMarketplaceTab = () => {
  const dispatch = useDispatch();
  const handleSelectTab = (tabValue: TEntityFilterByCategoriesFE) => {
    dispatch(setCategory(tabValue));
  };
  return handleSelectTab;
};

const useEntityCategory = (entityType: TMarketplaceTypes) => {
  const selectedTab = useSelector((state: RootState) => state.searchTools.tab);
  const filteredTabs = useFilteredTabs(entityType);
  const selectedTabIndex = useSelectedTabIndex(filteredTabs);
  const selectMarketplaceTab = useSelectMarketplaceTab();
  useSwitchCategoryWhenDisabled(entityType);

  const selectedMarketplaceCategoryTab = useMemo(
    () => filteredTabs.find((_, i) => selectedTabIndex === i),
    [selectedTabIndex]
  );

  return {
    selectedTabIndex,
    selectMarketplaceTab,
    marketplaceCategoryTabs: filteredTabs,
    selectedTab,
    selectedMarketplaceCategoryTab
  };
};

export default useEntityCategory;
