import {
  CustomToolInputFieldTypesEnum,
  type CustomToolInputField,
  type InputTypeMap,
  type ValidatedInput
} from "@toolflow/shared";
import {
  commonFieldTypes,
  isInputWithOptions
} from "../../../tools/components/editorToolCard/inputs/helpers/constants";

const genericEquality = (
  field: ValidatedInput,
  fieldType?: CustomToolInputFieldTypesEnum
) => {
  return field.type.toLowerCase() === fieldType?.toLowerCase();
};

const textFieldEquality = (field: ValidatedInput) => {
  return ["textfield", "largetextfield", "blockoutput"].includes(
    field.type.toLowerCase()
  );
};

const lookupDict: Record<
  CustomToolInputFieldTypesEnum,
  (f: ValidatedInput, fT?: CustomToolInputFieldTypesEnum) => boolean
> = {
  [CustomToolInputFieldTypesEnum.SELECT]: genericEquality,
  [CustomToolInputFieldTypesEnum.CHECKBOX]: genericEquality,
  [CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD]: genericEquality,
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: genericEquality,
  [CustomToolInputFieldTypesEnum.MULTI_SELECT]: genericEquality,
  [CustomToolInputFieldTypesEnum.TAGS_INPUT]: genericEquality,
  [CustomToolInputFieldTypesEnum.SLIDER]: genericEquality,
  [CustomToolInputFieldTypesEnum.WORD_REPLACE]: genericEquality,
  [CustomToolInputFieldTypesEnum.JSON_SCHEMA]: genericEquality,
  [CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD]: genericEquality,
  [CustomToolInputFieldTypesEnum.NUMERIC_FIELD]: genericEquality,
  [CustomToolInputFieldTypesEnum.PIPEDREAM_FIELDS]: genericEquality,
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: textFieldEquality,
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: textFieldEquality,
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: textFieldEquality,
  [CustomToolInputFieldTypesEnum.PIPEDREAM_ACTION]: textFieldEquality
};

const getCommonAvailableOptions = (
  fieldType: CustomToolInputFieldTypesEnum,
  availableOptions: ValidatedInput[]
) => {
  return availableOptions
    .filter((field) => {
      return lookupDict[fieldType](field, fieldType);
    })
    .map((field) => {
      return { name: field.name, type: field.type };
    });
};

function createInputTypeMap(
  inputTypeMap: { [key: string]: CustomToolInputField },
  allAvailableOptions: ValidatedInput[]
): InputTypeMap {
  const result: InputTypeMap = {};
  // Create a set of names from availableOptions for faster lookup
  const fieldNamesSet = new Set(allAvailableOptions.map((field) => field.name));

  for (const key in inputTypeMap) {
    const input = inputTypeMap[key];
    const fieldType = input.type;
    if (commonFieldTypes.includes(fieldType)) {
      const availableOptions = getCommonAvailableOptions(
        fieldType,
        allAvailableOptions
      );
      result[key] = {
        availableOptions,
        type: fieldType
      };
    } else {
      const otherNames = allAvailableOptions
        .filter(
          (field) =>
            ![
              CustomToolInputFieldTypesEnum.FILE_UPLOAD,
              CustomToolInputFieldTypesEnum.CHECKBOX
            ].includes(field.type)
        )
        .map((field) => {
          return {
            name: field.name,
            type: field.type,
            options: isInputWithOptions(field) ? field.options : undefined
          };
        });

      // Add availableOptions strings that do not exist in the availableOptions
      // this only works because all the block outputs that we allow to go into
      // this block are strings
      const additionalNames: ValidatedInput[] = allAvailableOptions
        .filter((option) => !fieldNamesSet.has(option.name))
        .map((f) => {
          return {
            name: f.name,
            type: CustomToolInputFieldTypesEnum.BLOCK_OUTPUT
          };
        });

      result[key] = {
        availableOptions: [...otherNames, ...additionalNames],
        type: input.type,
        dropdownOptions: isInputWithOptions(input) ? input.options : undefined
      };
    }
  }

  return result;
}

export default createInputTypeMap;
