import { AGENT, EntityType, type TMarketplaceTypes } from "@toolflow/shared";
import {
  AGENT_BUILDER_BASE_ROUTE_PATH,
  TOOLBUILDER_BASE_ROUTE_PATH
} from "../../../navigation/helpers/routePaths";
import { AddIcon } from "../../../../globalTheme/icons/icons";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import { Card, CardHeader, Typography } from "@mui/material";
import { parsedClassNames } from "../../../../utilities/functions/parsedClassNames";
import styles from "./entityCard.module.css";

const cx = parsedClassNames.bind(styles);

const builderLocations = {
  [AGENT]: AGENT_BUILDER_BASE_ROUTE_PATH,
  [EntityType.TOOL]: TOOLBUILDER_BASE_ROUTE_PATH
};

const EntityIconCard = ({ entityType }: { entityType: TMarketplaceTypes }) => {
  const navigate = useToolflowNavigate();

  const onClick = () => {
    navigate(builderLocations[entityType]);
  };

  return (
    <Card
      className={cx(
        styles["add-entity-card"],
        styles["entity-card"],
        "parent-hover background-color-add-entity-card"
      )}
      onClick={onClick}
    >
      <CardHeader
        className={cx(styles["entity-card-header"])}
        disableTypography
        classes={{
          content: "max-w-100-percent"
        }}
        title={
          <Typography
            variant="h6"
            noWrap
            className="add-entity-card-base-color"
          >
            {`Create ${entityType}`}
          </Typography>
        }
      />
      <div className="flex align-i-center justify-center m-t-12px add-entity-card-base-color">
        <AddIcon size={40} />
      </div>
    </Card>
  );
};

export default EntityIconCard;
