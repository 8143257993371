import type {
  PipedreamApp,
  TMarketplaceUniversalEntity
} from "@toolflow/shared";
import EntityCardContainer from "../../../../../entities/components/entityCard/EntityCardContainer";
import { IntegrationCardContainer } from "./IntegrationEntityCard";

export default function GridCardSelector({
  app,
  entity
}: {
  app?: PipedreamApp;
  entity?: TMarketplaceUniversalEntity;
}) {
  return (
    <>
      {app && <IntegrationCardContainer app={app} />}
      {entity && <EntityCardContainer entity={entity} />}
    </>
  );
}
