import React from "react";
import LoginButton from "../../auth/LoginButton";
import AuthenticationSplitter from "../../auth/AuthenticationSplitter";
import ToolsPageLoading from "./ToolsPageLoading";
import SearchToolsLoadingSplitter from "../../tools/components/searchToolsContainer/SearchToolsLoadingSplitter";

const ToolsPage = () => {
  return (
    <AuthenticationSplitter
      loadingComponent={<ToolsPageLoading />}
      authenticatedComponent={<SearchToolsLoadingSplitter />}
      externalComponent={
        <SearchToolsLoadingSplitter
          action={
            <div className="flex align-i-center">
              <LoginButton variant="outlined" />
            </div>
          }
        />
      }
    />
  );
};

export default ToolsPage;
