import { type NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { ChipNodeHtmlTags } from "@toolflow/shared";
import ThinkingAccordion from "../../../accordions/ThinkingAccordion";

const FlowThinkingViewerComponent = (props: NodeViewProps) => {
  return (
    <NodeViewWrapper
      className={`${ChipNodeHtmlTags.FlowThinkingComponent} dontTriggerBubble`}
    >
      <ThinkingAccordion value={props.node.textContent} />
    </NodeViewWrapper>
  );
};

export default FlowThinkingViewerComponent;
