import { FormControl, Slider } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicSliderFieldType
} from "@toolflow/shared";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import useDynamicSetting from "../../hooks/useDynamicSetting";

export default function DynamicSliderField() {
  const { field, id, getFieldValue, updateField } =
    useFieldsByTypeContext<DynamicSliderFieldType>();
  const {
    fieldKey,
    typeKey,
    marksMax = 100,
    defaultValue = 0,
    filterAvailableFields
  } = field.config;

  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = getFieldValue(fieldKey, defaultValue);
  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const disabled = useDynamicSetting<boolean>(field.disabled);

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={{
          ...field.config,
          allowedFields: [CustomToolInputFieldTypesEnum.SLIDER]
        }}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        addInputType={CustomToolInputFieldTypesEnum.SLIDER}
        onTypeChangeCallback={(type) => {
          if (type === DynamicFieldType.Dynamic) {
            updateField(CREATE_USER_INPUT_LABEL, fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <Slider
          className="nowheel nodrag nopan"
          value={fieldValue}
          disabled={disabled}
          onChange={(_, value) => updateField(value, fieldKey)}
          valueLabelDisplay="auto"
          step={marksMax ? null : undefined} // If max marks exist, we want steps to be limited to the marks max array
          scale={(value) => value / 50}
          marks={Array.from({ length: marksMax + 1 }, (_, i) => ({
            value: i
          }))}
        />
      </DynamicFieldWrapper>
    </FormControl>
  );
}
