import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import { type JSONContent } from "@tiptap/core";
import { jsonPromptFromUserInput } from "@toolflow/shared";
import { CSSProperties } from "react";
import { TextInput } from "../../../../../../../../pages/builder/toolBuilder/common/fieldsByType/fields/promptInput";
import { usePipedreamFieldsContext } from "../PipedreamFieldsProvider";
import PipedreamFieldLabel from "./PipedreamFieldLabel";

export function PipedreamControlInput(props: $TSAllowedAny) {
  const { onChange, prop, value } = useFormFieldContext();
  const { getProps } = useCustomize();
  const { updateDynamicConfiguredProps } = usePipedreamFieldsContext();

  const baseStyles: CSSProperties = {
    gridArea: "control"
  };

  const handleTipTapChange = (jsonContent: string | JSONContent) => {
    const textContent = jsonPromptFromUserInput(jsonContent, {}, []);
    onChange(textContent);
    updateDynamicConfiguredProps(prop.name, textContent);
  };

  return (
    <PipedreamFieldLabel>
      <div {...getProps("controlBoolean", baseStyles, props)}>
        <TextInput
          id={prop?.id}
          label=""
          placeholder={prop?.label}
          initialValue={value || ""}
          handleTipTapChange={handleTipTapChange}
          className="w-100-percent"
        />
      </div>
    </PipedreamFieldLabel>
  );
}
