import React from "react";
import { useMediaQuery } from "@mui/material";
import { useSubscriptionDetails } from "../../billing/hooks/useSubscriptionDetails";
import sidebarTheme from "../../../globalTheme/sidebarTheme";
import useLogRocket from "../../../infrastructure/logRocket/hooks/useLogRocket";
import useIntercom from "../../../infrastructure/intercom/useIntercom";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "./sidebarSlice";
import useGetCurrentUser from "../../user/hooks/useGetCurrentUser";
import { useAuthStore } from "../../auth/hooks/useAuthStore";
import { useRerouteToBillingPage } from "../../auth/hooks/useRerouteToBillingPage";

function SidebarContainer() {
  const isMediumScreen = useMediaQuery(sidebarTheme.breakpoints.down("md"));
  const { isAuthenticated } = useAuthStore();
  const user = useGetCurrentUser();
  const { activeTier } = useSubscriptionDetails();

  const open = useSelector(selectSidebarOpen);

  useLogRocket(user);
  useIntercom(user, isAuthenticated, activeTier);

  const rerouteToBilling = useRerouteToBillingPage();
  if (rerouteToBilling) {
    return <></>;
  }

  return (
    <Sidebar
      isMediumScreen={isMediumScreen}
      open={open}
      isAuthenticated={isAuthenticated}
    />
  );
}

export default SidebarContainer;
