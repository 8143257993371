import { IconButton } from "@mui/material";
import React from "react";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { AddIcon } from "../../../../../../../../globalTheme/icons/icons";

import { ClickableTypes, type TAddChipConfig } from "@toolflow/shared";
import AddChipPopover from "./AddChipPopover";

import { classNames } from "../../../../../../../../utilities/functions/parsedClassNames";

function AddChipButton({
  handleAddChip,
  className = "asset-button",
  chipsToUse
}: {
  handleAddChip: (type: ClickableTypes, id: string) => void;
  className?: string;
  chipsToUse?: TAddChipConfig;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={handleClick}
        className={classNames(className, "m-l-8px border-radius-16px")}
      >
        <RemixWrapperIconWithTheme
          Icon={AddIcon}
          size={16}
          // providedColor={toolflowTextDisabledColor}
        />
      </IconButton>
      <AddChipPopover
        handleAddChip={handleAddChip}
        chipsToUse={chipsToUse}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
}

export default AddChipButton;
