import { Box, Divider, Grid, Skeleton, Stack } from "@mui/material";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
import IntegrationEntityCardSkeleton from "./components/gridCards/IntegrationEntityCardSkeleton";

export default function MarketplaceTabsSkeleton({
  className
}: {
  className?: string;
}) {
  return (
    <div
      className={classNames("oflow-x-hidden", className)}
      data-testid="tools-loading"
    >
      <Stack direction="row" spacing={2} sx={{ padding: "4px 0" }}>
        <Skeleton variant="rounded" width={100} height={32} />
        <Skeleton variant="rounded" width={100} height={32} />
      </Stack>
      <Divider />
      <Box sx={{ mt: 1.5 }}>
        <Stack spacing={2}>
          {/* Category chips skeleton */}
          <Stack direction="row" spacing={1}>
            {[1, 2, 3, 4].map((key) => (
              <Skeleton key={key} variant="rounded" width={80} height={32} />
            ))}
          </Stack>

          {/* Grid skeleton */}
          <Box sx={{ mt: 2 }}>
            <Stack spacing={3}>
              <Box>
                <Grid container spacing={3}>
                  {[1, 2, 3, 4, 5, 6].map((key) => (
                    <Grid item key={key}>
                      <IntegrationEntityCardSkeleton />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </div>
  );
}
