import {
  BlockType,
  VERSION_1_VALUE,
  type AllBlockTypes,
  type DecimalString
} from "@toolflow/shared";
import UserIntegrationModal from "../../../slackBlock/UserIntegrationModal";

export const blockComponents: Record<
  string,
  Record<DecimalString, $TSFixMe>
> = {
  [BlockType.UserIntegration]: {
    [VERSION_1_VALUE]: {
      modalComponent: UserIntegrationModal
    }
  }
};

export function getBlockComponentsByVersion(
  type: AllBlockTypes,
  version: DecimalString = VERSION_1_VALUE
) {
  if (blockComponents.hasOwnProperty(type)) {
    return (
      blockComponents[type][version] || blockComponents[type][VERSION_1_VALUE]
    );
  }
}
