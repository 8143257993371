import { Box, Button, CircularProgress } from "@mui/material";
import {
  type PipedreamFieldType,
  type UserIntegrationsBlockData
} from "@toolflow/shared";
import { useEffect } from "react";
import useIntegrationActionsOptions from "../../../../../../../integrations/hooks/useIntegrationActionsOptions";

import { useModalState } from "../../../../blocks/hooks/useModalState";
import useGetDrawerNodeId from "../../../../store/hooks/useGetDrawerNodeId";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useDynamicSetting from "../../hooks/useDynamicSetting";
import PipedreamActionSelect from "../components/PipedreamActionSelect";

export function PipedreamActionField() {
  const { id, data, field, getFieldValue, updateField } =
    useFieldsByTypeContext<PipedreamFieldType, UserIntegrationsBlockData>();
  const drawerNodeId = useGetDrawerNodeId();
  const modalState = useModalState();
  const selectedIntegrationType = data.settings.selectedIntegrationType;

  useEffect(() => {
    if (!selectedIntegrationType && drawerNodeId === id) {
      modalState.openModal();
    }
  }, [selectedIntegrationType, drawerNodeId]);

  const handleOpenModal = () => {
    modalState.openModal();
  };

  const { options: unsortedOptions, isLoading } = useIntegrationActionsOptions(
    selectedIntegrationType
  );

  const options = selectedIntegrationType
    ? [...unsortedOptions].sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const disabled = useDynamicSetting<boolean>(field.disabled);
  const fieldKey = useDynamicSetting<string>(field.config.fieldKey);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleOpenModal}
        className="m-b-16px"
      >
        {selectedIntegrationType ? "Change integration" : "Select integration"}
      </Button>
      {selectedIntegrationType && (
        <PipedreamActionSelect
          id={id}
          label={label}
          placeholder={subtitle}
          disabled={disabled || !selectedIntegrationType}
          required
          options={options.map((opt) => ({
            ...opt,
            icon: data.settings.integrationLogo
          }))}
          value={getFieldValue(fieldKey, "")}
          fieldKey={fieldKey}
          onChange={(value) => {
            updateField(value, fieldKey);
            if (field.onChange) {
              field.onChange(value, data, updateField);
            }
          }}
        />
      )}
    </>
  );
}
