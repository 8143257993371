import { FormHelperText, Stack, TextField, Autocomplete } from "@mui/material";
import { useState } from "react";
import { DeleteIcon } from "../../../../../../../../globalTheme/icons/icons";
import styles from "../../../../../../../tools/components/inputs/inputListComponent.module.css";

import { isArray } from "lodash";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

interface Option {
  label: string;
  value: string;
}

interface PipedreamInputListInnerProps {
  label?: string;
  valuesList: string[];
  disableHelperText?: boolean;
  placeholder?: string;
  disabled?: boolean;
  handleChange: (valuesList: string[]) => void;
  options?: Option[];
}

export default function PipedreamInputListComponent({
  label,
  valuesList,
  handleChange,
  disableHelperText,
  placeholder,
  disabled,
  options
}: PipedreamInputListInnerProps) {
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | null>(null);

  const getHelperText = () => {
    if (error) return error;
    return options?.length
      ? "Select from dropdown or press Enter"
      : "Press Enter to add. Use commas for multiple items.";
  };

  const getDisplayLabel = (tag: string) => {
    if (!options?.length) return tag;
    return options.find((opt) => opt.value === tag)?.label || tag;
  };

  const handleTagDelete = (indexToRemove: number) => {
    const filteredTags = valuesList.filter((_, i) => i !== indexToRemove);
    handleChange(filteredTags);
  };

  const validateAndFilterTags = (newTags: string[]) => {
    const list = isArray(valuesList) ? valuesList : [];
    const filteredTags = newTags.filter(
      (item) => item.length && !list.includes(item)
    );

    if (!options?.length) {
      return filteredTags;
    }

    return filteredTags.filter((tag) =>
      options.some((opt) => opt.value === tag || opt.label === tag)
    );
  };

  const handleInputChange = (inputValue: string) => {
    if (!inputValue) return;

    const newTags = inputValue.split(",").map((item) => item.trim());
    const validTags = validateAndFilterTags(newTags);

    if (validTags.length) {
      const list = isArray(valuesList) ? valuesList : [];
      handleChange([...list, ...validTags]);
    }
    setValue("");
  };

  const renderTags = () => {
    if (!isArray(valuesList) || !valuesList.length) return null;

    return (
      <div className={cx("tags-list-container", "m-t-16px")}>
        {valuesList.map((tag, idx) => (
          <div key={tag} className={cx("tags-list-item tags-list-item-hover")}>
            {getDisplayLabel(tag)}
            <div
              onClick={() => handleTagDelete(idx)}
              className={cx(
                "tag-delete-button",
                "border-radius-16px flex align-i-center justify-center"
              )}
            >
              <DeleteIcon size={14} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Stack spacing={2}>
      <div>
        <Autocomplete
          disabled={disabled}
          freeSolo={!options?.length}
          size="small"
          value={value}
          onChange={(_, newValue) => {
            if (newValue) {
              handleInputChange(newValue);
            }
          }}
          onInputChange={(_, newValue) => {
            setValue(newValue);
            setError(null);
          }}
          options={options?.map((opt) => opt.value) || []}
          getOptionLabel={(option) => {
            if (options?.length) {
              const found = options.find((opt) => opt.value === option);
              return found?.label || option;
            }
            return option;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              onKeyDown={(e) => {
                if (e.key === "Enter" && value) {
                  e.preventDefault();
                  handleInputChange(value);
                }
              }}
            />
          )}
        />
        {!disableHelperText && (
          <FormHelperText error={!!error}>{getHelperText()}</FormHelperText>
        )}
      </div>
      {renderTags()}
    </Stack>
  );
}
