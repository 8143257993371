export function isEnumValue<T extends object>(
  value: unknown,
  enumObj: T
): value is T[keyof T] {
  return Object.values(enumObj).includes(value);
}

export function getEnumValues<T extends object>(enumObj: T): T[keyof T][] {
  return Object.values(enumObj);
}
