import type { ThemeOptions } from "@mui/material";
import type { TypographyStyleOptions } from "@mui/material/styles/createTypography";
import React from "react";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    h9: true;
    smallButton: true;
    chipLabel: true;
    h32Medium: true;
    badgeLabel: true;
    subtitleLarge: true;
    subtitleMedium: true;
    chipSmall: true;
    nodeHeader: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    smallButton: React.CSSProperties;
    chipLabel: React.CSSProperties;
    h32Medium: React.CSSProperties;
    badgeLabel: React.CSSProperties;
    subtitleLarge: React.CSSProperties;
    subtitleMedium: React.CSSProperties;
    chipSmall: React.CSSProperties;
    nodeHeader: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    h32Medium: React.CSSProperties;
    smallButton: React.CSSProperties;
    chipLabel: React.CSSProperties;
    chipSmall: React.CSSProperties;
    badgeLabel: React.CSSProperties;
    subtitleLarge: React.CSSProperties;
    subtitleMedium: React.CSSProperties;
    nodeHeader: React.CSSProperties;
  }
}

export const heading2: TypographyStyleOptions = {
  fontFamily: "Konnect",
  fontSize: "1.5rem",
  fontWeight: 600,
  lineHeight: "1.33"
};

export const nodeHeader: React.CSSProperties = {
  fontFamily: "Konnect",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "130%"
};

export const chipSmall: React.CSSProperties = {
  fontFamily: "Konnect",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "120%"
};

const toolflowTypography: ThemeOptions["typography"] = {
  fontFamily: "Konnect",
  badgeLabel: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.14px",
    fontFeatureSettings: "'liga' off, 'clig' off"
  },
  nodeHeader,
  chipLabel: {
    fontFamily: "Konnect",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "120%",
    letterSpacing: "0.16px",
    fontFeatureSettings: "'liga' off, 'clig' off"
  },
  chipSmall,
  h32Medium: {
    fontFamily: "Konnect",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "120%"
  },
  h9: {
    fontFamily: "Konnect",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%"
  },
  h8: {
    fontFamily: "Konnect",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%"
  },
  h7: {
    fontFamily: "Konnect",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  h6: {
    fontFamily: "Konnect",
    fontWeight: 500
  },
  h5: {
    fontFamily: "Konnect",
    fontWeight: 600
  },
  h4: {
    fontFamily: "Konnect",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.75"
  },
  h3: {
    fontFamily: "Konnect",
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "1.4"
  },
  h2: heading2,
  h1: {
    fontFamily: "Konnect",
    fontSize: "2.25rem",
    fontWeight: 500,
    lineHeight: "1.2"
  },
  smallButton: {
    fontFamily: "Konnect",
    color: "var(--text-secondary, rgba(18, 23, 41, 0.60))",
    /* button/small */
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px" /* 169.231% */,
    letterSpacing: "0.46px"
  },
  subtitleLarge: {
    fontFamily: "Konnect",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "var(--text-secondary, rgba(18, 23, 41, 0.60))"
  },
  subtitleMedium: {
    fontFamily: "Konnect",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "var(--text-secondary, rgba(18, 23, 41, 0.60))"
  }
};

export default toolflowTypography;
