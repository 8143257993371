import { Button, Grid, Stack } from "@mui/material";
import type { JSONSchemaItem } from "@toolflow/shared";
import SimpleAccordion from "./components/SimpleAccordion";
import useJsonSchemaConstructor from "./hooks/useJsonSchemaConstructor";
import ArrayTypeSchemaFields from "./fields/ArrayTypeSchemaFields";
import EnumSchemaFields from "./fields/EnumSchemaFields";
import GuidelineSchemaField from "./fields/GuidelineSchemaField";
import MainSchemaFields from "./fields/MainSchemaFields";
import ObjectTypeSchemaFields from "./fields/ObjectTypeSchemaFields";

export default function JsonSchemaCreationField({
  schema,
  onChange,
  updateFieldNameInTOF
}: {
  schema: JSONSchemaItem[];
  onChange(newSchema: JSONSchemaItem[]): void;
  updateFieldNameInTOF?(prevValue: string, newValue: string): void;
}) {
  const { addSchemaItem, removeIndex, updateSchemaItem } =
    useJsonSchemaConstructor({ schema, onChange });

  return (
    <Stack spacing={2} className="m-b-32px">
      {schema.map((schemaItem, index) => {
        return (
          <SimpleAccordion
            key={index}
            onDelete={() => removeIndex(index)}
            header={
              <MainSchemaFields
                schemaItem={schemaItem}
                updateFieldNameInTOF={updateFieldNameInTOF}
                onChange={(item) => {
                  updateSchemaItem(index, item);
                }}
              />
            }
          >
            <Grid container spacing={2}>
              {schemaItem.type !== "array" && (
                <Grid item xs={12}>
                  <GuidelineSchemaField
                    schemaItem={schemaItem}
                    onChange={(guideline) => {
                      updateSchemaItem(index, { ...schemaItem, guideline });
                    }}
                  />
                </Grid>
              )}

              {schemaItem.type === "array" && (
                <ArrayTypeSchemaFields
                  schemaItem={schemaItem}
                  onChange={(item) => {
                    updateSchemaItem(index, item);
                  }}
                />
              )}

              {schemaItem.type === "enum" && (
                <EnumSchemaFields
                  enums={schemaItem.enums}
                  onChange={(enums) => {
                    updateSchemaItem(index, { ...schemaItem, enums });
                  }}
                />
              )}

              {schemaItem.type === "object" && (
                <Grid container spacing={2} className="p-t-16px m-l-4px">
                  <ObjectTypeSchemaFields
                    schemaItem={schemaItem}
                    updateFieldNameInTOF={updateFieldNameInTOF}
                    onChange={(items) => {
                      updateSchemaItem(index, { ...schemaItem, items });
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </SimpleAccordion>
        );
      })}

      <Button sx={{ alignSelf: "start" }} onClick={addSchemaItem}>
        + Add new field
      </Button>
    </Stack>
  );
}
