// We can't use the navigator in shared, so we default to the US

export const formatNumberToUS = (num: number): string => {
  try {
    return new Intl.NumberFormat("en-US").format(num);
  } catch (error) {
    console.error(`Error formatting number: ${error}`);
    return "Error formatting number";
  }
};
