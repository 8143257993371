// ToolDetailsButton.tsx
import React from "react";
import { Typography } from "@mui/material";
import type { VisibilityTypes } from "@toolflow/shared"; // Adjust import path as necessary
import BaseListItemButton from "../../../../utilities/components/buttons/BaseListItemButton";
// import CreditCostDetails from "./CreditCostDetails";
import { UtiltityTooltip } from "../../../../utilities/components/toolTips/UtiltityTooltip";
import useNavigateToToolById from "../../../pages/workstation/hooks/useNavigateToToolById";
import { PlayIcon } from "../../../../globalTheme/icons/icons";

interface ToolDetailsButtonProps {
  secondaryAction?: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  avatar?: React.ReactNode;
  onClickHandler?: () => void;
  disableMaxWidth?: boolean;
  disableInfoTags?: boolean;
  runCount?: number;
  estimatedCreditCost?: number;
  visibility: VisibilityTypes;
  name: string;
  about?: string;
  id: string;
}

const Width64Div = ({ children }: { children?: React.ReactNode }) => (
  <div className="w-64px m-l-8px">{children}</div>
);

const ToolDetailsButtonInner: React.FC<ToolDetailsButtonProps> = ({
  id,
  name,
  about,
  visibility,
  runCount,
  // estimatedCreditCost,
  secondaryAction,
  disabled,
  icon,
  avatar,
  onClickHandler,
  disableMaxWidth,
  disableInfoTags
}) => {
  const handleNavigate = useNavigateToToolById();

  const isToolWithRunCount = !!runCount && runCount > 0;
  // const isToolWithCreditCost = estimatedCreditCost && estimatedCreditCost > 0;

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (onClickHandler) {
      e.preventDefault();
      onClickHandler();
      return;
    }
    handleNavigate(id)(e);
  };

  return (
    <BaseListItemButton
      visibility={visibility}
      name={name}
      about={about}
      onClick={handleClick}
      disabled={disabled}
      icon={icon}
      secondaryAction={secondaryAction}
      avatar={avatar}
      disableMaxWidth={disableMaxWidth}
    >
      {!disableInfoTags && (
        <>
          <Width64Div>
            {/* {isToolWithCreditCost && (
              <CreditCostDetails estimatedCreditCost={estimatedCreditCost} />
            )} */}
          </Width64Div>
          <Width64Div>
            {isToolWithRunCount && (
              <UtiltityTooltip title="Number of runs">
                <div className="flex align-i-center">
                  <PlayIcon />
                  <Typography className="m-l-8px" color="GrayText">
                    {runCount}
                  </Typography>
                </div>
              </UtiltityTooltip>
            )}
          </Width64Div>
        </>
      )}
    </BaseListItemButton>
  );
};

export default ToolDetailsButtonInner;
