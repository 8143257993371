import { ChipNodeComponents } from "../ChipNodeComponents";
import { ChipNodeHtmlTags } from "../ChipNodeHtmlTags";
import { ExtensionContent, ExtensionGroup } from "./configTypes";

export const ThinkingExtensionConfig = {
  config: {
    name: ChipNodeComponents.ThinkingComponent,

    group: ExtensionGroup.Inline,
    content: ExtensionContent.Inline,
    inline: true,
    draggable: false,
    editable: true,
    selectable: false, // needed so that we don't delete it when typing things if you click the tool

    atom: true
  },
  attributes: {},
  htmlTag: ChipNodeHtmlTags.ThinkingComponent
};
