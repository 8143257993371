import React, { useState, useEffect } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import BottomGradient from "../../../BottomGradient";
import TopGradient from "../../../TopGradient";
import AssetTextFieldContainer from "./AssetTextField";
import useScroller from "./hooks/useScroller";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";

const cx = classNames;

export const useShowTopGradient = (id: string) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Find the scrollable element after component is mounted
    const scrollableElement = document.querySelector(`.${id}`) as HTMLElement;

    if (scrollableElement) {
      const handleScroll = () => {
        const scrollTop = scrollableElement.scrollTop;
        setIsScrolled(scrollTop > 10); // Consider scrolled if more than 10px from top
      };

      // Add scroll event listener
      scrollableElement.addEventListener("scroll", handleScroll);

      // Initial check
      handleScroll();

      // Clean up
      return () => {
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []); // Empty dependency array means this runs once after mount

  return isScrolled;
};

const AssetTextFieldScrollWrapper = () => {
  const scroller = useScroller();
  const idClassName = "asset-text-field-id";
  const isScrolled = useShowTopGradient(idClassName);

  return (
    <>
      <TopGradient show={isScrolled} />
      <ScrollToBottom
        followButtonClassName="arrow-button"
        initialScrollBehavior="smooth"
        className={cx("h-100-percent scroll-to-bottom")}
        scrollViewClassName={`scrollable-content-important ${idClassName}`}
        scroller={scroller}
      >
        <AssetTextFieldContainer />
        {/* We add margin here because the Bottom gradient overlaps the border radius of the paper */}
        <BottomGradient />
      </ScrollToBottom>
    </>
  );
};

export default AssetTextFieldScrollWrapper;
