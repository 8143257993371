import { createTheme, responsiveFontSizes } from "@mui/material";
import { getCSSColor } from "./getCSSColor";
import toolflowTypography from "./toolflowTypography";
import { alpha } from "@mui/system";

export const rightSideContentBackground = getCSSColor(
  "--right-side-content-background",
  "#faf8f7"
);

export const white = getCSSColor("--white", "#FFF");
export const black = getCSSColor("--black", "#000");
export const structuredOutputOpenBrace = getCSSColor(
  "--structured-output-open-brace",
  "#e4dfd4"
);

export const toolflowPrimaryColor = getCSSColor("--primary-color", "#8a2dff");
export const toolflowErrorColor = "#d32f2f";
export const toolflowTextDisabledColor = getCSSColor(
  "--toolflow-text-disabled-color",
  "#12172961"
);
export const toolflowTextPrimaryColor = getCSSColor(
  "--primary-text-color",
  "#121729"
);
export const toolflowTextPrimaryColorLight = getCSSColor(
  "--primary-text-color-light",
  "#12172980"
);
export const toolflowDarkBackground = "#616161e5";
export const toolflowWhiteColor = "white";
export const toolflowTextSecondaryColor = getCSSColor(
  "--secondary-text-color",
  "#12172999"
);
export const toolflowPrimaryButtonBorderColor = getCSSColor(
  "--primary-button-border-color",
  "#6d12df"
);
export const lightButtonBorder = getCSSColor("--button-border", "#e4dfd4");
export const toolflowTextColorOrange = "#ff5722";
export const toolflowTextColorPrimary = "#000";
export const toolflowSecondaryMain = "#d016ff";
export const toolflowAppBackground = getCSSColor(
  "--app-background-color",
  "#faf6f0"
);
export const toolflowFlowAssetBackground = getCSSColor(
  "--flow-asset-background",
  "#efe9e0"
);
export const toolflowFlowAssetBorder = getCSSColor(
  "--light-chat-border",
  "#e4dfd4"
);
export const toolflowFlowAssetColor = getCSSColor(
  "--flow-asset-color",
  "#585858"
);

export const chipPurpleBackground = getCSSColor(
  "--chip-purple-background",
  "#f4ebff"
);

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    purple: true;
    white: true;
  }
}

declare module "@mui/material/styles" {
  interface ChipVariants {
    purple: React.CSSProperties;
    white: React.CSSProperties;
  }

  interface ChipVariantsOptions {
    purple: React.CSSProperties;
    white: React.CSSProperties;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 1536
    }
  },
  typography: toolflowTypography,
  palette: {
    mode: "light",
    primary: {
      main: toolflowPrimaryColor
    },
    error: {
      main: toolflowErrorColor
    },
    secondary: {
      main: toolflowSecondaryMain
    },
    textColor: {
      main: toolflowTextColorOrange
    },
    text: {
      primary: toolflowTextColorPrimary,
      secondary: toolflowTextSecondaryColor,
      disabled: toolflowTextDisabledColor
    }
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true // If we disable ripple update .Mui-focusVisible
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiDialog-paper": {
            borderRadius: "32px",
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "8px"
          },
          "&.MuiMenu-paper": {
            borderRadius: "16px",
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "0px 4px"
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: "small"
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          fontWeight: 500,
          padding: "6px 16px",
          borderRadius: "16px"
        }
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            backgroundColor: "rgba(138, 45, 255, 0.04)"
          }
        },
        {
          props: { variant: "secondaryContained" },
          style: {
            backgroundColor: alpha(toolflowPrimaryColor, 0.04),
            color: toolflowPrimaryColor
          }
        }
      ]
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "16px !important",
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: toolflowPrimaryColor
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 16
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: "small"
      }
    },
    MuiChip: {
      defaultProps: {
        size: "small"
      },
      variants: [
        {
          props: { variant: "purple" },
          style: {
            backgroundColor: chipPurpleBackground,
            color: toolflowPrimaryColor,
            borderRadius: "16px"
          }
        },
        {
          props: { variant: "white" },
          style: {
            backgroundColor: "white",
            borderRadius: "16px",
            border: `1px solid rgba(0, 0, 0, 0.2)`
          }
        }
      ]
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "subtitle1" }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          caption: "p"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "inherit"
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "16px"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          "&.notSelected": {
            "&.Mui-selected": {
              backgroundColor: "inherit"
              // fontWeight: "normal"
            },
            "&.Mui-selected:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)"
            }
          }
        }
      }
    },
    // Add styles for the selected value in the select field
    MuiSelect: {
      styleOverrides: {
        select: {
          // Reset styles for heading options in the select field
          '[data-value="Heading 2"]': {
            fontSize: "inherit",
            fontWeight: "inherit",
            lineHeight: "inherit",
            letterSpacing: "inherit"
          }
        }
      }
    }
  }
});

//This is so each h2 is correctly inline when using the menu select for headings in muiTiptap
const responseUpdatedTheme = responsiveFontSizes(
  createTheme(theme, {
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '.MuiPopover-root &[data-value="Heading 2"] span': {
              fontSize: theme.typography.h2.fontSize,
              fontWeight: theme.typography.h2.fontWeight,
              lineHeight: theme.typography.h2.lineHeight,
              fontFamily: theme.typography.h2.fontFamily
            }
          }
        }
      },
      // Add styles for the selected value in the select field
      MuiSelect: {
        styleOverrides: {
          select: {
            // Reset styles for heading options in the select field
            '[data-value="Heading 2"]': {
              fontSize: "inherit",
              fontWeight: "inherit",
              lineHeight: "inherit",
              letterSpacing: "inherit"
            }
          }
        }
      }
    }
  })
);

export default responseUpdatedTheme;
