import { Alert, AlertTitle } from "@mui/material";
import { ConfigurablePropAlert } from "@pipedream/sdk";
import { CSSProperties } from "react";

interface AlertProps {
  prop: ConfigurablePropAlert;
  baseStyles?: CSSProperties;
  component?: React.ComponentType<{
    prop: ConfigurablePropAlert;
    baseStyles: CSSProperties;
  }>;
}

export function PipedreamAlert({
  prop,
  component: CustomComponent,
  baseStyles
}: AlertProps) {
  if (CustomComponent) {
    return <CustomComponent prop={prop} baseStyles={baseStyles || {}} />;
  }

  return (
    <Alert severity={prop.alertType || "info"}>
      {prop.name && <AlertTitle>{prop.name}</AlertTitle>}
      {prop.content}
    </Alert>
  );
}
