import { type JSONValue } from "@toolflow/shared";
import { isObject } from "lodash";
import ReactJson from "react-json-view";
import OutputLoadingWrapper from "../../../../../../utilities/components/loading/HideWhenLoadingWrapper";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../../../../stores/actions";
import { useTheme } from "@mui/material";
import {
  black,
  structuredOutputOpenBrace,
  toolflowPrimaryColor,
  white
} from "../../../../../../globalTheme/muiUtils/appTheme";

const defaultTheme = {
  base00: "rgba(0, 0, 0, 0)", // Default background color - Main background color for the theme
  base01: "rgb(245, 245, 245)", // Lighter background color - Used for highlighted/selected areas
  base02: "rgb(235, 235, 235)", // Selection background - Used for selections and highlights
  base03: "red", // Comment color - Used for comments and subtle UI elements
  base04: "rgba(0, 0, 0, 0.3)", // Dark Foreground status bars - Used for secondary content
  base05: "#586e75", // Default Foreground - Main text color
  base06: "#073642", // Darker text color - Used for emphasized content
  base07: "#002b36", // Darker text color - Used for brightest elements
  base08: "#d33682", // Keyword color - Used for errors and variables
  base09: "#cb4b16", // Keyword color - Used for integers and constants
  base0A: "#dc322f", // String color - Used for strings and attributes
  base0B: "#859900", // Constant color - Used for constants and keywords
  base0C: "#6c71c4", // Variable color - Used for variables and escape characters
  base0D: "#586e75", // Property color - Used for properties and methods
  base0E: "#2aa198", // Function color - Used for functions and keywords
  base0F: "#268bd2" // String color - Used for embedded language tags
};

const useCopySuccess = () => {
  const reduxDispatch = useDispatch();
  return () => {
    reduxDispatch(setSuccessMessage("Copied"));
  };
};

export default function StructuredOutput({
  value,
  showLoading,
  className
}: {
  value: JSONValue;
  showLoading?: boolean;
  className?: string;
}) {
  const copy = useCopySuccess();
  const theme = useTheme();
  if (!isObject(value)) return null;
  return (
    <div className={className}>
      <OutputLoadingWrapper showLoading={showLoading}>
        <ReactJson
          src={value}
          name={false}
          iconStyle="circle"
          collapseStringsAfterLength={500}
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          enableClipboard={copy}
          theme={{
            ...defaultTheme,
            base07: white, // braces
            base08: toolflowPrimaryColor,
            base09: black,
            base0A: toolflowPrimaryColor,
            base0D: structuredOutputOpenBrace, // open brace
            base0E: toolflowPrimaryColor, // variables, collapsed icon
            base0F: toolflowPrimaryColor
          }}
          style={theme.typography.body1}
        />
      </OutputLoadingWrapper>
    </div>
  );
}
