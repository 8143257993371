import {
  BlockType,
  Condition,
  CustomToolInputFieldTypesEnum,
  DeepgramModel,
  FieldType,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  type BlockFieldsConfig,
  type DeepgramBlockDataV2
} from "../../../../../types";
import {
  commonLanguages,
  deepgramLanguages,
  deepgramModels
} from "../../constants";
import {
  filterAvailableFieldsByArray,
  filterAvailableFieldsByFile
} from "../../helpers";
import { getInitialState } from "../../initialStates";

const EvalWhisperSelected: Condition = [
  "settings.model.value",
  "==",
  DeepgramModel.WhisperMedium
];

const EvalDiarizeEnabled: Condition = ["settings.diarize.value", "true"];

const initialState = getInitialState(BlockType.Deepgram) as DeepgramBlockDataV2;

export const deepgramBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.DeepgramSettings,
      config: {}
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.FileField,
      label: "Audio file",
      config: {
        fieldKey: "settings.file"
      }
    },
    {
      type: FieldType.DynamicSelectField,
      label: "Model",
      subtitle: "Select audio transcription technology.",
      config: {
        typeKey: "settings.model.type",
        fieldKey: "settings.model.value",
        options: deepgramModels,
        defaultValue: initialState.settings.model.value,
        filterAvailableFields: filterAvailableFieldsByFile,
        allowedFields: [CustomToolInputFieldTypesEnum.SELECT]
      }
    },
    {
      type: FieldType.DynamicListField,
      label: "Keywords",
      hidden: EvalWhisperSelected,
      subtitle: "Add keywords to improve transcription accuracy.",
      config: {
        typeKey: "settings.keywords.type",
        fieldKey: "settings.keywords.value",
        filterAvailableFields: filterAvailableFieldsByArray,
        defaultValue: initialState.settings.keywords.value
      }
    },
    {
      type: FieldType.WordReplaceField,
      label: "Find and replace",
      subtitle:
        "Replace specific terms in transcription (case-sensitive, excludes speaker names).",
      hidden: EvalWhisperSelected,
      config: { fieldKey: "settings.wordsToReplace" }
    },
    {
      type: FieldType.Group,
      label: "Processing options",
      fields: [
        {
          type: FieldType.DynamicSelectField,
          label: "Audio language",
          subtitle: "Select language spoken in the audio file.",
          config: {
            filterAvailableFields: filterAvailableFieldsByFile,
            allowedFields: [CustomToolInputFieldTypesEnum.SELECT],
            typeKey: "settings.language.type",
            fieldKey: "settings.language.value",
            options: {
              conditions: Object.values(DeepgramModel).map((model) => [
                "settings.model.value",
                "==",
                model,
                deepgramLanguages[model]
              ]),
              else: commonLanguages
            },
            defaultValue: initialState.settings.language.value
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Diarize",
          subtitle: "Separate speakers in audio",
          config: {
            typeKey: "settings.diarize.type",
            fieldKey: "settings.diarize.value",
            defaultValue: initialState.settings.diarize.value
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Timestamps",
          disabled: EvalDiarizeEnabled,
          subtitle:
            "Include time markers in the transcript. (Cannot be used with Diarize selected.)",
          config: {
            typeKey: "settings.addTimestamps.type",
            fieldKey: "settings.addTimestamps.value"
          }
        },
        {
          type: FieldType.DynamicSelectField,
          label: "Add timestamps by",
          disabled: {
            logic: "OR",
            conditions: [
              ["settings.diarize.value", "true"],
              ["settings.addTimestamps.value", "false"]
            ]
          },
          subtitle:
            "Set timestamps for individual sentences or full paragraphs. (Can only be used with timestamp enabled)",
          config: {
            filterAvailableFields: filterAvailableFieldsByFile,
            allowedFields: [CustomToolInputFieldTypesEnum.SELECT],
            typeKey: "settings.timeStampByEntity.type",
            fieldKey: "settings.timeStampByEntity.value",
            options: [
              { label: "Paragraph", value: "paragraph" },
              { label: "Sentence", value: "sentence" }
            ]
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Summarize",
          subtitle: "Generate a summary of the content",
          config: {
            typeKey: "settings.addSummary.type",
            fieldKey: "settings.addSummary.value",
            defaultValue: initialState.settings.addSummary.value
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Dictation",
          subtitle:
            "Convert spoken dictation commands to their corresponding punctuation marks.",
          config: {
            typeKey: "settings.isFileDictation.type",
            fieldKey: "settings.isFileDictation.value",
            defaultValue: initialState.settings.isFileDictation.value
          }
        },
        {
          type: FieldType.DynamicSwitchField,
          label: "Convert measurements",
          subtitle:
            "Convert measurements to abbreviations (e.g. milligram(s) to mg).",
          config: {
            typeKey: "settings.convertMeasurementsValuesToAbbreviations.type",
            fieldKey: "settings.convertMeasurementsValuesToAbbreviations.value",
            defaultValue:
              initialState.settings.convertMeasurementsValuesToAbbreviations
                .value
          }
        }
      ]
    }
  ]
};
