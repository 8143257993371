import {
  blockConfig,
  BlockType,
  getLatestBlockVersion,
  type AllBlockTypes,
  type AllVersionedBlockConfigs,
  type BlockConfigFE,
  type DecimalString,
  type VersionedBlockConfig
} from "@toolflow/shared";
import getBlockConfigFunctions from "./blockConfigFns/blockConfigFns";
import { getBlockIcon } from "./blockIcons";

export const blockConfigFE = Object.entries(blockConfig).reduce(
  (acc, [blockType, versionedConfig]) => {
    acc[blockType as keyof AllVersionedBlockConfigs] = Object.entries(
      versionedConfig
    ).reduce((acc2, [version, config]) => {
      acc2[version as DecimalString] = {
        ...config,
        ...getBlockConfigFunctions(
          config.draggableItem.data.type,
          version as DecimalString
        ),
        icon: getBlockIcon(config.draggableItem.data.type)
      };
      return acc2;
    }, {} as VersionedBlockConfig<BlockConfigFE>);

    return acc;
  },
  {} as AllVersionedBlockConfigs<BlockConfigFE>
);

/**
 * Retrieves the block configuration for a given block type and version.
 *
 * @param {AllBlockTypes} type - The type of the block.
 * @param {DecimalString} [version] - The version of the block. If not provided or not found, the latest available version is used.
 * @returns {BlockConfigFE} The block configuration for the specified type and version.
 */
export function getBlockConfigFE(
  type: AllBlockTypes,
  version?: DecimalString
): BlockConfigFE {
  if (type in blockConfigFE) {
    return blockConfigFE[type][version || getLatestBlockVersion(type)];
  } else return {} as BlockConfigFE;
}

export function getAllBlockConfigsFE() {
  return Object.entries(blockConfigFE)
    .map(([type, versionedConfig]) => {
      if (type === BlockType.Anthropic) return null;
      const version = getLatestBlockVersion(type as AllBlockTypes);
      return versionedConfig[version];
    })
    .filter((config) => !!config);
}
