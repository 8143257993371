import { AGENT, type TMarketplaceUniversalEntity } from "@toolflow/shared";
import React, { FC } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";

import EntityCardHeader from "./EntityCardHeader";
import EntityCardSubHeader from "./EntityCardSubHeader";

import DuplicateEntity from "../../../tools/components/searchToolsContainer/DuplicateEntity";
import styles from "./entityCard.module.css";
import { useGetCurrentUserProfileId } from "../../../user/hooks/useGetCurrentUserProfileId";
import { parsedClassNames } from "../../../../utilities/functions/parsedClassNames";
import { OwnerActions } from "./OwnerActions";

const cx = parsedClassNames.bind(styles);

function NotOwnerActions({
  entity,
  children
}: {
  entity: TMarketplaceUniversalEntity;
  children: React.ReactNode;
}) {
  if (entity.type === AGENT) {
    return <>{children}</>;
  }
  return (
    <div>
      {children}
      <DuplicateEntity
        entityId={entity.id}
        entityType={entity.type}
        enableForking={entity.enableForking}
        variant="secondaryContained"
      />
    </div>
  );
}

function ActionSplitter({
  entity,
  children
}: {
  entity: TMarketplaceUniversalEntity;
  children?: React.ReactNode;
}) {
  const profileId = useGetCurrentUserProfileId();
  if (entity.userId === profileId) {
    return (
      <OwnerActions entity={entity} variant="secondaryContained">
        {children}
      </OwnerActions>
    );
  } else {
    return <NotOwnerActions entity={entity}>{children}</NotOwnerActions>;
  }
}

interface IEntityCardProps {
  entity: TMarketplaceUniversalEntity;
  onClick: (e: React.MouseEvent) => void;
  disableActions?: boolean;
}
const EntityCard: FC<IEntityCardProps> = ({
  entity,
  onClick,
  disableActions
}) => {
  return (
    <Card
      className={cx(
        styles["entity-card"],
        styles["standard-entity-card"],
        {
          [styles["entity-card-disabled-actions"]]: disableActions
        },
        "parent-hover pos-relative"
      )}
      onClick={onClick}
    >
      <CardHeader
        className={cx(styles["entity-card-header"])}
        disableTypography
        classes={{
          content: "max-w-100-percent"
        }}
        title={<EntityCardHeader entity={entity} />}
        // subheader={}
      />
      <CardContent className="p-b-0px p-t-8px pos-relative">
        <Typography
          variant="body2"
          color="text.secondary"
          paragraph
          className="m-0px oflow-two-lines"
          sx={{
            height: "40px"
          }}
        >
          {entity.description}
        </Typography>
      </CardContent>

      <Box
        className="show-on-hover pos-absolute bg-color-white border-radius-16px flex align-i-center"
        sx={{
          color: "text.disabled",
          right: "12px",
          bottom: "12px",
          zIndex: 1000 // needed to be able to click the buttons
        }}
      >
        {disableActions ? (
          <Button
            variant="contained"
            onClick={onClick}
            size="small"
            className="m-h-8px"
          >
            Use
          </Button>
        ) : (
          <ActionSplitter entity={entity}>
            <Button
              variant="contained"
              onClick={onClick}
              size="small"
              className="m-r-8px"
            >
              Open
            </Button>
          </ActionSplitter>
        )}
      </Box>
      <div
        style={{
          position: "absolute",
          bottom: "8px",
          left: "20px",
          width: "100%"
        }}
        className={"hide-on-hover"}
        onClick={(e) => e.stopPropagation()} // don't have default behavior of card click
      >
        <EntityCardSubHeader entity={entity} />
      </div>
    </Card>
  );
};

export default EntityCard;
