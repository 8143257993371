import { FormControl, TextField } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicInputFieldType
} from "@toolflow/shared";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useDynamicSetting from "../../hooks/useDynamicSetting";

export default function DynamicInputField() {
  const { id, field, getFieldValue, updateField } =
    useFieldsByTypeContext<DynamicInputFieldType>();
  const {
    fieldKey,
    typeKey,
    placeholder,
    htmlInputType,
    defaultValue,
    filterAvailableFields
  } = field.config;

  const dynamicFieldType = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = getFieldValue(fieldKey, defaultValue);
  const label = useDynamicSetting<string>(field.label, "");
  const subtitle = useDynamicSetting<string>(field.subtitle, "");
  const disabled = useDynamicSetting<boolean>(field.disabled);

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldType}
        label={label}
        subtitle={subtitle}
        configs={field.config}
        fieldValue={fieldValue}
        addInputType={CustomToolInputFieldTypesEnum.TEXTFIELD}
        filterOptions={filterAvailableFields}
        onTypeChangeCallback={(type) => {
          if (type === DynamicFieldType.Dynamic) {
            updateField(CREATE_USER_INPUT_LABEL, fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <TextField
            fullWidth
            size="small"
            value={fieldValue}
            placeholder={placeholder}
            type={htmlInputType}
            disabled={disabled}
            className="bg-color-white"
            onChange={(e) => {
              let value: string | number = e.target.value;
              if (htmlInputType === "number") {
                value = Number(value);
              }
              updateField(value, fieldKey);
            }}
          />
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
