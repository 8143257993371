import {
  type LogicType,
  type ValidatedInput,
  type LogicItem,
  type CustomToolInputField,
  CustomToolInputFieldTypesEnum
} from "@toolflow/shared";
import { isInputWithOptions } from "../../../../tools/components/editorToolCard/inputs/helpers/constants";

export const logicMap: Partial<
  Record<CustomToolInputFieldTypesEnum, LogicType>
> = {
  [CustomToolInputFieldTypesEnum.SELECT]: "is",
  [CustomToolInputFieldTypesEnum.CHECKBOX]: "checked",
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: "contains",
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: "contains",
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: "contains",
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: "exists"
};

export const getDefaultParameterValue = (
  inputLogicType: LogicType,
  toolInputField: CustomToolInputField | ValidatedInput
) => {
  let parameterValue;
  if (inputLogicType === "is") {
    parameterValue = isInputWithOptions(toolInputField)
      ? toolInputField.options[0]
      : "";
  } else if (inputLogicType === "contains") {
    parameterValue = "";
  }
  return parameterValue;
};

export const generateInitialLogicFromToolInput = (
  toolInputField: CustomToolInputField | ValidatedInput
): LogicItem => {
  if (toolInputField) {
    const inputLogicType = logicMap[toolInputField.type];
    if (inputLogicType) {
      return {
        input: toolInputField.name,
        inputLogicType: "exists",
        logicValue: "true"
      };
    }
  }
  return {
    input: "",
    inputLogicType: "exists",
    logicValue: "true"
  };
};
