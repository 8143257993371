import { ClickableTypes, EAddChipTab } from "@toolflow/shared";
import {
  AssetIcon,
  InputIcon,
  OutputIcon,
  SnippetIcon
} from "../../../../../../../../../globalTheme/icons/icons";

let tabId = 0;

export const ALL = "all";

export type TChipTab = {
  label: string;
  value: EAddChipTab | typeof ALL;
  indexValue: number;
  icon?: React.ElementType;
};

export const snippetIconDict = {
  [ClickableTypes.SNIPPET]: SnippetIcon,
  [ClickableTypes.INPUT]: AssetIcon,
  [ClickableTypes.TOOLBUILDER_INPUT]: InputIcon,
  [ClickableTypes.NODE_OUTPUT]: OutputIcon
};

export const addChipTabs: TChipTab[] = [
  {
    label: "All",
    value: ALL,
    indexValue: tabId++
  },
  {
    label: "Inputs",
    value: EAddChipTab.NODES,
    indexValue: tabId++,
    icon: InputIcon
  },
  {
    label: "Node Outputs",
    value: EAddChipTab.NODE_OUTPUTS,
    indexValue: tabId++,
    icon: OutputIcon
  },
  {
    label: "Assets",
    value: EAddChipTab.ASSETS,
    indexValue: tabId++,
    icon: AssetIcon
  },
  {
    label: "Knowledge",
    value: EAddChipTab.SNIPPETS,
    indexValue: tabId++,
    icon: SnippetIcon
  }
];
