import { Grid, TextField, Typography } from "@mui/material";
import { useFormFieldContext } from "@pipedream/connect-react";
import { FieldSelectorValue } from "@toolflow/shared";
import { isPlainObject } from "lodash";
import { ReactNode } from "react";
import { parsedClassNames } from "../../../../../../../../../utilities/functions/parsedClassNames";
import FieldSelector from "../../../../wrapper/FieldSelector";
import styles from "../PipedreamFields.module.css";
import { usePipedreamFieldsContext } from "../PipedreamFieldsProvider";

const cx = parsedClassNames.bind(styles);

export default function PipedreamFieldLabel({
  children,
  overrideKey,
  alternateDescription,
  onTypeChange
}: {
  children: ReactNode;
  overrideKey?: string;
  alternateDescription?: string;
  onTypeChange?: (type: FieldSelectorValue) => void;
}) {
  const { prop, value } = useFormFieldContext();

  const {
    allowAgentDeterminedValues,
    getCurrentValue,
    updateDynamicConfiguredProps
  } = usePipedreamFieldsContext();

  const currentValue = getCurrentValue(prop.name);
  const guideline = currentValue?.guideline || "";
  const fieldType =
    isPlainObject(currentValue) && "key" in currentValue
      ? FieldSelectorValue.Agent
      : FieldSelectorValue.Preset;

  const getAgentValue = (guidelineValue?: string) => ({
    key: overrideKey ? `${prop.name}-{{${overrideKey}}}` : prop.name,
    type: FieldSelectorValue.Agent,
    guideline: guidelineValue || null
  });

  function handleChange(v: FieldSelectorValue) {
    if (v === FieldSelectorValue.Agent) {
      updateDynamicConfiguredProps(prop.name, getAgentValue());
    } else {
      updateDynamicConfiguredProps(prop.name, value);
    }
    onTypeChange?.(v);
  }

  function handleDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    updateDynamicConfiguredProps(prop.name, getAgentValue(e.target.value));
  }

  const description =
    fieldType === FieldSelectorValue.Agent
      ? alternateDescription || prop.description
      : prop.description;

  return (
    <>
      <Grid
        container
        spacing={[1, 0.5]}
        className={cx("pd-label", "grid-area-label", "m-b-8px")}
      >
        <Grid item xs={8} className="flex align-i-center">
          <Typography variant="h8" className="m-r-8px capitalize">
            {prop.label}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {allowAgentDeterminedValues && (
            <FieldSelector value={fieldType} onChange={handleChange} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="text.secondary"
            className="font-size-12px"
          >
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {fieldType === FieldSelectorValue.Preset ? (
            children
          ) : (
            <TextField
              fullWidth
              multiline
              size="small"
              value={guideline}
              className="m-t-8px bg-color-white"
              placeholder="Enter a prop description for the agent to determine the value"
              onChange={handleDescriptionChange}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export function PipedreamAppLabel(props: $TSAllowedAny) {
  if (props.field.prop.type !== "app") return <></>;
  // This is a special case for "app" type inputs. Their labels are only available in Label Component.
  // So, This component is used to render the label for "app" type inputs.

  return (
    <Typography
      variant="h8"
      className={cx("pd-label", "grid-area-label")}
      sx={{ mb: 7.5 }}
    >
      {props.text}
    </Typography>
  );
}
