import {
  EntityType,
  TCategory,
  type TMarketplaceTypes,
  type TMarketplaceUniversalEntity
} from "@toolflow/shared";
import useEntityCategory from "../categories/hooks/useCategoryTabs";
import {
  ElementCardFC,
  EntityCardFC,
  IntegrationCardFC
} from "../context/SearchToolsContext";
import EntityNotFound from "../EntityNotFound";
import EntitiesGrid from "./EntitiesGrid/EntitiesGrid";
import IntegrationsGrid from "./integrationsGrid/IntegrationsGrid";

export default function ElementGrid({
  entities,
  entityType,
  EntityElement
}: {
  entities?: TMarketplaceUniversalEntity[];
  entityType: TMarketplaceTypes;
  EntityElement: ElementCardFC;
}) {
  const { selectedMarketplaceCategoryTab } = useEntityCategory(entityType);

  const isIntegration =
    entityType === EntityType.TOOL &&
    selectedMarketplaceCategoryTab?.value === TCategory.Integrations;

  return (
    <>
      {isIntegration ? (
        <IntegrationsGrid EntityElement={EntityElement as IntegrationCardFC} />
      ) : !!entities?.length ? (
        <EntitiesGrid
          marketplaceEntities={entities}
          EntityElement={EntityElement as EntityCardFC}
          entityType={entityType}
        />
      ) : (
        <EntityNotFound entityType={entityType} />
      )}
    </>
  );
}
