import { ReactNode, useEffect, useRef } from "react";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./drawerBottom.module.css";

const cx = parsedClassNames.bind(styles);

function DrawerBottom({
  mainContent = <div />,
  leftSlot,
  rightSlot,
  headerHeight = 72,
  scrollToTopKey
}: {
  mainContent?: ReactNode;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  headerHeight?: number;
  scrollToTopKey?: string;
}) {
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0;
    }
  }, [scrollToTopKey]);

  return (
    <div
      className="flex flex-column"
      style={{ height: `calc(100% - ${headerHeight}px)` }}
    >
      <div className="flex-grow-1 scrollable-content" ref={scrollableRef}>
        <div className="h-100-percent">{mainContent}</div>
      </div>
      {(rightSlot || leftSlot) && (
        <div
          className={cx(
            "flex p-16px justify-space-between align-i-center",
            styles.footer
          )}
        >
          {leftSlot || <div />}
          {rightSlot || <div />}
        </div>
      )}
    </div>
  );
}

export default DrawerBottom;
