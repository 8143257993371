import { Stack } from "@mui/material";
import {
  BlockNode,
  CopyableField,
  type BlockConfigFE,
  type BlockDataBase,
  type BlockPropsBase
} from "@toolflow/shared";
import DrawerBottom from "../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../../common/TestToolRun";
import TestFields from "./components/TestFields";
import TestOutput from "./components/TestOutput";
import useTest from "./hooks/useTest";
import useTestFields from "./hooks/useTestFields";

interface Props<T> extends Omit<BlockPropsBase<T>, "selected"> {
  config: BlockConfigFE;
}

export default function TestDrawer<T extends BlockDataBase>({
  id,
  data,
  config
}: Props<T>) {
  const { fields = [], outputType = CopyableField.LargeText } = config;

  const {
    inputFields,
    userInput,
    customFields,
    availableBlockFields,
    setUserInput
  } = useTestFields(id, fields, data);

  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest({
      data,
      id,
      blockType: config.draggableItem.type as BlockNode,
      fields: inputFields,
      userInput,
      customFields
    });

  if (!userInput) return null;

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestFields
            fields={availableBlockFields}
            uI={userInput}
            setUI={setUserInput}
          />
          <TestOutput output={output} label={label} type={outputType} />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}
