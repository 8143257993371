import {
  AGENT,
  TCategory,
  type TMarketplaceTypes,
  type TEntityFilterByCategoriesFE
} from "@toolflow/shared";
import React from "react";
import {
  AllFillIcon,
  AllIcon,
  BlockFillIcon,
  BlockIcon,
  GrowthFillIcon,
  GrowthIcon,
  MarketingFillIcon,
  MarketingIcon,
  PopularFillIcon,
  PopularIcon,
  SalesFillIcon,
  SalesIcon
} from "../../../../../globalTheme/icons/icons";
import { STARRED_TAB, USER_TOOLS_TAB } from "../utils/constants";
export type TUseCaseType = "useCase";
export type TCategoryType = "category";
export type TTabType = TUseCaseType | TCategoryType;
export type TEntityTab = {
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  label: string;
  value: TEntityFilterByCategoriesFE;
  RemixIconRaw: React.ElementType;
  disabledTabs?: string[];
  disabledType?: TMarketplaceTypes[];
};
const iconSize = 20;
export const marketplaceCategoryTabs: TEntityTab[] = [
  {
    label: "Popular",
    icon: <PopularIcon size={iconSize} />,
    selectedIcon: <PopularFillIcon size={iconSize} />,
    RemixIconRaw: PopularIcon,
    value: "popular",
    disabledTabs: [USER_TOOLS_TAB, STARRED_TAB]
  },
  {
    label: "All",
    icon: <AllIcon size={iconSize} />,
    selectedIcon: <AllFillIcon size={iconSize} />,
    RemixIconRaw: AllIcon,
    value: "all"
  },
  {
    label: "Sales",
    icon: <SalesIcon size={iconSize} />,
    selectedIcon: <SalesFillIcon size={iconSize} />,
    RemixIconRaw: SalesIcon,
    value: TCategory.Sales
  },
  {
    label: "Marketing",
    icon: <MarketingIcon size={iconSize} />,
    selectedIcon: <MarketingFillIcon size={iconSize} />,
    RemixIconRaw: MarketingIcon,
    value: TCategory.Marketing
  },
  {
    label: "Growth",
    icon: <GrowthIcon size={iconSize} />,
    selectedIcon: <GrowthFillIcon size={iconSize} />,
    RemixIconRaw: GrowthIcon,
    value: TCategory.Growth
  },
  {
    label: "General",
    icon: <BlockIcon size={iconSize} />,
    selectedIcon: <BlockFillIcon size={iconSize} />,
    RemixIconRaw: BlockIcon,
    value: TCategory.Blocks,
    disabledTabs: [USER_TOOLS_TAB, STARRED_TAB],
    disabledType: [AGENT]
  },
  {
    label: "Integrations",
    icon: <BlockIcon size={iconSize} />,
    selectedIcon: <BlockFillIcon size={iconSize} />,
    RemixIconRaw: BlockIcon,
    value: TCategory.Integrations,
    disabledTabs: [USER_TOOLS_TAB, STARRED_TAB],
    disabledType: [AGENT]
  }
];
