import { FormControl } from "@mui/material";
import {
  ComponentFormContainer,
  CustomizeProvider,
  FrontendClientProvider
} from "@pipedream/connect-react";
import {
  PIPEDREAM_OVERRIDE_PROPS,
  type BlockSettingsBase,
  type PipedreamFieldType
} from "@toolflow/shared";
import { usePipedreamClient } from "../../../../../../../integrations/context/PipedreamContext";
import useIntegrationActionsOptions from "../../../../../../../integrations/hooks/useIntegrationActionsOptions";
import { PipedreamAppLabel } from "../../../../../../../tools/components/editorToolCard/inputs/wsPipedreamFields/wsPipedreamConfigurationsFields/PipedreamConfigurationFields/components/PipedreamFieldLabel";
import useGetCurrentUser from "../../../../../../../user/hooks/useGetCurrentUser";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../hooks/useFieldsByTypeHelper";
import { PipedreamAccordion } from "./PipedreamAccordion";
import { PipedreamAppSelect } from "./PipedreamAppSelect";
import { PipedreamBoolean } from "./PipedreamBoolean";
import { PipedreamDescription } from "./PipedreamDescription";
import { PipedreamControlInput } from "./PipedreamInputField";
import { PipedreamSelectField } from "./PipedreamSelectField";
import { PipedreamAlert } from "./PipedreamAlert";

export default function PipedreamField() {
  const { field, data, updateField } = useFieldsByTypeContext<
    PipedreamFieldType,
    BlockSettingsBase
  >();
  const { config } = field;
  const { fieldKey } = config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });
  const { pdClient } = usePipedreamClient();
  const user = useGetCurrentUser();

  const selectedAction = getFieldValue("settings.action", "");
  const selectedIntegrationType = getFieldValue(
    "settings.selectedIntegrationType",
    ""
  );
  const configuredProps = getFieldValue(fieldKey, {});

  const userId = user?._id;

  const { isLoading } = useIntegrationActionsOptions(selectedIntegrationType);

  if (!pdClient || !selectedAction || !userId || isLoading) return null;

  const handleUpdateConfiguredProps = (props: $TSAllowedAny) => {
    const mergedProps = {
      ...configuredProps,
      ...props
    };
    updateField(mergedProps, fieldKey);
    updateField(mergedProps, "settings.configuredProps");
  };
  return (
    <FormControl margin="normal" fullWidth>
      <FrontendClientProvider client={pdClient}>
        <CustomizeProvider
          components={{
            ControlInput: PipedreamControlInput,
            ControlApp: PipedreamAppSelect,
            ControlSelect: PipedreamSelectField,
            ControlBoolean: PipedreamBoolean,
            Label: PipedreamAppLabel,
            OptionalFieldsContainer: PipedreamAccordion,
            Alert: PipedreamAlert,
            Description: PipedreamDescription
          }}
        >
          <ComponentFormContainer
            userId={user._id}
            componentKey={selectedAction}
            configuredProps={configuredProps}
            onUpdateConfiguredProps={handleUpdateConfiguredProps}
            overrideProps={PIPEDREAM_OVERRIDE_PROPS}
          />
        </CustomizeProvider>
      </FrontendClientProvider>
    </FormControl>
  );
}
