import { type ButtonVariantWithIcon, EntityType } from "@toolflow/shared";
import { useDuplicateObject } from "../../../../utilities/hooks/useDuplicateObject";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { ForkIcon } from "../../../../globalTheme/icons/icons";
import { Button } from "@mui/material";

const DuplicateEntity = ({
  entityId,
  entityType,
  enableForking,
  variant = "icon"
}: {
  entityId: string;
  entityType: EntityType;
  enableForking?: boolean;
  variant?: ButtonVariantWithIcon;
}) => {
  const { duplicateObject } = useDuplicateObject();

  if (entityType === EntityType.WORKFLOW || !enableForking) return null;

  const duplicateEntity = (e: React.MouseEvent) => {
    e.stopPropagation();
    duplicateObject(entityId, entityType);
  };

  if (variant !== "icon") {
    return (
      <Button onClick={duplicateEntity} size="small" variant={variant}>
        Copy to edit
      </Button>
    );
  }

  return (
    <RemixButton
      text="Create your own editable copy of this template"
      icon={ForkIcon}
      onClick={duplicateEntity}
    />
  );
};

export default DuplicateEntity;
