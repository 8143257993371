import { Grid } from "@mui/material";
import type { JSONSchemaItem, JSONSchemaItemType } from "@toolflow/shared";
import SchemaTypeField from "./SchemaTypeField";
import TextFieldWithLabel from "../components/TextFieldWithLabel";

export default function MainSchemaFields({
  skipTypes,
  schemaItem,
  onChange,
  updateFieldNameInTOF
}: {
  skipTypes?: JSONSchemaItemType[];
  schemaItem: JSONSchemaItem;
  onChange(schemaItem: JSONSchemaItem): void;
  updateFieldNameInTOF?(prevValue: string, newValue: string): void;
}) {
  function handleLabelUpdate(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const fieldLabel = event.target.value;
    onChange({ ...schemaItem, fieldLabel });

    if (schemaItem.type === "object") {
      schemaItem.items.forEach((item) => {
        updateFieldNameInTOF?.(
          `${schemaItem.fieldLabel}.${item.fieldLabel}`,
          `${fieldLabel}.${item.fieldLabel}`
        );
      });
    } else {
      updateFieldNameInTOF?.(schemaItem.fieldLabel, fieldLabel);
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextFieldWithLabel
          fullWidth
          size="small"
          label="Field label"
          value={schemaItem.fieldLabel}
          onChange={handleLabelUpdate}
        />
      </Grid>
      <Grid item xs={6}>
        <SchemaTypeField
          schemaItem={schemaItem}
          skip={skipTypes}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
}
