import { CircularProgress, Stack } from "@mui/material";
import { useToolContext } from "../../../../../tools/contexts/ToolContext";
import { ToolRunChip } from "../../../../../tools/components/ToolRunChip";

const LoadingToolDetails = () => {
  const { tool } = useToolContext();
  return (
    <>
      <ToolRunChip
        title={tool.name}
        Icon={(props) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <CircularProgress {...props} />
            {tool.avatar && (
              <img src={tool.avatar} alt="avatar" height={14} width={14} />
            )}
          </Stack>
        )}
      />
    </>
  );
};

export default LoadingToolDetails;
