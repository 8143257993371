import { ConfigurableProp } from "@pipedream/sdk";
import {
  type AgentDeterminedFieldValue,
  FieldSelectorValue
} from "@toolflow/shared";

export const getAgentDeterminedObj = (
  prop: ConfigurableProp,
  guidelineValue?: string
): AgentDeterminedFieldValue => ({
  key: prop.name,
  type: FieldSelectorValue.Agent,
  guideline: guidelineValue || null
});
