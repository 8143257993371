import {
  AllBlockTypes,
  AnthropicModel,
  BlockType,
  OpenAiTextModel,
  PerplexityModel,
  SelectOptionObject,
  anthropicPriceDict,
  openAIPriceDict,
  perplexityPriceDict,
  type TAnthropicPriceDictType,
  type TOpenAIPriceDictType,
  type TPerplexityPriceDictType
} from "../../../../types";
import { formatNumberToUS } from "../../../formatters";

export function getBaseModelOptions(
  priceDict:
    | TOpenAIPriceDictType
    | TAnthropicPriceDictType
    | TPerplexityPriceDictType,
  modelFilter?: string[],
  type?: AllBlockTypes
) {
  const excludedModels: string[] = [
    AnthropicModel.SONNET_3_5,
    OpenAiTextModel.GPT4o_Aug
  ];
  return Object.entries(priceDict)
    .reduce((acc, [k, v]) => {
      if (
        (!modelFilter || modelFilter.includes(k)) &&
        !excludedModels.includes(k)
      ) {
        acc.push({
          value: k,
          label: v.label || k,
          subText: `${formatNumberToUS(v.tokenCount)} tokens`,
          type
        });
      }
      return acc;
    }, [] as SelectOptionObject[])
    .sort((a, b) => (a.label > b.label ? -1 : 1));
}

export function getTextGenerationTechOptions(modelFilter?: string[]) {
  return [
    ...getBaseModelOptions(
      anthropicPriceDict,
      modelFilter,
      BlockType.Anthropic
    ),
    ...getBaseModelOptions(openAIPriceDict, modelFilter, BlockType.ChatGPT)
  ];
}

export function getAnthropicModelOptions() {
  return getBaseModelOptions(
    anthropicPriceDict,
    undefined,
    BlockType.Anthropic
  );
}

export function getPerplexityModelOptions() {
  return getBaseModelOptions(
    perplexityPriceDict,
    [
      PerplexityModel.Sonar,
      PerplexityModel.SonarDeepResearch,
      PerplexityModel.R1_1776,
      PerplexityModel.SonarReasoningPro,
      PerplexityModel.SonarReasoning,
      PerplexityModel.SonarPro
    ],
    BlockType.Perplexity
  );
}

export function getOpenAIModelOptions() {
  return getBaseModelOptions(openAIPriceDict, undefined, BlockType.ChatGPT);
}

export function getAgentModelOptions() {
  return getTextGenerationTechOptions([
    AnthropicModel.SONNET_3_7,
    AnthropicModel.SONNET_3_5_LATEST,
    AnthropicModel.HAIKU_3_5,
    OpenAiTextModel.GPT4o,
    OpenAiTextModel.GPT4o_Mini,
    OpenAiTextModel.o3_Mini
  ]);
}
