import { InputLabel, Typography } from "@mui/material";
import type { JSONContent } from "@tiptap/core";
import {
  CopyableField,
  isHandledChatStackError,
  validateAndParseJson,
  type CustomToolOutputFieldPartial,
  type DallEOutputType,
  type HandledChatStackError,
  type JSONValue,
  type LogicGate,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import { isArray, isObject, isString } from "lodash";
import React from "react";
import CopyableImage from "../../../../tools/components/editorToolCard/outputs/CopyableImage/CopyableImage";
import CopyableLargeTextField from "../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import StructuredOutput from "../../../../tools/components/editorToolCard/outputs/structuredOutput/StructuredOutput";
import { isLogicGate } from "../typeCheckers";
import OutputTipTapTextFieldContainer from "./OutputTipTapTextFieldContainer";

type TBaseOutputProps = {
  tvrId?: string;
  toolOutput: UserInputDictType | null;
  toolOutputField: CustomToolOutputFieldPartial;
  showLoading?: boolean;
  showWithoutResponse?: boolean;
  onChange?: TSetFormState;
};

interface OutputComponentConfig {
  component: React.ElementType;
  props?: (props: TBaseOutputProps) => Record<string, unknown>;
}

export type TOutputType = {
  [key in CopyableField]: {
    value: (
      toolOutput: UserInputDictType | null,
      name: string
    ) => JSONValue | DallEOutputType | string;
    static: OutputComponentConfig;
    editable: OutputComponentConfig;
  };
};

export const valueFunc = (
  toolOutput: UserInputDictType | null,
  name: string
) => (toolOutput && name in toolOutput ? (toolOutput[name] as string) : "");

export const outputComponentMap: TOutputType = {
  [CopyableField.LargeText]: {
    value: valueFunc,
    static: {
      component: CopyableLargeTextField,
      props: ({ showWithoutResponse }: { showWithoutResponse?: boolean }) => {
        return {
          disabledCopy: showWithoutResponse
        };
      }
    },
    editable: {
      component: OutputTipTapTextFieldContainer,
      props: ({ toolOutput, toolOutputField, onChange }) => {
        return {
          initialValue: valueFunc(toolOutput, toolOutputField.name),
          onChange
        };
      }
    }
  },
  [CopyableField.Image]: {
    value: (toolOutput, name) =>
      (toolOutput && (toolOutput[name] as DallEOutputType)) || [],
    static: {
      component: CopyableImage
    },
    editable: {
      component: CopyableImage
    }
  },
  [CopyableField.Structured]: {
    value(toolOutput, name) {
      if (toolOutput && toolOutput[name]) {
        if (isString(toolOutput[name])) {
          const jsonValue = validateAndParseJson(toolOutput[name]);
          if (jsonValue) {
            return { [name]: jsonValue };
          }
        } else if (isObject(toolOutput[name]) || isArray(toolOutput[name])) {
          return toolOutput;
        }
        return {};
      }
      return {};
    },
    static: {
      component: StructuredOutput
    },
    editable: {
      component: StructuredOutput
    }
  }
  // Add more mappings as needed
};

export const RenderOutputField = ({
  showLoading,
  toolOutputField,
  toolOutput,
  showWithoutResponse,
  isEditable,
  onChange
}: TBaseOutputProps & {
  isEditable?: boolean;
  onChange?: (e: string | JSONContent) => void;
}) => {
  const ComponentConfig = outputComponentMap[toolOutputField.type];
  if (!ComponentConfig) return null; // Handle unknown type or skip rendering

  const OutputComponent =
    ComponentConfig[isEditable ? "editable" : "static"].component;
  // If there were prop filters or additional props to be handled, they would be processed here.

  return (
    <OutputComponent
      showLoading={showLoading}
      name={toolOutputField.name}
      value={ComponentConfig.value(toolOutput, toolOutputField.name)}
      {...ComponentConfig[isEditable ? "editable" : "static"].props?.({
        showLoading,
        toolOutputField,
        toolOutput,
        showWithoutResponse,
        onChange
      })}
    />
  );
};

function OutputField({
  toolOutput,
  toolOutputField,
  showLoading,
  showWithoutResponse,
  className,
  tvrId
}: {
  tvrId?: string;
  className?: string;
  toolOutput: UserInputDictType | null;
  toolOutputField: CustomToolOutputFieldPartial;
  showLoading?: boolean;
  showWithoutResponse?: boolean;
}) {
  if (
    toolOutput &&
    ((isLogicGate(toolOutput[toolOutputField.name]) &&
      !(toolOutput[toolOutputField.name] as LogicGate).passed) ||
      !toolOutput[toolOutputField.name])
  ) {
    return null;
  }

  return (
    <div className={className}>
      {toolOutput &&
      isHandledChatStackError(toolOutput[toolOutputField.name]) ? (
        <div className="flex align-i-center justify-space-between">
          <div className="w-100-percent">
            <div className="flex align-i-center justify-space-between">
              <InputLabel>{toolOutputField.name}</InputLabel>
            </div>
            <Typography variant="caption">
              {
                (toolOutput[toolOutputField.name] as HandledChatStackError).data
                  .message
              }
            </Typography>
          </div>
        </div>
      ) : (
        <>
          <RenderOutputField
            tvrId={tvrId}
            showLoading={showLoading}
            toolOutputField={toolOutputField}
            toolOutput={toolOutput}
            showWithoutResponse={showWithoutResponse}
          />
        </>
      )}
    </div>
  );
}

export default OutputField;
