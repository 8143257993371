import CloseIcon from "@mui/icons-material/Close";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import type { PipedreamApp } from "@toolflow/shared";
import IntegrationEntityCard from "../../../../../tools/components/searchToolsContainer/components/gridCards/IntegrationEntityCard";
import IntegrationsGrid from "../../../../../tools/components/searchToolsContainer/components/integrationsGrid/IntegrationsGrid";
import { useIntegrationSelection } from "../hooks/useIntegrationSelection";
import styles from "./UserIntegrationModal.module.css";

interface UserIntegrationModalProps {
  open: boolean;
  onClose: () => void;
  nodeId: string;
}

const UserIntegrationModal = ({
  onClose,
  nodeId
}: UserIntegrationModalProps) => {
  const { handleActionSelect } = useIntegrationSelection(nodeId);

  const handleIntegrationSelect = (integration: PipedreamApp) => {
    handleActionSelect(integration);
    onClose();
  };

  return (
    <>
      <DialogTitle className={styles["dialog-title"]}>
        Select integration
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={styles["close-button"]}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="m-b-16px"
        sx={{ height: "100vh", overflow: "hidden" }}
      >
        <IntegrationsGrid
          offset={140}
          EntityElement={({ app }: { app: PipedreamApp }) => (
            <IntegrationEntityCard
              app={app}
              onClick={() => handleIntegrationSelect(app)}
            />
          )}
        />
      </DialogContent>
    </>
  );
};

export default UserIntegrationModal;
