import React from "react";
import { Avatar, Paper, Typography } from "@mui/material";
import styles from "../blockPaperHeader.module.css";
import ReactDOM from "react-dom/client";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import BlockIconWrapper from "../../blockIcons/BlockIconWrapper";
import { toolflowTextSecondaryColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import { DeleteNodeButton } from "../../deleteNodeButton/DeleteNodeButtonContainer";
import { DarkUtilityTooltip } from "../../../../../../../../utilities/components/toolTips/DarkUtilityTooltip";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { nodeHeader } from "../../../../../../../../globalTheme/muiUtils/toolflowTypography";

const cx = parsedClassNames.bind(styles);

// Create a separate component for the drag preview
const DragPreview: React.FC<{
  label: string;
  BlockIcon: React.ElementType;
}> = ({ label, BlockIcon }) => {
  return (
    <div className={styles["width-256px"]}>
      <Paper
        className={cx(
          "border-on-select cursor-pointer border-radius-16px bg-color-right-side-content",
          styles["paper-header-container"]
        )}
      >
        <Paper
          className={cx(
            "flex align-i-center justify-space-between p-8px border-radius-16px",
            styles["paper-header"]
          )}
          variant="outlined"
        >
          <div
            className="align-i-center flex w-100-percent"
            style={{ maxWidth: "88%" }}
          >
            {BlockIcon && (
              <BlockIconWrapper
                BlockIcon={
                  <BlockIcon color={toolflowTextSecondaryColor} size={20} />
                }
              />
            )}
            <div className="m-l-8px flex flex-column">
              <Typography style={nodeHeader}>{label}</Typography>
            </div>
          </div>
          <div className="flex align-i-center">
            <DeleteNodeButton />
          </div>
        </Paper>
      </Paper>
    </div>
  );
};

function DraggableBlock({
  onDragStart,
  icon,
  label
}: {
  onDragStart?: React.DragEventHandler<HTMLDivElement>;
  icon: React.ElementType;
  label: string;
}) {
  const handleDragStart: React.DragEventHandler<HTMLDivElement> = (event) => {
    // Create a temporary container
    const container = document.createElement("div");
    container.className = "drag-preview";
    document.body.appendChild(container);

    // Render the React component into the container
    const root = ReactDOM.createRoot(container);
    root.render(<DragPreview label={label} BlockIcon={icon} />);

    // Set the drag image to our custom element
    const PREVIEW_WIDTH = 256; // Width of the preview in pixels
    event.dataTransfer.setDragImage(container, PREVIEW_WIDTH / 2, 0);

    // Clean up after a short delay
    setTimeout(() => {
      root.unmount();
    }, 0);

    // Call the original onDragStart handler
    if (onDragStart) {
      onDragStart(event);
    }
  };

  return (
    <DarkUtilityTooltip title={label} placement="right">
      <Avatar
        onDragStart={handleDragStart}
        draggable
        className={cx(
          styles["block-draggable-block"],
          "cursor-grab flex align-i-center justify-center"
        )}
      >
        <RemixWrapperIconWithTheme Icon={icon} size={20} />
      </Avatar>
    </DarkUtilityTooltip>
  );
}

export default DraggableBlock;
