import type { BrowserClient } from "@pipedream/sdk";
import type {
  CustomToolInputFieldWithOptions,
  DispatchFormState,
  UserInputDictType
} from "@toolflow/shared";
import { get } from "lodash";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { usePipedreamClient } from "../../../../../../../integrations/context/PipedreamContext";
import useGetCurrentUser from "../../../../../../../user/hooks/useGetCurrentUser";

interface IPipedreamFieldsContext {
  action: string;
  userId: string;
  pdClient: BrowserClient;
  configuredProps: Record<string, $TSAllowedAny>;
  allowAgentDeterminedValues: boolean;
  getCurrentValue: (key: string) => $TSAllowedAny;
  updateDynamicConfiguredProps: (key: string, value: $TSAllowedAny) => void;
}

const PipedreamFieldsContext = createContext<IPipedreamFieldsContext | null>(
  null
);

export function PipedreamFieldsProvider({
  action,
  useIds,
  children,
  formState,
  toolInputField,
  allowAgentDeterminedValues = false,
  setFormState
}: {
  action: string;
  useIds?: boolean;
  children: React.ReactNode;
  toolInputField: CustomToolInputFieldWithOptions;
  formState: UserInputDictType;
  allowAgentDeterminedValues?: boolean;
  setFormState: DispatchFormState;
}) {
  const propsKey = useIds ? toolInputField.id : toolInputField.name;
  const previousActionRef = useRef(action);

  const user = useGetCurrentUser();
  const { pdClient } = usePipedreamClient();

  // We want to reset the form state when the action changes
  // Otherwise the form state holds old keys in the object which
  // messes up the agent
  useEffect(() => {
    if (previousActionRef.current !== action) {
      setFormState((prev) => ({
        ...prev,
        [propsKey]: {}
      }));
    }
    previousActionRef.current = action;
  }, [action, propsKey]);

  const configuredProps = useMemo(
    () => get(formState, propsKey, {}) as Record<string, $TSAllowedAny>,
    [formState, propsKey]
  );

  function updateDynamicConfiguredProps(key: string, value: $TSAllowedAny) {
    setFormState((prev) => ({
      ...prev,
      [propsKey]: { ...(get(prev, propsKey, {}) as object), [key]: value }
    }));
  }

  function getCurrentValue(key: string) {
    return get(configuredProps, key);
  }

  if (!pdClient || !user?._id) return <></>;

  return (
    <PipedreamFieldsContext.Provider
      value={{
        action,
        userId: user._id,
        pdClient,
        configuredProps,
        allowAgentDeterminedValues,
        getCurrentValue,
        updateDynamicConfiguredProps
      }}
    >
      {children}
    </PipedreamFieldsContext.Provider>
  );
}

export function usePipedreamFieldsContext() {
  const context = useContext(PipedreamFieldsContext);
  if (!context) {
    throw new Error(
      "usePipedreamFieldsContext must be used within a PipedreamFieldsProvider"
    );
  }
  return context;
}
