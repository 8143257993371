import { BaseEdge, getSmoothStepPath } from "reactflow";
import type { EdgeProps } from "reactflow";
import StartMarker from "./StartMarker";
import DeleteEdgeButton from "./DeleteEdgeButton";
import {
  toolflowPrimaryColor,
  toolflowTextPrimaryColorLight
} from "../../../../../../globalTheme/muiUtils/appTheme";
import EndMarker from "./EndMarker";

// import "./buttonedge.css";

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  markerStart,
  data
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY: sourceY - 32,
    sourcePosition,
    targetX,
    targetY: targetY + 28,
    targetPosition,
    borderRadius: 16
  });
  const { isHovered } = data;

  return (
    <>
      <StartMarker />
      <EndMarker isHovered={isHovered} />
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        markerStart={markerStart}
        style={{
          strokeWidth: 1,
          stroke: isHovered
            ? toolflowPrimaryColor
            : toolflowTextPrimaryColorLight
        }}
        interactionWidth={19}
      />
      <DeleteEdgeButton id={id} labelX={labelX} labelY={labelY} />
    </>
  );
}

export default CustomEdge;
