import { v4 as uuidv4 } from "uuid";
import { TChipTag, TUseCaseTag } from "./hooks/useCategoryTags";
import { TCategory } from "@toolflow/shared";

export const useCaseTags: TUseCaseTag[] = [
  { value: "prospecting", selected: false, id: uuidv4() },
  { value: "call notes", selected: false, id: uuidv4() },
  { value: "content creation", selected: false, id: uuidv4() },
  { value: "research", selected: false, id: uuidv4() },
  { value: "web scraping", selected: false, id: uuidv4() },
  { value: "other", selected: false, id: uuidv4() }
];

export const categoriesTags: TChipTag[] = [
  { value: TCategory.Sales, selected: false, id: uuidv4() },
  { value: TCategory.Marketing, selected: false, id: uuidv4() },
  { value: TCategory.Growth, selected: false, id: uuidv4() }
];
