import React from "react";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
const cx = classNames;

function TopGradient({
  className = "",
  show = false
}: {
  className?: string;
  show?: boolean;
}) {
  return (
    <div
      className={cx(
        "pos-absolute w-100-percent top-gradient",
        show && "is-scrolled",
        className
      )}
    />
  );
}

export default TopGradient;
