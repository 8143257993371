import {
  BlockType,
  FieldType,
  VERSION_1_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";
import { getInitialState } from "../../initialStates";

const initialState = getInitialState(BlockType.Knowledge);

export const knowledgeBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.PromptInputV2,
      label: "Query",
      subtitle: "Set instructions for querying your knowledge base.",
      config: { fieldKey: "settings.query" }
    },
    {
      type: FieldType.DynamicInputField,
      label: "Count",
      subtitle: "Set maximum number of results to return.",
      config: {
        typeKey: "settings.count.type",
        fieldKey: "settings.count.value",
        htmlInputType: "number"
      }
    },
    {
      type: FieldType.DynamicSliderField,
      label: "Score",
      subtitle:
        "Adjust minimum confidence level of response. Higher level returns only the most accurate results.",
      config: {
        typeKey: "settings.minScore.type",
        fieldKey: "settings.minScore.value",
        defaultValue: initialState.settings.minScore.value
      }
    }
  ]
};
