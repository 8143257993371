import {
  CustomToolInputFieldTypesEnum,
  type CustomToolInputField,
  type CustomToolInputFieldWithOptions,
  type ValidatedInput
} from "@toolflow/shared";

export const firstOutputLabel = "Text Output Name";
export const firstOutputName = `${firstOutputLabel} 1`;
export const firstInputName = "User Input";

export const ABOUT_CHARACTER_LENGTH = 140;

export const inputTypeArray: CustomToolInputFieldTypesEnum[] = [
  CustomToolInputFieldTypesEnum.TEXTFIELD,
  CustomToolInputFieldTypesEnum.NUMERIC_FIELD,
  CustomToolInputFieldTypesEnum.CHECKBOX,
  CustomToolInputFieldTypesEnum.FILE_UPLOAD,
  CustomToolInputFieldTypesEnum.SELECT,
  CustomToolInputFieldTypesEnum.TAGS_INPUT,
  CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD
];

export const commonFieldTypes: CustomToolInputFieldTypesEnum[] = [
  CustomToolInputFieldTypesEnum.FILE_UPLOAD,
  CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD,
  CustomToolInputFieldTypesEnum.TAGS_INPUT,
  CustomToolInputFieldTypesEnum.CHECKBOX,
  CustomToolInputFieldTypesEnum.TEXTFIELD,
  CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD,
  CustomToolInputFieldTypesEnum.NUMERIC_FIELD,
  CustomToolInputFieldTypesEnum.SLIDER
];

export function isInputWithOptions(
  option: CustomToolInputField | ValidatedInput | null
): option is CustomToolInputFieldWithOptions {
  if (!option) return false;
  return "options" in option && Array.isArray(option.options);
}

function getDefaultInputBase(
  baseObject: Partial<CustomToolInputField>,
  oldObject: CustomToolInputField
): CustomToolInputField {
  return {
    ...baseObject,
    name: oldObject.name,
    description: oldObject.description,
    id: oldObject.id
  } as CustomToolInputField;
}

export function updateInputType<T extends CustomToolInputFieldTypesEnum>(
  type: T,
  oldField: CustomToolInputField
): CustomToolInputField {
  switch (type) {
    case CustomToolInputFieldTypesEnum.SELECT:
    case CustomToolInputFieldTypesEnum.MULTI_SELECT:
      return getDefaultInputBase({ type, options: [] }, oldField);
    case CustomToolInputFieldTypesEnum.TAGS_INPUT:
      return getDefaultInputBase({ type, settings: { tags: [] } }, oldField);
    default:
      return getDefaultInputBase({ type }, oldField);
  }
}

export const CREATE_USER_INPUT_LABEL = "Create user input";
