import {
  getStringsBetweenBraces,
  type FieldTypeConfigs,
  type FieldType,
  type ValidatedInput
} from "@toolflow/shared";
import { isString } from "lodash";

export interface AvailableTestField {
  key?: string | null;
  type: FieldType;
  fieldLabel: string;
  fieldValue?: $TSAllowedAny;
  previewLabel?: string | null;
}

export default class TestFieldSet {
  private _fields: AvailableTestField[];

  constructor(private readonly availableFields: ValidatedInput[]) {
    this._fields = [];
  }

  /**
   * Adds test fields to the current list by validating from available fields.
   */
  add({
    field,
    fieldLabel,
    fieldValue
  }: {
    field: FieldTypeConfigs;
    fieldLabel: string;
    fieldValue?: $TSAllowedAny;
  }) {
    const availableField = this.availableFields.find(
      (f) => f.name === fieldLabel || f.config?.label === fieldLabel
    );

    if (
      availableField &&
      !this._fields.some((s) => s.fieldLabel === fieldLabel)
    ) {
      this._fields.push({
        fieldLabel,
        type: field.type,
        fieldValue,
        key:
          field.config && "fieldKey" in field.config
            ? field.config.fieldKey
            : null,
        previewLabel: isString(field.label) ? field.label : null
      });
    }

    const fieldsInBraces = getStringsBetweenBraces(fieldLabel);
    if (!!fieldsInBraces.length) {
      fieldsInBraces.map((f) => this.add({ fieldLabel: f, field, fieldValue }));
    }
  }

  /**
   * Get array of unique test fields.
   */
  public toArray() {
    return [...this._fields];
  }
}
