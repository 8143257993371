import {
  VERSION_1_VALUE,
  EntityType,
  FieldType,
  type BlockFieldsConfig
} from "../../../../../types";

export const toolWithinToolBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.SelectToolForToolBuilder,
      config: {
        fieldKey: EntityType.TOOL,
        inputMapKey: "inputMap"
      }
    }
  ]
};
