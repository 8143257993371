import type {
  PipedreamApp,
  TMarketplaceTypes,
  TMarketplaceUniversalEntity
} from "@toolflow/shared";
import React, { createContext, useContext } from "react";

export type EntityCardFC = React.FC<{ entity: TMarketplaceUniversalEntity }>;
export type IntegrationCardFC = React.FC<{ app: PipedreamApp }>;
export type ElementCardFC = EntityCardFC | IntegrationCardFC;

interface SearchToolsContextType {
  EntityElement: ElementCardFC;
  entityType: TMarketplaceTypes;
}

const SearchToolsContext = createContext<SearchToolsContextType | undefined>(
  undefined
);

export function SearchToolsContextComponent({
  children,
  EntityElement,
  entityType
}: Readonly<{
  children: React.ReactNode;
  EntityElement?: ElementCardFC;
  entityType: TMarketplaceTypes;
}>) {
  if (!EntityElement || !entityType) {
    return null;
  }
  return (
    <SearchToolsContext.Provider value={{ EntityElement, entityType }}>
      {children}
    </SearchToolsContext.Provider>
  );
}

export const useSearchToolsContext = () => {
  const context = useContext(SearchToolsContext);
  if (context === undefined) {
    throw new Error(
      "useSearchToolsContext must be used within a SearchToolsProvider"
    );
  }
  return context;
};

export default SearchToolsContext;
