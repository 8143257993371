import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent
} from "@mui/material";
import {
  getLabelFromSelectOption,
  getValueFromSelectOption,
  type CustomToolInputFieldWithOptions,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import FieldWrapper from "./wrapper/FieldWrapper";

export const InputSelect = (props: {
  toolInputField: CustomToolInputFieldWithOptions;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  autofocus?: boolean;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  const {
    toolInputField,
    setFormState,
    formState,
    disabled,
    autofocus,
    useIds
  } = props;

  const key = useIds ? toolInputField.id : toolInputField.name;
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setFormState({ ...formState, [key]: value });
  };

  return (
    <FieldWrapper {...props}>
      <FormControl fullWidth className="w-100-percent" size="small">
        <Select
          name={toolInputField.name}
          autoFocus={autofocus}
          className="bg-color-white"
          id={toolInputField.id}
          value={(formState[key] as string) || ""}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {toolInputField.options?.map((option, idx) => (
            <MenuItem key={idx} value={getValueFromSelectOption(option)}>
              {getLabelFromSelectOption(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FieldWrapper>
  );
};
